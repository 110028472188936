import React from "react";
import { BundleEntity } from "components/common/entities";
import { useSelectLabel } from "../useSelectLabel";
import type { BundleModel } from "models/BundleModel";
import type { TLabelComponent } from "utils/ui/select";

const BundleLabel: TLabelComponent<BundleModel> = ({ disabled = false, value, withIcon }) => {
	const entityProps = useSelectLabel(disabled, withIcon);

	return <BundleEntity noWrap relative bundle={value} {...entityProps} />;
};

BundleLabel.isSelectLabel = true;

export { BundleLabel };
