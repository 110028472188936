import { List } from "immutable";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { MaintainersIcon } from "components/ui/Icons/MaintainersIcon";
import { TColumn, VirtualTable } from "components/ui/VirtualTable";
import { HeaderCellContent } from "components/ui/VirtualTable/components";
import { useOpenGlobalErrorModal } from "hooks/useGlobalError";
import { DirectoryGroupMaintainerModel } from "models/DirectoryGroupMaintainerModel";
import { DirectoryGroupModel } from "models/DirectoryGroupModel";
import { UserMaintainerModel } from "models/UserMaintainerModel";
import { UserModel } from "models/UserModel";
import { ActionCell } from "./components/ActionCell";
import { MaintainerCell } from "./components/MaintainerCell";
import { checkIfMaintainerIsNew } from "./utils";
import type { TMaintainer } from "components/pages/IntegrationPage/components/MaintainersSection";

type TProps = {
	maintainers: List<TMaintainer> | null;
	onUpdateMaintainers: (maintainers: List<TMaintainerModel>) => Promise<void>;
	setIsMaintainerNew: (isMaintainerNew: boolean) => void;
	setMaintainers: (maintainers: List<TMaintainer>) => void;
};

const COLUMNS_WIDTHS = {
	maintainer: "minmax(168px, 1fr)",
	action: "132px"
};

export const MaintainersTable: FC<TProps> = ({
	className,
	maintainers,
	onUpdateMaintainers,
	innerRef,
	setIsMaintainerNew,
	setMaintainers
}) => {
	const { t } = useTranslation("translation", { keyPrefix: "common.tables.maintainers" });
	const openGlobalErrorModal = useOpenGlobalErrorModal();

	const [deletingMaintainerId, setDeletingMaintainerId] = useState<string | null>(null);

	const onAdd = useCallback(
		async (maintainer: UserModel | DirectoryGroupModel) => {
			const newMaintainer =
				maintainer instanceof UserModel
					? UserMaintainerModel.fromEntity(maintainer)
					: DirectoryGroupMaintainerModel.fromEntity(maintainer);

			const maintainersToUpdate = maintainers
				?.filter((maintainer: TMaintainer): maintainer is TMaintainerModel => !checkIfMaintainerIsNew(maintainer))
				.push(newMaintainer);
			try {
				if (!maintainersToUpdate) return;
				await onUpdateMaintainers(maintainersToUpdate);
			} catch (err) {
				openGlobalErrorModal(err as Error);
			} finally {
				setIsMaintainerNew(false);
			}
		},
		[maintainers, onUpdateMaintainers, openGlobalErrorModal, setIsMaintainerNew]
	);

	const onRemove = useCallback(
		async (maintainer: TMaintainerModel) => {
			try {
				setDeletingMaintainerId(maintainer.id);
				await onUpdateMaintainers((maintainers as List<TMaintainerModel>).filter(({ id }) => id !== maintainer.id));
				setIsMaintainerNew(false);
			} catch (err) {
				openGlobalErrorModal(err as Error);
			} finally {
				setDeletingMaintainerId(null);
			}
		},
		[maintainers, onUpdateMaintainers, openGlobalErrorModal, setIsMaintainerNew]
	);

	const handleDeleteNewRow = useCallback(() => {
		setMaintainers(List(maintainers?.filter(maintainer => !checkIfMaintainerIsNew(maintainer))));
		setIsMaintainerNew(false);
	}, [maintainers, setIsMaintainerNew, setMaintainers]);

	const deleteMaintainerHandler = useCallback(
		async (maintainer: TMaintainer) => {
			if (checkIfMaintainerIsNew(maintainer)) {
				return handleDeleteNewRow();
			}
			return onRemove(maintainer);
		},
		[handleDeleteNewRow, onRemove]
	);

	const columns = useMemo(
		() =>
			[
				{
					renderCell: MaintainerCell,
					getProps: (maintainer: TMaintainer) => ({
						maintainer: maintainer,
						handleCreateMaintainer: onAdd
					}),
					header: <HeaderCellContent text={t("headers.maintainer")} icon={<MaintainersIcon />} />,
					key: "maintainer",
					width: COLUMNS_WIDTHS.maintainer,
					overflow: true
				},
				{
					renderCell: (maintainer: TMaintainer) => (
						<ActionCell
							maintainer={maintainer}
							removeMaintainer={deleteMaintainerHandler}
							isLoading={!checkIfMaintainerIsNew(maintainer) && deletingMaintainerId === maintainer.id}
						/>
					),
					header: null,
					key: "action",
					width: COLUMNS_WIDTHS.action
				}
			] as TColumn<TMaintainer>[],
		[t, onAdd, deleteMaintainerHandler, deletingMaintainerId]
	);

	const maintainersArray = useMemo(() => maintainers?.toArray() || [], [maintainers]);

	return (
		<VirtualTable
			innerRef={innerRef}
			className={className}
			columns={columns}
			totalRows={maintainersArray.length}
			rows={maintainersArray}
			emptyTableMessage={t("noMaintainers")}
		/>
	);
};
