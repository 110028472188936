import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	titlePart: {
		alignItems: "center",
		display: "flex",
		gap: "var(--spacing-x1, 4px)"
	},
	titleHeaderPart: {
		maxWidth: "100%",
		minWidth: 0
	},
	titleContainer: {
		display: "flex",
		flexGrow: 1,
		justifyContent: "space-between",
		maxWidth: "100%"
	},
	roleContainer: {
		display: "flex",
		flexDirection: "column",
		width: "100%",
		gap: "var(--spacing-x3, 12px)",
		alignItems: "flex-start",
		overflow: "auto"
	},
	childrenCount: {
		minWidth: "fit-content"
	}
});
