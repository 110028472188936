import { List } from "immutable";
import partition from "lodash/partition";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { DescribedCheckbox } from "components/common/DescribedCheckbox";
import { DurationsOverrideSelect } from "components/common/DurationsOverrideSelect";
import { VirtualizedRoleSelectInputs } from "components/common/VirtualizedRoleSelectInputs";
import { Button } from "components/ui/Button";
import { Form } from "components/ui/Form";
import { InfoIcon } from "components/ui/Icons/InfoIcon";
import { Input } from "components/ui/Input";
import { Typography } from "components/ui/legacy/Typography";
import { Select, TTargetValue } from "components/ui/Select";
import { IntegrationInheritUserLabel, UserWithoutEmailLabel } from "components/ui/selectLabels/UserLabel";
import { UserAvatarOption } from "components/ui/selectOptions/UserAvatarOption";
import { WorkflowOption } from "components/ui/selectOptions/WorkflowOption";
import { Tooltip } from "components/ui/Tooltip";
import { useApprovalAlgorithms } from "hooks/useApprovalAlgorithms";
import { useAuthenticatedUser } from "hooks/useAuthenticatedUser";
import { useCompany } from "hooks/useCompany";
import { useIntegrations } from "hooks/useIntegrations";
import { useSubscriber, type TSubscriptionFunc } from "hooks/useSubscriber";
import { useUser } from "hooks/useUser";
import { useUsersSelect } from "hooks/useUsersSelect";
import { ApprovalAlgorithmModel } from "models/ApprovalAlgorithmModel";
import { notEmpty } from "utils/comparison";
import { sortByFullName, sortByName } from "utils/sortUtils";
import { removeRedundantSpaces } from "utils/strings";
import { INHERIT, isInheritProps, type TInherit, type TLabelComponentProps } from "utils/ui/select";
import { getNameValidators, getTypeValidators } from "utils/validation/validationUtils";
import { useStyles } from "./styles";
import type { TIntegrationResourceBody, TIntegrationResourceCreateBody } from "api/integrationResources";
import type { IntegrationResourceModel } from "models/IntegrationResourceModel";
import type { UserModel } from "models/UserModel";
import type { TTicketDuration } from "utils/durationsOptions";

interface IProps {
	integrationId: string;
	resource?: IntegrationResourceModel;
	subscribeToResetForm?: TSubscriptionFunc;
	onSubmit: (resource: TIntegrationResourceBody) => Promise<void>;
}

type TOwnerOption = UserModel | TInherit;
type TWorkflowOption = ApprovalAlgorithmModel | TInherit;

export const ResourceForm: FC<IProps> = ({ integrationId, resource, onSubmit, subscribeToResetForm }) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const company = useCompany();
	const integrations = useIntegrations();
	const { addSubscription: subscribeToClearVirtualRoleForm, notify: notifyClearVirtualRoleForm } = useSubscriber();
	const integration = integrations?.get(integrationId || "");
	const [virtualizedRoleId, setVirtualizedRoleId] = useState<string | null>(null);

	const { user } = useAuthenticatedUser();
	const [ownerQuery, setOwnerQuery] = useState("");
	const { items: users, isLoading } = useUsersSelect(ownerQuery, { includeDeleted: false });

	const approvalAlgorithms = useApprovalAlgorithms();

	const getApprovalAlgorithmName = useCallback(
		(workflow: TWorkflowOption) => {
			if (workflow === INHERIT) {
				return t("pages.integration.resource.inherit");
			}
			return workflow.name;
		},
		[t]
	);

	const [loading, setLoading] = useState(false);
	const [name, setName] = useState(resource?.name || "");
	const [roleName, setRoleName] = useState(resource?.name || "");
	const [type, setType] = useState(resource?.type || "");
	const [approvalAlgorithmId, setApprovalAlgorithmId] = useState<string>(INHERIT);
	const [ownerId, setOwnerId] = useState<string | null>(
		resource?.ownerId !== undefined ? resource.ownerId : user?.id || null
	);
	const [multirole, setMultirole] = useState(false);
	const [allowedDurations, setAllowedDurations] = useState(List<TTicketDuration>());
	const [overrideAllowedDurations, setOverrideAllowedDurations] = useState(Boolean(resource?.allowedDurations));

	const [approvalAlgorithmError, setApprovalAlgorithmError] = useState("");
	const [ownerError, setOwnerError] = useState("");

	const selectedUser = useUser(ownerId);
	const integrationOwner = useUser(integration?.ownerId);

	useEffect(() => {
		if (resource?.allowedDurations) {
			setAllowedDurations(resource.allowedDurations);
		} else if (integration?.allowedDurations) {
			setAllowedDurations(integration.allowedDurations);
		} else if (company?.allowedDurations) {
			setAllowedDurations(company.allowedDurations);
		}
	}, [company, integration, resource]);

	const onAlgorithmChange = useCallback(
		(value: TWorkflowOption | null) => {
			if (value) {
				const newApprovalAlgorithm = typeof value === "string" ? null : approvalAlgorithms?.get(value.id || "");
				setApprovalAlgorithmId(newApprovalAlgorithm?.id || INHERIT);
				setApprovalAlgorithmError("");
			}
		},
		[approvalAlgorithms]
	);

	const onOwnerInputChange = useCallback((event: TTargetValue | React.ChangeEvent<HTMLInputElement>) => {
		setOwnerQuery(event.target.value);
	}, []);

	const onOwnerChange = useCallback((option: TOwnerOption | null) => {
		setOwnerId(option === INHERIT || option === null ? null : option.id);
		setOwnerError("");
	}, []);

	const clearForm = useCallback(() => {
		setName("");
		setType("");
		setVirtualizedRoleId(null);
		notifyClearVirtualRoleForm();
		setApprovalAlgorithmId(INHERIT);
		if (user?.id) {
			setOwnerId(user.id);
		}
	}, [notifyClearVirtualRoleForm, user?.id]);

	const validate = useCallback(() => {
		let valid = true;

		if (approvalAlgorithmId === null) {
			valid = false;
			setApprovalAlgorithmError(
				t("validationErrors.global.cannotBeEmpty", { field: t("modelsFields.integration.defaultApprovalAlgorithm") })
			);
		}

		if (ownerId !== null && !selectedUser) {
			valid = false;
			setOwnerError(t("validationErrors.global.cannotBeEmpty", { field: t("modelsFields.integration.owner") }));
		}

		return valid;
	}, [approvalAlgorithmId, ownerId, selectedUser, t]);

	const submit = useCallback(async () => {
		const normalizedName = removeRedundantSpaces(name);
		setLoading(true);
		if (validate()) {
			await onSubmit({
				name: normalizedName,
				integrationId,
				approvalAlgorithmId: approvalAlgorithmId === INHERIT ? undefined : approvalAlgorithmId,
				ownerUserId: ownerId,
				type: type || undefined,
				id: resource?.id || undefined,
				multirole,
				allowedDurations: overrideAllowedDurations ? allowedDurations : null,
				roles: integration?.virtual ? [{ virtualizedRoleId: virtualizedRoleId, name: roleName }] : undefined
			} as TIntegrationResourceCreateBody);
			if (!resource) {
				clearForm();
			}
		}
		setLoading(false);
	}, [
		name,
		validate,
		onSubmit,
		integrationId,
		approvalAlgorithmId,
		ownerId,
		type,
		resource,
		multirole,
		overrideAllowedDurations,
		allowedDurations,
		integration?.virtual,
		virtualizedRoleId,
		roleName,
		clearForm
	]);

	const toggleMultirole = useCallback(() => setMultirole(current => !current), []);

	const approvalAlgorithmOptions = useMemo(
		() => ([INHERIT] as TWorkflowOption[]).concat(approvalAlgorithms?.toList().toArray() || []),
		[approvalAlgorithms]
	);

	const approvalAlgorithm = useMemo(() => {
		if (approvalAlgorithmId === INHERIT) {
			return null;
		}
		return approvalAlgorithms?.get(approvalAlgorithmId);
	}, [approvalAlgorithmId, approvalAlgorithms]);

	const ownerOptions: TOwnerOption[] = useMemo(() => {
		const usersOptions = users.filter(notEmpty).toArray();
		if (!integrationOwner) return usersOptions;
		return ([INHERIT] as TOwnerOption[]).concat(usersOptions);
	}, [integrationOwner, users]);

	const nameValidators = useMemo(
		() => getNameValidators(`${t("shared.resourceTypes.resource")} ${t("shared.name")}`),
		[t]
	);
	const typeValidators = useMemo(
		() => getTypeValidators(`${t("shared.resourceTypes.resource")} ${t("pages.integration.resource.type")}`, true),
		[t]
	);

	const isValid = useMemo(() => {
		const resourceFieldsValid =
			!nameValidators.some(validate => validate(name)) && !typeValidators.some(validate => validate(type));
		const virtualFieldsValid = !integration?.virtual || Boolean(virtualizedRoleId);

		return resourceFieldsValid && virtualFieldsValid;
	}, [integration?.virtual, name, nameValidators, type, typeValidators, virtualizedRoleId]);

	const isChanged = useMemo(() => resource?.type !== type, [resource?.type, type]);

	useEffect(() => {
		if (subscribeToResetForm) {
			subscribeToResetForm("resourceForm", clearForm);
		}
	}, [subscribeToResetForm, clearForm]);

	const getFullNameLabel = useCallback(
		(option: TOwnerOption) => (option === INHERIT ? t("shared.integrationDefault") : option.fullName),
		[t]
	);
	const renderLabel = (props: TLabelComponentProps<TOwnerOption>) => {
		if (isInheritProps(props)) {
			return <IntegrationInheritUserLabel inheritUser={integrationOwner} {...props} />;
		}
		return <UserWithoutEmailLabel {...(props as TLabelComponentProps<UserModel>)} />;
	};

	const getOptionKey = useCallback((option: TOwnerOption) => (option === INHERIT ? "inherit" : option.id), []);

	if (resource) {
		return (
			<Form className={classes.resourceForm}>
				<div>
					<Form.Field>
						<Input label={t("pages.integration.resource.type")} value={type} onValueChange={setType} />
					</Form.Field>
					<Form.Actions>
						<Button
							variant="primary"
							type="submit"
							loading={loading}
							disabled={!isValid || !isChanged}
							onClick={submit}>
							{t("buttons.save")}
						</Button>
					</Form.Actions>
				</div>
			</Form>
		);
	}

	return (
		<Form className={classes.resourceForm}>
			<div>
				<Form.Field>
					<Input
						label={t("pages.integration.resource.name")}
						value={name}
						validators={nameValidators}
						onValueChange={setName}
						isRequired
					/>
				</Form.Field>
				<Form.Field className={classes.checkboxField}>
					<DescribedCheckbox
						label={t("pages.integration.resource.multirole")}
						description={t("pages.integration.resource.multiroleHelp")}
						selected={multirole}
						disabled={integration?.virtual}
						onClick={toggleMultirole}
					/>
				</Form.Field>
				<Form.Field>
					<Input
						label={t("pages.integration.resource.type")}
						value={type}
						onValueChange={setType}
						validators={typeValidators}
					/>
				</Form.Field>
				<Form.Field>
					<Select
						disabled={!approvalAlgorithms?.size}
						errors={[approvalAlgorithmError]}
						getOptionLabel={getApprovalAlgorithmName}
						isOptionEqualToValue={areOptionsEqual}
						label={t("pages.integration.defaultApprovalAlgorithm")}
						onChange={onAlgorithmChange}
						options={approvalAlgorithmOptions}
						renderOption={WorkflowOption}
						required
						sort={workflowSort}
						value={approvalAlgorithm || INHERIT}
					/>
				</Form.Field>
				<Form.Field>
					<Select
						disabled={!ownerOptions?.length}
						errors={[ownerError]}
						getOptionKey={getOptionKey}
						getOptionLabel={getFullNameLabel}
						isOptionEqualToValue={areOptionsEqual}
						label={t("pages.integration.resource.owner")}
						loading={isLoading}
						onChange={onOwnerChange}
						onInputChange={onOwnerInputChange}
						options={ownerOptions}
						renderLabel={renderLabel}
						renderOption={UserAvatarOption}
						sort={sortOwners}
						value={selectedUser || INHERIT}
					/>
				</Form.Field>
				{integration?.virtual && (
					<Form.Field>
						<div className={classes.defaultRoleForm}>
							<Input
								label={
									<Typography variant="small" className={classes.roleName}>
										<Tooltip content={t("pages.integration.resource.roleTooltip")}>
											<InfoIcon size="small" color="var(--color-blue-light)" />
										</Tooltip>
										{t("pages.integration.resource.roleName")}
									</Typography>
								}
								value={roleName}
								validators={nameValidators}
								onValueChange={setRoleName}
								isRequired
							/>
							<div className={classes.virtualizedRoleSelectInputs}>
								<VirtualizedRoleSelectInputs
									subscribeToClearForm={subscribeToClearVirtualRoleForm}
									setVirtualizedRoleId={setVirtualizedRoleId}
								/>
							</div>
						</div>
					</Form.Field>
				)}
				<Form.Field>
					<DurationsOverrideSelect
						overrideAllowedDurations={overrideAllowedDurations}
						setOverrideAllowedDurations={setOverrideAllowedDurations}
						allowedDurations={allowedDurations}
						setAllowedDurations={setAllowedDurations}
						disabled={!integration}
						type="resource"
					/>
				</Form.Field>
				<Form.Actions className={classes.actions}>
					<Button variant="primary" type="submit" size="medium" loading={loading} disabled={!isValid} onClick={submit}>
						{t("buttons.create")}
					</Button>
				</Form.Actions>
			</div>
		</Form>
	);
};

const areOptionsEqual = (option: TOwnerOption | TWorkflowOption, value: TOwnerOption | TWorkflowOption) => {
	if (option === INHERIT) return value === INHERIT;
	if (value === INHERIT) return false;
	return option.id === value.id;
};

const sortOwners = (options: TOwnerOption[]) => {
	const [inheritOwner, otherOwners] = partition(options, option => option === INHERIT);
	return (inheritOwner as TOwnerOption[]).concat(sortByFullName(otherOwners));
};

const workflowSort = (options: TWorkflowOption[]) => {
	const [inheritOption, otherOptions] = partition(options, option => option === INHERIT);
	return (inheritOption as TWorkflowOption[]).concat(sortByName(otherOptions));
};
