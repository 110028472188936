import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AllowedDurations } from "components/common/AllowedDurations";
import { AreYouSureModal } from "components/common/AreYouSureModal";
import { PageTemplate } from "components/templates/PageTemplate";
import { Block } from "components/ui/Block";
import { LoadingSpinner } from "components/ui/LoadingSpinner";
import { useCompanyContext } from "context/companyContext";
import { useIdentityProvidersContext } from "context/identityProvidersContext";
import { useOpenGlobalErrorModal } from "hooks/useGlobalError";
import { TTicketDuration } from "utils/durationsOptions";
import { AccessReviewImmediateRevoke } from "./components/AccessReviewImmediateRevoke";
import { AdminsManagement } from "./components/AdminsManagement";
import { AgentTokensList } from "./components/AgentTokens";
import { ApiTokens } from "./components/ApiTokens";
import { AuditLogsWebhooks } from "./components/AuditLogsWebhooks";
import { Forwards } from "./components/Forwards";
import { IdentityProviders } from "./components/IdentityProviders";
import { Integrations } from "./components/Integrations";
import { PendingAccessReportsReminder } from "./components/PendingAccessReportsReminder";
import { PendingRequestsReminder } from "./components/PendingRequestsReminder";
import { Timezone } from "./components/Timezone";

export interface IAreYouSureModalProps {
	content?: string;
	title?: string;
	onAction: () => void | Promise<void>;
}

export const SettingsPage: FC = ({ className }) => {
	const { t } = useTranslation();
	const [areYouSureModalProps, setAreYouSureModalProps] = useState<IAreYouSureModalProps | null>(null);

	const {
		state: { isLoading: isLoadingIdentityProviders }
	} = useIdentityProvidersContext();

	const {
		state: { company },
		actions: { toggleAllowedDuration, loadCompany }
	} = useCompanyContext();

	useEffect(() => {
		void loadCompany();
	}, [loadCompany]);

	const openAreYouSureModal = useCallback((props: IAreYouSureModalProps) => setAreYouSureModalProps(props), []);
	const closeAreYouSureModal = useCallback(() => setAreYouSureModalProps(null), []);
	const handleError = useOpenGlobalErrorModal();
	const onToggle = useCallback(
		async (duration: TTicketDuration) => {
			try {
				await toggleAllowedDuration(duration);
			} catch (error) {
				handleError(error as Error);
			}
		},
		[handleError, toggleAllowedDuration]
	);

	const onAreYouSureModalAction = useCallback(async () => {
		if (areYouSureModalProps?.onAction) {
			await areYouSureModalProps.onAction();
		}
		closeAreYouSureModal();
	}, [areYouSureModalProps, closeAreYouSureModal]);

	if (!company) {
		return <div />;
	}

	const hasNotifications = company.integratedToSlack || company.integratedToTeams;

	return (
		<PageTemplate className={className}>
			<AreYouSureModal
				isOpen={Boolean(areYouSureModalProps?.onAction)}
				onClose={closeAreYouSureModal}
				title={areYouSureModalProps?.title}
				content={areYouSureModalProps?.content}
				onAction={onAreYouSureModalAction}
			/>
			<PageTemplate.Title>{t("pages.settings.title")}</PageTemplate.Title>
			<PageTemplate.Content>
				{!isLoadingIdentityProviders ? (
					<>
						<Block>
							<Integrations company={company} />
						</Block>
						<Block>
							<IdentityProviders openAreYouSureModal={openAreYouSureModal} />
						</Block>
					</>
				) : (
					<LoadingSpinner />
				)}

				<Block>
					<ApiTokens openAreYouSureModal={openAreYouSureModal} />
				</Block>
				<Block>
					<AgentTokensList openAreYouSureModal={openAreYouSureModal} />
				</Block>
				<Block>
					<AdminsManagement />
				</Block>
				<Block>
					<AllowedDurations
						onToggle={onToggle}
						selectedDurations={company.allowedDurations}
						title={t("pages.settings.allowedDurations.title")}
					/>
				</Block>
				<Block>
					<AuditLogsWebhooks openAreYouSureModal={openAreYouSureModal} />
				</Block>
				<Block>
					<Forwards type="accessRequest" />
				</Block>
				{company.enableAccessReviews && (
					<Block>
						<Forwards type="accessReview" />
					</Block>
				)}
				<Block>
					<Timezone />
				</Block>
				{company.enableAccessReviews && (
					<Block>
						<AccessReviewImmediateRevoke />
					</Block>
				)}
				{hasNotifications && (
					<>
						<Block>
							<PendingRequestsReminder />
						</Block>
						{company.enableAccessReviews && (
							<Block>
								<PendingAccessReportsReminder />
							</Block>
						)}
					</>
				)}
			</PageTemplate.Content>
		</PageTemplate>
	);
};
