import classNames from "classnames";
import React, { useMemo } from "react";
import { ResourceEntity } from "components/common/entities";
import { ResourceDetailsTooltip, type TResourceDetailsTooltipProps } from "components/common/ResourceDetailsTooltip";
import { IconPrefix } from "components/ui/IconPrefix";
import { ResourcesIcon } from "components/ui/Icons/ResourcesIcon";
import { IntegrationResourceModel } from "models/IntegrationResourceModel";
import { ROLE_BAR_RESOURCE_MIN_WIDTH_PX, useStyles } from "./styles";
import { MAX_CHIPS_BEFORE_HIDDEN_CHIP, RoleBarTag } from "../RoleBarTag";
import type { Require } from "types/utilTypes";

export const ROLE_BAR_RESOURCE_WIDTH = `minmax(${ROLE_BAR_RESOURCE_MIN_WIDTH_PX}px, 1fr)`;

type TProps = TResourceDetailsTooltipProps & {
	tags?: string[];
	withoutTooltip?: boolean;
	linkable?: boolean;
	resource?: Require<IntegrationResourceModel, "integrationId">;
};

export const RoleBarResource: FC<TProps> = ({
	className,
	innerRef,
	tags,
	withoutTooltip,
	linkable = false,
	resource,
	...restProps
}) => {
	const classes = useStyles();

	const content = useMemo(() => {
		if ("amount" in restProps) {
			if (withoutTooltip) {
				return (
					<IconPrefix
						Icon={ResourcesIcon}
						content={restProps.amount || 0}
						contentClassName={classes.iconPrefixContent}
					/>
				);
			}
			return (
				<ResourceDetailsTooltip {...restProps}>
					<IconPrefix
						Icon={ResourcesIcon}
						content={restProps.amount || 0}
						contentClassName={classes.iconPrefixContent}
					/>
				</ResourceDetailsTooltip>
			);
		}
		const currentResource = resource || {
			name: restProps.name,
			euid: restProps.euid,
			description: restProps.description,
			type: restProps.type,
			isDeleted: restProps.isDeleted
		};

		const resourceProps =
			linkable && "integrationId" in currentResource
				? { variant: "link" as const, resource: currentResource }
				: {
						variant: undefined,
						resource: currentResource
					};

		return <ResourceEntity relative noWrap withIcon size="medium" {...resourceProps} />;
	}, [restProps, resource, linkable, withoutTooltip, classes.iconPrefixContent]);
	return (
		<div
			className={classNames(
				classes.container,
				{
					[classes.withChips]: !!tags?.length,
					[classes.withHiddenChip]: tags && tags.length > MAX_CHIPS_BEFORE_HIDDEN_CHIP
				},
				className
			)}
			ref={innerRef}>
			{content}
			{tags ? <RoleBarTag tags={tags} className={classes.chips} /> : null}
		</div>
	);
};
