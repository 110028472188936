import { List } from "immutable";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { TIntegrationUpdateData, updateIntegrationImage as apiUpdateIntegrationImage } from "api/integrations";
import { AreYouSureModal } from "components/common/AreYouSureModal";
import { DescribedCheckbox } from "components/common/DescribedCheckbox";
import { DurationsOverrideSelect } from "components/common/DurationsOverrideSelect";
import { ResourceHeader } from "components/common/ResourceHeader";
import { SettingsPopup, type TSettingsPopupRef } from "components/common/SettingsPopup";
import { SyncButton } from "components/common/SyncButton";
import { ConfigurationEdit } from "components/pages/CreateIntegrationPage/components/ConfigurationEdit";
import { OwnerSelect, type TOwnerOption } from "components/pages/CreateIntegrationPage/components/OwnerSelect";
import { Button } from "components/ui/Button";
import { Divider } from "components/ui/Divider";
import { Form } from "components/ui/Form";
import { AddIcon } from "components/ui/Icons/AddIcon";
import { useApplications } from "hooks/useApplications";
import { useChangeConfig } from "hooks/useChangeConfig";
import { useCompany } from "hooks/useCompany";
import { useOpenGlobalErrorModal } from "hooks/useGlobalError";
import { useImageUpload } from "hooks/useImageUpload";
import { useIsOpenState } from "hooks/useIsOpenState";
import { IntegrationModel } from "models/IntegrationModel";
import { UserModel } from "models/UserModel";
import { useStyles } from "./styles";
import type { TTicketDuration } from "utils/durationsOptions";

interface IProps {
	onSave: (data: TIntegrationUpdateData) => Promise<void>;
	onRename?: (name: string) => Promise<void>;
	onDeleteButtonClick?: () => void;
	sync?: {
		onClick: () => Promise<void>;
		nextSyncTime: Date | null;
	};
	lastSync?: Date | null;
	integration: IntegrationModel;
	enableOverrideDurations?: boolean;
	onInitialImageChange?: (integration: IntegrationModel) => Promise<void>;
}

export const IntegrationHeader: FC<IProps> = ({
	onRename,
	onDeleteButtonClick,
	sync,
	integration,
	enableOverrideDurations,
	lastSync,
	onSave: propOnSave,
	onInitialImageChange
}) => {
	const classes = useStyles();
	const company = useCompany();
	const navigate = useNavigate();
	const { t } = useTranslation();
	const openGlobalErrorModal = useOpenGlobalErrorModal();
	const updateIntegrationImage = useCallback(
		async (file: File) => {
			await apiUpdateIntegrationImage(integration.id, file);
			if (integration.imageUrl?.includes("applications") && onInitialImageChange) {
				await onInitialImageChange(integration.set("imageUrl", `/api/v1/integrations/${integration.id}/image`));
			}
		},
		[integration, onInitialImageChange]
	);
	const { fileInputRef, imageTimestamp, handleButtonClick, onFileSelection } = useImageUpload(updateIntegrationImage);
	const areYouSureModal = useIsOpenState();
	const [applyDefaultAllowsRequestsToAll, setApplyDefaultAllowsRequestsToAll] = useState(false);
	const [ownerId, setOwnerId] = useState(integration.ownerId);
	const [allowedDurations, setAllowedDurations] = useState(List<TTicketDuration>());
	const [overrideAllowedDurations, setOverrideAllowedDurations] = useState(Boolean(integration.allowedDurations));
	const [allowsRequests, setAllowsRequests] = useState(integration.allowsRequests || false);
	const [canCreateActors, setCanCreateActors] = useState(integration.canCreateActors || false);
	const [canEditPermissions, setCanEditPermissions] = useState(integration.canEditPermissions || false);
	const [notifyAboutExternalPermissions, setNotifyAboutExternalPermissions] = useState(
		integration.notifyAboutExternalPermissions || false
	);
	const [readonly, setIsReadonly] = useState(integration?.readonly || false);
	const [configEdit, setConfigEdit] = useState(false);
	const [defaultAllowsRequests, setDefaultAllowsRequests] = useState(integration.defaultAllowsRequests);
	const [autoAssignRecommendedResourceMaintainers, setUseRecommendedResourceMaintainers] = useState(
		integration.autoAssignRecommendedResourceMaintainers
	);
	const [autoAssignRecommendedResourceOwner, setUseRecommendedResourceOwner] = useState(
		integration.autoAssignRecommendedResourceOwner
	);
	const applications = useApplications();
	const application = useMemo(() => {
		if (integration.application) return integration.application;
		if (integration.applicationId && applications?.has(integration.applicationId)) {
			return applications.get(integration.applicationId)!;
		}
		return null;
	}, [applications, integration.application, integration.applicationId]);

	const changeConfig = useChangeConfig(application, integration.agentTokenId || undefined, integration.id || undefined);

	useEffect(() => {
		if (integration.allowedDurations) {
			setAllowedDurations(integration.allowedDurations);
		} else if (company?.allowedDurations) {
			setAllowedDurations(company.allowedDurations);
		}
	}, [company, integration]);

	const toggleAllowsRequests = useCallback(() => setAllowsRequests(curr => !curr), []);

	const toggleCanEditPermissions = useCallback(
		() =>
			setCanEditPermissions(curr => {
				if (curr) {
					setCanCreateActors(true);
					setIsReadonly(false);
				}
				return !curr;
			}),
		[]
	);

	const toggleConfigurationUpdate = useCallback(() => setConfigEdit(curr => !curr), []);

	const toggleCreateActors = useCallback(() => setCanCreateActors(curr => !curr), []);

	const toggleNotifyAboutExternalPermissions = useCallback(() => setNotifyAboutExternalPermissions(curr => !curr), []);

	const toggleReadonly = useCallback(() => {
		setIsReadonly(curr => {
			if (!curr) {
				setCanCreateActors(false);
			} else if (application?.canCreateActors === "required") {
				setCanCreateActors(true);
			}
			return !curr;
		});
	}, [application]);
	const toggleDefaultAllowsRequests = useCallback(() => setDefaultAllowsRequests(curr => !curr), []);

	const toggleApplyDefaultAllowsRequestsToAll = useCallback(
		() => setApplyDefaultAllowsRequestsToAll(curr => !curr),
		[]
	);

	const toggleUseRecommendedResourceMaintainers = useCallback(
		() => setUseRecommendedResourceMaintainers(curr => !curr),
		[]
	);
	const toggleUseRecommendedResourceOwner = useCallback(() => setUseRecommendedResourceOwner(curr => !curr), []);

	const onClose = useCallback(() => {
		setAllowsRequests(integration.allowsRequests);
		setCanCreateActors(integration.canCreateActors);
		setCanEditPermissions(integration.canEditPermissions);
		setIsReadonly(integration.readonly);
		setOverrideAllowedDurations(Boolean(integration.allowedDurations));
		setOwnerId(integration.ownerId);
		if (integration.allowedDurations) {
			setAllowedDurations(integration.allowedDurations);
		} else if (company?.allowedDurations) {
			setAllowedDurations(company.allowedDurations);
		}
		setDefaultAllowsRequests(integration.defaultAllowsRequests);
		setUseRecommendedResourceMaintainers(integration.autoAssignRecommendedResourceMaintainers);
		setUseRecommendedResourceOwner(integration.autoAssignRecommendedResourceOwner);
	}, [company?.allowedDurations, integration]);

	const onSave = useCallback(
		async (shouldConfirmNewConnection = true) => {
			const { checkConfig, config, validConfig, configState } = changeConfig;
			if (configEdit) {
				if (validConfig === "invalid") {
					return false;
				}
				if (validConfig === "unchecked") {
					const isValid = await checkConfig();
					if (!isValid) {
						return false;
					}
				}

				if (shouldConfirmNewConnection) {
					areYouSureModal.open();
					return;
				}
			}

			return propOnSave({
				allowedDurations: overrideAllowedDurations ? allowedDurations : null,
				allowsRequests,
				autoAssignRecommendedResourceMaintainers,
				autoAssignRecommendedResourceOwner,
				canCreateActors,
				canEditPermissions,
				defaultAllowsRequests: {
					value: defaultAllowsRequests,
					updateAllResources:
						applyDefaultAllowsRequestsToAll && defaultAllowsRequests !== integration.defaultAllowsRequests
				},
				id: integration.id,
				notifyAboutExternalPermissions,
				ownerUserId: ownerId,
				readonly,
				...(configEdit ? { configuration: { ...config, configurationSchemaName: configState?.label } } : {})
			});
		},
		[
			allowedDurations,
			allowsRequests,
			applyDefaultAllowsRequestsToAll,
			areYouSureModal,
			autoAssignRecommendedResourceMaintainers,
			autoAssignRecommendedResourceOwner,
			canCreateActors,
			canEditPermissions,
			changeConfig,
			configEdit,
			defaultAllowsRequests,
			integration.defaultAllowsRequests,
			integration.id,
			notifyAboutExternalPermissions,
			overrideAllowedDurations,
			ownerId,
			propOnSave,
			readonly
		]
	);

	const setOwner = useCallback((owner: TOwnerOption) => {
		if (owner instanceof UserModel) {
			setOwnerId(owner.id);
		}
	}, []);

	const onConfirmNewConnection = useCallback(async () => {
		try {
			await onSave(false);
		} catch (error) {
			openGlobalErrorModal(error as Error);
		} finally {
			areYouSureModal.close();
		}
	}, [areYouSureModal, onSave, openGlobalErrorModal]);

	const onAddRule = useCallback(() => {
		navigate("/rules/create", {
			state: { integrationId: integration.id }
		});
	}, [integration.id, navigate]);

	const handleDelete = useCallback(() => {
		settingPopupRef?.current?.closePopup();
		onDeleteButtonClick?.();
	}, [onDeleteButtonClick]);

	const settingPopupRef = useRef<TSettingsPopupRef>(null);

	return (
		<div className={classes.header}>
			<div className={classes.resourceHeaderBlock}>
				<AreYouSureModal
					closeLabel={t("pages.integration.newConnectionAreYouSureModal.close")}
					actionLabel={t("pages.integration.newConnectionAreYouSureModal.confirm")}
					content={t("pages.integration.newConnectionAreYouSureModal.content")}
					title={t("pages.integration.newConnectionAreYouSureModal.title")}
					isOpen={areYouSureModal.isOpen}
					onClose={areYouSureModal.close}
					onAction={onConfirmNewConnection}
				/>
				<div className={classes.resourceHeaderSection}>
					<ResourceHeader
						name={integration.name}
						imageUrl={integration.imageUrl}
						imageTimestamp={imageTimestamp}
						onRename={onRename}
						resourceType={"integration"}
						lastSync={lastSync}
						ownerId={integration.ownerId || undefined}
						showOwner
						applicationName={integration.application?.name}
						handleButtonClick={handleButtonClick}
						onFileSelection={onFileSelection}
						fileInputRef={fileInputRef}
					/>
				</div>
				<div className={classes.resourceHeaderBlock}>
					{sync && !integration.adapterless && (
						<SyncButton nextSyncTime={sync.nextSyncTime} propsOnClick={sync.onClick} withIcon />
					)}
					<Button variant="secondary" size="medium" prefix={<AddIcon />} onClick={onAddRule}>
						{t("shared.addRule")}
					</Button>

					<SettingsPopup innerRef={settingPopupRef} onClose={onClose} onSave={onSave} className={classes.settingsModal}>
						<Form.Field>
							<OwnerSelect ownerId={ownerId} setOwner={setOwner} />
						</Form.Field>
						{integration && (
							<>
								<Form.Field>
									<DescribedCheckbox
										label={t("pages.integration.readonlyHeader")}
										selected={readonly}
										description={t("pages.integration.readonlyDescription")}
										onClick={toggleReadonly}
										disabled={application?.adapterless || !canEditPermissions}
										descriptionClassName={classes.description}
									/>
								</Form.Field>
								<Form.Field>
									<DescribedCheckbox
										label={t("pages.integration.canEditPermissions.label")}
										onClick={toggleCanEditPermissions}
										selected={canEditPermissions}
										disabled={application?.canEditPermissions !== "optional" || readonly}
										description={t("pages.integration.canEditPermissions.description")}
										descriptionClassName={classes.description}
									/>
								</Form.Field>
								<Form.Field>
									<DescribedCheckbox
										label={t("pages.integration.canCreateActors.label")}
										onClick={toggleCreateActors}
										selected={canCreateActors}
										disabled={application?.canCreateActors !== "optional" || !canEditPermissions || readonly}
										description={t("pages.integration.canCreateActors.description")}
										descriptionClassName={classes.description}
									/>
								</Form.Field>
								<Form.Field>
									<DescribedCheckbox
										label={t("pages.integration.requestableHeader")}
										selected={allowsRequests}
										description={t("pages.integration.requestableDescription")}
										onClick={toggleAllowsRequests}
										descriptionClassName={classes.description}
									/>
								</Form.Field>
								<Form.Field>
									<DescribedCheckbox
										label={t("pages.integration.defaultAllowsRequests.label")}
										onClick={toggleDefaultAllowsRequests}
										disabled={false}
										selected={defaultAllowsRequests}
										description={t("pages.integration.defaultAllowsRequests.description")}
										descriptionClassName={classes.description}
									/>
									{integration.defaultAllowsRequests !== defaultAllowsRequests && (
										<DescribedCheckbox
											label={t("pages.integration.defaultAllowsRequests.applyToAll")}
											onClick={toggleApplyDefaultAllowsRequestsToAll}
											selected={applyDefaultAllowsRequestsToAll}
											descriptionClassName={classes.description}
										/>
									)}
								</Form.Field>
								<Form.Field>
									<DescribedCheckbox
										label={t("pages.integration.autoAssignRecommendedResourceOwner")}
										description={t("pages.integration.autoAssignRecommendedResourceOwnerHelp")}
										selected={autoAssignRecommendedResourceOwner}
										onClick={toggleUseRecommendedResourceOwner}
										descriptionClassName={classes.description}
										disabled={application?.virtual}
									/>
								</Form.Field>
								<Form.Field>
									<DescribedCheckbox
										label={t("pages.integration.autoAssignRecommendedResourceMaintainers")}
										description={t("pages.integration.autoAssignRecommendedResourceMaintainersHelp")}
										selected={autoAssignRecommendedResourceMaintainers}
										onClick={toggleUseRecommendedResourceMaintainers}
										descriptionClassName={classes.description}
										disabled={application?.virtual}
									/>
								</Form.Field>
								<Form.Field>
									<DescribedCheckbox
										label={t("pages.integration.notifyAboutExternalPermissions")}
										description={t("pages.integration.notifyAboutExternalPermissionsHelp")}
										selected={notifyAboutExternalPermissions}
										onClick={toggleNotifyAboutExternalPermissions}
										descriptionClassName={classes.description}
										disabled={integration.adapterless}
									/>
								</Form.Field>
								{enableOverrideDurations && company && (
									<Form.Field>
										<DurationsOverrideSelect
											overrideAllowedDurations={overrideAllowedDurations}
											setOverrideAllowedDurations={setOverrideAllowedDurations}
											allowedDurations={allowedDurations}
											setAllowedDurations={setAllowedDurations}
											disabled={Boolean(!company)}
											type="integration"
										/>
									</Form.Field>
								)}
								{!integration.adapterless && (
									<>
										<Form.Field className={classes.connectionField}>
											<DescribedCheckbox
												label={t("pages.integration.connectionHeader")}
												onClick={toggleConfigurationUpdate}
												selected={configEdit}
												description={t("pages.integration.updateConnection")}
											/>
										</Form.Field>
										{configEdit && <ConfigurationEdit changeConfig={changeConfig} withHeader={false} />}
									</>
								)}
							</>
						)}
						{onDeleteButtonClick && (
							<>
								<Divider horizontal />
								<div className={classes.deleteButtonContainer}>
									<Button size="medium" variant="secondary" onClick={handleDelete}>
										{t("pages.integration.deleteIntegration")}
									</Button>
								</div>
							</>
						)}
					</SettingsPopup>
				</div>
			</div>
		</div>
	);
};
