import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	container: {
		color: "var(--color-black)",
		background: "var(--accordion-background-color)",
		transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
		borderRadius: "var(--border-radius-semi-rounded)",
		"&$disabled": {
			backgroundColor: "rgba(0,0,0, 0.12)",
			opacity: "0.48"
		}
	},
	accordion: {
		height: "fit-content",
		"&$accordion$accordion": {
			borderRadius: 0,
			border: "none",
			boxShadow: "none",
			"&$noMargin": {
				margin: 0
			},
			"&$rounded": {
				borderRadius: "var(--border-radius-semi-rounded)"
			},
			"&:before": {
				backgroundColor: "transparent"
			}
		}
	},
	title: {
		display: "flex",
		width: "100%"
	},
	rounded: {},
	noMargin: {},
	disabled: {}
});
