import React from "react";
import { useTranslation } from "react-i18next";
import { RoleBarTooltip } from "components/common/BasicRoleBar";
import { StaticChip } from "components/ui/chips/StaticChip";
import { DirectAccessIcon } from "components/ui/Icons/DirectAccessIcon";
import { GiveAccessToIcon } from "components/ui/Icons/GiveAccessToIcon";
import { IndirectAccessIcon } from "components/ui/Icons/IndirectAccessIcon";
import { useStyles } from "./styles";
import type { IEntitlement } from "components/pages/IntegrationResourcePage/utils";

type TProps = {
	entitlement: IEntitlement;
};

export const SourceCell: FC<TProps> = ({ entitlement }) => {
	const { t } = useTranslation();
	const classes = useStyles();

	const chip = (
		<StaticChip
			PrefixIcon={entitlement.source ? IndirectAccessIcon : DirectAccessIcon}
			variant={entitlement.source ? "regular" : "white"}>
			{entitlement.source ? t(`common.tables.permissions.indirect`) : t(`common.tables.permissions.direct`)}
		</StaticChip>
	);

	return (
		<div className={classes.centeredCellContent}>
			{entitlement.source ? (
				<RoleBarTooltip
					noInteractions
					linkable
					role={entitlement.source}
					tooltipTitle={t("pages.integrationResource.givingPermissionAutomatically")}
					Icon={GiveAccessToIcon}>
					{chip}
				</RoleBarTooltip>
			) : (
				chip
			)}
		</div>
	);
};
