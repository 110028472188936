import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	header: {
		padding: "0.75em 1.5em",
		borderRadius: "8px 8px 0 0",
		background: "var(--color-almost-white)"
	},
	resourceHeaderBlock: {
		display: "flex",
		alignItems: "flex-end",
		justifyContent: "space-between",
		gap: "0.5rem",
		flexWrap: "wrap"
	},
	resourceHeaderSection: {
		display: "inline-flex",
		flexDirection: "column",
		alignItems: "center",
		flexWrap: "wrap",
		minWidth: 0,
		flex: 1
	},
	settingsModal: {
		maxWidth: "50rem"
	},
	deleteButtonContainer: {
		marginTop: "var(--spacing-x4, 16px)"
	},
	deleteButtonDivider: {
		marginTop: "var(--spacing-x4, 16px)"
	}
});
