import { List } from "immutable";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { FixedHeightCommonAccordion } from "components/common/FixedHeightCommonAccordion";
import { IconPrefix } from "components/ui/IconPrefix";
import { PermissionsIcon } from "components/ui/Icons/PermissionsIcon";
import { Typography } from "components/ui/Typography";
import { PermissionsTable } from "./components/PermissionsTable";
import { useStyles } from "./styles";
import type { IEntitlementsByUser } from "../../utils";

const MAX_ROWS = 15;
const MIN_ROWS = 5;

const DEFAULT_ROW_HEIGHT_PX = 60;
const DEFAULT_ACCORDION_HEIGHT_PX = 152;

interface IProps {
	entitlements: List<IEntitlementsByUser>;
	isLoading: boolean;
}

export const PermissionsSection: FC<IProps> = ({ entitlements, isLoading }) => {
	const { t } = useTranslation();
	const classes = useStyles();

	const userEntitlementData = useMemo(() => {
		return entitlements.flatMap(({ userId, entitlements }) =>
			entitlements.map(entitlement => ({
				userId,
				entitlement
			}))
		);
	}, [entitlements]);

	const titleContent = useMemo(
		() => (
			<div className={classes.titleContainer}>
				<div className={classes.titlePart}>
					<IconPrefix
						Icon={PermissionsIcon}
						content={t("pages.integrationResource.permissions")}
						className={classes.icon}
					/>
					<Typography variant="body_reg">{`( ${t("number", { value: userEntitlementData.size || 0 })} )`}</Typography>
				</div>
			</div>
		),
		[classes.icon, classes.titleContainer, classes.titlePart, t, userEntitlementData.size]
	);

	return (
		<FixedHeightCommonAccordion
			titleContent={titleContent}
			maxRows={MAX_ROWS}
			totalResults={userEntitlementData.size}
			minRows={MIN_ROWS}
			defaultRowHeight={DEFAULT_ROW_HEIGHT_PX}
			defaultAccordionHeight={DEFAULT_ACCORDION_HEIGHT_PX}>
			<PermissionsTable entitlementsByUser={userEntitlementData} isLoading={isLoading} />
		</FixedHeightCommonAccordion>
	);
};
