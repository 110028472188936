import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	actions: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-end"
	},
	checkboxField: {
		alignItems: "center",
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between"
	},
	roleForm: {
		display: "flex",
		flexDirection: "column",
		height: "fit-content",
		gap: "var(--spacing-x3, 12px)",
		padding: "0",
		width: "480px"
	},
	virtualizedRoleField: {
		backgroundColor: "var(--surface-content-contrast-background-color)",
		borderRadius: "var(--border-radius-semi-rounded)",
		display: "flex",
		flexDirection: "column",
		gap: "var(--spacing-x2, 8px)",
		padding: "var(--spacing-x2, 8px)"
	}
});
