import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { notEmpty } from "utils/comparison";
import { cleanString, getLabel, sortOptions } from "utils/ui/select";
import { useControlled } from "./useControlled";
import { useOnClickOutside } from "./useOnClickOutside";
import { useTooltip } from "./useTooltip";
import type { ISelectItemListProps, TGrouping } from "components/ui/SelectItemList";

export type TTargetValue = { target: { value: string }; [key: string]: unknown };
export type TInputChangeEvent = React.ChangeEvent<HTMLInputElement> | TTargetValue;

type TSelectOptionsParams<Option> = {
	shouldLog?: boolean;
	options: Option[];
	filterQuery: string;
	filter?: ((options: Option[], inputValue: string) => Option[]) | null;
	getOptionLabel: (option: Option) => string;
	getQuery?: (value: string) => string;
	groupBy?: ISelectItemListProps<Option>["groupBy"];
	limit: number;
	sort?: ((options: Option[]) => Option[] | null) | null;
};

const defaultGetQuery = (value: string) => value;
export const defaultValueEquality = <Value>(newValue: Value | null | undefined, value: Value | null | undefined) =>
	newValue === value;
const defaultHandleOptionSelected = <Value, Option>(value: Value | null | undefined, option: Option) =>
	option as unknown as Value;

const getGroupingOrder = (grouping: TGrouping) => (typeof grouping === "string" ? grouping : grouping.order);
const getGroupingKey = (grouping: TGrouping) => (typeof grouping === "string" ? grouping : grouping.key);

export const useSelectOptions = <Value>({
	options: propOptions,
	filter,
	filterQuery,
	getOptionLabel,
	getQuery = defaultGetQuery,
	groupBy,
	limit,
	sort
}: TSelectOptionsParams<Value>) => {
	const options = useMemo(() => {
		const newOptions = sort !== null ? ((sort ?? sortOptions)(propOptions) as Value[]) || propOptions : propOptions;

		if (groupBy) {
			return newOptions.sort((a, b) => {
				if (!groupBy(a)) return -1;
				if (!groupBy(b)) return 1;
				return getGroupingOrder(groupBy(a)) > getGroupingOrder(groupBy(b)) ? 1 : -1;
			});
		}

		return newOptions;
	}, [groupBy, sort, propOptions]);

	const filteredOptions = useMemo(() => {
		if (filter === null) {
			return options.slice(0, limit);
		}
		const cleanFilterQuery = cleanString(getQuery(filterQuery));

		if (groupBy && cleanFilterQuery) {
			return options
				.filter(
					option =>
						cleanString(getGroupingKey(groupBy(option))).includes(cleanFilterQuery) ||
						cleanString(getOptionLabel(option)).includes(cleanFilterQuery)
				)
				.slice(0, limit);
		}

		if (cleanFilterQuery.length > 0) {
			if (filter) {
				return filter(options, cleanFilterQuery).slice(0, limit);
			}
			return options.filter(option => cleanString(getOptionLabel(option)).includes(cleanFilterQuery)).slice(0, limit);
		}
		return options.slice(0, limit);
	}, [filter, getQuery, filterQuery, groupBy, options, limit, getOptionLabel]);

	return filteredOptions;
};

type TSelectParams<Value, Option> = {
	defaultValue?: Value | null;
	innerRef?: TProps["innerRef"];
	value?: Value | null;
	debug?: boolean;
	equality?: (newValue: Value | null | undefined, value: Value | null | undefined) => boolean;
	handleOptionSelected?: (value: Value | null | undefined, option: Option) => Value;
	onChange?: (value: Value | null) => unknown;
	onFocus?: (isFocused: boolean) => unknown;
	userErrors: string[] | null;
	onInputChange?: (event: TInputChangeEvent) => unknown;
	onOpenOptions?: () => void;
	onSelectItem?: (option: Option) => unknown;
	validators?: ((value: string) => string | null)[];
} & Omit<TSelectOptionsParams<Option>, "filterQuery">;

export const useSelect = <Value, Option = Value>({
	defaultValue = null,
	value: propValue,
	limit,
	options,
	debug,
	innerRef,
	handleOptionSelected = defaultHandleOptionSelected,
	equality = defaultValueEquality,
	filter,
	onChange: propOnChange,
	onFocus,
	onOpenOptions,
	onSelectItem,
	getOptionLabel: propGetOptionLabel,
	getQuery,
	groupBy,
	sort,
	userErrors,
	onInputChange: propOnInputChange,
	validators
}: TSelectParams<Value, Option>) => {
	const [highlightedIndex, setHighlightedIndex] = useState(-1);
	const [filterQuery, setFilterQuery] = useState("");
	const [open, setOpen] = useState(false);
	const [inputValue, setInputValue] = useState("");
	const [value, setValue] = useControlled<Value | null>({ controlled: propValue, default: defaultValue });
	const inputRef = useRef<HTMLInputElement>(null);
	const selectFieldRefFallback = useRef<HTMLDivElement>(null);
	const selectFieldRef = innerRef || selectFieldRefFallback;
	const clearTriggerRef = useRef<HTMLElement>(null);
	const [errorMessages, setErrorMessages] = useControlled<string[] | null | undefined>({
		controlled: userErrors,
		default: undefined
	});

	const { visible, setTooltipRef, getTooltipProps, setTriggerRef, triggerRef, tooltipRef } = useTooltip({
		visible: open,
		offset: [0, 6],
		placement: "bottom-end",
		popperOptions: {
			modifiers: []
		}
	});

	useEffect(() => {
		if (!triggerRef) return;
		const alterTarget = (event: MouseEvent) => {
			// the underlined element that triggers the event is detached by the time we check the click outside which causes immediate close
			if (event.target === triggerRef || clearTriggerRef.current?.contains(event.target as HTMLElement)) return;
			event.stopPropagation();
			triggerRef.dispatchEvent(new MouseEvent("mousedown", { bubbles: true }));
		};
		triggerRef.addEventListener("mousedown", alterTarget);
		return () => {
			triggerRef.removeEventListener("mousedown", alterTarget);
		};
	});

	const resolvedPropGetOptionLabel = useMemo(() => {
		if (propGetOptionLabel === null) return () => "";
		return propGetOptionLabel || getLabel;
	}, [propGetOptionLabel]);

	const getOptionLabel = useCallback(
		(option?: Option | null) => {
			if (!option) return "";
			const optionLabel = resolvedPropGetOptionLabel(option);
			return typeof optionLabel === "string" ? optionLabel : "";
		},
		[resolvedPropGetOptionLabel]
	);

	const filteredOptions = useSelectOptions({
		options,
		filter,
		filterQuery,
		sort,
		getOptionLabel,
		getQuery,
		groupBy,
		limit
	});

	const handleErrors = useCallback(
		(toValidate: string) => {
			const errors = validators?.map(validator => validator(toValidate)).filter(notEmpty);
			setErrorMessages(errors?.length ? errors : undefined);
		},
		[setErrorMessages, validators]
	);

	const handleOpen = useCallback(() => {
		setOpen(true);
		onOpenOptions?.();
	}, [onOpenOptions]);

	const toggleOpen = useCallback(
		() =>
			setOpen(value => {
				if (!value) onOpenOptions?.();
				return !value;
			}),
		[onOpenOptions]
	);

	const handleClose = useCallback(
		(force = false) => {
			if (!open) return;
			if (!debug || force) {
				setOpen(false);
				setHighlightedIndex(-1);
				setFilterQuery("");
				inputRef.current?.blur();
			}
		},
		[debug, open]
	);

	const handleClickOutside = useCallback(
		(event: MouseEvent | TouchEvent) => {
			if (triggerRef?.contains(event.target as HTMLElement) || tooltipRef?.contains(event.target as HTMLElement)) {
				return;
			}
			handleClose();
		},
		[handleClose, tooltipRef, triggerRef]
	);
	useOnClickOutside(triggerRef ?? undefined, handleClickOutside);
	useOnClickOutside(tooltipRef ?? undefined, handleClickOutside);

	const resetInputValue = useCallback(
		(event?: React.SyntheticEvent | null, newValue?: Option | null) => {
			const newInputValue = getOptionLabel(newValue);

			if (newInputValue === inputValue) {
				return;
			}
			handleErrors(newInputValue);
			setInputValue(newInputValue);
			setHighlightedIndex(-1);
			if (propOnInputChange) {
				propOnInputChange(
					event ? { ...event, target: { ...event.target, value: newInputValue } } : { target: { value: newInputValue } }
				);
			}
		},
		[getOptionLabel, handleErrors, inputValue, propOnInputChange, setInputValue]
	);

	const handleBlur = useCallback(
		(event: React.SyntheticEvent) => {
			onFocus?.(false);
			if (!open) {
				return;
			}
			handleClose();
		},
		[handleClose, onFocus, open]
	);

	const handleValue = useCallback(
		(newValue: Value | null) => {
			if (equality(newValue, value)) return;
			propOnChange?.(newValue);
			setValue(newValue);
			resetInputValue(null, null);
			setFilterQuery("");
		},
		[equality, value, propOnChange, resetInputValue, setValue]
	);

	const selectNewValue = useCallback(
		(event: React.SyntheticEvent, newValue: Option, close = true) => {
			handleValue(handleOptionSelected(value, newValue));
			if (close) {
				handleClose(true);
			}
			onSelectItem?.(newValue);
		},
		[handleClose, handleValue, value, handleOptionSelected, onSelectItem]
	);

	const onSelectItemListKeyDown = useCallback(
		(event: React.KeyboardEvent<HTMLInputElement>) => {
			if (event.key === "ArrowUp") {
				setHighlightedIndex(Math.max(highlightedIndex - 1, 0));
				event.preventDefault();
			} else if (event.key === "ArrowDown") {
				setHighlightedIndex(Math.min(highlightedIndex + 1, limit - 1, filteredOptions.length - 1));
				event.preventDefault();
			} else if (event.key === "Escape") {
				handleClose();
				event.preventDefault();
			} else if (event.key === "Enter") {
				if (highlightedIndex === -1) {
					return;
				}
				const newValue = filteredOptions.at(highlightedIndex);
				if (newValue) selectNewValue(event, newValue);

				handleBlur(event);
			}
		},
		[highlightedIndex, limit, filteredOptions, selectNewValue, handleBlur, handleClose]
	);

	const handleInputChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			const newInputValue = event.target.value;
			if (newInputValue !== inputValue) {
				setInputValue(newInputValue);
				setHighlightedIndex(index => (index === -1 ? -1 : 0));
				setFilterQuery(newInputValue);
				if (propOnInputChange) {
					propOnInputChange(event);
				}
			}
		},
		[inputValue, propOnInputChange, setInputValue]
	);

	const handleClear = useCallback(
		(event: React.SyntheticEvent) => {
			handleValue(defaultValue);
			resetInputValue(event, null);
			event.stopPropagation();
		},
		[handleValue, resetInputValue, defaultValue]
	);

	return {
		clearTriggerRef,
		filterQuery,
		filteredOptions,
		hasMoreOptions: options.length > limit && filteredOptions.length === limit,
		highlightedIndex,
		errorMessages,
		inputRef,
		inputValue,
		open,
		tooltipRef,
		selectFieldRef,
		value,
		visible,
		getOptionLabel,
		setFilterQuery,
		handleClear,
		handleClose,
		handleInputChange,
		handleOpen,
		handleValue,
		toggleOpen,
		selectNewValue,
		onSelectItemListKeyDown,
		resetInputValue,
		setTooltipRef,
		getTooltipProps,
		setTriggerRef
	};
};
