import React, { useCallback } from "react";
import { UserSelect, type TUserSelectProps } from "components/common/UserSelect";
import { UserLabel, UserWithoutEmailLabel } from "components/ui/selectLabels/UserLabel";
import { devLog } from "utils/devtools/devLogging";
import { useVirtualTableContext } from "../../virtualTableContext";
import type { UserModel } from "models/UserModel";
import type { TInherit, TLabelComponentProps } from "utils/ui/select";
import type { IRowOptions } from "../../types";

type TProps = Omit<TUserSelectProps, "variant"> & IRowOptions;

const UserSelectCellContent: FC<TProps> = props => {
	const {
		state: { compact }
	} = useVirtualTableContext();

	const { renderLabel: propRenderLabel } = props;
	const renderLabel = useCallback(
		(labelProps: TLabelComponentProps<UserModel | TInherit>) => {
			if (propRenderLabel) {
				return propRenderLabel(labelProps);
			}
			if (typeof labelProps.value === "string") {
				devLog({
					message: "user select value was string instead of UserModel (this should not happen)",
					level: "error"
				});
				return null;
			}
			return compact ? (
				<UserWithoutEmailLabel {...(labelProps as TLabelComponentProps<UserModel>)} />
			) : (
				<UserLabel {...(labelProps as TLabelComponentProps<UserModel>)} />
			);
		},
		[propRenderLabel, compact]
	);

	return (
		<UserSelect {...props} noClear renderLabel={renderLabel} variant="table" size={compact ? "medium" : "large"} />
	);
};

const UserSelectCellContentMemo = React.memo(UserSelectCellContent) as typeof UserSelectCellContent;

export { UserSelectCellContentMemo as UserSelectCellContent };
