import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { SubmitModal } from "components/common/Stepper/components/SubmitModal";
import { useStepperContext } from "components/common/Stepper/stepperContext";
import { PageTitleContent } from "components/templates/PageTitleContentTemplate";
import { Button } from "components/ui/Button";
import { IconButton } from "components/ui/IconButton";
import { CloseIcon } from "components/ui/Icons/CloseIcon";
import { GrantedIcon } from "components/ui/Icons/GrantedIcon";
import { LoadingSpinner } from "components/ui/LoadingSpinner";
import { useIsOpenState } from "hooks/useIsOpenState";
import { routes } from "routes/routes";
import { devLog } from "utils/devtools/devLogging";
import { AccessDurationSection } from "./components/AccessDurationSection";
import { ChooseActorModal } from "./components/ChooseActorModal";
import { CopyTemplate } from "./components/CopyTemplate";
import { OnBehalfSection } from "./components/OnBehalfSection";
import { ReceiverMissingActorModal } from "./components/ReceiverMissingActorModal";
import { RemoveAllTargetsModal } from "./components/RemoveAllTargetsModal";
import { RequestTargetsSection } from "./components/RequestTargetsSection";
import { NotAllowedRequestModal } from "./components/UnauthorizedDataModal";
import {
	SUMMARY_STEP_ACTOR_ERROR,
	useHandleNewRequestIntegrationActors,
	useNewRequestSubmit,
	useSwitchablePrePopulatedData
} from "./summaryStep.hooks";
import { useNewRequestFormContext } from "../../newRequestFormContext";
import { RequestReasonInputs } from "../RequestReasonInputs";

export const SummaryStep: FC<{ onExit: () => void }> = ({ className, innerRef, onExit }) => {
	const { t } = useTranslation("translation", { keyPrefix: "pages.newRequest.summaryStep" });
	const {
		actions: { setFooterAction }
	} = useStepperContext();

	const {
		actorModalsShown,
		chooseActorModal,
		isIntegrationActorsValid,
		multipleAndMissingActorsIntegrations,
		allMissingActorIntegrations,
		receiverMissingActorIntegrationsIds,
		showMissingAndChoiceModals,
		receiverMissingActorsModal,
		apiMissingIntegrationActors,
		fillMissingActors,
		missingActorsIntegrationIds,
		multipleActorsIntegrationIds
	} = useHandleNewRequestIntegrationActors();

	const {
		state: { isFormValid }
	} = useNewRequestFormContext();

	const { isLoading: isLoadingUrlData, hasPrePopulatedData, isNotAllowed } = useSwitchablePrePopulatedData();
	const createRequestModal = useIsOpenState();
	const removeAllTargetsModal = useIsOpenState();
	const { onSubmit: apiNewRequestSubmit, setError: setSumbitModalError, error, success } = useNewRequestSubmit();

	const integrationActorsAreValid = useMemo(() => isIntegrationActorsValid(), [isIntegrationActorsValid]);

	const actorIntegration = useMemo(
		() =>
			multipleAndMissingActorsIntegrations?.find(integration => chooseActorModal.modalToShow === integration.id) ||
			null,
		[chooseActorModal.modalToShow, multipleAndMissingActorsIntegrations]
	);

	const onSubmit = useCallback(async () => {
		if (!integrationActorsAreValid && !actorModalsShown) {
			showMissingAndChoiceModals();
			return;
		}
		if (!isFormValid || !integrationActorsAreValid) return;
		createRequestModal.open();

		if (apiMissingIntegrationActors?.size) {
			try {
				await fillMissingActors();
			} catch (e) {
				devLog({ message: "Error filling missing actors", level: "error", extra: e as Error });
				setSumbitModalError(SUMMARY_STEP_ACTOR_ERROR);
				return;
			}
		}
		await apiNewRequestSubmit();
	}, [
		integrationActorsAreValid,
		actorModalsShown,
		createRequestModal,
		apiMissingIntegrationActors?.size,
		isFormValid,
		apiNewRequestSubmit,
		showMissingAndChoiceModals,
		fillMissingActors,
		setSumbitModalError
	]);

	const submitButton = useMemo(() => {
		let disabled = !isFormValid;
		let tooltip = undefined;
		if (actorModalsShown) {
			disabled = !(isFormValid && integrationActorsAreValid);
			tooltip = integrationActorsAreValid ? undefined : t("invalidSubmitTooltip");
		}
		return (
			<Button
				variant="primary"
				size="large"
				suffix={<GrantedIcon size={24} />}
				onClick={onSubmit}
				disabled={disabled}
				tooltip={tooltip}>
				{t("submitButtonLabel")}
			</Button>
		);
	}, [actorModalsShown, integrationActorsAreValid, isFormValid, onSubmit, t]);

	useEffect(() => {
		setFooterAction("complete", submitButton);
		return () => setFooterAction("complete", undefined);
	}, [setFooterAction, submitButton]);

	const isDataValid = useMemo(() => {
		const isUrlFormDataValid = !isLoadingUrlData && !isNotAllowed;
		return !hasPrePopulatedData || isUrlFormDataValid;
	}, [isLoadingUrlData, isNotAllowed, hasPrePopulatedData]);

	return (
		<PageTitleContent innerRef={innerRef} className={className} noTransition>
			<PageTitleContent.Header>
				<PageTitleContent.Header.Top>
					<PageTitleContent.Header.Actions>
						<IconButton size="large" onClick={onExit}>
							<CloseIcon />
						</IconButton>
					</PageTitleContent.Header.Actions>
				</PageTitleContent.Header.Top>
				<PageTitleContent.Header.Bottom>
					<PageTitleContent.Header.Title title={t("title")} />
					<PageTitleContent.Header.Actions>{isDataValid ? <CopyTemplate /> : null}</PageTitleContent.Header.Actions>
				</PageTitleContent.Header.Bottom>
			</PageTitleContent.Header>
			<PageTitleContent.Body>
				<NotAllowedRequestModal isOpen={!!isNotAllowed} />
				{isDataValid ? (
					<>
						<ReceiverMissingActorModal
							missingActorIntegrations={allMissingActorIntegrations}
							isOpen={receiverMissingActorsModal.isOpen}
							close={receiverMissingActorsModal.close}
						/>
						<ChooseActorModal
							actorIntegration={actorIntegration}
							isOpen={chooseActorModal.isOpen}
							close={chooseActorModal.onClose}
						/>
						<SubmitModal
							close={createRequestModal.close}
							isOpen={createRequestModal.isOpen}
							retry={onSubmit}
							error={!!error}
							success={success}
							navigateTo={routes.requests.main}
							successMessage={t("createRequestModal.success")}
							errorTitle={t("createRequestModal.error.title")}
							errorContent={t("createRequestModal.error.content")}
							loadingMessage={t("createRequestModal.loading")}
							goBackMessage={t("createRequestModal.error.goBack")}
						/>
						<RemoveAllTargetsModal isOpen={removeAllTargetsModal.isOpen} close={removeAllTargetsModal.close} />
						<OnBehalfSection />
						<RequestTargetsSection
							onExit={removeAllTargetsModal.open}
							chooseActor={chooseActorModal.open}
							receiverMissingActorIntegrationsIds={receiverMissingActorIntegrationsIds}
							missingActorsIntegrationIds={missingActorsIntegrationIds}
							multipleActorsIntegrationIds={multipleActorsIntegrationIds}
						/>
						<AccessDurationSection />
						<RequestReasonInputs hasPrePopulatedData={hasPrePopulatedData} />
					</>
				) : null}
				{isLoadingUrlData ? <LoadingSpinner /> : null}
			</PageTitleContent.Body>
		</PageTitleContent>
	);
};
