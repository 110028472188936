import constate from "constate";
import { useCallback, useState } from "react";

const EMPTY_ARRAY: (React.Ref<HTMLElement> | HTMLElement)[] = [];
const usePropagation = () => {
	const [refs, setRefs] = useState<(React.Ref<HTMLElement> | HTMLElement)[]>(EMPTY_ARRAY);

	const addPropagationElement = useCallback((ref: React.Ref<HTMLElement> | HTMLElement) => {
		setRefs(prevState => (prevState.includes(ref) ? prevState : [...prevState, ref]));
	}, []);

	const removePropagationElement = useCallback((ref: React.Ref<HTMLElement> | HTMLElement) => {
		setRefs(prevState => prevState.filter(item => item !== ref));
	}, []);

	return { state: { refs }, actions: { addPropagationElement, removePropagationElement } };
};

export const [PropgationProvider, usePropagationContext] = constate(usePropagation);
