import uniqueId from "lodash/uniqueId";
import React, { useCallback, useMemo } from "react";
import { getIntegrationResources } from "api/integrationResources";
import { ResourcesIcon } from "components/ui/Icons/ResourcesIcon";
import { Select } from "components/ui/Select";
import { ResourceLabel } from "components/ui/selectLabels/ResourceLabel";
import { ResourceOption } from "components/ui/selectOptions/ResourceOption";
import { useSelectSearchProps } from "hooks/useSelectSearchProps";
import { IntegrationResourceModel } from "models/IntegrationResourceModel";
import { getIntegrationResourceFilters } from "utils/api/filters";
import { sortByName } from "utils/sortUtils";
import type { TPaginationSortFilterOptions } from "types/pagination";

interface IProps {
	disabled?: boolean;
	error?: string;
	inputLabel: string;
	limit?: number;
	onChange: (value: IntegrationResourceModel | null) => void;
	placeholder?: string;
	selected: IntegrationResourceModel | null;
	selectedIntegrationId?: string;
	shouldFilter?: (integrationResource: IntegrationResourceModel) => boolean;
	required?: boolean;
	withIcon?: boolean;
}

const getOptionLabel = (option: IntegrationResourceModel) => option?.name || "";
const equality = (option: IntegrationResourceModel, selected: IntegrationResourceModel) => option.id === selected?.id;

const OPTIONS_LIMIT = 100;

export const ResourceSelectInput: FC<IProps> = ({
	className,
	disabled = false,
	error = "",
	inputLabel,
	limit = OPTIONS_LIMIT,
	onChange,
	placeholder,
	selected,
	selectedIntegrationId,
	shouldFilter,
	required = false,
	withIcon = false
}) => {
	const abortKey = useMemo(() => uniqueId("resource-select-"), []);
	const fetchHandler = useCallback(
		async (query: string) => {
			if (selectedIntegrationId && !disabled) {
				const queryOptions: TPaginationSortFilterOptions & { abortKey: string } = {
					perPage: limit,
					sortFields: ["nameLength", "name", "type"],
					order: "ASC",
					filters: getIntegrationResourceFilters({ integrationId: [selectedIntegrationId], name: [query] }),
					abortKey
				};

				const { result } = await getIntegrationResources(queryOptions);

				return result.toArray();
			}

			return [];
		},
		[disabled, limit, selectedIntegrationId, abortKey]
	);
	const { selectProps, query } = useSelectSearchProps(fetchHandler, selected, true);

	const resources = selectProps.options;

	const filteredResources = useMemo(
		() => (shouldFilter ? resources.filter(resource => shouldFilter(resource)) : resources),
		[shouldFilter, resources]
	);

	const errors = useMemo(() => (error ? [error] : []), [error]);
	const labelIcon = useMemo(() => (withIcon ? <ResourcesIcon /> : null), [withIcon]);

	return (
		<Select
			{...selectProps}
			className={className}
			disabled={!selectedIntegrationId || disabled}
			errors={errors}
			filter={null}
			getOptionLabel={getOptionLabel}
			isOptionEqualToValue={equality}
			label={inputLabel}
			labelIcon={labelIcon}
			limit={limit}
			onChange={onChange}
			renderOption={ResourceOption}
			options={filteredResources}
			placeholder={placeholder}
			renderLabel={ResourceLabel}
			required={required}
			sort={query ? null : sortByName}
			value={selected}
		/>
	);
};
