import React from "react";
import { SearchOption } from "components/pages/NewTicketPage/components/NewTicketForm/components/NewTicketBySearchForm/components/SearchOption";
import { SelectOption } from "../SelectOption";
import type { TNewTicketOption } from "components/pages/NewTicketPage/components/NewTicketForm/types";
import type { TOptionComponent } from "utils/ui/select";

const PermissionOption: TOptionComponent<TNewTicketOption> = ({
	disabled = false,
	inputValue,
	isHighlighted = false,
	isSelected = false,
	onSelect,
	option,
	optionKey
}) => {
	return (
		<SelectOption
			onSelect={onSelect}
			isHighlighted={isHighlighted}
			isSelected={isSelected}
			disabled={disabled}
			value={option}
			key={optionKey}>
			<SearchOption key={option.value.id} {...option} query={inputValue || null} />
		</SelectOption>
	);
};

PermissionOption.isSelectOption = true;

export { PermissionOption };
