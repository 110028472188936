import classNames from "classnames";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { getDynamicSizeIcon } from "components/ui/dynamicSizeIcon";
import { IntegrationIcon } from "components/ui/Icons/IntegrationIcon";
import { TitleBody } from "components/ui/TitleBody";
import { Tooltip } from "components/ui/Tooltip";
import { useAuthenticatedUser } from "hooks/useAuthenticatedUser";
import { requirePropertyOf } from "utils/security";
import { useStyles } from "./styles";
import { EntityNodeContent } from "../components/EntityNodeContent";
import { DeletedIndicator } from "../components/indicators/DeletedIndicator";
import { EntityLabel } from "../EntityLabel";
import type { TBaseEntityNodeProps } from "../types";

const IntegrationTooltipContent: FC<{ name: string }> = ({ name }) => {
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<div className={classes.tooltipContainer}>
			<TitleBody size="small" title={t("common.roleBar.roleBarIntegration.name")} body={name} />
		</div>
	);
};

type TBaseIntegration = {
	name: string;
	imageUrl?: string | null;
	isDeleted?: boolean;
	id?: string;
};

type TIntegrationForLink = TBaseIntegration & {
	id: string;
};

type TProps = TBaseEntityNodeProps & {
	withIcon?: boolean;
	withBackground?: boolean;
} & (
		| { integration: TBaseIntegration; variant?: "standard" | "bold" }
		| { integration: TIntegrationForLink; variant: "link" }
	);
export const IntegrationEntity: FC<TProps> = ({
	delayShowTooltip,
	integration,
	withIcon,
	size,
	noWrap,
	inactive: propInactive,
	withBackground = false,
	variant: propVariant,
	className,
	...entityNodeProps
}) => {
	const { user } = useAuthenticatedUser();
	const isAdmin = user?.isAdmin ?? false;
	const classes = useStyles();
	const indicators = useMemo(() => {
		const indicators: React.ReactNode[] = [];
		if (integration.isDeleted) {
			indicators.push(<DeletedIndicator />);
		}

		return indicators;
	}, [integration.isDeleted]);

	const Icon = useMemo(() => {
		const imageUrl = integration?.imageUrl;
		if (imageUrl) {
			return getDynamicSizeIcon(<img src={imageUrl} />);
		}
		return IntegrationIcon;
	}, [integration.imageUrl]);

	const inactive = propInactive || integration.isDeleted;

	const variant = propVariant !== "link" || (!inactive && isAdmin) ? propVariant : undefined;

	const contentText = useMemo(() => {
		const text = integration.name;
		if (variant === "link" && propVariant === "link") {
			// the second condition is for typescript to understand the type correctly and not really needed
			return (
				<a href={`/integrations/${integration.id}`} target="_blank" rel="noopener noreferrer" className={classes.link}>
					{text}
				</a>
			);
		}
		return text;
	}, [variant, integration.name, classes.link, integration.id, propVariant]);

	const customIconSizeClassName = useMemo(() => requirePropertyOf(classes, size), [classes, size]);
	const content = useMemo(() => {
		return (
			<Tooltip delayShow={delayShowTooltip} content={<IntegrationTooltipContent name={integration.name} />}>
				<EntityNodeContent
					iconClassName={classNames(customIconSizeClassName, { [classes.iconWithBackground]: withBackground })}
					text={contentText}
					withIcon={withIcon}
					size={size}
					icon={
						<Icon
							className={classNames(classes.icon, {
								[classes.inactive]: inactive,
								[classes.withBackground]: withBackground
							})}
						/>
					}
					inactive={inactive}
					noWrap={noWrap}
					variant={variant}
					{...entityNodeProps}
				/>
			</Tooltip>
		);
	}, [
		delayShowTooltip,
		integration,
		withIcon,
		size,
		variant,
		noWrap,
		withBackground,
		entityNodeProps,
		classes.icon,
		classes.inactive,
		Icon,
		inactive,
		contentText,
		classes.withBackground,
		classes.iconWithBackground,
		customIconSizeClassName
	]);

	return <EntityLabel content={content} size={size} indicators={indicators} className={className} />;
};
