import React, { useMemo } from "react";
import { ResourceDetailsTooltip } from "components/common/ResourceDetailsTooltip";
import { ResourcesIcon } from "components/ui/Icons/ResourcesIcon";
import { useAuthenticatedUser } from "hooks/useAuthenticatedUser";
import { useStyles } from "./styles";
import { EntityNodeContent } from "../components/EntityNodeContent";
import { DeletedIndicator } from "../components/indicators/DeletedIndicator";
import { EntityLabel } from "../EntityLabel";
import type { TBaseEntityNodeProps } from "../types";

type TBaseResource = {
	name: string;
	isDeleted?: boolean;
	description?: string | null;
	euid?: string;
	type?: string;
	id?: string;
	integrationId?: string;
};

type TResourceForLink = TBaseResource & {
	id: string;
	integrationId: string;
};

type TProps = TBaseEntityNodeProps & {
	withIcon?: boolean;
} & ({ resource: TBaseResource; variant?: "bold" | "standard" } | { resource: TResourceForLink; variant: "link" });

export const ResourceEntity: FC<TProps> = ({
	resource,
	withIcon,
	size,
	className,
	delayShowTooltip,
	variant: propVariant,
	inactive: propInactive,
	...entityNodeProps
}) => {
	const classes = useStyles();
	const { user } = useAuthenticatedUser();
	const isAdmin = user?.isAdmin ?? false;
	const indicators = useMemo(() => {
		const indicators: React.ReactNode[] = [];
		if (resource.isDeleted) {
			indicators.push(<DeletedIndicator />);
		}

		return indicators;
	}, [resource.isDeleted]);

	const inactive = propInactive || resource.isDeleted;
	const variant = propVariant !== "link" || (!inactive && isAdmin) ? propVariant : undefined;

	const contentText = useMemo(() => {
		const text = resource.name;
		if (variant === "link") {
			return (
				<a
					href={`/integrations/${resource.integrationId}/resources/${resource.id}`}
					target="_blank"
					rel="noopener noreferrer"
					className={classes.link}>
					{text}
				</a>
			);
		}
		return text;
	}, [variant, resource.name, classes.link, resource.integrationId, resource.id]);

	const content = useMemo(() => {
		return (
			<ResourceDetailsTooltip
				delayShowTooltip={delayShowTooltip}
				description={resource.description}
				euid={resource.euid}
				name={resource.name}
				type={resource.type}>
				<EntityNodeContent
					text={contentText}
					withIcon={withIcon}
					size={size}
					icon={<ResourcesIcon />}
					inactive={inactive}
					variant={variant}
					{...entityNodeProps}
				/>
			</ResourceDetailsTooltip>
		);
	}, [
		delayShowTooltip,
		resource.description,
		resource.euid,
		resource.name,
		resource.type,
		withIcon,
		size,
		inactive,
		variant,
		contentText,
		entityNodeProps
	]);

	return <EntityLabel content={content} size={size} indicators={indicators} className={className} />;
};
