import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	spinner: {
		margin: "auto"
	},
	approvalAlgorithmContainer: {
		overflow: "hidden"
	},
	title: {
		display: "flex",
		flexDirection: "row",
		width: "100%",
		alignItems: "center",
		justifyContent: "space-between",
		gap: "var(--spacing-x2, 8px)"
	},
	approvalAlgorithmOption: {
		display: "flex",
		flexDirection: "row",
		width: "100%",
		alignItems: "center",
		gap: "0.5rem",
		paddingLeft: "1rem",
		paddingRight: "1rem",
		cursor: "pointer",
		minHeight: "40px"
	}
});
