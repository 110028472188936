import { isEqual } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Select } from "components/ui/Select";
import { TextOption } from "components/ui/selectOptions/TextOption";
import { removeRedundantSpaces } from "utils/strings";
import { getCategoryValidators } from "utils/validation/validationUtils";

interface IProps {
	categories: string[];
	setSelected: (option: ICategoryOption | null) => void;
	selected: string | null;
}

export interface ICategoryOption {
	label: string;
	value: string;
}

const equality = (option: ICategoryOption, selected: ICategoryOption) => option.value === selected.value;
const getOption = (text: string) => ({ label: text, value: text });
const getOptionLabel = (option: ICategoryOption) => option.label;

export const CategorySelectInput: FC<IProps> = ({ categories, setSelected, selected }) => {
	const { t } = useTranslation();
	const [options, setOptions] = useState<ICategoryOption[]>([]);
	const [userOption, setUserOption] = useState<ICategoryOption | null>(null);

	useEffect(() => {
		setOptions(categories.map(category => getOption(category || "")));
	}, [categories]);

	const onInputChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement> | { target: { value: string } }) => {
			const text = removeRedundantSpaces(event.target.value);
			const alreadyExists = categories.find(category => category === text);
			setUserOption(!alreadyExists && !!text.length ? getOption(text) : null);
		},
		[categories]
	);

	const onChange = useCallback(
		(option: ICategoryOption | null) => {
			if (!isEqual(userOption, option)) {
				setUserOption(null);
			}
			setSelected(option);
		},
		[setSelected, userOption]
	);

	const loading = useMemo(() => !options, [options]);

	const categoryOptions = useMemo(() => {
		return userOption ? [userOption, ...options] : options;
	}, [userOption, options]);

	const selectedOption = useMemo(() => {
		return selected ? getOption(selected) : null;
	}, [selected]);

	const categoryValidators = useMemo(() => getCategoryValidators(t("pages.bundles.bundleEditor.category")), [t]);

	const getQuery = useCallback((value: string) => removeRedundantSpaces(value), []);

	return (
		<Select
			getOptionLabel={getOptionLabel}
			getQuery={getQuery}
			isOptionEqualToValue={equality}
			label={t("pages.bundles.bundleEditor.category")}
			loading={loading}
			onChange={onChange}
			onInputChange={onInputChange}
			renderOption={TextOption}
			options={categoryOptions}
			placeholder={t("pages.bundles.bundleEditor.placeholders.selectCategory")}
			validators={categoryValidators}
			value={selectedOption}
		/>
	);
};
