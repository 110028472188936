import classNames from "classnames";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { RoleEntity } from "components/common/entities";
import { IconPrefix } from "components/ui/IconPrefix";
import { RoleIcon } from "components/ui/Icons/RoleIcon";
import { TitleBody } from "components/ui/TitleBody";
import { Tooltip } from "components/ui/Tooltip";
import { ROLE_BAR_ROLE_MIN_WIDTH_PX, useStyles } from "./styles";
import { RoleBarTag } from "../RoleBarTag";
import type { IntegrationResourceRoleModel } from "models/IntegrationResourceRoleModel";
import type { Require } from "types/utilTypes";

export const ROLE_BAR_ROLE_WIDTH = `minmax(${ROLE_BAR_ROLE_MIN_WIDTH_PX}px, 400px)`;

export type TSingleRoleProps = {
	role:
		| {
				id: string;
				name: string;
				managed: boolean;
		  }
		| Require<IntegrationResourceRoleModel, "integrationResource">;
	amount?: never;
	withoutTooltip?: never;
};

type TMultipleRoleProps = {
	amount: number;
	role?: never;
	withoutTooltip?: boolean;
};

type TProps = (TSingleRoleProps | TMultipleRoleProps) & {
	tags?: string[];
	linkable?: boolean;
};

export const RoleBarRole: FC<TProps> = ({
	className,
	innerRef,
	tags,
	withoutTooltip,
	linkable = false,
	...restProps
}) => {
	const classes = useStyles();
	const { t } = useTranslation("translation", { keyPrefix: "common.roleBar.roleBarRole" });
	const isAmount = "amount" in restProps;
	const amount = restProps.amount || 0;

	const content = useMemo(() => {
		if (isAmount) {
			if (withoutTooltip) {
				return <IconPrefix Icon={RoleIcon} content={amount} />;
			}
			const tooltipContent = (
				<div className={classes.tooltipContainer}>
					<TitleBody size="small" title={t("amount")} body={amount} />
				</div>
			);
			return (
				<Tooltip content={tooltipContent}>
					<IconPrefix Icon={RoleIcon} content={amount} />
				</Tooltip>
			);
		}

		const entityRoleProps =
			linkable && "integrationResource" in restProps.role
				? {
						variant: "link" as const,
						role: restProps.role
					}
				: { variant: undefined, role: restProps.role };

		return <RoleEntity withIcon noWrap relative size="medium" {...entityRoleProps} />;
	}, [isAmount, restProps.role, linkable, withoutTooltip, classes.tooltipContainer, t, amount]);

	return (
		<div className={classNames(classes.container, className)} ref={innerRef}>
			<div className={classes.innerContainer}>
				{content}
				{tags ? <RoleBarTag tags={tags} /> : null}
			</div>
		</div>
	);
};
