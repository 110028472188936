import classNames from "classnames";
import { PanZoom } from "panzoom";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import Xarrow, { Xwrapper, anchorType, useXarrow } from "react-xarrows";
import { TLine, useIdentityGraphContext } from "components/pages/IdentityGraphPage/identityGraphContext";
import { IconButton } from "components/ui/IconButton";
import { AddIcon } from "components/ui/Icons/AddIcon";
import { CenterIcon } from "components/ui/Icons/CenterIcon";
import { RemoveIcon } from "components/ui/Icons/RemoveIcon";
import { LoadingDots } from "components/ui/LoadingDots";
import { Separator } from "components/ui/Separator";
import { VerticesColumn } from "./components/Columns";
import { EmptyGraphState } from "./components/EmptyGraphState";
import { Legend } from "./components/Legend";
import { LINE_BOTH_COLOR, LINE_DIRECT_COLOR, LINE_INDIRECT_COLOR, useStyles } from "./styles";
import { useGraphPanAndZoom, useGroupedData } from "../../graphHooks";

const LinesObserver: FC<{ panRef: React.RefObject<PanZoom> }> = ({ panRef }) => {
	const updateLines = useXarrow();

	useEffect(() => {
		if (panRef.current) {
			const pan = panRef.current;
			pan.on("transform", updateLines);
			return () => {
				pan.off("transform", updateLines);
			};
		}
		return;
	}, [updateLines, panRef]);

	return null;
};
const LinesContainer: FC = ({ className }) => {
	const {
		state: { lines, stepMapRef }
	} = useIdentityGraphContext();

	const containerRef = useRef<HTMLDivElement>(null);

	const lineExists = useCallback(
		(line: TLine) => line.start.current.offsetParent !== null && line.end.current.offsetParent !== null,
		[]
	);

	const lineStepDirection = useCallback(
		(line: TLine) => {
			const startStep = stepMapRef.current.get(line.start.id);
			const endStep = stepMapRef.current.get(line.end.id);
			if (!lineExists(line) || typeof startStep !== "number" || typeof endStep !== "number") return null;

			if (startStep === endStep) return "same";
			if (endStep < startStep) return "back";
			return "forward";
		},
		[lineExists, stepMapRef]
	);

	const startAnchor: anchorType = useMemo(() => ({ position: "right" as const, offset: { x: 8 } }), []);
	const endAnchor: anchorType = useMemo(() => ({ position: "left" as const, offset: { x: -8 } }), []);

	const getAnchors = useCallback(
		(line: TLine) => {
			const direction = lineStepDirection(line);
			if (direction === "back") {
				return {
					startAnchor: endAnchor,
					endAnchor: startAnchor
				};
			} else if (direction === "same") {
				return {
					startAnchor: startAnchor,
					endAnchor: startAnchor
				};
			} else if (direction === "forward") {
				return {
					startAnchor: startAnchor,
					endAnchor: endAnchor
				};
			} else {
				return {};
			}
		},
		[endAnchor, lineStepDirection, startAnchor]
	);

	const lineColor = useCallback((connectionType: TLine["connectionType"]) => {
		switch (connectionType) {
			case "direct":
				return LINE_DIRECT_COLOR;
			case "indirect":
				return LINE_INDIRECT_COLOR;
			case "both":
				return LINE_BOTH_COLOR;
		}
	}, []);

	return (
		<div className={className} ref={containerRef}>
			{lines
				? lines.map(line => (
						<Xarrow
							start={line.start}
							end={line.end}
							key={line.id}
							color={lineColor(line.connectionType)}
							{...getAnchors(line)}
							strokeWidth={1}
							headSize={5}
							zIndex={-1}
							showXarrow={lineExists(line)}
						/>
					))
				: null}
		</div>
	);
};

export const IdentityGraph: FC = ({ className }) => {
	const {
		state: { elementRef, isLoading, graphData },
		actions: { setRef }
	} = useIdentityGraphContext();
	const { t } = useTranslation("translation", { keyPrefix: "pages.identityGraph" });

	const groupedByStepAndIntegrationId = useGroupedData();

	const { cursorStyle, graphRef, panRef, viewRef, returnToStart, zoomIn, zoomOut } =
		useGraphPanAndZoom(!!groupedByStepAndIntegrationId);

	const classes = useStyles({ cursor: cursorStyle });

	const columns = useMemo(() => {
		if (!groupedByStepAndIntegrationId || !groupedByStepAndIntegrationId.size) return null;
		return groupedByStepAndIntegrationId
			.sortBy((_, step) => step)
			.map((groupedVertices, step) => (
				<VerticesColumn key={`column-${step}`} groupedVertices={groupedVertices} refSetter={setRef} />
			))
			.toList()
			.toArray();
	}, [groupedByStepAndIntegrationId, setRef]);

	return (
		<div className={classNames(classes.identityGraph, className)} ref={graphRef}>
			<div className={classes.graphView}>
				<div className={classes.graphHeader}>
					<Legend vertices={graphData?.vertices} loading={isLoading} />
					<div className={classes.transformActions}>
						<IconButton variant="secondary" onClick={returnToStart} tooltip={t("center")}>
							<CenterIcon />
						</IconButton>
						<Separator />
						<div className={classes.zoomActions}>
							<IconButton variant="secondary" onClick={zoomOut} tooltip={t("zoomOut")}>
								<RemoveIcon />
							</IconButton>
							<IconButton variant="secondary" onClick={zoomIn} tooltip={t("zoomIn")}>
								<AddIcon />
							</IconButton>
						</div>
					</div>
				</div>

				<div className={classes.elementGraphContainer} ref={elementRef}>
					<div className={classes.graphContainer} ref={viewRef}>
						{isLoading ? (
							<LoadingDots className={classes.fullPage} center />
						) : (
							<>{columns ? columns : <EmptyGraphState />}</>
						)}
					</div>
				</div>
				<Xwrapper>
					<LinesObserver panRef={panRef} />
					<LinesContainer />
				</Xwrapper>
			</div>
		</div>
	);
};
