import { List } from "immutable";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { AccessReviewPermissionCommentCell } from "components/common/AccessReviewPermissionCommentCell";
import { UserEntity } from "components/common/entities/UserEntity";
import { CloseCircleIcon } from "components/ui/Icons/CloseCircleIcon";
import { DoneCircleIcon } from "components/ui/Icons/DoneCircleIcon";
import { Tabs } from "components/ui/legacy/Tabs";
import { Typography } from "components/ui/legacy/Typography";
import { PageSelect } from "components/ui/PageSelect";
import { Select } from "components/ui/Select";
import { TextOption } from "components/ui/selectOptions/TextOption";
import { Table } from "components/ui/Table";
import { Unknown } from "components/ui/Unknown";
import { useMultiUsers } from "hooks/useMultiUsers";
import { AccessReviewModel } from "models/AccessReviewModel";
import { AccessReviewResourcePermissionModel } from "models/AccessReviewResourcePermissionModel";
import { AccessReviewSubordinatePermissionModel } from "models/AccessReviewSubordinatePermissionModel";
import { useStyles } from "./styles";
import { useEntitlementsTable } from "./useEntitlementsTable";
import { useReportTable } from "./useReportsTable";
import { RoleName } from "../RoleName";

interface IProps {
	accessReview: AccessReviewModel;
}

const isSubordinatePermission = (
	value: AccessReviewSubordinatePermissionModel | AccessReviewResourcePermissionModel
): value is AccessReviewSubordinatePermissionModel => {
	return Boolean((value as AccessReviewSubordinatePermissionModel).accessReviewSubordinateId);
};

const EntitlementTableRow = ({
	entitlement
}: {
	entitlement: AccessReviewSubordinatePermissionModel | AccessReviewResourcePermissionModel;
}) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const userIds = useMemo(() => {
		let ids: string[] = [];
		const actorUserIds = entitlement.accessReviewPermission!.integrationActorPermission!.integrationActor?.userIds;
		if (actorUserIds) {
			ids = ids.concat(actorUserIds.toArray());
		}
		if (isSubordinatePermission(entitlement)) {
			const reporterId = entitlement.accessReviewSubordinate!.accessReviewReport!.userId;
			const subordinateId = entitlement.accessReviewSubordinate!.userId;
			ids.push(reporterId);
			ids.push(subordinateId);
			return ids;
		}
		const reporterId = entitlement.accessReviewResource!.accessReviewReport!.userId;
		ids.push(reporterId);
		return ids;
	}, [entitlement]);

	const users = useMultiUsers(userIds);
	const integrationId = entitlement.integrationId;
	const roleName = entitlement.roleName;
	const resourceName = entitlement.resourceName;
	const account = entitlement.accessReviewPermission!.actorName;
	const status = t(`common.accessReview.statuses.permissions.${entitlement.status}`);
	const { reporterType, reporter, permissionUsers } = useMemo(() => {
		if (!users) return { permissionUsers: [] as string[], reporterType: "subordinate" };
		if (isSubordinatePermission(entitlement)) {
			const reporter = users.get(entitlement.accessReviewSubordinate!.accessReviewReport!.userId);
			const subordinate = users.get(entitlement.accessReviewSubordinate!.userId);
			return {
				reporter,
				reporterType: "subordinate",
				permissionUsers: subordinate ? [subordinate.fullName] : ([] as string[])
			};
		}
		const reporter = users?.get(entitlement.accessReviewResource!.accessReviewReport!.userId);
		const actorUsers = entitlement.accessReviewPermission!.integrationActorPermission!.integrationActor?.userIds;

		return {
			reporterType: "resource",
			permissionUsers: (actorUsers?.toArray() || [])
				.map(user => users?.get(user)?.fullName)
				.filter(Boolean) as string[],
			reporter
		};
	}, [users, entitlement]);

	const comments = useMemo(
		() =>
			List(entitlement.accessReviewPermission!.comments?.filter(comment => comment.creatorId === reporter?.id) || []),
		[entitlement.accessReviewPermission, reporter?.id]
	);

	return (
		<Table.Row key={entitlement.id}>
			<Table.Cell>
				<RoleName integrationId={integrationId} name={roleName} resourceName={resourceName} />
			</Table.Cell>
			<Table.Cell>
				<div className={classes.reporterContainer}>
					<Unknown unknown={!reporter}>{reporter && <UserEntity withIcon user={reporter} />}</Unknown>
					<Typography variant="tiny" className={classes.reporterType}>
						{t(`pages.accessReview.dashboard.reporterType.${reporterType as "subordinate" | "resource"}`)}
					</Typography>
				</div>
			</Table.Cell>
			<Table.Cell>
				<Typography>{account}</Typography>
			</Table.Cell>
			<Table.Cell className={classes.user}>
				{permissionUsers.map(permissionUser => {
					const user = users?.find(user => user.fullName === permissionUser);
					return user && <UserEntity withIcon key={user.id} user={user} />;
				})}
			</Table.Cell>
			<Table.Cell>
				<AccessReviewPermissionCommentCell comments={comments} readonly />
			</Table.Cell>
			<Table.Cell>
				<Typography>{status}</Typography>
			</Table.Cell>
		</Table.Row>
	);
};

const OverviewTabs: FC<IProps> = ({ accessReview }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const navigate = useNavigate();
	const reports = useReportTable(accessReview.reports?.toArray() || []);
	const reportUsers = useMultiUsers(reports.page.map(report => report.userId));
	const entitlements = useEntitlementsTable(accessReview.id);

	const onClick = useCallback(
		(reportId: string) => {
			navigate(`/accessReview/reports/${reportId}`);
		},
		[navigate]
	);

	return (
		<Tabs defaultActiveKey={"reports"}>
			<Tabs.Tab
				key="reports"
				tab={reports.title}
				suffix={
					<Select
						hideInput
						getOptionLabel={reports.getStatusLabel}
						noClear
						onChange={reports.setStatusFilter}
						options={reports.statusOptions}
						value={reports.statusFilter}
						renderOption={TextOption}
					/>
				}>
				<Table gridColumns="repeat(5, 1fr)" outline>
					<Table.Row>
						{reports.headers.map(title => (
							<Table.Header key={title}>
								<Typography>{title}</Typography>
							</Table.Header>
						))}
					</Table.Row>
					{reports.page.map(report => {
						const user = reportUsers?.get(report.userId);
						return (
							<Table.Row key={report.id} onClick={() => onClick(report.id)}>
								<Table.Cell>{user && <UserEntity withIcon user={user} />}</Table.Cell>
								<Table.Cell>
									<Typography>{report.permissionsSize}</Typography>
								</Table.Cell>
								<Table.Cell>
									<Typography className={classes.statistics}>
										{report.statistics?.permissionsStatus.approved ? (
											<>
												<DoneCircleIcon />
												{report.statistics?.permissionsStatus.approved}
											</>
										) : (
											"-"
										)}
									</Typography>
								</Table.Cell>
								<Table.Cell>
									<Typography className={classes.statistics}>
										{report.statistics?.permissionsStatus.denied ? (
											<>
												<CloseCircleIcon />
												{report.statistics.permissionsStatus.denied}
											</>
										) : (
											"-"
										)}
									</Typography>
								</Table.Cell>
								<Table.Cell>
									<Typography>{t(`common.accessReview.statuses.subordinates.${report.calculatedStatus}`)}</Typography>
								</Table.Cell>
							</Table.Row>
						);
					})}
				</Table>
				{reports.totalPages > 1 && (
					<PageSelect
						changePage={reports.changePage}
						currentPageNumber={reports.currentPageNumber}
						pagesCount={reports.totalPages}
						pagesShownAmount={3}
					/>
				)}
			</Tabs.Tab>
			<Tabs.Tab
				key="entitlements"
				tab={entitlements.title}
				suffix={
					<Select
						hideInput
						getOptionLabel={entitlements.getStatusLabel}
						noClear
						onChange={entitlements.setStatusFilter}
						options={entitlements.statusOptions}
						value={entitlements.statusFilter}
						renderOption={TextOption}
					/>
				}>
				<Table gridColumns="3fr 3fr 2fr 3fr 2fr 2fr" loading={entitlements.isLoading} outline>
					<Table.Row>
						{entitlements.headers.map(title => (
							<Table.Header key={title}>
								<Typography>{title}</Typography>
							</Table.Header>
						))}
					</Table.Row>
					{entitlements.page?.map(entitlement => (
						<EntitlementTableRow entitlement={entitlement} key={entitlement.id} />
					)) || null}
				</Table>
				{entitlements.totalPages > 1 && (
					<PageSelect
						changePage={entitlements.changePage}
						currentPageNumber={entitlements.currentPageNumber}
						pagesCount={entitlements.totalPages}
						pagesShownAmount={3}
					/>
				)}
			</Tabs.Tab>
		</Tabs>
	);
};

const MemoizedOverviewTabs = React.memo(OverviewTabs);
export { MemoizedOverviewTabs as OverviewTabs };
