import classNames from "classnames";
import { List, Set } from "immutable";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { getPrerequisitePermissions } from "api/accessRequestForm";
import { AreYouSureModal } from "components/common/AreYouSureModal";
import { useNewRequestFormContext } from "components/pages/NewRequestPage/newRequestFormContext";
import { Button } from "components/ui/Button";
import { StaticChip } from "components/ui/chips/StaticChip";
import { PrerequisiteIcon } from "components/ui/Icons/PrerequisiteIcon";
import { Section } from "components/ui/Section";
import { Typography } from "components/ui/Typography";
import { useIsOpenState } from "hooks/useIsOpenState";
import { ignoreAbortError } from "utils/api/ignoreAbortError";
import { PrerequisitePermissionsModal } from "./components/PrerequisitePermissionsModal";
import { RequestTarget } from "./components/RequestTarget";
import { useStyles } from "./styles";
import { EditButton } from "../EditButton";
import type { TRequestTarget } from "components/pages/NewRequestPage/types";
import type { IntegrationResourceRoleModel } from "models/IntegrationResourceRoleModel";

const usePrerequisitePermissions = (targets: List<TRequestTarget>, receiverUserId?: string) => {
	const [prerequisitePermissions, setPrerequisitePermissions] = useState(List<IntegrationResourceRoleModel>());

	useEffect(() => {
		if (!receiverUserId) return;
		void ignoreAbortError(
			getPrerequisitePermissions(
				receiverUserId,
				targets
					.map(target => ({
						type: target.type,
						id: target.id
					}))
					.toArray()
			),
			setPrerequisitePermissions
		);
	}, [receiverUserId, targets]);

	return prerequisitePermissions;
};

type TRequestTargetSectionProps = {
	receiverMissingActorIntegrationsIds?: Set<string>;
	missingActorsIntegrationIds: Set<string>;
	multipleActorsIntegrationIds: Set<string>;
	chooseActor: (id: string) => void;
	onExit: () => void;
};

export const RequestTargetsSection: FC<TRequestTargetSectionProps> = ({
	className,
	innerRef,
	chooseActor,
	onExit,
	receiverMissingActorIntegrationsIds,
	missingActorsIntegrationIds,
	multipleActorsIntegrationIds
}) => {
	const { t } = useTranslation("translation", { keyPrefix: "pages.newRequest.summaryStep.requestTargetsSection" });
	const { t: globalTranslation } = useTranslation();
	const classes = useStyles();

	const [targetToRemove, setTargetToRemove] = useState<string>();

	const { isOpen: prerequisiteIsOpen, open: prerequisiteOpen, close: prerequisiteClose } = useIsOpenState();
	const { isOpen: areYouSureIsOpen, open: areYouSureOpen, close: areYouSureClose } = useIsOpenState();

	const {
		state: { requestTargets, receiverUser },
		actions: { removeTarget }
	} = useNewRequestFormContext();

	const requestTargetsSorted = useMemo(
		() => requestTargets.sortBy(target => (target.type === "bundle" ? -1 : 1)),
		[requestTargets]
	);
	const prerequisitePermissions = usePrerequisitePermissions(requestTargetsSorted, receiverUser?.id);

	const title = useMemo(() => {
		return (
			<div className={classes.titleContainer}>
				<div className={classes.titleChipContainer}>
					<Typography variant="body_sb">{t("title")}</Typography>
					<StaticChip size="small" variant="regular">
						{globalTranslation("number", { value: requestTargetsSorted.size })}
					</StaticChip>
				</div>
				<EditButton stepIndex={0} />
			</div>
		);
	}, [classes.titleChipContainer, classes.titleContainer, requestTargetsSorted.size, t, globalTranslation]);

	const titleActions = useMemo(() => {
		if (prerequisitePermissions.size === 0) return null;
		return (
			<Button variant="text" size="small" onClick={prerequisiteOpen} prefix={<PrerequisiteIcon size={12} />}>
				{t("prerequisites")}
			</Button>
		);
	}, [prerequisitePermissions.size, prerequisiteOpen, t]);

	const hasBundles = useMemo(() => {
		return requestTargetsSorted.some(target => target.type === "bundle");
	}, [requestTargetsSorted]);

	const onRemoveTargetClick = useCallback(
		(targetId: string) => {
			if (requestTargetsSorted.size === 1) {
				onExit();
			} else {
				setTargetToRemove(targetId);
				areYouSureOpen();
			}
		},
		[areYouSureOpen, onExit, requestTargetsSorted.size]
	);

	const onRemoveTarget = useCallback(() => {
		if (targetToRemove) {
			areYouSureClose();
			return removeTarget(targetToRemove);
		}
	}, [areYouSureClose, removeTarget, targetToRemove]);

	return (
		<>
			<PrerequisitePermissionsModal
				isOpen={prerequisiteIsOpen}
				onClose={prerequisiteClose}
				prerequisitePermissions={prerequisitePermissions}
			/>
			<AreYouSureModal
				onAction={onRemoveTarget}
				isOpen={areYouSureIsOpen}
				onClose={areYouSureClose}
				onCancel={areYouSureClose}
				content={t("areYouSureModal.content")}
				actionLabel={t("areYouSureModal.actionLabel")}
				closeLabel={t("areYouSureModal.cancelLabel")}
			/>
			<Section className={className} title={title} innerRef={innerRef} titleActions={titleActions}>
				<div className={classes.targets}>
					<div className={classes.targetsHeader}>
						<Typography variant="body_reg" className={classes.rolesTitle}>
							{t("roles")}
						</Typography>
						<Typography
							variant="body_reg"
							className={classNames(classes.accountsTitle, { [classes.hasBundles]: hasBundles })}>
							{globalTranslation("shared.accounts")}
						</Typography>
					</div>
					{requestTargetsSorted.map(target => (
						<RequestTarget
							hasBundles={hasBundles}
							key={target.id}
							receiverMissingActorIntegrationsIds={receiverMissingActorIntegrationsIds}
							missingActorsIntegrationIds={missingActorsIntegrationIds}
							multipleActorsIntegrationIds={multipleActorsIntegrationIds}
							chooseActor={chooseActor}
							removeTarget={onRemoveTargetClick}
							target={target}
						/>
					))}
				</div>
			</Section>
		</>
	);
};
