import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	modalContent: {
		display: "flex",
		flexDirection: "column",
		gap: "var(--spacing-x5, 20px)",
		alignItems: "flex-start",
		maxWidth: "500px",
		minWidth: "380px"
	},
	resourcePermissionForm: {
		display: "flex",
		gap: "var(--spacing-x5, 20px)",
		flexDirection: "column",
		alignItems: "flex-start",
		width: "100%"
	},
	input: {
		width: "100%"
	},
	modalContainer: {
		display: "grid",
		gap: "var(--spacing-x10, 40px)"
	}
});
