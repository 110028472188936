import classNames from "classnames";
import React, { useRef } from "react";
import { Tooltip } from "components/ui/Tooltip";
import { Typography, TTypographyVariant } from "components/ui/Typography";
import { useIsOverflowed } from "hooks/useIsOverflowed";
import { useStyles } from "./styles";
import type { Config } from "react-popper-tooltip";

interface IProps {
	content: React.ReactNode;
	multiline?: boolean; // default: false
	maxInlineWidth?: number | string; // default: 100%
	relative?: boolean;
	textVariant?: TTypographyVariant;
	tooltipClassName?: string;
	width?: number | string; // default: auto
	notInteractive?: boolean; // default: false
	tooltipPlacement?: Config["placement"];
	delayShow?: number;
}

export const TooltipOnOverflow: FC<IProps> = ({
	className,
	content,
	delayShow,
	tooltipPlacement,
	maxInlineWidth = "100%",
	relative = false,
	textVariant = "body_reg",
	tooltipClassName,
	width = "auto",
	multiline = false,
	notInteractive = false
}) => {
	const classes = useStyles({ width, maxInlineWidth });
	const textRef = useRef<HTMLDivElement>(null);
	const { overflowedX, overflowedY } = useIsOverflowed(textRef);
	const overflowed = overflowedX || (overflowedY && multiline);

	return overflowed ? (
		<Tooltip
			delayShow={delayShow}
			placement={tooltipPlacement}
			notInteractive={notInteractive}
			className={tooltipClassName}
			triggerRef={textRef}
			content={content}>
			<Typography
				variant={textVariant}
				relative={relative}
				className={classNames(classes.container, className)}
				noWrap={!multiline}>
				{content}
			</Typography>
		</Tooltip>
	) : (
		<Typography
			innerRef={textRef}
			variant={textVariant}
			relative={relative}
			className={classNames(classes.container, className)}
			noWrap={!multiline}>
			{content}
		</Typography>
	);
};
