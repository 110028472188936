import { List } from "immutable";
import { BundleItemModel } from "models/BundleItemModel";
import { BundleModel } from "models/BundleModel";
import { IntegrationModel } from "models/IntegrationModel";
import { FormBundleItemModel } from "./formBundleItemModel";
import type { TBundleData } from "./useBundleFormState";

export function bundleModelToBundleData(
	bundle: BundleModel | undefined,
	integrationMapping: Immutable.Map<string, IntegrationModel> | null
) {
	if (!bundle) return bundle;
	return {
		bundleItems: List(
			bundle.bundleItems?.map(
				item =>
					new FormBundleItemModel({
						integration: item.integrationResourceRole.integrationResource?.integrationId
							? integrationMapping?.get(item.integrationResourceRole.integrationResource?.integrationId) || null
							: null,
						resource: item.integrationResourceRole.integrationResource,
						role: item.integrationResourceRole,
						id: item.id
					})
			)
		),
		allowedDurations: bundle.allowedDurations,
		approvalAlgorithm: bundle.approvalAlgorithm,
		category: bundle.category,
		name: bundle.name,
		id: bundle.id,
		description: bundle.description,
		tags: bundle.tags
	};
}

export function bundleDataToBundleModel(bundle: TBundleData, overrideDurations: boolean) {
	return new BundleModel({
		...bundle,
		category: bundle.category,
		allowedDurations: overrideDurations ? bundle.allowedDurations : null,
		bundleItems: bundle.bundleItems?.map(
			resource =>
				new BundleItemModel({
					id: resource.get("id"),
					integrationResourceRole: resource.get("role") || undefined
				})
		)
	});
}
