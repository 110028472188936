import { List, Set, Map } from "immutable";
import isEmpty from "lodash/isEmpty";
import uniq from "lodash/uniq";
import uniqBy from "lodash/uniqBy";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { getIntegrationActors } from "api/integrationActors";
import { getMissingActorIntegrationIds, createNewTicket, respondToTicket } from "api/tickets";
import { changeProfile } from "api/user";
import { useAsyncImpact } from "hooks/useAsyncImpact";
import { useAuthenticatedUser } from "hooks/useAuthenticatedUser";
import { useAvailableDurations } from "hooks/useAvailableDurations";
import { useFetchedState } from "hooks/useFetchedState";
import { useOpenGlobalErrorModal } from "hooks/useGlobalError";
import { useIntegrations } from "hooks/useIntegrations";
import { useIsOpenState } from "hooks/useIsOpenState";
import { useLoadingState } from "hooks/useLoadingState";
import { useUpdateUser } from "hooks/useUpdateUser";
import { useUser } from "hooks/useUser";
import { IntegrationModel } from "models/IntegrationModel";
import { IntegrationResourceRoleModel } from "models/IntegrationResourceRoleModel";
import { notEmpty } from "utils/comparison";
import { IsNullError } from "utils/errors/isNullError";
import { escapeJson } from "utils/strings";
import { urlFieldsParser } from "utils/urlFormDataParser";
import {
	useNewRequestIntegrationResourceRoles,
	useNewRequestBundles,
	useNewRequestOriginalTicket
} from "../../newRequestDataContext";
import { useNewRequestFormContext } from "../../newRequestFormContext";
import type { UserModel } from "models/UserModel";
import type { Require } from "types/utilTypes";
import type { TTicketDuration } from "utils/durationsOptions";
import type ApiError from "utils/errors/apiError";
import type { TPrePopulatedFormData, TRequestTarget } from "../../types";

const MODAL_SHOW_DELAY_MS = 500;

interface IUsePrePopulateData {
	bundleIds?: string[];
	roles?: { id: string }[];
	duration?: TTicketDuration;
	justification?: string;
	ticketingIntegrationTicketId?: string;
	receiverId?: string;
}

type TPrePopulatedFormDataMandateTargets = Omit<TPrePopulatedFormData, "targets"> & { targets: List<TRequestTarget> };

const getIntegrationInUserIntegrationActors = (integrationId: string, user?: UserModel) =>
	user?.integrationActors?.filter(actor => actor.integrationId === integrationId);

const checkIfIntegrationMissingFromUserIntegrationActors = (integrationId: string, user?: UserModel) => {
	const integrations = getIntegrationInUserIntegrationActors(integrationId, user);
	return !integrations?.size;
};

const getIntegrationId = (role: IntegrationResourceRoleModel) => {
	const virtualizedRole = role.virtualizedRole;
	if ((virtualizedRole && !virtualizedRole.integrationResource) || !role.integrationResource) {
		throw IsNullError.from({
			location: "NewRequestPage.SummaryStep.getIntegrationId",
			parentObject: {
				name: "role",
				value: (virtualizedRole ?? role).toJS()
			},
			requestedProperty: "integrationResource"
		});
	}
	return virtualizedRole ? virtualizedRole.integrationResource!.integrationId : role.integrationResource.integrationId;
};

const useTargetIntegrations = () => {
	const {
		state: { requestTargets }
	} = useNewRequestFormContext();

	const integrations = useIntegrations();

	const targetToIntegrations = useMemo(() => {
		if (!integrations)
			return List<{
				targetId: string;
				integrations: List<IntegrationModel>;
			}>();
		const targetToIntegrationIds = requestTargets
			.flatMap(target => {
				if (target.type === "role") {
					const roleToCheck = target.fullTarget;
					return [{ targetId: target.id, integrationIds: Set([getIntegrationId(roleToCheck)]) }];
				} else {
					// Bundle target is necessary for RequestTarget component to identify if a bundle has integrations issues
					const bundleTarget = {
						targetId: target.id,
						integrationIds: target.fullTarget.bundleItems
							.map(bundleItem => getIntegrationId(bundleItem.integrationResourceRole))
							.toSet()
					};
					return target.fullTarget.bundleItems
						.map(bundleItem => ({
							targetId: bundleItem.id,
							integrationIds: Set([getIntegrationId(bundleItem.integrationResourceRole)])
						}))
						.concat(bundleTarget);
				}
			})
			.filter(notEmpty);
		return targetToIntegrationIds.map(({ targetId, integrationIds }) => ({
			targetId,
			integrations: integrationIds
				.map(id => integrations.get(id))
				.filter(notEmpty)
				.toList()
		}));
	}, [integrations, requestTargets]);

	const targetIntegrations = useMemo(() => {
		const { targetIntegrations } = targetToIntegrations.reduce(
			(acc, { integrations }) => {
				if (integrations.size === 0) return acc;
				integrations.forEach(integration => {
					if (acc.addedIntegrations.has(integration.id)) return;
					acc.addedIntegrations = acc.addedIntegrations.add(integration.id);
					acc.targetIntegrations = acc.targetIntegrations.push(integration);
				});
				return acc;
			},
			{ addedIntegrations: Set<string>(), targetIntegrations: List<IntegrationModel>() }
		);
		return targetIntegrations;
	}, [targetToIntegrations]);

	return { targetIntegrations, targetToIntegrations };
};

const useNewRequestIntegrationActors = () => {
	const {
		state: { fullReceiverUser, receiverIntegrationActors, requestTargets, currentUser },
		actions: { addReceiverIntegrationActor }
	} = useNewRequestFormContext();
	const integrations = useIntegrations();

	const { targetIntegrations, targetToIntegrations } = useTargetIntegrations();

	const [apiMissingIntegrationActors, setApiMissingIntegrationActors] = useState(List<IntegrationModel>());

	// We need to filter out integration actors that have already been added in the accounts select(receiverIntegrationActors)
	const apiMissingIntegrationActorsWithoutSelected = useMemo(
		() =>
			apiMissingIntegrationActors?.filter(
				integration => !receiverIntegrationActors.keySeq().some(key => integration.id === key)
			),
		[apiMissingIntegrationActors, receiverIntegrationActors]
	);

	useAsyncImpact(async () => {
		if (!fullReceiverUser || requestTargets.size === 0 || !integrations) return;
		const missingActors = await getMissingActorIntegrationIds(
			requestTargets
				.map(target => ({
					id: target.id,
					type: target.type
				}))
				.toArray(),
			fullReceiverUser.id
		);
		setApiMissingIntegrationActors(
			List(missingActors.map(integrationId => integrations.get(integrationId)).filter(notEmpty))
		);
	}, [fullReceiverUser, requestTargets, integrations]);

	const { multipleAndMissingActorsIntegrationsWithSelected, isReceiverMissingActor } = useMemo(() => {
		if (!fullReceiverUser || targetIntegrations.size === 0)
			return {
				multipleAndMissingActorsIntegrationsWithSelected: null,
				isReceiverMissingActor: false
			};
		return targetIntegrations.reduce(
			(acc, integration) => {
				if (integration.manual || integration.virtual) return acc;
				const integrationActors = fullReceiverUser.integrationActors!.filter(
					actor => actor.integrationId === integration.id
				);
				const hasIntegration = acc.multipleAndMissingActorsIntegrationsWithSelected.some(
					multipleAndMissingActorsIntegration => multipleAndMissingActorsIntegration.id === integration.id
				);
				if (integrationActors.size === 1) {
					addReceiverIntegrationActor(integration.id, integrationActors.first()!.id);
				} else if (integrationActors.size === 0 && !integration.canCreateActors) {
					// Missing integrations actors
					if (!hasIntegration) {
						acc.multipleAndMissingActorsIntegrationsWithSelected =
							acc.multipleAndMissingActorsIntegrationsWithSelected.push(integration);
						if (currentUser?.id !== fullReceiverUser?.id) {
							acc.isReceiverMissingActor = true;
						}
					}
				} else if (integrationActors.size > 1) {
					// Multiple integrations actors
					if (!hasIntegration) {
						acc.multipleAndMissingActorsIntegrationsWithSelected =
							acc.multipleAndMissingActorsIntegrationsWithSelected.push(integration);
					}
				}
				return acc;
			},
			{
				isReceiverMissingActor:
					!!apiMissingIntegrationActorsWithoutSelected?.size && currentUser?.id !== fullReceiverUser?.id,
				multipleAndMissingActorsIntegrationsWithSelected: apiMissingIntegrationActorsWithoutSelected
			}
		);
	}, [
		addReceiverIntegrationActor,
		apiMissingIntegrationActorsWithoutSelected,
		currentUser?.id,
		fullReceiverUser,
		targetIntegrations
	]);

	const multipleAndMissingActorsIntegrations = useMemo(
		() =>
			multipleAndMissingActorsIntegrationsWithSelected?.filter(
				integration => !receiverIntegrationActors.has(integration.id)
			) || List<IntegrationModel>(),
		[multipleAndMissingActorsIntegrationsWithSelected, receiverIntegrationActors]
	);

	const isIntegrationActorsValid = useCallback(() => {
		if (
			!fullReceiverUser ||
			targetIntegrations.size === 0 ||
			!multipleAndMissingActorsIntegrations ||
			multipleAndMissingActorsIntegrations.size > 0
		) {
			return false;
		}
		return true;
	}, [fullReceiverUser, multipleAndMissingActorsIntegrations, targetIntegrations.size]);

	const [multipleActorsIntegrations, missingActorIntegrations] = useMemo(() => {
		const integrations = multipleAndMissingActorsIntegrationsWithSelected || List<IntegrationModel>();

		return integrations.partition(integration =>
			checkIfIntegrationMissingFromUserIntegrationActors(integration.id, fullReceiverUser)
		);
	}, [fullReceiverUser, multipleAndMissingActorsIntegrationsWithSelected]);

	const allMissingActorIntegrations = useMemo(() => {
		// In the future, we can avoid using concat if we no longer need apiMissingIntegrationActors
		const missingActorIntegrationsCombined = missingActorIntegrations?.concat(apiMissingIntegrationActors);
		if (!missingActorIntegrationsCombined) return null;

		return List(uniqBy(missingActorIntegrationsCombined.toArray(), integration => integration.id)).filter(integration =>
			checkIfIntegrationMissingFromUserIntegrationActors(integration.id, fullReceiverUser)
		);
	}, [apiMissingIntegrationActors, fullReceiverUser, missingActorIntegrations]);

	const receiverMissingActorIntegrationsIds = useMemo(
		() =>
			currentUser?.id !== fullReceiverUser?.id
				? allMissingActorIntegrations?.map(integration => integration.id).toSet()
				: Set<string>(),
		[currentUser?.id, fullReceiverUser?.id, allMissingActorIntegrations]
	);

	const missingActorsIntegrationIds = useMemo(() => {
		if (!missingActorIntegrations) return Set<string>();
		return missingActorIntegrations.map(integration => integration.id).toSet();
	}, [missingActorIntegrations]);

	const multipleActorsIntegrationIds = useMemo(() => {
		if (!multipleActorsIntegrations) return Set<string>();
		return multipleActorsIntegrations.map(integration => integration.id).toSet();
	}, [multipleActorsIntegrations]);

	// This will return the first integration id that has multiple or missing actors
	const getMultipleIntegrationIdByTarget = useCallback(
		(targetId: string) => {
			const integrations = targetToIntegrations.find(target => target.targetId === targetId)?.integrations;
			if (!integrations) return null;
			return (
				integrations.find(
					integration =>
						!!multipleAndMissingActorsIntegrations?.find(actorIntegration => actorIntegration.id === integration.id)
				)?.id || null
			);
		},
		[multipleAndMissingActorsIntegrations, targetToIntegrations]
	);

	return {
		isIntegrationActorsValid,
		multipleAndMissingActorsIntegrations,
		multipleAndMissingActorsIntegrationsWithSelected,
		missingActorIntegrations,
		allMissingActorIntegrations,
		receiverMissingActorIntegrationsIds,
		getMultipleIntegrationIdByTarget,
		isReceiverMissingActor,
		apiMissingIntegrationActors,
		missingActorsIntegrationIds,
		multipleActorsIntegrationIds
	};
};

/* 
	This hook is for managing the state of the choose actor modals
 	There could be multiple or missing actors, the first time they open should be after onSubmit click for the wizard
	When the first modal is closed, the next one should open, and so on
	If the actors problem is not dealt with, the modals should be opened manually by clicking on the relevant request target
*/
const useChooseActorModals = (
	multipleAndMissingActorsIntegrations: List<IntegrationModel> | null,
	getTargetIntegrationId: (targetId: string) => string | null,
	isSequentialModalOpen: boolean
) => {
	const chooseActorModal = useIsOpenState();
	const [shownModals, setShownModals] = useState(Set<string>());
	const passedAllModalsRef = useRef(false);
	const passedFirstModalRef = useRef(false);

	const [modalToShow, setModalToShow] = useState<string | null>(null);

	const openModal = useCallback(
		(nextModal: IntegrationModel) => {
			setShownModals(current => current.add(nextModal.id));
			chooseActorModal.open();
		},
		[chooseActorModal]
	);

	const showNext = useCallback(() => {
		chooseActorModal.close();
		const nextModal = multipleAndMissingActorsIntegrations?.find(integration => !shownModals.has(integration.id));
		if (nextModal && !passedAllModalsRef.current) {
			setModalToShow(nextModal.id);
			if (!passedFirstModalRef.current) {
				openModal(nextModal);
				passedFirstModalRef.current = true;
				return;
			}
			setTimeout(() => openModal(nextModal), MODAL_SHOW_DELAY_MS);
		} else {
			passedAllModalsRef.current = true;
		}
	}, [chooseActorModal, multipleAndMissingActorsIntegrations, openModal, shownModals]);

	const open = useCallback(
		(id?: string) => {
			if (id) {
				if (multipleAndMissingActorsIntegrations?.find(integration => integration.id === id)) {
					setModalToShow(id);
					chooseActorModal.open();
				} else {
					const integrationId = getTargetIntegrationId(id);
					if (
						!integrationId ||
						!multipleAndMissingActorsIntegrations?.find(integration => integration.id === integrationId)
					) {
						return;
					}
					setModalToShow(integrationId);
					chooseActorModal.open();
				}
			} else {
				showNext();
			}
		},
		[chooseActorModal, getTargetIntegrationId, multipleAndMissingActorsIntegrations, showNext]
	);

	return useMemo(
		() => ({
			open,
			modalToShow,
			onClose: isSequentialModalOpen ? showNext : chooseActorModal.close,
			isOpen: chooseActorModal.isOpen,
			passedAllModalsRef
		}),
		[chooseActorModal.close, chooseActorModal.isOpen, isSequentialModalOpen, modalToShow, open, showNext]
	);
};

export const useHandleNewRequestIntegrationActors = () => {
	const [actorModalsShown, setActorModalsShown] = useState(false);
	const isSequentialModalOpen = useRef(false);
	const openGlobalErrorModal = useOpenGlobalErrorModal();
	const { user } = useAuthenticatedUser();
	const updateUser = useUpdateUser();
	const {
		state: { receiverIntegrationActors }
	} = useNewRequestFormContext();
	const {
		isIntegrationActorsValid,
		multipleAndMissingActorsIntegrations,
		multipleAndMissingActorsIntegrationsWithSelected,
		missingActorIntegrations,
		allMissingActorIntegrations,
		receiverMissingActorIntegrationsIds,
		getMultipleIntegrationIdByTarget,
		isReceiverMissingActor,
		apiMissingIntegrationActors,
		missingActorsIntegrationIds,
		multipleActorsIntegrationIds
	} = useNewRequestIntegrationActors();
	const intervalRef = useRef<number>();

	const receiverMissingActorsModal = useIsOpenState();
	const chooseActorModal = useChooseActorModals(
		multipleAndMissingActorsIntegrations,
		getMultipleIntegrationIdByTarget,
		isSequentialModalOpen.current
	);

	const delayedOpenMissingActorModal = useCallback(() => {
		if (chooseActorModal.passedAllModalsRef.current) {
			clearInterval(intervalRef.current);
			intervalRef.current = undefined;
			setActorModalsShown(true);
			isSequentialModalOpen.current = false;
		}
	}, [chooseActorModal]);

	const showMissingAndChoiceModals = useCallback(() => {
		if (isReceiverMissingActor) {
			receiverMissingActorsModal.open();
			return;
		}
		if (multipleAndMissingActorsIntegrations) {
			chooseActorModal.open();
			isSequentialModalOpen.current = true;
			if (multipleAndMissingActorsIntegrations?.size) {
				intervalRef.current = window.setInterval(delayedOpenMissingActorModal, MODAL_SHOW_DELAY_MS);
			} else {
				setActorModalsShown(true);
			}
		}
	}, [
		chooseActorModal,
		delayedOpenMissingActorModal,
		isReceiverMissingActor,
		multipleAndMissingActorsIntegrations,
		receiverMissingActorsModal
	]);

	const addUserIntegrationActors = useCallback(
		async (newIntegrationActorsIds: string[]) => {
			try {
				const userIntegrationActors = user?.integrationActors;
				const integrationActorsIds = uniq([
					...newIntegrationActorsIds,
					...(userIntegrationActors?.map(actor => actor.id) || [])
				]);
				const newUser = await changeProfile({
					integrationActors: integrationActorsIds
				});
				updateUser(newUser);
			} catch (error) {
				openGlobalErrorModal(error as Error);
			}
		},
		[openGlobalErrorModal, updateUser, user?.integrationActors]
	);

	const fillMissingActors = useCallback(async () => {
		const newIntegrationActorIds = receiverIntegrationActors
			.entrySeq()
			.filter(([key]) => apiMissingIntegrationActors?.some(integration => integration.id === key))
			?.map(([_key, actorId]) => actorId)
			.toArray();
		await addUserIntegrationActors(newIntegrationActorIds);
	}, [addUserIntegrationActors, apiMissingIntegrationActors, receiverIntegrationActors]);

	return {
		actorModalsShown,
		chooseActorModal,
		isIntegrationActorsValid,
		multipleAndMissingActorsIntegrations,
		multipleAndMissingActorsIntegrationsWithSelected,
		missingActorIntegrations,
		allMissingActorIntegrations,
		receiverMissingActorIntegrationsIds,
		receiverMissingActorsModal,
		showMissingAndChoiceModals,
		apiMissingIntegrationActors,
		fillMissingActors,
		missingActorsIntegrationIds,
		multipleActorsIntegrationIds
	};
};

export const SUMMARY_STEP_ACTOR_ERROR = "ACTOR_ERROR";
type TActorError = typeof SUMMARY_STEP_ACTOR_ERROR;

export const useNewRequestSubmit = () => {
	const [searchParams] = useSearchParams();
	const { isLoading: submitIsLoading, withLoader: withSubmitLoader } = useLoadingState();
	const [error, setError] = useState<TActorError | "UNKNOWN_ERROR" | undefined>();
	const [success, setSuccess] = useState(false);
	const originalTicketId = useMemo(() => searchParams.get("originalTicket"), [searchParams]);
	const {
		state: {
			isFormValid,
			duration,
			justification,
			receiverIntegrationActors,
			receiverUser,
			requestTargets,
			ticketingIntegrationTicketId
		}
	} = useNewRequestFormContext();

	const onSubmit = useCallback(async () => {
		if (!isFormValid) return;
		setSuccess(false);
		setError(undefined);
		try {
			const normalizedTargets = requestTargets
				.map(target => {
					return {
						type: target.type,
						id: target.id
					};
				})
				.toArray();
			await withSubmitLoader(
				createNewTicket({
					comment: escapeJson(justification),
					ticketingIntegrationTicketId: ticketingIntegrationTicketId ?? undefined,
					// isFormValid validates that the following are not empty
					duration: duration!,
					receiverId: receiverUser!.id,
					receiverIntegrationActorIds: receiverIntegrationActors.toJS(),
					targets: normalizedTargets
				})
			);
			if (originalTicketId) {
				await respondToTicket(originalTicketId, false);
			}
			setSuccess(true);
		} catch (error) {
			const apiError = error as ApiError;
			if (
				apiError.errorId &&
				apiError.params &&
				apiError.errorId === "user.actor.notFound" &&
				apiError.params.integrationId
			) {
				setError("ACTOR_ERROR");
			} else {
				setError("UNKNOWN_ERROR");
			}
		}
	}, [
		duration,
		isFormValid,
		justification,
		originalTicketId,
		receiverIntegrationActors,
		receiverUser,
		requestTargets,
		ticketingIntegrationTicketId,
		withSubmitLoader
	]);

	return { error, onSubmit, setError, submitIsLoading, success };
};

const usePrePopulatedData = (data: IUsePrePopulateData = {}) => {
	const receiver = useUser(data.receiverId);
	const {
		state: { fullReceiverUser, requestTargets, ticketingIntegrationTicketId, duration: selectedDuration },
		actions: { prePopulateForm, changeDuration }
	} = useNewRequestFormContext();
	const {
		data: rolesByResources,
		loadingSpecificIdsState: rolesLoadingState,
		fetchRoleIds
	} = useNewRequestIntegrationResourceRoles();
	const { allData: allBundles, fetchBundleIds, loadingSpecificIdsState: bundlesLoadingState } = useNewRequestBundles();
	const { loadingState: durationsLoadingState } = useAvailableDurations(requestTargets, fullReceiverUser);

	const [isPrePopulated, setIsPrePopulated] = useState(!!requestTargets.size);
	const isDataValid = useMemo(() => !!Object.keys(data).length, [data]);
	const rolesById = useMemo(() => {
		if (!rolesByResources.size) return Map<string, IntegrationResourceRoleModel>();
		return rolesByResources
			.valueSeq()
			.flatMap(roles => roles.integrationResourceRoles.valueSeq())
			.reduce((acc, role) => acc.set(role.id, role), Map<string, IntegrationResourceRoleModel>());
	}, [rolesByResources]);

	// requests targets loading may be successfull or result in error - both cases are considered as finished loading
	const requestTargetsFinishedLoading = useMemo(() => {
		if (isEmpty(data)) return true;
		if (data.bundleIds?.length) {
			if (bundlesLoadingState === "Initial" || bundlesLoadingState === "Loading") {
				return false;
			}
			if (bundlesLoadingState === "Loaded" && !requestTargets.size) {
				return false;
			}
		}

		if (data.roles?.length) {
			if (rolesLoadingState === "Initial" || rolesLoadingState === "Loading") {
				return false;
			}
			if (rolesLoadingState === "Loaded" && !requestTargets.size) {
				return false;
			}
		}
		return true;
	}, [bundlesLoadingState, data, requestTargets.size, rolesLoadingState]);

	const requestTargetsSuccessfullyLoaded = requestTargetsFinishedLoading && !!requestTargets.size;
	const isLoadingDurations =
		durationsLoadingState === "Loading" || (durationsLoadingState === "Initial" && requestTargetsSuccessfullyLoaded);

	const isLoading = !requestTargetsFinishedLoading || isLoadingDurations;

	const userId = useMemo(() => data.receiverId || fullReceiverUser?.id, [data.receiverId, fullReceiverUser]);

	const isNotAllowed = useMemo(() => {
		if (
			!data ||
			isLoading ||
			isPrePopulated ||
			(data.roles?.length && rolesLoadingState === "Initial") ||
			(data.bundleIds?.length && bundlesLoadingState === "Initial")
		) {
			return false;
		}
		const { roles, bundleIds } = data;
		const [bundleTargets, roleTargets] = requestTargets.partition(target => target.type === "role");
		const isRolesValid = !roles || roles.length === roleTargets.size;
		const isBundlesValid = !bundleIds || bundleIds.length === bundleTargets.size;
		return !isRolesValid || !isBundlesValid;
	}, [bundlesLoadingState, isPrePopulated, isLoading, requestTargets, rolesLoadingState, data]);

	useEffect(() => {
		if (!userId || !isDataValid || isPrePopulated) return;
		const { bundleIds, roles } = data;
		const uniqueRoleIds = data?.roles
			? Array.from(Set(data.roles.flatMap(role => [role.id]) || [])).filter(Boolean)
			: [];
		if (bundleIds?.length) {
			void fetchBundleIds({ ids: bundleIds, userId });
		}
		if (roles?.length) {
			void fetchRoleIds({ ids: uniqueRoleIds, userId });
		}
	}, [fetchBundleIds, fetchRoleIds, fullReceiverUser, isPrePopulated, isDataValid, data, userId]);

	useEffect(() => {
		if (!fullReceiverUser || !isDataValid || isPrePopulated || (data.receiverId && !receiver)) return;
		const { bundleIds, roles } = data;

		const form: TPrePopulatedFormDataMandateTargets = {
			targets: List<TRequestTarget>(),
			justification: data.justification || "",
			receiverUser: data.receiverId && receiver ? receiver : fullReceiverUser,
			ticketingIntegrationTicketId: data.ticketingIntegrationTicketId || ""
		};

		if (bundleIds && allBundles.bundles.size) {
			bundleIds.forEach(bundleId => {
				const fullTarget = allBundles.bundles.get(bundleId);
				if (!fullTarget) return;
				const target: TRequestTarget = { id: bundleId, type: "bundle", fullTarget: fullTarget };
				form.targets = form.targets.push(target);
			});
		}
		if (roles?.length && rolesById.size) {
			roles.forEach(role => {
				const fullTarget = rolesById.get(role.id) as
					| Require<IntegrationResourceRoleModel, "integrationResource">
					| undefined;
				if (!fullTarget) return;
				const target: TRequestTarget = {
					id: role.id,
					type: "role",
					integrationId: fullTarget.integrationResource.integrationId,
					resourceId: fullTarget.integrationResource.id,
					fullTarget
				};
				form.targets = form.targets.push(target);
			});
		}
		const [bundleTargets, roleTargets] = form.targets.partition(target => target.type === "role");
		const isRolesValid = !roles || roles.length === roleTargets.size;
		const isBundlesValid = !bundleIds || bundleIds.length === bundleTargets.size;
		if (isRolesValid && isBundlesValid) {
			prePopulateForm(form);
			setIsPrePopulated(true);
		}
	}, [
		allBundles,
		bundlesLoadingState,
		fullReceiverUser,
		isPrePopulated,
		prePopulateForm,
		requestTargets,
		rolesById,
		ticketingIntegrationTicketId,
		data,
		isDataValid,
		receiver,
		durationsLoadingState
	]);

	useEffect(() => {
		const { duration } = data;
		if (!duration || selectedDuration || durationsLoadingState !== "Loaded") return;
		changeDuration(duration);
	}, [changeDuration, data, durationsLoadingState, prePopulateForm, selectedDuration]);

	return {
		isNotAllowed,
		isLoading
	};
};

export const useSwitchablePrePopulatedData = () => {
	const [searchParams] = useSearchParams();
	const originalTicketId = searchParams.get("originalTicket");
	const [originalTicketFormData, setOriginalTicketFormData] = useState<IUsePrePopulateData>({});
	const urlFormData = useMemo(() => urlFieldsParser(searchParams) || {}, [searchParams]);

	const {
		data: ticket,
		fetch: fetchOriginalTicket,
		loadingState: originalTicketLoadingState
	} = useNewRequestOriginalTicket();

	const data = useMemo(
		() => (originalTicketId ? originalTicketFormData : urlFormData),
		[originalTicketFormData, originalTicketId, urlFormData]
	);
	const { isLoading: isLoadingPrepopulatedData, isNotAllowed } = usePrePopulatedData(data);
	useEffect(() => {
		if (originalTicketId && originalTicketLoadingState === "Initial") {
			void fetchOriginalTicket({ id: originalTicketId });
		}
	}, [fetchOriginalTicket, originalTicketId, originalTicketLoadingState]);

	useEffect(() => {
		if (!originalTicketId || !ticket || !ticket.targets || !isEmpty(originalTicketFormData)) return;
		const [bundleTargets, roleTargets] = ticket.targets.partition(target => target.type === "role");

		const form: IUsePrePopulateData = {
			duration: ticket.duration,
			receiverId: ticket.receiverId,
			justification: ticket.justification,
			roles: roleTargets.map(target => ({ id: target.targetId })).toArray(),
			bundleIds: bundleTargets.map(target => target.targetId).toArray(),
			ticketingIntegrationTicketId: ticket.ticketingIntegrationTicket?.id
		};
		setOriginalTicketFormData(form);
	}, [originalTicketId, originalTicketFormData, ticket]);

	const isLoadingOriginalTicket = Boolean(originalTicketId) && isEmpty(originalTicketFormData);

	const isLoading = isLoadingOriginalTicket || isLoadingPrepopulatedData;

	const hasPrePopulatedData = Boolean(originalTicketId) || !isEmpty(data);

	return {
		isLoading,
		hasPrePopulatedData,
		isNotAllowed
	};
};

export const useIntegrationActorsSelect = (integrationId: string | undefined) => {
	const {
		state: { fullReceiverUser }
	} = useNewRequestFormContext();

	const fetchActors = useCallback(async () => {
		if (!integrationId) return null;
		return getIntegrationActors(integrationId);
	}, [integrationId]);

	const { data: actors, isLoading, loadData } = useFetchedState(fetchActors);

	const currentUserIntegrationActors = useMemo(() => {
		if (!fullReceiverUser?.integrationActors || !integrationId) return null;
		return getIntegrationInUserIntegrationActors(integrationId, fullReceiverUser);
	}, [fullReceiverUser, integrationId]);

	const isMissingActors = currentUserIntegrationActors?.size === 0;
	const isMultipleActors = (currentUserIntegrationActors?.size || 0) > 1;

	useEffect(() => {
		if (isMissingActors) void loadData();
	}, [integrationId, isMissingActors, loadData]);

	return {
		actors: isMissingActors ? actors : currentUserIntegrationActors,
		isMultipleActors,
		isLoading
	};
};
