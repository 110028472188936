import classNames from "classnames";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNewRequestFormContext } from "components/pages/NewRequestPage/newRequestFormContext";
import { Field } from "components/ui/Field";
import { Select } from "components/ui/Select";
import { AccountLabelWithoutTooltipOnOverflow } from "components/ui/selectLabels/AccountLabel";
import { AccountOption } from "components/ui/selectOptions/AccountOption";
import { Tooltip } from "components/ui/Tooltip";
import { useIntegrations } from "hooks/useIntegrations";
import { sortByDisplayName } from "utils/sortUtils";
import { useStyles } from "./styles";
import { useIntegrationActorsSelect } from "../../../../summaryStep.hooks";
import type { IntegrationActorModel } from "models/IntegrationActorModel";

type TAccountsSelectProps = {
	integrationId: string;
	disabled?: boolean;
	isSelectionRequired?: boolean;
	tooltipContent?: string;
	openModal?: () => void;
};

const TRANSLATION_PREFIX = "pages.newRequest.summaryStep.requestTargetsSection.requestTarget.accountSelectInput";
const MAX_OPTIONS = 50;

const isEqual = (option: IntegrationActorModel, value: IntegrationActorModel) => option.id === value.id;
const getLabel = (option: IntegrationActorModel) => option.displayName;

export const AccountsSelect: FC<TAccountsSelectProps> = ({
	className,
	innerRef,
	integrationId,
	disabled = false,
	isSelectionRequired = false,
	tooltipContent,
	openModal
}) => {
	const { t } = useTranslation("translation", { keyPrefix: TRANSLATION_PREFIX });
	const { t: globalTranslation } = useTranslation();
	const classes = useStyles();
	const [query, setQuery] = useState("");
	const integrations = useIntegrations();
	const integration = integrations?.get(integrationId);

	const {
		state: { receiverIntegrationActors },
		actions: { addReceiverIntegrationActor, removeReceiverIntegrationActor }
	} = useNewRequestFormContext();

	const { actors, isLoading } = useIntegrationActorsSelect(integrationId);

	// Slice because actors are not paginated list and might be in large numbers
	const options = useMemo(
		() =>
			actors
				?.filter(actor => actor.displayName.toLowerCase().includes(query.toLowerCase()))
				?.slice(0, MAX_OPTIONS)
				.toArray() || [],
		[actors, query]
	);

	const value = useMemo(() => {
		const selectedIntegrationActorId = receiverIntegrationActors.entrySeq().find(([key]) => key === integrationId)?.[1];
		if (!selectedIntegrationActorId) return null;
		return options.find(option => option.id === selectedIntegrationActorId);
	}, [integrationId, options, receiverIntegrationActors]);

	const handleValueChange = useCallback(
		(actor: IntegrationActorModel | null) => {
			if (actor) {
				addReceiverIntegrationActor(integrationId, actor.id);
			} else {
				removeReceiverIntegrationActor(integrationId);
			}

			setQuery("");
		},
		[removeReceiverIntegrationActor, integrationId, addReceiverIntegrationActor]
	);

	const isSingleOption = options.length === 1;
	const isHidden = (!isSelectionRequired && !isSingleOption) || !integration?.canEditPermissions || integration?.manual;
	const content = useMemo(() => {
		if (isSingleOption && value) {
			return (
				<Field>
					<AccountLabelWithoutTooltipOnOverflow value={value} />
				</Field>
			);
		} else if (openModal && !value) {
			return (
				<div onClick={!disabled ? openModal : undefined} className={classes.openMissingAccount}>
					<Field error disabled={disabled}>
						{t("selectMissingAccount")}
					</Field>
				</div>
			);
		}
		return (
			<Select
				getOptionLabel={getLabel}
				options={options}
				placeholder={!disabled ? globalTranslation("common.accountSelectInput.placeholder") : ""}
				innerRef={innerRef}
				onChange={handleValueChange}
				renderOption={AccountOption}
				isOptionEqualToValue={isEqual}
				renderLabel={AccountLabelWithoutTooltipOnOverflow}
				sort={sortByDisplayName}
				value={value}
				inputValue={query}
				loading={isLoading}
				disabled={disabled}
				error={disabled || (!isSingleOption && !value)}
				noClear
			/>
		);
	}, [
		classes.openMissingAccount,
		disabled,
		globalTranslation,
		handleValueChange,
		innerRef,
		isLoading,
		isSingleOption,
		openModal,
		options,
		query,
		t,
		value
	]);

	return (
		<div className={classNames(className, { [classes.hide]: isHidden })} ref={innerRef}>
			<Tooltip content={tooltipContent} className={classes.tooltip} placement="top-start">
				{content}
			</Tooltip>
		</div>
	);
};
