import { List } from "immutable";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import {
	deleteIntegrationResource,
	editIntegrationResource,
	TIntegrationResourceBody,
	addResourcePermission as apiAddResourcePermission,
	deleteResourcePermission as apiDeleteResourcePermission
} from "api/integrationResources";
import { ApprovalAlgorithmBlock, INHERIT_FROM_PARENT_ID } from "components/common/ApprovalAlgorithmBlock";
import { AreYouSureModal } from "components/common/AreYouSureModal";
import { Breadcrumbs } from "components/common/Breadcrumbs";
import { GiveAccessSection } from "components/common/GiveAccessSection";
import { HasAccessFromSection } from "components/common/HasAccessFromSection";
import { PrerequisitePermissionsBlock } from "components/common/PrerequisitePermissionsBlock";
import { RequestDetails } from "components/common/RequestDetails";
import { PageTemplate } from "components/templates/PageTemplate";
import { LoadingSpinner } from "components/ui/LoadingSpinner";
import { useIntegrationsContext } from "context/integrationsContext";
import { useAsyncImpact } from "hooks/useAsyncImpact";
import { useIntegrationResource } from "hooks/useIntegrationResource";
import { useIntegrations } from "hooks/useIntegrations";
import { useIsOpenState } from "hooks/useIsOpenState";
import { useLoadingState } from "hooks/useLoadingState";
import { useStopPropagation } from "hooks/useStopPropagation";
import { useUser } from "hooks/useUser";
import { UserMaintainerModel } from "models/UserMaintainerModel";
import { GiveAccessModal } from "./components/GiveAccessModal";
import { HasAccessModal } from "./components/HasAccessModal";
import { PermissionsSection } from "./components/PermissionsSection";
import { ResourceHeaderBlock } from "./components/ResourceHeaderBlock";
import { RolesSection } from "./components/RolesSection";
import { useStyles } from "./styles";
import { getEntitlementsByUsers, IEntitlementsByUser } from "./utils";
import { MaintainersSection } from "../IntegrationPage/components/MaintainersSection";

type TAreYouSureModalMode = "delete" | "hideResource";

export const IntegrationResourcePage: FC = ({ className }) => {
	const params = useParams<{ integrationResourceId: string }>();
	const {
		addResourcePermission,
		loadResource,
		resource: integrationResource,
		deleteResourcePermission
	} = useIntegrationResource(params?.integrationResourceId || "", true, false, true);
	const integrations = useIntegrations();
	const [entitlementsByUsers, setEntitlementsByUsers] = useState<List<IEntitlementsByUser> | null | undefined>(null);

	const {
		actions: { loadIntegration }
	} = useIntegrationsContext();
	const [prerequisitePermissionId, setPrerequisitePermissionId] = useState<string | null>(
		integrationResource?.prerequisitePermissionId || null
	);

	const areYouSureModal = useIsOpenState();
	const givesAccessModal = useIsOpenState();
	const hasAccessFromModal = useIsOpenState();
	const { withLoader: withLoadAddResourcePermission, isLoading: addResourcePermissionIsLoading } = useLoadingState();
	const { withLoader: withLoaderFetchUserEntitlements, isLoading: fetchUserEntitlementIsLoading } = useLoadingState();

	const [areYouSureModalMode, setAreYouSureModalMode] = useState<TAreYouSureModalMode | null>(null);

	const classes = useStyles();
	const { t } = useTranslation();
	const navigate = useNavigate();

	const closeModal = useCallback(() => {
		setAreYouSureModalMode(null);
		areYouSureModal.close();
	}, [areYouSureModal]);

	const areYouSureModalProps = useMemo(() => {
		if (!integrationResource || areYouSureModalMode === "delete") return undefined;
		const prefix = integrationResource.allowsRequests ? "hide" : "show";
		return {
			content: t(`pages.integration.${prefix}ResourceConfirm`),
			actionLabel: t(`pages.integration.${prefix}Resource`)
		};
	}, [areYouSureModalMode, integrationResource, t]);

	const integration = useMemo(() => {
		if (integrationResource && integrations) {
			return integrations.get(integrationResource.integrationId);
		}
		return null;
	}, [integrationResource, integrations]);

	const approvalAlgorithmId = useMemo(() => {
		return integrationResource?.approvalAlgorithmId || integration?.defaultApprovalAlgorithm?.id || null;
	}, [integrationResource, integration]);

	useAsyncImpact(async () => {
		if (integrationResource) {
			const entitlements = await withLoaderFetchUserEntitlements(getEntitlementsByUsers(integrationResource));
			setEntitlementsByUsers(entitlements);
		}
	}, [integrationResource, withLoaderFetchUserEntitlements]);

	const owner = useUser(integrationResource?.ownerId);

	const onEditResource = useCallback(
		async (resource: Partial<TIntegrationResourceBody>) => {
			if (!params?.integrationResourceId) {
				return;
			}
			await editIntegrationResource({ id: params.integrationResourceId, ...resource });
			void loadResource();
		},
		[loadResource, params.integrationResourceId]
	);

	const onDeleteResource = useCallback(async () => {
		if (integrationResource) {
			await deleteIntegrationResource(integrationResource.id);
			void loadIntegration(integrationResource.integrationId);
		}
		closeModal();
		if (!integration) {
			return;
		}
		navigate(`/integrations/${integration.id}`);
	}, [integrationResource, closeModal, integration, navigate, loadIntegration]);

	const onHideResourceToggle = useCallback(async () => {
		if (integrationResource) {
			await onEditResource({ allowsRequests: !integrationResource.allowsRequests });
			void loadIntegration(integrationResource.integrationId);
		}
		closeModal();
	}, [closeModal, integrationResource, loadIntegration, onEditResource]);

	const areYouSureModalActionsMap = useMemo(
		() =>
			new Map<TAreYouSureModalMode, () => Promise<void>>([
				["delete", onDeleteResource],
				["hideResource", onHideResourceToggle]
			]),
		[onDeleteResource, onHideResourceToggle]
	);

	const onApprovalAlgorithmChange = useCallback(
		(id: string) => {
			void onEditResource({ approvalAlgorithmId: id === INHERIT_FROM_PARENT_ID ? null : id });
		},
		[onEditResource]
	);

	const onRename = useCallback((name: string) => onEditResource({ name }), [onEditResource]);

	const openAreYouSureModal = useCallback(
		(mode: TAreYouSureModalMode) => {
			setAreYouSureModalMode(mode);
			areYouSureModal.open();
		},
		[areYouSureModal]
	);

	const openDeleteModal = useCallback(() => openAreYouSureModal("delete"), [openAreYouSureModal]);

	const updateMaintainers = useCallback(
		async (maintainers: List<TMaintainerModel>) => {
			if (!integrationResource) return;
			const mappedMaintainers = maintainers
				.map(m => ({
					id: m.entityId,
					type: m instanceof UserMaintainerModel ? ("user" as const) : ("directoryGroup" as const)
				}))
				.toArray();
			await onEditResource({
				id: integrationResource.id,
				maintainers: mappedMaintainers
			});
		},
		[integrationResource, onEditResource]
	);

	const addAccess = useCallback(
		async (roleId: string, resourceId: string) => {
			if (!integrationResource) return;
			if (resourceId === integrationResource.id) {
				await withLoadAddResourcePermission(addResourcePermission(roleId));
			} else {
				await withLoadAddResourcePermission(apiAddResourcePermission(resourceId, roleId));
			}
			void loadResource();
		},
		[integrationResource, withLoadAddResourcePermission, addResourcePermission, loadResource]
	);

	const openGivesAccessToModal = useStopPropagation(givesAccessModal.open);

	const openHasAccessFromModal = useStopPropagation(hasAccessFromModal.open);

	const headerBlockAdapterlessProps = integration?.adapterless
		? {
				onDeleteButtonClick: openDeleteModal,
				onRename
			}
		: {};

	useEffect(() => {
		if (integrationResource) setPrerequisitePermissionId(integrationResource.prerequisitePermissionId);
	}, [integrationResource]);

	const breadcrumbs = useMemo(() => {
		if (!integration || !integrationResource) return [];
		return [
			{
				title: t("navigation.integrations"),
				url: "/integrations"
			},
			{
				title: integration.name,
				url: `/integrations/${integration.id}`
			},
			{
				title: integrationResource.name
			}
		];
	}, [integration, integrationResource, t]);

	const totalAccessCount = useMemo(() => {
		return integrationResource?.integrationResourceRoles?.reduce((sum, role) => {
			return sum + (role.hasAccessFromResources?.size || 0) + (role.hasAccessFromRoles?.size || 0);
		}, 0);
	}, [integrationResource?.integrationResourceRoles]);

	const removeAccess = useCallback(
		async (roleId: string, resourceId: string) => {
			if (!integrationResource) return;
			await apiDeleteResourcePermission(resourceId, roleId);
			await loadResource();
		},
		[integrationResource, loadResource]
	);

	const showGivesAccessTable = useMemo(
		() =>
			(integrationResource &&
				integration &&
				!integration.virtual &&
				integrationResource &&
				integrationResource.givesAccessToRoles &&
				integrationResource.givesAccessToRoles.size !== 0) ||
			integration?.manual,
		[integrationResource, integration]
	);
	const showHasAccessFromTable = useMemo(
		() =>
			(integrationResource &&
				integrationResource &&
				integration &&
				!integration.virtual &&
				totalAccessCount &&
				totalAccessCount > 0) ||
			integration?.manual,
		[integrationResource, integration, totalAccessCount]
	);

	return (
		<PageTemplate subPage className={className}>
			<RequestDetails />
			<AreYouSureModal
				{...areYouSureModalProps}
				isOpen={areYouSureModal.isOpen && Boolean(areYouSureModalMode)}
				onClose={closeModal}
				onAction={areYouSureModalActionsMap.get(areYouSureModalMode!)!}
			/>
			<GiveAccessModal
				isOpen={givesAccessModal.isOpen}
				onClose={givesAccessModal.close}
				addResourcePermission={addAccess}
				isLoading={addResourcePermissionIsLoading}
				originResourceId={integrationResource?.id || ""}
			/>

			<HasAccessModal
				isOpen={hasAccessFromModal.isOpen}
				onClose={hasAccessFromModal.close}
				addResourcePermission={addAccess}
				isLoading={addResourcePermissionIsLoading}
				originResourceId={integrationResource?.id || ""}
			/>

			<PageTemplate.Title>
				<Breadcrumbs parts={breadcrumbs} />
			</PageTemplate.Title>
			<PageTemplate.Content noBorder noBackground className={classes.page}>
				{integrationResource && integration ? (
					<div className={classes.resourcePage}>
						<ResourceHeaderBlock
							integrationResource={integrationResource}
							adapterless={integration.adapterless}
							onSave={onEditResource}
							owner={owner || null}
							inherited={!integrationResource.ownerId}
							type={integrationResource?.type}
							{...headerBlockAdapterlessProps}
						/>
						<div className={classes.content}>
							{approvalAlgorithmId && (
								<ApprovalAlgorithmBlock
									rounded
									approvalAlgorithmId={approvalAlgorithmId}
									onChangeAlgorithm={onApprovalAlgorithmChange}
									inheritOptionText={t("pages.integration.resource.inherit")}
								/>
							)}
							<RolesSection
								integrationResource={integrationResource}
								onChange={loadResource}
								manual={integration.manual}
								virtual={integration.virtual}
								withUnmanaged
							/>
							{showGivesAccessTable && (
								<GiveAccessSection
									givesAccessRoles={integrationResource.givesAccessToRoles}
									openGivesAccessToModal={openGivesAccessToModal}
									manual={integration.manual}
									onDelete={integration.manual ? deleteResourcePermission : undefined}
								/>
							)}
							{showHasAccessFromTable && (
								<HasAccessFromSection
									integrationResource={integrationResource}
									roles={integrationResource.integrationResourceRoles}
									openHasAccessFromModal={openHasAccessFromModal}
									totalAccessCount={totalAccessCount}
									manual={integration.manual}
									onDelete={integration.manual ? removeAccess : undefined}
								/>
							)}
							{entitlementsByUsers && entitlementsByUsers.size > 0 && (
								<PermissionsSection entitlements={entitlementsByUsers} isLoading={fetchUserEntitlementIsLoading} />
							)}
							{integrationResource && (
								<MaintainersSection
									autoAssigned={integration.autoAssignRecommendedResourceMaintainers}
									maintainers={integrationResource.maintainers}
									onUpdate={updateMaintainers}
									entity={integrationResource}
								/>
							)}
							{!integration?.virtual && (
								<PrerequisitePermissionsBlock
									prerequisitePermissionId={prerequisitePermissionId}
									afterAction={setPrerequisitePermissionId}
									asset={{
										prohibitedIds: {
											integrationResourceIds: !integrationResource.multirole ? [integrationResource.id] : null
										},
										id: integrationResource.id,
										name: integrationResource.name,
										type: "integrationResources"
									}}
								/>
							)}
						</div>
					</div>
				) : (
					<LoadingSpinner className={classes.spinner} />
				)}
			</PageTemplate.Content>
		</PageTemplate>
	);
};
