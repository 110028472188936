import classNames from "classnames";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { AccountEntity } from "components/common/entities/AccountEntity";
import { IntegrationImage } from "components/common/IntegrationImage";
import { RoleBar, TRoleBarColumn } from "components/common/RoleBar";
import { ROLE_BAR_BUNDLE_WIDTH_PX, RoleBarBundle } from "components/common/RoleBar/components/RoleBarBundle";
import {
	ROLE_BAR_INTEGRATION_WIDTH,
	RoleBarIntegration
} from "components/common/RoleBar/components/RoleBarIntegration";
import { ROLE_BAR_RESOURCE_WIDTH, RoleBarResource } from "components/common/RoleBar/components/RoleBarResource";
import { ROLE_BAR_ROLE_WIDTH, RoleBarRole } from "components/common/RoleBar/components/RoleBarRole";
import { RoleIcon } from "components/ui/Icons/RoleIcon";
import { Tooltip } from "components/ui/Tooltip";
import { TooltipOnOverflow } from "components/ui/TooltipOnOverflow";
import { Typography } from "components/ui/Typography";
import { useBundles } from "hooks/useBundles";
import { useIntegrations } from "hooks/useIntegrations";
import { useStyles } from "./styles";
import { TICKET_PERMISSION_STATUS_ICON } from "../../ticketStatusIcons";
import type { ParseKeys, TOptions } from "i18next";
import type { List, Map } from "immutable";
import type { TFullIntegrationResourceRole } from "components/common/RequestDetails";
import type { IntegrationActorModel } from "models/IntegrationActorModel";
import type { IntegrationResourceModel } from "models/IntegrationResourceModel";
import type { IntegrationResourceRoleModel } from "models/IntegrationResourceRoleModel";
import type { TTicketPermissionStatus } from "models/TicketPermissionModel";
import type { TicketTargetModel } from "models/TicketTargetModel";

const ICON_SIZE_PX = 20;
const TOOLTIP_TRANSLATION_PREFIX = "common.requestDetails.requests.tooltipTitles.";
const ROLE_BAR_ACCOUNT_WIDTH = "minmax(164px, 400px)";

interface ITooltipSection {
	titleKey: string;
	content: string;
}

const ColumnTextWithTooltip: FC<
	{ content: React.ReactNode } & ({ tooltipSections: ITooltipSection[] } | { tooltipContent: React.ReactNode })
> = props => {
	const { content, className } = props;
	const classes = useStyles();
	const { t } = useTranslation();

	const tooltipContent = useMemo(
		() =>
			"tooltipSections" in props ? (
				<div className={classes.tooltipContainer}>
					{props.tooltipSections.map(({ titleKey, content }) => (
						<div key={titleKey} className={classes.tooltipSection}>
							<Typography variant="text_sm_sb">{t((TOOLTIP_TRANSLATION_PREFIX + titleKey) as ParseKeys)}</Typography>
							{typeof content === "string" ? <Typography variant="text_sm_reg">{content}</Typography> : content}
						</div>
					))}
				</div>
			) : (
				props.tooltipContent
			),
		[classes.tooltipContainer, classes.tooltipSection, props, t]
	);

	return (
		<Tooltip content={tooltipContent}>
			<Typography variant="body_reg" noWrap className={className}>
				{content}
			</Typography>
		</Tooltip>
	);
};

const IntegrationColumn: FC<{ integrationId: string }> = ({ integrationId }) => {
	const integrations = useIntegrations(true);
	const integration = integrations?.get(integrationId);
	const classes = useStyles();
	const { t } = useTranslation();

	if (!integrations) return null;

	return integration ? (
		<RoleBarIntegration imageUrl={integration.imageUrl} name={integration.name} isDeleted={integration.isDeleted} />
	) : (
		<div className={classes.columnContent}>
			<IntegrationImage noBackground className={classes.image} />
			<Typography noWrap variant="body_reg">
				{t("common.unknown.integration")}
			</Typography>
		</div>
	);
};

const ResourceColumn: FC<{ integrationResource?: IntegrationResourceModel }> = ({ integrationResource }) => {
	const classes = useStyles();
	const { t } = useTranslation();

	return integrationResource ? (
		<RoleBarResource
			name={integrationResource.name}
			euid={integrationResource.euid}
			description={integrationResource.description}
			type={integrationResource.type}
			isDeleted={integrationResource.isDeleted}
			tags={integrationResource.calculatedTags}
			className={classes.columnContent}
		/>
	) : (
		<div className={classes.columnContent}>
			<TooltipOnOverflow textVariant="body_reg" content={t("common.unknown.resource")} />
		</div>
	);
};

const RoleColumn: FC<{ integrationResourceRole?: IntegrationResourceRoleModel }> = ({ integrationResourceRole }) => {
	const classes = useStyles();
	const { t } = useTranslation();

	return integrationResourceRole ? (
		<RoleBarRole role={integrationResourceRole} />
	) : (
		<div className={classes.columnContent}>
			<RoleIcon size="large" />
			<TooltipOnOverflow textVariant="body_reg" content={t("common.unknown.role")} />
		</div>
	);
};

const BundleColumn: FC<{ bundleId?: string }> = ({ bundleId }) => {
	const bundles = useBundles(true);
	const bundle = bundles?.get(bundleId ?? "");

	const props = bundle ? { name: bundle.name, description: bundle.description } : {};

	return <RoleBarBundle {...props} />;
};

const StatusColumn: FC<{ status: TTicketPermissionStatus; ticketApproved?: boolean }> = ({
	status,
	ticketApproved
}) => {
	const classes = useStyles();
	const { t } = useTranslation("translation", { keyPrefix: "common.requestDetails.requestStatuses" });
	const Icon = TICKET_PERMISSION_STATUS_ICON.get(status)!;

	const tooltipSections = useMemo(() => {
		let translationKey = status;
		if (status === "pending") {
			translationKey += ticketApproved ? ".grant" : ".approval";
		}

		return [
			{
				content: t(translationKey as ParseKeys<"translation", TOptions, "common.requestDetails.requestStatuses">),
				titleKey: "status"
			} as ITooltipSection
		];
	}, [ticketApproved, status, t]);
	const content = useMemo(
		() => <Icon color={status === "pending" ? "var(--color-black)" : "var(--color-white)"} size={ICON_SIZE_PX} />,
		[Icon, status]
	);

	return (
		<ColumnTextWithTooltip
			className={classNames(classes.iconColumn, {
				[classes.failure]: status === "failed" || status === "revoked",
				[classes.success]: status === "granted"
			})}
			content={content}
			tooltipSections={tooltipSections}
		/>
	);
};

const RoleRowComponent: FC<{
	ticketApproved?: boolean;
	integrationActor: IntegrationActorModel | null;
	role: TFullIntegrationResourceRole;
	status: TTicketPermissionStatus;
	targetsById: Map<string, TicketTargetModel>;
	ticketTargetIds: List<string>;
	hasBundleColumn?: boolean;
}> = ({ role, integrationActor, status, targetsById, ticketTargetIds, hasBundleColumn, ticketApproved }) => {
	const roleRows: TRoleBarColumn[][] = useMemo(() => {
		const columns: TRoleBarColumn[] = [
			{
				content: <IntegrationColumn integrationId={role?.integrationResource?.integrationId || ""} />,
				width: ROLE_BAR_INTEGRATION_WIDTH
			},
			{
				content: <ResourceColumn integrationResource={role?.integrationResource} />,
				width: ROLE_BAR_RESOURCE_WIDTH
			},
			{ content: <RoleColumn integrationResourceRole={role} />, width: ROLE_BAR_ROLE_WIDTH },
			{
				content: integrationActor ? <AccountEntity actor={integrationActor} size="medium" withIcon /> : null,
				width: ROLE_BAR_ACCOUNT_WIDTH as `minmax(${string})`
			},
			{ content: <StatusColumn status={status} ticketApproved={ticketApproved} /> }
		];

		if (!hasBundleColumn) return [columns];
		const bundleTargetIds = ticketTargetIds.filter(id => targetsById.get(id)?.type === "bundle");
		const bundleTargets = bundleTargetIds.map(id => targetsById.get(id)!).toArray();

		if (bundleTargets?.length) {
			return bundleTargets.map(({ targetId }) => [
				{ content: <BundleColumn bundleId={targetId} />, width: ROLE_BAR_BUNDLE_WIDTH_PX, suffixDivider: true },
				...columns
			]);
		}

		return [[{ content: <BundleColumn />, width: ROLE_BAR_BUNDLE_WIDTH_PX, suffixDivider: false }, ...columns]];
	}, [hasBundleColumn, integrationActor, role, status, targetsById, ticketApproved, ticketTargetIds]);

	return (
		<>
			{roleRows.map((columns, index) => (
				<RoleBar key={index} selected id={role?.id} columns={columns} />
			))}
		</>
	);
};

export const RoleRow = React.memo(RoleRowComponent);
