import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	overflowContainer: {
		overflow: "auto",
		width: "100%",
		maxHeight: "100%"
	},
	listContainer: {
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-start",
		gap: "var(--spacing-x3, 12px)",
		minWidth: "984px",
		overflow: "auto",
		paddingBottom: "var(--spacing-x2, 8px)",
		width: "100%"
	},
	barContainer: {
		padding: "0px var(--spacing-x4, 16px)",
		gap: "var(--spacing-x5, 20px)",
		display: "grid",
		gridTemplateColumns: "80px 1px minmax(84px, 1fr) 1px minmax(272px, 1fr) 1px minmax(248px, 1fr) 40px",
		width: "100%"
	},
	headersContainer: {
		height: "20px",
		border: "2px solid var(--color-white)"
	},
	fullWidth: {
		width: "100%",
		height: "100%"
	},
	draggable: {
		"&:hover": {
			cursor: "grab"
		}
	},
	emptyTableMessage: {
		display: "flex",
		justifyContent: "center",
		color: "var(--color-grey-700, #5F6066)",
		paddingTop: "180px"
	}
});
