import classNames from "classnames";
import { List } from "immutable";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { CommonAccordion } from "components/common/CommonAccordion";
import { Button } from "components/ui/Button";
import { IconButton } from "components/ui/IconButton";
import { IconPrefix } from "components/ui/IconPrefix";
import { AddIcon } from "components/ui/Icons/AddIcon";
import { HasAccessFromIcon } from "components/ui/Icons/HasAccessFromIcon";
import { InfoIcon } from "components/ui/Icons/InfoIcon";
import { Tooltip } from "components/ui/Tooltip";
import { Typography } from "components/ui/Typography";
import { IntegrationResourceModel } from "models/IntegrationResourceModel";
import { IntegrationResourceRoleModel } from "models/IntegrationResourceRoleModel";
import { AccessRoles } from "./components/AccessRoles";
import { useStyles } from "./styles";

interface IProps {
	roles: List<IntegrationResourceRoleModel> | null;
	openHasAccessFromModal?: (e: React.MouseEvent<HTMLElement>) => void;
	totalAccessCount?: number;
	manual?: boolean;
	onDelete?: (roleId: string, resourceId: string) => Promise<void>;
	integrationResource: IntegrationResourceModel;
}

export const HasAccessFromSection: FC<IProps> = ({
	openHasAccessFromModal,
	totalAccessCount,
	manual = false,
	onDelete,
	integrationResource,
	roles
}) => {
	const classes = useStyles();
	const { t } = useTranslation();

	const title = useMemo(
		() => (
			<div className={classes.titleContainer}>
				<div className={classNames(classes.titlePart, classes.titleHeaderPart)}>
					<IconPrefix Icon={HasAccessFromIcon} content={t("pages.integrationResource.rolesAutomaticAccess")} />
					<Typography
						className={classes.childrenCount}
						variant="body_reg">{`( ${t("number", { value: totalAccessCount || 0 })} )`}</Typography>
					<Tooltip
						placement="top-start"
						content={
							<Typography variant="text_sm_reg">{t(`pages.integrationResource.hasAccessTooltipText`)}</Typography>
						}>
						<IconButton>
							<InfoIcon />
						</IconButton>
					</Tooltip>
				</div>
				{manual ? (
					<div className={classes.titlePart}>
						<Button onClick={openHasAccessFromModal} prefix={<AddIcon />} variant="secondary" size="medium">
							{t("buttons.add")}
						</Button>
					</div>
				) : null}
			</div>
		),
		[
			classes.childrenCount,
			classes.titleContainer,
			classes.titlePart,
			classes.titleHeaderPart,
			manual,
			openHasAccessFromModal,
			t,
			totalAccessCount
		]
	);
	return (
		<CommonAccordion title={title}>
			{roles ? (
				<AccessRoles integrationResource={integrationResource} roles={roles} manual={manual} onDelete={onDelete} />
			) : null}
		</CommonAccordion>
	);
};
