import { useEffect, RefObject } from "react";
import { usePropagationContext } from "context/propagationContext";

export function useOnClickOutside(
	ref: RefObject<HTMLElement> | HTMLElement | undefined,
	callback: (event: MouseEvent | TouchEvent) => void,
	wrapperRef?: RefObject<HTMLElement>
) {
	const {
		state: { refs: propagationRefs }
	} = usePropagationContext();

	useEffect(() => {
		const listener = (event: MouseEvent | TouchEvent) => {
			// Do nothing if clicking ref's element or descendent elements
			if (!ref || !callback) return;
			if (isRef(ref)) {
				if (!ref.current || ref.current.contains(event.target as Node)) {
					return;
				}
			} else if (ref.contains(event.target as Node)) {
				return;
			}

			callback(event);
		};

		const currentWrapper = wrapperRef?.current;
		if (currentWrapper) {
			currentWrapper.addEventListener("mousedown", listener);
			currentWrapper.addEventListener("touchstart", listener);
		}

		if (propagationRefs?.length > 0) {
			propagationRefs.forEach(ref => {
				if (isRef(ref)) {
					ref.current?.addEventListener("mousedown", listener);
					ref.current?.addEventListener("touchstart", listener);
				} else if (ref instanceof HTMLElement) {
					ref.addEventListener("mousedown", listener);
					ref.addEventListener("touchstart", listener);
				}
			});
		}
		document.addEventListener("mousedown", listener);
		document.addEventListener("touchstart", listener);

		return () => {
			if (currentWrapper) {
				currentWrapper.removeEventListener("mousedown", listener);
				currentWrapper.removeEventListener("touchstart", listener);
			}

			if (propagationRefs?.length > 0) {
				propagationRefs.forEach(ref => {
					if (isRef(ref)) {
						ref.current?.removeEventListener("mousedown", listener);
						ref.current?.removeEventListener("touchstart", listener);
					} else if (ref instanceof HTMLElement) {
						ref.removeEventListener("mousedown", listener);
						ref.removeEventListener("touchstart", listener);
					}
				});
			}
			document.removeEventListener("mousedown", listener);
			document.removeEventListener("touchstart", listener);
		};
	}, [ref, propagationRefs, callback, wrapperRef]);
}

const isRef = (value: unknown): value is RefObject<HTMLElement> => {
	return value instanceof Object && "current" in value;
};
