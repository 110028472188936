import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	container: {
		alignItems: "flex-start",
		display: "inline-flex",
		gap: "0.85rem",
		justifyContent: "center",
		flexDirection: "column",
		width: "100%"
	},
	childrenCount: {
		minWidth: "fit-content"
	},
	headerInfo: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		flexWrap: "wrap",
		gap: "var(--spacing-x3, 12px)",
		maxWidth: "100%",
		width: "100%",
		flex: 1
	},
	titlePart: {
		display: "inline-flex",
		gap: "var(--spacing-x2, 8px)",
		maxWidth: "100%"
	},
	displayName: {
		display: "flex",
		alignItems: "center",
		gap: "1rem",
		width: "100%"
	},
	descriptionTooltip: { wordBreak: "break-word" },
	editableInput: {
		minWidth: 0
	},
	name: {
		display: "flex",
		flexDirection: "row",
		gap: "0.5rem",
		maxWidth: "100%"
	},
	lighterText: {
		color: "var(--color-grey-dark)"
	},
	clickable: {
		cursor: "pointer"
	},
	entityContainer: {
		display: "flex",
		justifyContent: "center",
		gap: "0.5rem",
		alignItems: "flex-end",
		minWidth: 0
	},
	hiddenInput: {
		display: "none"
	},
	hiddenResource: {
		color: "var(--color-grey-dark)"
	},
	infoIcon: {
		color: "var(--color-blue-dark)",
		cursor: "pointer"
	},
	typeContainer: {
		color: "var(--color-grey-dark)",
		display: "flex",
		alignItems: "center",
		maxWidth: "25rem"
	},
	descriptionContainer: {
		marginTop: "1.2rem",
		display: "flex",
		alignItems: "center",
		maxWidth: "80rem"
	},
	tagsContainer: {
		marginTop: "1.2rem",
		gap: "0.3rem",
		display: "flex",
		width: "100%",
		flexWrap: "wrap"
	},
	resourceTagChip: {
		"&:hover": {
			backgroundColor: "var(--color-white)"
		}
	},
	type: {
		margin: "0 0.5rem"
	},
	ownerContainer: {
		display: "flex",
		flexDirection: "column",
		gap: "2px",
		minWidth: 0
	},
	inheritOwner: {
		height: 0,
		display: "flex"
	}
});
