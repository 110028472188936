import React from "react";
import { AccountEntity } from "components/common/entities/AccountEntity";
import { IntegrationActorModel } from "models/IntegrationActorModel";
import { useSelectLabel } from "../useSelectLabel";
import type { TLabelComponent } from "utils/ui/select";

const AccountLabel: TLabelComponent<IntegrationActorModel> = ({ disabled = false, value, withIcon }) => {
	const entityProps = useSelectLabel(disabled, withIcon);

	return <AccountEntity actor={value} {...entityProps} tooltipOnOverflow />;
};

const AccountLabelWithoutTooltipOnOverflow: TLabelComponent<IntegrationActorModel> = ({
	disabled = false,
	value,
	withIcon
}) => {
	const entityProps = useSelectLabel(disabled, withIcon);

	return <AccountEntity actor={value} {...entityProps} />;
};

AccountLabel.isSelectLabel = true;
AccountLabelWithoutTooltipOnOverflow.isSelectLabel = true;

export { AccountLabel, AccountLabelWithoutTooltipOnOverflow };
