import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	tabsHeaderContainer: {
		maxWidth: "600px"
	},
	userAccountsTabContainer: {
		display: "block",
		alignContent: "start"
	}
});
