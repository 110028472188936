import { List } from "immutable";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Select, TTargetValue } from "components/ui/Select";
import { DirectoryGroupLabel } from "components/ui/selectLabels/DirectoryGroupLabel";
import { UserLabel } from "components/ui/selectLabels/UserLabel";
import { DirectoryGroupOption } from "components/ui/selectOptions/DirectoryGroupOption";
import { UserAvatarOption } from "components/ui/selectOptions/UserAvatarOption";
import {
	isCustomerEntityDirectoryGroup,
	isCustomerEntityUser,
	useCustomerEntitiesSelect
} from "hooks/useCustomerEntitiesSelect";
import { useLoadingState } from "hooks/useLoadingState";
import { DirectoryGroupModel } from "models/DirectoryGroupModel";
import { UserModel } from "models/UserModel";
import { getNameAndIcon } from "utils/ui/directoryGroups";
import type { TMaintainerOption } from "components/pages/BulkActionsPage/utils";
import type { TLabelComponentProps, TOptionComponentProps } from "utils/ui/select";

type TProps = {
	handleCreateMaintainer: (maintainer: UserModel | DirectoryGroupModel) => Promise<void>;
};

export const MaintainerSelect: FC<TProps> = ({ handleCreateMaintainer }) => {
	const [query, setQuery] = useState("");
	const { t } = useTranslation();
	const { isLoading: createMaintainerIsLoading, withLoader: withMaintainerCreationLoader } = useLoadingState();

	const { items, isLoading: isLoadingEntities } = useCustomerEntitiesSelect(query, {
		entities: ["directoryGroup", "user"]
	});

	const onChange = useCallback(
		(value: TMaintainerOption | null) => {
			if (!value) return;
			void withMaintainerCreationLoader(handleCreateMaintainer(value));
		},
		[handleCreateMaintainer, withMaintainerCreationLoader]
	);

	const onInputChange = useCallback((event: TTargetValue | React.ChangeEvent<HTMLInputElement>) => {
		setQuery(event.target.value);
	}, []);

	const options = useMemo(() => {
		if (isLoadingEntities) return [];
		const filteredItems = items.filter(
			item => (isCustomerEntityUser(item) || isCustomerEntityDirectoryGroup(item)) && !item.isDeleted
		) as List<UserModel | DirectoryGroupModel>;
		return filteredItems.toArray();
	}, [isLoadingEntities, items]);

	const getKey = useCallback((option: TMaintainerOption) => option.id, []);

	const getOptionRenderer = useCallback((props: TOptionComponentProps<TMaintainerOption>) => {
		return props.option instanceof DirectoryGroupModel ? (
			<DirectoryGroupOption {...(props as TOptionComponentProps<DirectoryGroupModel>)} />
		) : (
			<UserAvatarOption {...(props as TOptionComponentProps<UserModel>)} />
		);
	}, []);

	const renderLabel = useCallback((props: TLabelComponentProps<DirectoryGroupModel | UserModel>) => {
		return props.value instanceof DirectoryGroupModel ? (
			<DirectoryGroupLabel {...(props as TLabelComponentProps<DirectoryGroupModel>)} />
		) : (
			<UserLabel {...(props as TLabelComponentProps<UserModel>)} />
		);
	}, []);

	const getOptionLabel = useCallback((option: TMaintainerOption) => {
		const name = option instanceof DirectoryGroupModel ? option.name : option.fullName;
		const { name: label } = getNameAndIcon(name);
		return label;
	}, []);

	const isOptionsEqual = useCallback((option1: TMaintainerOption, option2: TMaintainerOption) => {
		return option1.id === option2.id;
	}, []);

	return (
		<Select
			renderLabel={renderLabel}
			getOptionKey={getKey}
			placeholder={t("common.tables.maintainers.selectMaintainer")}
			getOptionLabel={getOptionLabel}
			isOptionEqualToValue={isOptionsEqual}
			onChange={onChange}
			onInputChange={onInputChange}
			loading={isLoadingEntities || createMaintainerIsLoading}
			inputValue={query}
			options={options}
			variant="table"
			renderOption={getOptionRenderer}
		/>
	);
};
