import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	container: {
		display: "flex",
		flexDirection: "column",
		"&$withTags": {
			gap: "var(--spacing-x2, 8px)"
		}
	},
	tagsContainer: {
		display: "flex",
		flexWrap: "nowrap",
		gap: "var(--spacing-x1, 4px)",
		maxWidth: "100%",
		overflow: "auto",
		paddingBottom: "var(--spacing-x1, 4px)",
		width: "100%"
	},
	withTags: {}
});
