import { matchSorter } from "match-sorter";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { GroupChip } from "components/ui/chips/GroupChip";
import { IRenderChipParams, MultipleSelect } from "components/ui/MultipleSelect";
import { DirectoryGroupOption } from "components/ui/selectOptions/DirectoryGroupOption";
import { useDirectoryGroupsSelect } from "hooks/useDirectoryGroupsSelect";
import { useRankedSort } from "hooks/useRankedSort";
import { DirectoryGroupModel } from "models/DirectoryGroupModel";
import { getNameAndIcon } from "utils/ui/directoryGroups";

const EMPTY_LABEL_TRANSLATION = "flow.anyUser";

interface IProps {
	chipClassName?: string;
	disabled?: boolean;
	placeholder?: string;
	label?: string;
	onChange: (groupIds: string[] | null) => void;
	values: string[];
}

const getOptionLabel = ({ name }: DirectoryGroupModel) => name;

const renderChip = ({ className, option, onRemove }: IRenderChipParams<DirectoryGroupModel>) => (
	<GroupChip value={option} selected onDelete={onRemove} variant="regular" className={className} />
);

const SORT_OPTIONS = {
	keys: [
		{
			key: (item: DirectoryGroupModel) => getNameAndIcon(item.name ?? "").name,
			threshold: matchSorter.rankings.MATCHES
		}
	]
};

export const GroupsSelectInput: FC<IProps> = ({ values, onChange, className, label, disabled, placeholder }) => {
	const { sort, onInputChange, query } = useRankedSort<DirectoryGroupModel>(SORT_OPTIONS);
	const { t } = useTranslation();

	const {
		items: directoryGroups,
		selectedItems: selectedDirectoryGroups,
		isLoading: isLoadingDirectoryGroups
	} = useDirectoryGroupsSelect(query, { selectedIds: values });

	const options = useMemo(
		() => directoryGroups.filter(group => !group.isDeleted || values?.includes(group.id)).toArray(),
		[directoryGroups, values]
	);

	const handleChange = useCallback(
		(newValues: DirectoryGroupModel[] | null) => {
			onChange(newValues ? newValues.map(({ id }) => id) : null);
		},
		[onChange]
	);

	const selectedOptions = useMemo(() => selectedDirectoryGroups.toArray(), [selectedDirectoryGroups]);

	const currentPlaceholder = useMemo(() => {
		if (values.length) return;
		return placeholder || t(EMPTY_LABEL_TRANSLATION);
	}, [placeholder, t, values.length]);

	return (
		<MultipleSelect
			className={className}
			disabled={disabled}
			onInputChange={onInputChange}
			getOptionLabel={getOptionLabel}
			label={label}
			loading={isLoadingDirectoryGroups}
			onChange={handleChange}
			options={options}
			placeholder={currentPlaceholder}
			renderChip={renderChip}
			limitChipType="group"
			sort={sort}
			value={selectedOptions}
			renderOption={DirectoryGroupOption}
		/>
	);
};
