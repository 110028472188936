import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BetaContext } from "context/betaContext";

export const BetaTriggerPage: React.FC = () => {
	const navigate = useNavigate();
	const { pathname, search, hash } = useLocation();
	const {
		actions: { setBeta }
	} = BetaContext();

	useEffect(() => {
		navigate(
			{
				pathname: pathname.replace("/beta", ""),
				search,
				hash
			},
			{ replace: true }
		);
	}, [pathname, search, hash, navigate, setBeta]);

	return null;
};
