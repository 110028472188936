import React from "react";
import { ResourceEntity } from "components/common/entities";
import { useSelectLabel } from "../useSelectLabel";
import type { IntegrationResourceModel } from "models/IntegrationResourceModel";
import type { TLabelComponent } from "utils/ui/select";

const ResourceLabel: TLabelComponent<IntegrationResourceModel> = ({ disabled = false, value, withIcon }) => {
	const entityProps = useSelectLabel(disabled, withIcon);
	return <ResourceEntity resource={value} {...entityProps} />;
};

ResourceLabel.isSelectLabel = true;

export { ResourceLabel };
