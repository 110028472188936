import classNames from "classnames";
import React, { useState, useCallback, useEffect, useMemo, useRef } from "react";
import { isEmptyReactNode } from "utils/ui/components";
import { useStyles } from "./styles";
import { Description, Errors, FieldTitle } from "../fieldHelpers/fieldHelpers";
import { SearchIcon } from "../Icons/SearchIcon";
import { WarningIcon } from "../Icons/WarningIcon";
import { Tooltip } from "../Tooltip";
import { Typography } from "../Typography";
import type { TInputSize } from "../fieldHelpers/types";

export type TContentProps<TElement extends HTMLElement> = TProps<{
	ref: React.Ref<TElement>;
}>;
export interface IBaseInputProps<TElement extends HTMLElement> {
	active?: boolean;
	disabled?: boolean;
	error?: boolean;
	errors?: string[];
	focused?: boolean;
	description?: string;
	contentContainerClassName?: string;
	contentRef?: React.Ref<TElement>;
	isRequired?: boolean;
	label?: React.ReactNode;
	labelIcon?: React.ReactNode | IconComponent;
	labelInfo?: React.ReactNode;
	prefix?: React.ReactNode;
	renderContent?: (props: TContentProps<TElement>) => React.ReactNode;
	onFieldMouseDown?: (event: React.MouseEvent) => void;
	size?: TInputSize;
	suffix?: React.ReactNode;
	variant?: "search" | "table" | "text";
}

export type TFieldProps<TElement extends HTMLElement> = TProps<IBaseInputProps<TElement>>;
export const Field = <TElement extends HTMLElement>({
	active,
	className,
	children,
	disabled,
	error,
	errors: userErrors,
	focused = false,
	description,
	innerRef,
	contentContainerClassName,
	contentRef: propContentRef,
	isRequired,
	label,
	labelIcon,
	labelInfo,
	prefix: propPrefix = null,
	renderContent,
	onFieldMouseDown,
	size = "large",
	suffix: propSuffix = null,
	variant = "text"
}: TFieldProps<TElement>) => {
	const classes = useStyles();
	const [isError, setIsError] = useState(
		userErrors ? userErrors.length > 0 && userErrors.every(Boolean) : (error ?? false)
	);
	const [errorMessages, setErrorMessages] = useState(userErrors);
	const fallbackRef = useRef(null);
	const contentRef = propContentRef || fallbackRef;

	useEffect(() => {
		setIsError(userErrors ? userErrors.length > 0 && userErrors.every(Boolean) : (error ?? false));
		setErrorMessages(userErrors);
	}, [error, userErrors]);

	const onInputContainerMouseDown = useCallback(
		(event: React.MouseEvent) => {
			onFieldMouseDown?.(event);
		},
		[onFieldMouseDown]
	);

	const title = useMemo(() => {
		if (!label) return null;
		if (typeof label === "string") {
			return <FieldTitle title={label} icon={labelIcon} info={labelInfo} required={isRequired} size={size} />;
		}
		return label;
	}, [label, labelIcon, labelInfo, isRequired, size]);

	const prefix = useMemo(
		() =>
			!isEmptyReactNode(propPrefix) || variant === "search" ? (
				<div className={classes.prefix}>{variant === "search" ? <SearchIcon size={16} /> : propPrefix}</div>
			) : null,
		[classes.prefix, propPrefix, variant]
	);

	const errorSuffix = useMemo(() => {
		if (variant === "table" && isError && errorMessages?.length) {
			const tooltipContent = (
				<div className={classes.errorTooltipContent}>
					{errorMessages.map((errorMessage, index) => (
						<Typography variant="body_reg" key={`${index}${errorMessage}`}>
							{errorMessage}
						</Typography>
					))}
				</div>
			);
			return (
				<Tooltip content={tooltipContent} placement="top-start">
					<div
						className={classNames(classes.errorButtonContainer, {
							[classes.large]: size === "large",
							[classes.medium]: size === "medium"
						})}>
						<WarningIcon />
					</div>
				</Tooltip>
			);
		}
		return null;
	}, [
		variant,
		isError,
		errorMessages,
		classes.errorTooltipContent,
		classes.errorButtonContainer,
		classes.medium,
		classes.large,
		size
	]);

	const suffix = useMemo(
		() => (!isEmptyReactNode(propSuffix) ? <div className={classes.suffix}>{propSuffix}</div> : null),
		[classes.suffix, propSuffix]
	);

	return (
		<div className={classNames(classes.container, className)}>
			{title || description ? (
				<div className={classNames(classes.top, { [classes.disabled]: disabled })}>
					{title}
					<Description description={description} size={size} />
				</div>
			) : null}
			<div
				ref={innerRef}
				className={classNames(classes.contentContainer, contentContainerClassName, {
					[classes.active]: active,
					[classes.disabled]: disabled,
					[classes.error]: isError,
					[classes.focused]: focused && !disabled,
					[classes.large]: size === "large",
					[classes.medium]: size === "medium",
					[classes.text]: variant === "text",
					[classes.search]: variant === "search",
					[classes.table]: variant === "table",
					[classes.noPrefix]: !prefix,
					[classes.noSuffix]: !suffix
				})}
				onMouseDown={onInputContainerMouseDown}>
				{prefix}
				{renderContent ? renderContent({ ref: contentRef }) : children}
				{suffix}
				{errorSuffix}
			</div>
			{isError && !errorSuffix ? <Errors errorMessages={errorMessages ?? null} /> : null}
		</div>
	);
};
