import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { BasicRoleBar } from "components/common/BasicRoleBar";
import { CommonAccordion } from "components/common/CommonAccordion";
import { IconPrefix } from "components/ui/IconPrefix";
import { VirtualPermissionIcon } from "components/ui/Icons/VirtualPermissionIcon";
import { LoadingDots } from "components/ui/LoadingDots";
import { Typography } from "components/ui/Typography";
import { Unknown } from "components/ui/Unknown";
import { useIntegrationResourceRole } from "hooks/useIntegrationResourceRole";
import { useIntegrations } from "hooks/useIntegrations";
import { useStyles } from "./styles";

interface IProps {
	virtualizedRoleId: string;
}

export const VirtualizedRoleBlock: FC<IProps> = ({ virtualizedRoleId }) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const { role, isLoading } = useIntegrationResourceRole(virtualizedRoleId);
	const integrations = useIntegrations();
	const integration = integrations?.get(role?.integrationResource?.integrationId || "");

	const showRoleBar = role?.integrationResource && integration;

	const title = useMemo(
		() => (
			<IconPrefix
				content={t("pages.integrationResourceRole.virtualizedRole.title")}
				Icon={VirtualPermissionIcon}
				className={classes.icon}
			/>
		),
		[classes.icon, t]
	);

	return (
		<CommonAccordion rounded title={title} detailsClassname={classes.detailsContainer}>
			{!integrations && isLoading ? (
				<LoadingDots className={classes.loading} />
			) : (
				<div className={classes.resource}>
					<Typography variant="body_sb">{t("pages.integrationResourceRole.virtualizedRole.subtitle")}</Typography>
					<Unknown unknown={!showRoleBar}>
						{showRoleBar && (
							<BasicRoleBar selected role={role} integration={integration} resource={role.integrationResource} />
						)}
					</Unknown>
				</div>
			)}
		</CommonAccordion>
	);
};
