import { Set } from "immutable";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { editIntegrationResourceRole, type TIntegrationResourceRole } from "api/integrationResourceRoles";
import { IntegrationEntity, RoleEntity, ResourceEntity } from "components/common/entities";
import { IntegrationIcon } from "components/ui/Icons/IntegrationIcon";
import { OwnerIcon } from "components/ui/Icons/OwnerIcon";
import { RequestsIcon } from "components/ui/Icons/RequestsIcon";
import { ResourcesIcon } from "components/ui/Icons/ResourcesIcon";
import { WorkflowsIcon } from "components/ui/Icons/WorkflowsIcon";
import { ResourceInheritUserLabel } from "components/ui/selectLabels/UserLabel";
import { PaginatedVirtualTable, type TTableSelectionProps, type TColumn } from "components/ui/VirtualTable";
import {
	HeaderCellContent,
	TextCellContent,
	ToggleSwitchCellContent,
	UserCellContent
} from "components/ui/VirtualTable/components";
import { useApprovalAlgorithms, useApprovalAlgorithmsList } from "hooks/useApprovalAlgorithms";
import { useIntegrations } from "hooks/useIntegrations";
import { useMultiUsers } from "hooks/useMultiUsers";
import { ApprovalAlgorithmModel } from "models/ApprovalAlgorithmModel";
import { INHERIT, type TInherit } from "utils/ui/select";
import { WorkflowCell } from "../WorkflowCell";
import type { TGetProps } from "components/ui/VirtualTable/types";
import type { IntegrationResourceRoleModel } from "models/IntegrationResourceRoleModel";
import type { Require } from "types/utilTypes";

export type TRoleWithResource = Require<IntegrationResourceRoleModel, "integrationResource">;

type TProps = {
	disabled?: boolean;
	fetchIntegrationResourceRoles: (page: number) => void | Promise<void>;
	integrationResourceRoles: TRoleWithResource[];
	perPage?: number;
	onRoleUpdate: (role: Partial<TRoleWithResource> & { id: string }) => void;
	totalIntegrationResourceRoles: number;
} & TTableSelectionProps<TRoleWithResource>;

const COLUMNS_WIDTHS = [
	"minmax(196px, 340px)",
	"minmax(182px, 280px)",
	"minmax(176px, 1fr)",
	"minmax(176px, 1fr)",
	"minmax(168px, 280px)",
	"minmax(200px, 340px)",
	"180px"
] as const;

const DEFAULT_PER_PAGE = 10;

export const useIntegrationResourceRolesTable = ({
	disabled,
	onRoleUpdate
}: Pick<TProps, "disabled" | "onRoleUpdate">) => {
	const integrations = useIntegrations();
	const approvalAlgorithms = useApprovalAlgorithms();
	const approvalAlgorithmList = useApprovalAlgorithmsList();

	const approvalAlgorithmOptions = useMemo<(ApprovalAlgorithmModel | TInherit)[]>(
		() => [INHERIT, ...(approvalAlgorithmList?.toArray() || [])],
		[approvalAlgorithmList]
	);

	const [loadingIntegrationResourceRoles, setLoadingIntegrationResourceRoles] = useState(Set<string>());

	const onUpdateIntegrationResourceRole = useCallback(
		async (
			role: Pick<Partial<TIntegrationResourceRole>, "allowsRequests" | "approvalAlgorithmId"> & {
				id: string;
			}
		) => {
			setLoadingIntegrationResourceRoles(current => current.add(role.id));
			await editIntegrationResourceRole(role);
			setLoadingIntegrationResourceRoles(current => current.remove(role.id));
			onRoleUpdate(role);
		},
		[onRoleUpdate]
	);

	const onUpdateAllowsRequests = useCallback(
		async (integrationResourceRoleId: string, allowsRequests: boolean) => {
			await onUpdateIntegrationResourceRole({ id: integrationResourceRoleId, allowsRequests });
		},
		[onUpdateIntegrationResourceRole]
	);

	const onUpdateApprovalAlgorithm = useCallback(
		async (integrationResourceRoleId: string, newApprovalAlgorithm: ApprovalAlgorithmModel | TInherit) => {
			await onUpdateIntegrationResourceRole({
				id: integrationResourceRoleId,
				approvalAlgorithmId: newApprovalAlgorithm === INHERIT ? null : newApprovalAlgorithm.id
			});
		},
		[onUpdateIntegrationResourceRole]
	);

	const getIsDisabled = useCallback(
		(entity: string | TRoleWithResource) =>
			disabled || loadingIntegrationResourceRoles.has(typeof entity === "string" ? entity : entity.id),
		[disabled, loadingIntegrationResourceRoles]
	);

	const getIntegration = useCallback(
		(integrationResourceRole: TRoleWithResource) => {
			return integrations?.get(integrationResourceRole.integrationResource?.integrationId || "") || null;
		},
		[integrations]
	);

	return {
		approvalAlgorithmOptions,
		approvalAlgorithms,
		getIntegration,
		getIsDisabled,
		onUpdateAllowsRequests,
		onUpdateApprovalAlgorithm
	};
};

export const IntegrationResourceRolesTable: FC<TProps> = ({
	className,
	disabled = false,
	fetchIntegrationResourceRoles,
	innerRef,
	isLoading,
	integrationResourceRoles,
	perPage = DEFAULT_PER_PAGE,
	onRoleUpdate: onRoleUpdate,
	totalIntegrationResourceRoles,
	...selectionProps
}) => {
	const { t } = useTranslation("translation", { keyPrefix: "common.tables.integrationResourceRoles" });
	const {
		approvalAlgorithmOptions,
		approvalAlgorithms,
		getIntegration,
		getIsDisabled,
		onUpdateAllowsRequests,
		onUpdateApprovalAlgorithm
	} = useIntegrationResourceRolesTable({ disabled, onRoleUpdate: onRoleUpdate });

	const resourceOwnerIds = useMemo(
		() => integrationResourceRoles.map(role => role.integrationResource.ownerId),
		[integrationResourceRoles]
	);

	const resourcesOwners = useMultiUsers(resourceOwnerIds);

	const getOwnerLabel = useCallback((integrationResourceRole: TRoleWithResource) => {
		return (
			<ResourceInheritUserLabel integrationResource={integrationResourceRole.integrationResource} value={INHERIT} />
		);
	}, []);

	const columns = useMemo<TColumn<TRoleWithResource>[]>(() => {
		const columns: TColumn<TRoleWithResource>[] = [
			{
				renderCell: integrationResourceRole => {
					const integration = getIntegration(integrationResourceRole);
					if (!integration) {
						return <TextCellContent text="" disabled={getIsDisabled(integrationResourceRole)} />;
					}
					return <IntegrationEntity withIcon noWrap integration={integration} size="medium" />;
				},
				header: <HeaderCellContent text={t("headers.integration")} icon={<IntegrationIcon />} />,
				key: "integration",
				width: COLUMNS_WIDTHS[0]
			},
			{
				renderCell: TextCellContent,
				getProps: integrationResourceRole => {
					return {
						text: integrationResourceRole.integrationResource?.type ?? "",
						disabled: getIsDisabled(integrationResourceRole.id)
					};
				},
				header: <HeaderCellContent text={t("headers.resourceType")} />,
				key: "resourceType",
				width: COLUMNS_WIDTHS[1]
			},
			{
				renderCell: integrationResourceRole => (
					<ResourceEntity resource={integrationResourceRole.integrationResource} size="medium" variant="link" />
				),
				header: <HeaderCellContent text={t("headers.integrationResource")} icon={<ResourcesIcon />} />,
				key: "resource",
				width: COLUMNS_WIDTHS[2]
			},
			{
				renderCell: integrationResourceRole => (
					<RoleEntity role={integrationResourceRole} size="medium" variant="link" />
				),
				header: <HeaderCellContent text={t("headers.integrationResourceRole")} icon={<ResourcesIcon />} />,
				key: "role",
				width: COLUMNS_WIDTHS[3]
			},
			{
				renderCell: (integrationResourceRole, options) => {
					const onChange = async (newApprovalAlgorithm: ApprovalAlgorithmModel | TInherit | null) => {
						if (newApprovalAlgorithm) {
							await onUpdateApprovalAlgorithm(integrationResourceRole.id, newApprovalAlgorithm);
						}
					};
					return (
						<WorkflowCell
							integrationResource={integrationResourceRole.integrationResource}
							defaultValue={t("selectNullValue")}
							options={approvalAlgorithmOptions}
							value={
								integrationResourceRole.approvalAlgorithmId
									? approvalAlgorithms?.get(integrationResourceRole.approvalAlgorithmId)
									: INHERIT
							}
							disabled={options.disabled || getIsDisabled(integrationResourceRole.id)}
							onChange={onChange}
						/>
					);
				},
				header: <HeaderCellContent text={t("headers.approvalAlgorithm")} icon={<WorkflowsIcon />} />,
				key: "workflow",
				width: COLUMNS_WIDTHS[4],
				overflow: true
			},
			{
				renderCell: UserCellContent,
				getProps: integrationResourceRole => ({
					user: resourcesOwners?.get(integrationResourceRole.integrationResource.ownerId || ""),
					emptyState: getOwnerLabel(integrationResourceRole),
					disabled: getIsDisabled(integrationResourceRole.id)
				}),
				header: <HeaderCellContent text={t("headers.owner")} icon={<OwnerIcon />} />,
				key: "owner",
				width: COLUMNS_WIDTHS[5]
			} as TColumn<TRoleWithResource>,
			{
				renderCell: ToggleSwitchCellContent,
				getProps: (integrationResourceRole => {
					const onChange = (checked: boolean) => {
						void onUpdateAllowsRequests(integrationResourceRole.id, checked);
					};
					return {
						disabled: getIsDisabled(integrationResourceRole.id),
						checked: integrationResourceRole.allowsRequests,
						onChange
					};
				}) as TGetProps<IntegrationResourceRoleModel, typeof ToggleSwitchCellContent>,
				header: <HeaderCellContent text={t("headers.requestable")} icon={<RequestsIcon />} />,
				key: "requestable",
				width: COLUMNS_WIDTHS[6]
			}
		];
		return columns;
	}, [
		t,
		getIntegration,
		getIsDisabled,
		approvalAlgorithmOptions,
		approvalAlgorithms,
		onUpdateApprovalAlgorithm,
		resourcesOwners,
		getOwnerLabel,
		onUpdateAllowsRequests
	]);

	return (
		<PaginatedVirtualTable
			isLoading={isLoading}
			className={className}
			innerRef={innerRef}
			columns={columns}
			rows={integrationResourceRoles}
			totalRows={totalIntegrationResourceRoles || integrationResourceRoles.length}
			perPage={perPage}
			fetchPage={fetchIntegrationResourceRoles}
			shouldDisableRow={getIsDisabled}
			{...selectionProps}
		/>
	);
};
