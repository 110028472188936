import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	container: {
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-start",
		width: "100%",
		gap: "var(--spacing-x3, 12px)"
	},
	spinner: {
		padding: "var(--spacing-x1, 4px)",
		borderRadius: "var(--spacing-x1, 4px)"
	}
});
