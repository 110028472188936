import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { getIntegrationResourceRoles, type TFullIntegrationResourceRole } from "api/integrationResourceRoles";
import { getIntegrationResources } from "api/integrationResources";
import { IntegrationSelectInput } from "components/common/IntegrationSelectInput";
import { getDynamicSizeIcon } from "components/ui/dynamicSizeIcon";
import { MultipleSelect } from "components/ui/MultipleSelect";
import { Select, TTargetValue } from "components/ui/Select";
import { BundleOption } from "components/ui/selectOptions/BundleOption";
import { ResourceOption } from "components/ui/selectOptions/ResourceOption";
import { RoleOption } from "components/ui/selectOptions/RoleOption";
import { useBundles } from "hooks/useBundles";
import { useDebounceFn } from "hooks/useDebounce";
import { useIntegrations } from "hooks/useIntegrations";
import { IntegrationModel } from "models/IntegrationModel";
import { getIntegrationResourceFilters, getIntegrationResourceRoleFilters } from "utils/api/filters";
import { notEmpty } from "utils/comparison";
import { getLabel, type TOptionComponent } from "utils/ui/select";
import { useStyles } from "./styles";
import { getArrayValue, IFilterProps } from "../../";
import type { BundleModel } from "models/BundleModel";
import type { IntegrationResourceModel } from "models/IntegrationResourceModel";

const TRANSLATION_PREFIX = "common.ticketFilters";
const OPTIONS_LIMIT = 100;
const CHIPS_LIMIT = 5;

const equality = (a: { id: string }, b: { id: string }) => a.id === b.id;

const getOptionLabel = (option: { name: string }) => option?.name || "";

export const TargetFilter: FC<IFilterProps> = ({ onFiltersChange, ticketFilters }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const bundles = useBundles();
	const integrations = useIntegrations();
	const [resourceInput, setResourceInput] = useState("");
	const [resourceOptions, setResourceOptions] = useState<IntegrationResourceModel[] | null>(null);
	const [rolesInput, setRolesInput] = useState("");
	const [roleOptions, setRolesOptions] = useState<TFullIntegrationResourceRole[] | null>(null);

	const target = ticketFilters.target;

	const selectedBundles = useMemo(
		() =>
			bundles
				? getArrayValue(target?.bundle)
						.map(id => bundles.get(id))
						.filter(notEmpty)
				: [],
		[bundles, target]
	);

	const integration = (target?.integration && integrations && integrations.get(target.integration)) || null;
	const resource =
		(target?.resource && resourceOptions && resourceOptions.find(resource => resource.id === target.resource)) || null;
	const selectedRoles = useMemo(
		() =>
			roleOptions
				? getArrayValue(target?.role)
						.map(roleId => roleOptions.find(role => role.id === roleId))
						.filter(notEmpty)
				: [],
		[roleOptions, target?.role]
	);

	useEffect(() => {
		async function fetchData(integrationId: string) {
			const { result } = await getIntegrationResources({
				perPage: OPTIONS_LIMIT,
				filters: getIntegrationResourceFilters({ integrationId: [integrationId], name: [resourceInput] })
			});
			setResourceOptions(result.toArray());
		}
		if (target?.integration) {
			void fetchData(target.integration);
		}
	}, [resourceInput, target?.integration]);

	useEffect(() => {
		async function fetchData(resourceId: string) {
			const { result } = await getIntegrationResourceRoles({
				perPage: OPTIONS_LIMIT,
				sortFields: rolesInput.length ? ["nameLength"] : undefined,
				filters: getIntegrationResourceRoleFilters({
					integrationResourceId: [resourceId],
					name: [rolesInput]
				})
			});
			setRolesOptions(result.toArray());
		}
		if (target?.resource) {
			void fetchData(target.resource);
		}
	}, [rolesInput, target?.resource]);

	const bundleOptions = useMemo(() => bundles?.toList().toArray() || [], [bundles]);

	const changeBundle = useCallback(
		(bundles: BundleModel[] | null) => {
			onFiltersChange(current => ({
				...current,
				target: { ...current.target, bundle: bundles?.map(({ id }) => id) || [] }
			}));
		},
		[onFiltersChange]
	);
	const changeIntegration = useCallback(
		(value: IntegrationModel | null) => {
			onFiltersChange(current => ({
				...current,
				target: { ...current.target, integration: value?.id, resource: undefined, role: undefined }
			}));
		},
		[onFiltersChange]
	);
	const changeResource = useCallback(
		(resource: IntegrationResourceModel | null) => {
			onFiltersChange(current => ({
				...current,
				target: { ...current.target, resource: resource?.id, role: undefined }
			}));
		},
		[onFiltersChange]
	);
	const changeRoles = useCallback(
		(roles: TFullIntegrationResourceRole[] | null) => {
			onFiltersChange(current => ({
				...current,
				target: { ...current.target, role: roles?.map(({ id }) => id) }
			}));
		},
		[onFiltersChange]
	);

	const debouncedSetResourceInput = useDebounceFn(setResourceInput, 300);
	const debouncedSetRolesInput = useDebounceFn(setRolesInput, 300);

	const changeResourceInput = useCallback(
		(event: TTargetValue | React.ChangeEvent<HTMLInputElement>) => debouncedSetResourceInput(event.target.value),
		[debouncedSetResourceInput]
	);
	const changeRolesInput = useCallback(
		(event: TTargetValue | React.ChangeEvent<HTMLInputElement>) => debouncedSetRolesInput(event.target.value),
		[debouncedSetRolesInput]
	);

	return (
		<div className={classes.container}>
			<MultipleSelect
				chipsLimit={CHIPS_LIMIT}
				isOptionEqualToValue={equality}
				getOptionLabel={getLabel}
				label={t(`${TRANSLATION_PREFIX}.target.bundle`)}
				onChange={changeBundle}
				renderOption={BundleOption}
				options={bundleOptions}
				value={selectedBundles}
			/>
			<div className={classes.container}>
				<IntegrationSelectInput
					label={t(`${TRANSLATION_PREFIX}.target.integration`)}
					onChange={changeIntegration}
					options={integrations}
					value={integration}
				/>
				<Select
					disabled={!target?.integration}
					getOptionLabel={getOptionLabel}
					isOptionEqualToValue={equality}
					label={t(`${TRANSLATION_PREFIX}.target.resource`)}
					loading={!resourceOptions && !!target?.integration}
					onChange={changeResource}
					onInputChange={changeResourceInput}
					options={resourceOptions || []}
					renderOption={ResourceOption}
					value={resource}
				/>
				<MultipleSelect
					chipsLimit={CHIPS_LIMIT}
					chipsPrefix={integration?.imageUrl ? getDynamicSizeIcon(<img src={integration?.imageUrl} />) : undefined}
					disabled={!target?.resource}
					getOptionLabel={getOptionLabel}
					isOptionEqualToValue={equality}
					label={t(`${TRANSLATION_PREFIX}.target.role`)}
					loading={!roleOptions && !!target?.resource}
					onChange={changeRoles}
					onInputChange={changeRolesInput}
					options={roleOptions || []}
					renderOption={RoleOption as TOptionComponent<TFullIntegrationResourceRole>}
					value={selectedRoles}
				/>
			</div>
		</div>
	);
};
