import React from "react";
import { RoleEntity } from "components/common/entities";
import { useSelectLabel } from "../useSelectLabel";
import type { IntegrationResourceRoleModel } from "models/IntegrationResourceRoleModel";
import type { TLabelComponent } from "utils/ui/select";

const RoleLabel: TLabelComponent<IntegrationResourceRoleModel> = ({ disabled = false, value, withIcon }) => {
	const entityProps = useSelectLabel(disabled, withIcon);

	return <RoleEntity role={value} noWrap relative {...entityProps} />;
};

const RoleWithoutIconLabel: TLabelComponent<IntegrationResourceRoleModel> = ({ disabled = false, value }) => {
	const entityProps = useSelectLabel(disabled, false);

	return <RoleEntity role={value} noWrap relative {...entityProps} />;
};

RoleLabel.isSelectLabel = true;
RoleWithoutIconLabel.isSelectLabel = true;

export { RoleLabel, RoleWithoutIconLabel };
