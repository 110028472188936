import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	header: {
		padding: "0.75em 1.5em",
		borderRadius: "8px 8px 0 0",
		background: "var(--color-almost-white)"
	},
	roleHeaderBlock: {
		display: "flex",
		alignItems: "flex-end",
		justifyContent: "space-between",
		gap: "0.5rem",
		flexWrap: "wrap"
	},
	roleHeaderSection: {
		display: "inline-flex",
		flexDirection: "column",
		alignItems: "center"
	},
	settingsModal: {
		maxWidth: "50rem"
	}
});
