import { createUseStyles } from "react-jss";
import { ENTITY_NODE_HEIGHT } from "components/common/entities/Nodes/EntityNode/styles";

export const useStyles = createUseStyles({
	container: {
		alignItems: "flex-start",
		alignSelf: "stretch",
		background: "var(--color-purple-50, #FBF9FB)",
		borderRadius: "12px",
		display: "flex",
		flexDirection: "column",
		gap: "var(--spacing-x5, 20px)",
		padding: "var(--spacing-x4, 16px) var(--spacing-x3, 12px) var(--spacing-x4, 16px) var(--spacing-x4, 16px)"
	},
	content: {
		alignItems: "center",
		display: "flex",
		gap: "var(--spacing-x7)",
		justifyContent: "flex-start",
		maxWidth: "100%",
		overflow: "auto",
		paddingBottom: "var(--spacing-x3)",
		paddingTop: "var(--spacing-x4)",
		width: "100%"
	},
	select: {
		backgroundColor: "var(--color-white)"
	},
	readonlySelect: {
		width: "100px",
		minWidth: "100px"
	},
	emptyState: {
		height: `${ENTITY_NODE_HEIGHT}px`
	},
	selectItem: {
		width: "100%"
	}
});
