import classNames from "classnames";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getIntegrationResourceRoles } from "api/integrationResourceRoles";
import { FixedHeightCommonAccordion } from "components/common/FixedHeightCommonAccordion";
import { Button } from "components/ui/Button";
import { IconPrefix } from "components/ui/IconPrefix";
import { AddIcon } from "components/ui/Icons/AddIcon";
import { EditIcon } from "components/ui/Icons/EditIcon";
import { RoleIcon } from "components/ui/Icons/RoleIcon";
import { Input } from "components/ui/Input";
import { Typography } from "components/ui/Typography";
import { IntegrationResourceRoleIntegrationResourceIdFilter } from "filters/integrationResourceRole";
import { useDebouncedPaginatedSearch } from "hooks/useDebouncedPaginationSearch";
import { useIsOpenState } from "hooks/useIsOpenState";
import { useStopPropagation } from "hooks/useStopPropagation";
import { IntegrationResourceModel } from "models/IntegrationResourceModel";
import { getIntegrationResourceRoleFilters } from "utils/api/filters";
import { RolesTable } from "./components/RolesTable";
import { CreateRoleFormModal } from "./CreateRoleFormModal";
import { useStyles } from "./styles";
import type { TRoleWithResource } from "components/common/tables/IntegrationResourceRolesTable";
import type { IPaginatedSearchOptions } from "utils/searchUtils";

const PAGE_SIZE = 15;
const MIN_ROWS = 3;
const DEFAULT_ROW_HEIGHT_PX = 60;
const DEFAULT_ACCORDION_HEIGHT_PX = 180;

interface IProps {
	integrationResource: IntegrationResourceModel;
	onChange?: () => Promise<void>;
	manual: boolean;
	virtual: boolean;
	withUnmanaged?: boolean;
}

export const RolesSection: FC<IProps> = ({ integrationResource, onChange, withUnmanaged = false, manual, virtual }) => {
	const { t } = useTranslation();
	const { open: expend, isOpen: expanded, close: closeExpend } = useIsOpenState();
	const { open, isOpen, close } = useIsOpenState();
	const [query, setQuery] = useState("");
	const navigate = useNavigate();
	const classes = useStyles();

	const onInputChange = useCallback((event: { target: { value: string } }) => {
		setQuery(event.target.value);
	}, []);

	const fetchRoles = useCallback(
		({ sort = {}, pagination = {} }: IPaginatedSearchOptions) => {
			return getIntegrationResourceRoles({
				...pagination,
				...sort,
				withUnmanaged,
				filters: getIntegrationResourceRoleFilters({
					integrationResourceId: [integrationResource.id],
					name: [query]
				})
			});
		},
		[integrationResource.id, query, withUnmanaged]
	);

	const {
		isLoading,
		totalResults,
		setPartialItem,
		fetchPage,
		getPage,
		itemsForVirtualTable: roles
	} = useDebouncedPaginatedSearch<TRoleWithResource>(query, {
		fetchItems: fetchRoles,
		perPage: PAGE_SIZE,
		initialFilter: null,
		disableSearch: false
	});

	const handleOnAdd = useStopPropagation(open);

	const handleBulkActionsClick = useCallback(() => {
		const integrationResourceFilter = new IntegrationResourceRoleIntegrationResourceIdFilter({
			value: [integrationResource.id]
		});
		navigate({
			pathname: "/bulkActions",
			search: `?tab=role&${integrationResourceFilter.toURLSearchParams().toString()}`
		});
	}, [integrationResource.id, navigate]);

	useEffect(() => {
		if (integrationResource.rolesCount) {
			void fetchPage(1, false, true);
		}
	}, [fetchPage, integrationResource.rolesCount]);

	const titleContent = useMemo(
		() => (
			<div className={classes.titleContainer}>
				<div className={classNames(classes.titlePart, classes.roleLabel)}>
					<IconPrefix Icon={RoleIcon} content={t("pages.integration.resource.roles")} className={classes.icon} />
					<Typography variant="body_reg">{`( ${t("number", { value: integrationResource.rolesCount || 0 })} )`}</Typography>
				</div>
				<div className={classNames(classes.titlePart, classes.roleActions)}>
					<Button prefix={<EditIcon size="small" />} size="medium" variant="secondary" onClick={handleBulkActionsClick}>
						{t("pages.integration.resource.bulkActions")}
					</Button>
					{(manual || virtual) && (
						<Button variant="secondary" size="medium" prefix={<AddIcon />} onClick={handleOnAdd}>
							{t("buttons.add")}
						</Button>
					)}
				</div>
			</div>
		),
		[
			classes.titleContainer,
			classes.titlePart,
			classes.roleActions,
			classes.roleLabel,
			classes.icon,
			handleBulkActionsClick,
			handleOnAdd,
			integrationResource.rolesCount,
			manual,
			t,
			virtual
		]
	);

	return (
		<FixedHeightCommonAccordion
			titleContent={titleContent}
			maxRows={PAGE_SIZE}
			totalResults={totalResults}
			minRows={MIN_ROWS}
			defaultRowHeight={DEFAULT_ROW_HEIGHT_PX}
			expanded={expanded}
			setExpanded={expanded ? closeExpend : expend}
			defaultAccordionHeight={DEFAULT_ACCORDION_HEIGHT_PX}>
			<div className={classes.content}>
				<CreateRoleFormModal resource={integrationResource} onSubmit={onChange} isOpen={isOpen} onClose={close} />
				<Input
					className={classes.searchBar}
					onChange={onInputChange}
					placeholder={t("pages.integration.resource.search.placeholder")}
					value={query}
					variant="search"
					size="medium"
				/>
				<RolesTable
					loadRolesPage={getPage as (page: number) => Promise<void>}
					roles={roles || []}
					isLoading={isLoading}
					totalResults={totalResults}
					onRoleUpdate={setPartialItem}
				/>
			</div>
		</FixedHeightCommonAccordion>
	);
};
