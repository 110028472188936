import classNames from "classnames";
import React, { useRef } from "react";
import { useIsOverflowed } from "hooks/useIsOverflowed";
import { useStyles } from "./styles";
import { Tooltip } from "../../Tooltip/Tooltip";
import { TVariant, Typography } from "../Typography";

interface IProps {
	content: React.ReactNode;
	delayShow?: number;
	width?: number | string; // default: auto
	maxInlineWidth?: number | string; // default: 100%
	tooltipClassName?: string;
	textVariant?: TVariant;
}

export const TooltipOnOverflow: FC<IProps> = ({
	className,
	content,
	delayShow,
	tooltipClassName,
	textVariant = "standard",
	width = "auto",
	maxInlineWidth = "100%"
}) => {
	const classes = useStyles({ width, maxInlineWidth });
	const textRef = useRef<HTMLDivElement>(null);
	const { overflowedX } = useIsOverflowed(textRef);

	return overflowedX ? (
		<Tooltip delayShow={delayShow} className={tooltipClassName} triggerRef={textRef} content={content}>
			<Typography variant={textVariant} relative className={classNames(classes.container, className)} noWrap>
				{content}
			</Typography>
		</Tooltip>
	) : (
		<Typography
			innerRef={textRef}
			variant={textVariant}
			relative
			className={classNames(classes.container, className)}
			noWrap>
			{content}
		</Typography>
	);
};
