import React from "react";
import { GroupCard } from "components/common/GroupCard";
import { useDirectoryGroup } from "hooks/useDirectoryGroup";
import { DirectoryGroupModel } from "models/DirectoryGroupModel";
import { useStyles } from "./styles";
import { OPTION_TOOLTIP_DELAY, SelectOption } from "../SelectOption";
import type { TOptionComponentProps } from "utils/ui/select";

function DirectoryGroupNodeOption<T extends DirectoryGroupModel | string>({
	disabled = false,
	isHighlighted = false,
	isSelected = false,
	onSelect,
	option,
	optionKey
}: TOptionComponentProps<T>) {
	const classes = useStyles();

	const currentGroup = useDirectoryGroup(typeof option === "string" ? option : undefined);
	const remoteGroup = typeof option === "string" && currentGroup ? currentGroup : (option as DirectoryGroupModel);
	return (
		<SelectOption
			onSelect={onSelect}
			disabled={disabled}
			isHighlighted={isHighlighted}
			value={option}
			readonly
			key={optionKey}>
			<GroupCard
				delayShowTooltip={OPTION_TOOLTIP_DELAY}
				highlighted={isHighlighted}
				group={remoteGroup}
				selected={isSelected}
				className={classes.group}
			/>
		</SelectOption>
	);
}

DirectoryGroupNodeOption.isSelectOption = true;

export { DirectoryGroupNodeOption };
