import classNames from "classnames";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { WorkflowsIcon } from "components/ui/Icons/WorkflowsIcon";
import { Select, ISelectProps } from "components/ui/Select";
import { WorkflowLabel } from "components/ui/selectLabels/WorkflowLabel";
import { WorkflowOption } from "components/ui/selectOptions/WorkflowOption";
import { useApprovalAlgorithmsList } from "hooks/useApprovalAlgorithms";
import { ApprovalAlgorithmModel } from "models/ApprovalAlgorithmModel";
import { sortByName } from "utils/sortUtils";

interface IProps {
	onChange: (value: ApprovalAlgorithmModel) => void | Promise<void>;
	selected: ApprovalAlgorithmModel | null;
	inputClassName?: string;
	optionClassName?: string;
	showLabel?: boolean;
	selectInputProps?: Partial<TProps<ISelectProps<ApprovalAlgorithmModel>>>;
}

const getOptionLabel = (option: ApprovalAlgorithmModel) => option.name;
const equality = (option: ApprovalAlgorithmModel, selected: ApprovalAlgorithmModel) => option.id === selected.id;

export const ApprovalAlgorithmsSelectInput: FC<IProps> = ({
	onChange,
	selected,
	className,
	inputClassName,
	selectInputProps,
	showLabel = true
}) => {
	const approvalAlgorithms = useApprovalAlgorithmsList();
	const { t } = useTranslation();

	const options = useMemo(() => approvalAlgorithms?.toArray() || [], [approvalAlgorithms]);

	const handleChange = useCallback(
		(value: ApprovalAlgorithmModel | null) => {
			if (value) {
				void onChange(value);
			}
		},
		[onChange]
	);

	const labelIcon = useMemo(() => <WorkflowsIcon />, []);
	const loading = !approvalAlgorithms;

	return (
		<Select
			{...selectInputProps}
			className={classNames(className, inputClassName)}
			disabled={loading}
			labelIcon={labelIcon}
			getOptionLabel={getOptionLabel}
			isOptionEqualToValue={equality}
			label={showLabel ? t("pages.bundles.bundleEditor.approvalAlgorithm") : undefined}
			loading={loading}
			noClear
			onChange={handleChange}
			options={options}
			placeholder={t("pages.bundles.bundleEditor.placeholders.selectApprovalAlgorithm")}
			renderOption={WorkflowOption}
			renderLabel={WorkflowLabel}
			required
			sort={sortByName}
			value={selected}
		/>
	);
};
