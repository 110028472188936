import { matchSorter } from "match-sorter";
import React, { useCallback, useEffect, useMemo } from "react";
import { Select } from "components/ui/Select";
import { UserLabel } from "components/ui/selectLabels/UserLabel";
import { UserAvatarOption } from "components/ui/selectOptions/UserAvatarOption";
import { useRankedSort } from "hooks/useRankedSort";
import { useUser } from "hooks/useUser";
import { useUsersSelect } from "hooks/useUsersSelect";
import { UserModel } from "models/UserModel";
import { useStyles } from "./styles";

const getLabel = (user: UserModel) => user.fullName;
const equality = (option: UserModel, value: UserModel) => option.id === value.id;

const SORT_OPTIONS = {
	keys: [{ key: (item: UserModel) => item.fullName, threshold: matchSorter.rankings.MATCHES }]
};

export const UserSelect: FC<{ userId: string; setUserId: (userId: string) => void; excludedUserIds?: string[] }> = ({
	userId,
	setUserId,
	excludedUserIds = []
}) => {
	const classes = useStyles();
	const { sort, query, onInputChange } = useRankedSort<UserModel>(SORT_OPTIONS);
	const { items: users, isLoading } = useUsersSelect(query);
	const selectedUser = useUser(userId);

	const options = useMemo(
		() => users.toArray().filter(({ isDeleted, id }) => !(isDeleted || excludedUserIds.includes(id))) || [],
		[excludedUserIds, users]
	);

	const onUserChange = useCallback(
		(user: UserModel | null) => {
			if (user) setUserId(user.id);
		},
		[setUserId]
	);

	useEffect(() => {
		if (excludedUserIds.includes(userId)) {
			setUserId("");
		}
	}, [excludedUserIds, userId, setUserId]);

	return (
		<Select
			className={classes.userSelectContainer}
			noClear
			filter={null}
			getOptionLabel={getLabel}
			isOptionEqualToValue={equality}
			limit={8}
			loading={isLoading}
			onChange={onUserChange}
			onInputChange={onInputChange}
			renderOption={UserAvatarOption}
			options={options}
			renderLabel={UserLabel}
			required
			sort={sort}
			value={selectedUser || null}
			variant="table"
		/>
	);
};
