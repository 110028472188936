import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { getUrlParams as getNewRequestUrlParams } from "components/pages/NewRequestPage/components/SummaryStep/components/CopyTemplate/copyTemplate.utils";
import { Button } from "components/ui/Button";
import { DropdownButton } from "components/ui/DropdownButton";
import { EditIcon } from "components/ui/Icons/EditIcon";
import { GrantedIcon } from "components/ui/Icons/GrantedIcon";
import { RefreshIcon } from "components/ui/Icons/RefreshIcon";
import { Tooltip } from "components/ui/Tooltip";
import { useChildLink } from "hooks/useChildLink";
import { useIsOpenState } from "hooks/useIsOpenState";
import { useLoadingState } from "hooks/useLoadingState";
import { useStopPropagation } from "hooks/useStopPropagation";
import { TicketModel } from "models/TicketModel";
import { routes } from "routes/routes";
import { useStyles } from "./styles";
import { useTicketRenewal } from "./ticketRenewalButton.hooks";
import { Link } from "../Link";

const RENEWED_STATE_TIMEOUT = 1000;
const getNewRequestParams = (ticket: TicketModel) =>
	getNewRequestUrlParams({
		targets: ticket.targets?.map(target => ({ type: target.type, id: target.targetId })).toArray() ?? [],
		duration: ticket.duration,
		justification: ticket.justification,
		ticketingIntegrationTicketId: ticket.ticketingIntegrationTicket?.id ?? undefined
	});

export const TicketRenewalButton: FC<{ ticket: TicketModel }> = ({ ticket }) => {
	const ticketId = ticket.id;
	const classes = useStyles();
	const { isLoading, withLoader } = useLoadingState();
	const { close, open, isOpen } = useIsOpenState();
	const { t } = useTranslation();
	const { renewed, renewTicket } = useTicketRenewal(ticketId);
	const [buttonState, setButtonState] = useState<"renew" | "renewed">("renew");

	const hasDeletedTargets = useMemo(() => ticket.targets?.some(target => target.isDeleted), [ticket.targets]);

	const handleRenew = useCallback(async () => {
		await withLoader(renewTicket(ticketId));
		setButtonState("renewed");
	}, [renewTicket, ticketId, withLoader]);

	const { onClick: onSelectEditBeforeRenew, ref } = useChildLink();

	const stopPropagation = useStopPropagation();
	const renewOptions = useMemo(() => {
		if (hasDeletedTargets) return [];

		const route = routes.newRequest.main;
		const url = route + `?${getNewRequestParams(ticket)}`;
		return [
			{
				// We need a link instead of a window.open callback since it opens it in a new window otherwise
				label: (
					<Link
						innerRef={ref}
						className={classes.link}
						external
						onClick={stopPropagation}
						noDecoration
						to={url}
						target="_blank"
						rel="noopener noreferrer">
						{t("common.ticketRenewal.editRenewal")}
					</Link>
				),
				value: "renewAndEdit",
				icon: EditIcon
			},
			{
				label: renewed ? t("common.ticketRenewal.renewed") : t("common.ticketRenewal.renew"),
				value: "renew",
				icon: renewed ? GrantedIcon : RefreshIcon,
				disabled: renewed
			}
		];
	}, [hasDeletedTargets, ticket, ref, classes.link, stopPropagation, t, renewed]);

	const onSelect = useCallback(
		(option: string) => {
			if (option === "renew") {
				void handleRenew();
			} else if (option === "renewAndEdit") {
				onSelectEditBeforeRenew();
			}
		},
		[handleRenew, onSelectEditBeforeRenew]
	);

	const dropdown = useMemo(
		() => (
			<DropdownButton
				disabled={hasDeletedTargets}
				size="medium"
				variant="secondary"
				loading={isLoading}
				onClose={close}
				open={isOpen}
				onClick={open}
				onSelectOption={onSelect}
				position="bottom-start"
				options={renewOptions}>
				{t("common.ticketSidebar.renew")}
			</DropdownButton>
		),
		[close, hasDeletedTargets, isLoading, isOpen, onSelect, open, renewOptions, t]
	);

	useEffect(() => {
		if (buttonState === "renew") return;
		const id = setTimeout(() => setButtonState("renew"), RENEWED_STATE_TIMEOUT);
		return () => {
			clearTimeout(id);
		};
	}, [buttonState]);

	if (buttonState === "renewed") {
		return (
			<Button size="medium" variant="primary" onClick={handleRenew} suffix={<GrantedIcon />}>
				{t("common.ticketSidebar.renewed")}
			</Button>
		);
	}

	return hasDeletedTargets ? (
		<Tooltip content={t("common.ticketRenewal.deletedTargetTooltip")}>
			<div>{dropdown}</div>
		</Tooltip>
	) : (
		dropdown
	);
};
