import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	actions: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-end"
	},
	addResourceButtonContainer: {
		display: "flex"
	},
	addResourceButton: {
		backgroundColor: "var(--color-white)",
		borderRadius: "var(--border-radius-rounder)"
	},
	divider: {
		margin: "var(--spacing-x10, 40px) 0"
	},
	modal: {
		minHeight: "35%",
		width: "800px"
	},
	modalContent: {
		display: "flex",
		flexDirection: "column",
		gap: "var(--spacing-x5, 20px)",
		minWidth: "400px"
	},
	resourcesAndRoles: {
		display: "flex",
		flexDirection: "column",
		borderRadius: "var(--border-radius-semi-rounded)",
		gap: "var(--spacing-x5, 20px)"
	},
	resourcesAndRolesField: {
		"&$resourcesAndRolesField": {
			marginTop: 0
		}
	}
});
