import { List } from "immutable";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ResourceColumn, TRole } from "components/common/BasicRoleBar";
import { IntegrationImage } from "components/common/IntegrationImage";
import { RoleBar, type TRoleBarColumn } from "components/common/RoleBar";
import { RoleBarIntegration } from "components/common/RoleBar/components/RoleBarIntegration";
import { RoleBarRole } from "components/common/RoleBar/components/RoleBarRole";
import { Divider } from "components/ui/Divider";
import { IconButton } from "components/ui/IconButton";
import { ArrowRightIcon } from "components/ui/Icons/ArrowRightIcon";
import { CloseIcon } from "components/ui/Icons/CloseIcon";
import { RoleIcon } from "components/ui/Icons/RoleIcon";
import { LoadingSpinner } from "components/ui/LoadingSpinner";
import { TooltipOnOverflow } from "components/ui/TooltipOnOverflow";
import { Typography } from "components/ui/Typography";
import { useOpenGlobalErrorModal } from "hooks/useGlobalError";
import { useIntegrations } from "hooks/useIntegrations";
import { IntegrationModel } from "models/IntegrationModel";
import { IntegrationResourceModel } from "models/IntegrationResourceModel";
import { IntegrationResourceRoleModel } from "models/IntegrationResourceRoleModel";
import { useStyles } from "./styles";

interface IProps {
	integrationResource: IntegrationResourceModel;
	roles: List<IntegrationResourceRoleModel>;
	manual?: boolean;
	onDelete?: (roleId: string, resourceId: string) => Promise<void>;
}

const COLUMNS_WIDTHS = {
	integration: "172px" as const,
	resource: "minmax(172px, 1fr)" as const,
	role: "172px" as const
};

const AccessRoleBar: FC<{
	role?: TRole;
	resource: IntegrationResourceModel;
	integration: IntegrationModel;
	noInteractions?: boolean;
	selected?: boolean;
}> = ({ role, resource, integration, noInteractions, selected }) => {
	const { t } = useTranslation();
	const classes = useStyles();

	const columns: TRoleBarColumn[] = useMemo(
		() => [
			{
				content: integration ? (
					<RoleBarIntegration integration={integration} name={integration.name} linkable />
				) : (
					<div className={classes.columnContent}>
						<IntegrationImage noBackground className={classes.image} />
						<TooltipOnOverflow textVariant="body_reg" content={t("common.unknown.integration")} />
					</div>
				),
				width: COLUMNS_WIDTHS.integration
			},
			{
				content: <ResourceColumn integrationResource={resource} linkable />,
				width: COLUMNS_WIDTHS.resource
			},
			{
				content: role ? (
					<RoleBarRole role={role} linkable />
				) : (
					<div className={classes.columnContent}>
						<RoleIcon size="large" />
						<TooltipOnOverflow textVariant="body_reg" content={t("pages.integrationResource.anyRole")} />
					</div>
				),
				width: COLUMNS_WIDTHS.role
			}
		],
		[integration, classes.columnContent, classes.image, t, resource, role]
	);

	return <RoleBar selected={selected} noInteraction={noInteractions} columns={columns} />;
};

export const AccessRoles: FC<IProps> = ({ roles, manual = false, onDelete, integrationResource }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const openGlobalErrorModal = useOpenGlobalErrorModal();
	const [deletedRoleId, setDeletedRoleId] = useState<string | null>(null);
	const integrations = useIntegrations();

	const hasAccessFromResourcesItems = useMemo(
		() =>
			roles.flatMap(
				role =>
					role.hasAccessFromResources?.map(resource => ({
						giver: resource,
						given: role.set("integrationResource", integrationResource)
					})) || []
			),
		[roles, integrationResource]
	);

	const hasAccessFromRolesItems = useMemo(
		() =>
			roles.flatMap(
				givenRole =>
					givenRole.hasAccessFromRoles?.map(role => ({
						giver: role,
						given: givenRole
					})) || []
			),
		[roles]
	);

	const handleRoleDelete = useCallback(
		async (giver: IntegrationResourceModel | IntegrationResourceRoleModel, given?: IntegrationResourceRoleModel) => {
			const resourceId = giver instanceof IntegrationResourceModel ? giver.id : giver.integrationResourceId;
			const roleId = giver instanceof IntegrationResourceRoleModel ? giver.id : given?.id;

			if (onDelete && resourceId && roleId) {
				try {
					setDeletedRoleId(roleId);
					await onDelete(roleId, resourceId);
				} catch (err) {
					openGlobalErrorModal(err as Error);
				} finally {
					setDeletedRoleId(null);
				}
			}
		},
		[onDelete, openGlobalErrorModal]
	);

	const accessRoles = useMemo(
		() => hasAccessFromResourcesItems.concat(hasAccessFromRolesItems),
		[hasAccessFromResourcesItems, hasAccessFromRolesItems]
	);

	return integrations ? (
		<div className={classes.container}>
			{accessRoles.map(({ giver, given }, index) => {
				const giverIntegration = integrations.get(
					giver instanceof IntegrationResourceModel ? giver.integrationId : giver.integrationResource!.integrationId
				)!;
				const givenIntegration = integrations.get(integrationResource.integrationId)!;

				return (
					<div className={classes.accessRow} key={giver.id}>
						{index === 0 && (
							<div className={classes.textRow}>
								<Typography variant="body_sb" className={classes.title}>
									{t("pages.integrationResource.rolesGivenAccess")}
								</Typography>
								<Typography variant="body_sb">{t("pages.integrationResource.rolesBeingGiven")}</Typography>
							</div>
						)}

						<div className={classes.row}>
							<div className={classes.rowColumn}>
								<AccessRoleBar
									role={giver instanceof IntegrationResourceRoleModel ? giver : undefined}
									resource={giver instanceof IntegrationResourceModel ? giver : giver.integrationResource!}
									integration={giverIntegration}
									noInteractions
									selected
								/>
							</div>

							<ArrowRightIcon size={24} />

							<div className={classes.rowColumn}>
								<AccessRoleBar
									role={given}
									resource={integrationResource}
									integration={givenIntegration}
									noInteractions
									selected
								/>
							</div>

							{manual && (
								<>
									<Divider vertical />
									{giver.id === deletedRoleId || given.id === deletedRoleId ? (
										<div className={classes.spinner}>
											<LoadingSpinner size={20} />
										</div>
									) : (
										<IconButton
											onClick={() =>
												handleRoleDelete(giver, giver instanceof IntegrationResourceModel ? given : undefined)
											}
											tooltip={<Typography>{t("pages.integrationResource.deleteGiverRole")}</Typography>}>
											<CloseIcon />
										</IconButton>
									)}
								</>
							)}
						</div>
					</div>
				);
			})}
		</div>
	) : null;
};
