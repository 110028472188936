import classNames from "classnames";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "components/ui/Button";
import { Input } from "components/ui/Input";
import { Typography } from "components/ui/legacy/Typography";
import { Select } from "components/ui/Select";
import { ApplicationLabel } from "components/ui/selectLabels/ApplicationLabel";
import { TextOption } from "components/ui/selectOptions/TextOption";
import { Table } from "components/ui/Table";
import { useIdentityProvidersContext } from "context/identityProvidersContext";
import { useLoadingState } from "hooks/useLoadingState";
import {
	IdentityProviderModel,
	IDENTITY_PROVIDER_APPLICATIONS,
	TIdentityProviderApplication
} from "models/IdentityProviderModel";
import ApiError from "utils/errors/apiError";
import { requirePropertyOf } from "utils/security";
import { getLabel, type TOptionComponentProps } from "utils/ui/select";
import { sourceIcons } from "utils/ui/sourceIcons";
import { useStyles } from "./styles";

interface ICreateProps {
	close: () => void;
}

interface IShowProps {
	identityProvider: IdentityProviderModel;
	onDelete: (id: string) => void;
}

type TProps = ICreateProps | IShowProps;

const ApplicationOption: FC<{ application: TIdentityProviderApplication }> = ({ application }) => {
	const { t } = useTranslation();
	const classes = useStyles();

	const SourceIcon = useMemo(() => {
		return requirePropertyOf(sourceIcons, application);
	}, [application]);
	return (
		<Typography component="div" className={classes.option}>
			<SourceIcon />
			{t(`pages.settings.identityProviders.${application}`)}
		</Typography>
	);
};

export const IdentityProvider: FC<TProps> = props => {
	const identityProvider = "identityProvider" in props ? props.identityProvider : null;
	const onDelete = "onDelete" in props ? props.onDelete : null;
	const close = "close" in props ? props.close : null;
	const classes = useStyles();
	const { t } = useTranslation();
	const { withLoader: withCreateLoading, isLoading: isCreateLoading } = useLoadingState();
	const [inputErrors, setInputErrors] = useState<string[]>([]);
	const [errors, setErrors] = useState<string[]>([]);
	const [metadataUrl, setMetadataUrl] = useState<string>(identityProvider?.metadataUrl ?? "");
	const [application] = useState<TIdentityProviderApplication>(
		identityProvider?.application ?? IDENTITY_PROVIDER_APPLICATIONS[0]
	);
	const {
		actions: { createIdentityProvider }
	} = useIdentityProvidersContext();

	const urlValidator = useCallback(
		(url: string) => {
			if (URL.canParse(url)) {
				return null;
			} else {
				return t("pages.settings.identityProviders.invalidUrl");
			}
		},
		[t]
	);
	const urlValidityChecks = useMemo(() => [urlValidator], [urlValidator]);

	const onInputError = useCallback((newErrors: string[] | null) => setInputErrors(newErrors || []), []);

	const onCreate = useCallback(async () => {
		if (!metadataUrl) {
			return;
		}
		setErrors([]);
		try {
			await withCreateLoading(createIdentityProvider(application, metadataUrl));
			close?.();
		} catch (error) {
			let errorMessage = (error as Error).message;
			if (error instanceof ApiError && error.errorId === "identityProvider.entityIdAlreadyExists") {
				errorMessage = t("pages.settings.identityProviders.entityIdAlreadyExists");
			}
			setErrors([errorMessage]);
		}
	}, [metadataUrl, withCreateLoading, createIdentityProvider, application, close, t]);

	const onDeleteClick = useCallback(() => {
		onDelete!(identityProvider!.id);
	}, [onDelete, identityProvider]);

	const getOptionRenderer = useCallback(
		(props: TOptionComponentProps<TIdentityProviderApplication>) => {
			const getTextContent = () => t(`pages.settings.identityProviders.${props.option}`);
			return (
				<TextOption
					{...props}
					PrefixIcon={requirePropertyOf(sourceIcons, application)}
					getTextContent={getTextContent}
				/>
			);
		},
		[application, t]
	);

	return (
		<Table.Row>
			<Table.Cell className={classes.fieldContainer}>
				{!identityProvider ? (
					<Select
						disabled={IDENTITY_PROVIDER_APPLICATIONS.length === 1}
						getOptionLabel={getLabel}
						renderOption={getOptionRenderer}
						options={IDENTITY_PROVIDER_APPLICATIONS}
						renderLabel={ApplicationLabel}
						value={IDENTITY_PROVIDER_APPLICATIONS[0]}
					/>
				) : (
					<ApplicationOption application={IDENTITY_PROVIDER_APPLICATIONS[0]} />
				)}
			</Table.Cell>
			<Table.Cell className={classes.fieldContainer}>
				{!identityProvider ? (
					<Input
						autoFocus
						disabled={isCreateLoading}
						errors={errors.length ? errors : inputErrors}
						onError={onInputError}
						onValueChange={setMetadataUrl}
						validators={urlValidityChecks}
						value={metadataUrl}
					/>
				) : (
					<Typography>{metadataUrl}</Typography>
				)}
			</Table.Cell>

			<Table.Cell className={classNames(classes.actionsContainer, { [classes.create]: !identityProvider })}>
				{!identityProvider ? (
					<>
						<Button
							disabled={isCreateLoading || inputErrors.length > 0}
							loading={isCreateLoading}
							onClick={onCreate}
							variant="text"
							size="small">
							{t("buttons.save")}
						</Button>
						<Button disabled={isCreateLoading} onClick={close ?? undefined} variant="text" size="small">
							{t("buttons.cancel")}
						</Button>
					</>
				) : (
					<Button onClick={onDeleteClick} variant="text" size="small">
						{t("buttons.remove")}
					</Button>
				)}
			</Table.Cell>
		</Table.Row>
	);
};
