import React from "react";
import { IntegrationEntity } from "components/common/entities";
import { useSelectLabel } from "../useSelectLabel";
import type { IntegrationModel } from "models/IntegrationModel";
import type { TLabelComponent } from "utils/ui/select";

const IntegrationLabel: TLabelComponent<IntegrationModel> = ({ disabled = false, value, withIcon }) => {
	const entityProps = useSelectLabel(disabled, withIcon);

	return <IntegrationEntity integration={value} {...entityProps} />;
};

IntegrationLabel.isSelectLabel = true;

export { IntegrationLabel };
