import React, { useCallback } from "react";
import { DirectoryGroupEntity } from "components/common/entities/DirectoryGroupEntity";
import { useDirectoryGroup } from "hooks/useDirectoryGroup";
import { DirectoryGroupModel } from "models/DirectoryGroupModel";
import { SelectOption, type TEntityOverrideProps } from "../SelectOption";
import type { TEntityOption } from "utils/entityOptionType";
import type { TOptionComponentProps } from "utils/ui/select";

function DirectoryGroupOption<T extends DirectoryGroupModel | TEntityOption | string>({
	disabled = false,
	innerRef,
	isHighlighted = false,
	isSelected = false,
	onSelect,
	option,
	optionKey
}: TOptionComponentProps<T>) {
	const isEntity = typeof option === "object" && "identifier" in option;
	const isId = typeof option === "string";
	const id = isEntity ? option.identifier : isId ? option : null;
	const currentGroup = useDirectoryGroup(id || undefined);
	const remoteGroup = id && currentGroup ? currentGroup : (option as DirectoryGroupModel);

	const renderOption = useCallback(
		(entityProps: TEntityOverrideProps) => (
			<DirectoryGroupEntity withIcon innerRef={innerRef} directoryGroup={remoteGroup} {...entityProps} />
		),
		[innerRef, remoteGroup]
	);
	return (
		<SelectOption
			onSelect={onSelect}
			isHighlighted={isHighlighted}
			isSelected={isSelected}
			disabled={disabled}
			value={option}
			renderOption={renderOption}
			key={optionKey}
		/>
	);
}

DirectoryGroupOption.isSelectOption = true;

export { DirectoryGroupOption };
