import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	detailsContainer: {
		paddingLeft: "var(--spacing-x7, 28px)",
		paddingRight: "var(--spacing-x7, 28px)",
		paddingTop: "var(--spacing-x6, 24px)",
		paddingBottom: "var(--spacing-x4, 16px)"
	},
	loading: {
		margin: "auto"
	},
	resource: {
		alignItems: "flex-start",
		display: "flex",
		flexDirection: "column",
		gap: "var(--spacing-x2, 8px)"
	},
	resourceLogo: {
		height: "2rem",
		width: "2rem"
	},
	resourceLogoContainer: {
		alignItems: "start",
		display: "flex",
		height: "100%",
		justifyContent: "start"
	},
	targetContainer: {
		display: "flex",
		wordBreak: "break-word",
		gap: "0.5rem",
		height: "100%",
		justifyContent: "center"
	},
	subText: {
		color: "#5F6066"
	},
	icon: {
		gap: "var(--spacing-x2, 8px)"
	}
});
