import React, { useCallback, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { ResourceEntity } from "components/common/entities";
import { isFullIntegration, useIntegrationResourcesTable } from "components/common/tables/IntegrationResourcesTable";
import { WorkflowCell } from "components/common/tables/WorkflowCell";
import { StaticChip } from "components/ui/chips/StaticChip";
import { OwnerIcon } from "components/ui/Icons/OwnerIcon";
import { RequestsIcon } from "components/ui/Icons/RequestsIcon";
import { ResourcesIcon } from "components/ui/Icons/ResourcesIcon";
import { RoleIcon } from "components/ui/Icons/RoleIcon";
import { WorkflowsIcon } from "components/ui/Icons/WorkflowsIcon";
import { IntegrationInheritUserLabel } from "components/ui/selectLabels/UserLabel";
import { UserAvatarOption } from "components/ui/selectOptions/UserAvatarOption";
import { PaginatedVirtualTable, TColumn } from "components/ui/VirtualTable";
import {
	HeaderCellContent,
	TextCellContent,
	ToggleSwitchCellContent,
	UserCellContent,
	UserSelectCellContent
} from "components/ui/VirtualTable/components";
import { IntegrationResourceModel } from "models/IntegrationResourceModel";
import { UserModel } from "models/UserModel";
import { INHERIT, isInheritProps, TInherit, type TLabelComponentProps, type TLabelRenderer } from "utils/ui/select";
import { useStyles } from "./styles";
import type { TGetProps } from "components/ui/VirtualTable/types";

type TProps = {
	fetchIntegrationResources: (page: number) => Promise<void>;
	integrationResources: (IntegrationResourceModel | undefined)[] | null;
	onResourceUpdate: (resource: Partial<IntegrationResourceModel> & { id: string }) => void;
	perPage?: number;
	totalIntegrationResources: number;
	isLoading: boolean;
};

const DEFAULT_PER_PAGE = 15;

const COLUMNS_WIDTHS = {
	resource: "minmax(168px, 1fr)",
	resourceType: "minmax(168px, 200px)",
	roles: "168px",
	workflow: "minmax(168px, 240px)",
	owner: "minmax(168px, 240px)",
	requestable: "180px"
};

export const ResourcesTable: FC<TProps> = ({
	className,
	isLoading,
	fetchIntegrationResources,
	innerRef,
	integrationResources,
	onResourceUpdate,
	perPage = DEFAULT_PER_PAGE,
	totalIntegrationResources
}) => {
	const { t } = useTranslation("translation", { keyPrefix: "common.tables.integrationResources" });
	const classes = useStyles();

	const {
		approvalAlgorithmOptions,
		approvalAlgorithms,
		getIntegration,
		getIsDisabled,
		onUpdateAllowsRequests,
		onUpdateApprovalAlgorithm,
		onUpdateOwner
	} = useIntegrationResourcesTable({ disabled: false, onResourceUpdate });

	const getOwnerOptionLabel = useCallback(
		(option: UserModel | TInherit) => (option === INHERIT ? t("selectNullValue") : option.fullName),
		[t]
	);

	const getOwnerLabelByResourceId = useRef(new Map<IntegrationResourceModel, TLabelRenderer<UserModel | TInherit>>());

	const getRenderOwnerLabel = useCallback(
		(resource: IntegrationResourceModel) => {
			if (!getOwnerLabelByResourceId.current.has(resource)) {
				const renderOwnerLabel = (props: TLabelComponentProps<UserModel | TInherit>) => {
					return isInheritProps(props) ? (
						<IntegrationInheritUserLabel
							{...props}
							integration={isFullIntegration(resource.integration) ? resource.integration : resource.integrationId}
						/>
					) : (
						<UserCellContent user={props.value} disabled={getIsDisabled(resource.id)} />
					);
				};

				getOwnerLabelByResourceId.current.set(resource, renderOwnerLabel);
			}

			return getOwnerLabelByResourceId.current.get(resource);
		},
		[getIsDisabled]
	);

	const columns = useMemo(
		() =>
			[
				{
					renderCell: resource => <ResourceEntity resource={resource} size="medium" variant="link" />,
					header: <HeaderCellContent text={t("headers.integrationResource")} icon={<ResourcesIcon />} />,
					key: "resource",
					width: COLUMNS_WIDTHS.resource
				},
				{
					renderCell: TextCellContent,
					getProps: integrationResource => {
						return {
							text: integrationResource.type ?? "",
							disabled: getIsDisabled(integrationResource.id)
						};
					},
					header: <HeaderCellContent text={t("headers.resourceType")} />,
					key: "resourceType",
					width: COLUMNS_WIDTHS.resourceType
				},
				{
					renderCell: resource => (
						<div className={classes.centeredCellContent}>
							<StaticChip size="medium" PrefixIcon={RoleIcon}>
								{resource.rolesCount}
							</StaticChip>
						</div>
					),
					header: <HeaderCellContent text={t("headers.roles")} icon={<RoleIcon />} />,
					key: "roles",
					width: COLUMNS_WIDTHS.roles
				},
				{
					renderCell: (integrationResource, options) => (
						<WorkflowCell
							integration={getIntegration(integrationResource)}
							defaultValue={t("selectNullValue")}
							options={approvalAlgorithmOptions}
							value={
								integrationResource.approvalAlgorithmId
									? approvalAlgorithms?.get(integrationResource.approvalAlgorithmId)
									: INHERIT
							}
							disabled={options.disabled || getIsDisabled(integrationResource.id)}
							onChange={async newApprovalAlgorithm => {
								if (newApprovalAlgorithm === null) return;
								await onUpdateApprovalAlgorithm(integrationResource.id, newApprovalAlgorithm);
							}}
						/>
					),
					header: <HeaderCellContent text={t("headers.approvalAlgorithm")} icon={<WorkflowsIcon />} />,
					key: "workflow",
					width: COLUMNS_WIDTHS.workflow,
					overflow: true
				},
				{
					renderCell: UserSelectCellContent,
					getProps: (integrationResource, options) => ({
						selectedIds: integrationResource.ownerId ? [integrationResource.ownerId] : undefined,
						value: !integrationResource.ownerId ? INHERIT : undefined,
						staticOptions: [INHERIT],
						disabled: options.disabled || getIsDisabled(integrationResource.id),
						onChange: (newOwner: UserModel | TInherit | null) => {
							if (newOwner === null) return;
							void onUpdateOwner(integrationResource.id, newOwner);
						},
						getOptionLabel: getOwnerOptionLabel,
						renderLabel: getRenderOwnerLabel(integrationResource),
						hideClear: true,
						sort: null,
						renderOption: UserAvatarOption
					}),
					header: <HeaderCellContent text={t("headers.owner")} icon={<OwnerIcon />} />,
					key: "owner",
					width: COLUMNS_WIDTHS.owner,
					overflow: true
				} as TColumn<IntegrationResourceModel>,

				{
					renderCell: ToggleSwitchCellContent,
					getProps: (integrationResource => ({
						disabled: getIsDisabled(integrationResource.id),
						checked: integrationResource.allowsRequests,
						onChange: checked => {
							void onUpdateAllowsRequests(integrationResource.id, checked);
						}
					})) as TGetProps<IntegrationResourceModel, typeof ToggleSwitchCellContent>,
					header: <HeaderCellContent text={t("headers.requestable")} icon={<RequestsIcon />} />,
					key: "requestable",
					width: COLUMNS_WIDTHS.requestable
				} as TColumn<IntegrationResourceModel>
			] as TColumn<IntegrationResourceModel>[],
		[
			approvalAlgorithmOptions,
			approvalAlgorithms,
			classes.centeredCellContent,
			getIntegration,
			getIsDisabled,
			getOwnerOptionLabel,
			getRenderOwnerLabel,
			onUpdateAllowsRequests,
			onUpdateApprovalAlgorithm,
			onUpdateOwner,
			t
		]
	);

	return (
		<PaginatedVirtualTable
			isLoading={isLoading}
			className={className}
			innerRef={innerRef}
			columns={columns}
			rows={integrationResources || []}
			totalRows={totalIntegrationResources}
			perPage={perPage || DEFAULT_PER_PAGE}
			fetchPage={fetchIntegrationResources}
			emptyTableMessage={t(`noResources`)}
		/>
	);
};
