import { List } from "immutable";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ApprovalAlgorithmModel } from "models/ApprovalAlgorithmModel";
import { FormBundleItemModel } from "./formBundleItemModel";
import type { TTicketDuration } from "utils/durationsOptions";

export type TBundleData = {
	bundleItems?: List<FormBundleItemModel>;
	category?: string | null;
	approvalAlgorithm?: ApprovalAlgorithmModel | null;
	allowedDurations?: List<TTicketDuration> | null;
	name?: string;
	id?: string;
	description: string | null;
	tags: string[] | null;
};
export const useBundleFormState = (initialBundle?: TBundleData) => {
	const [category, setCategory] = useState(initialBundle?.category || null);
	const [name, setName] = useState(initialBundle?.name);
	const [description, setDescription] = useState(initialBundle?.description || null);
	const [tags, setTags] = useState(initialBundle?.tags || []);
	const [approvalAlgorithm, setApprovalAlgorithm] = useState(initialBundle?.approvalAlgorithm || null);
	const [bundleItems, setBundleItems] = useState(initialBundle?.bundleItems || List([]));
	const [allowedDurations, setAllowedDurations] = useState(List<TTicketDuration>());
	const [overrideAllowedDurations, setOverrideAllowedDurations] = useState(Boolean(initialBundle?.allowedDurations));
	const addNewBundleItem = useCallback(() => {
		setBundleItems(bundleItems.push(FormBundleItemModel.createEmptyItem()));
	}, [bundleItems]);

	const removeBundleItem = useCallback(
		(index: number) => {
			if (bundleItems.size !== 1) {
				setBundleItems(bundleItems.removeIn([index]));
			}
		},
		[bundleItems]
	);

	useEffect(() => {
		if (bundleItems.isEmpty()) {
			addNewBundleItem();
		}
	}, [addNewBundleItem, bundleItems]);

	const state = useMemo(
		() => ({
			category,
			name,
			description,
			tags,
			approvalAlgorithm,
			bundleItems,
			id: initialBundle?.id,
			allowedDurations,
			overrideAllowedDurations
		}),
		[
			allowedDurations,
			approvalAlgorithm,
			bundleItems,
			category,
			description,
			initialBundle?.id,
			name,
			overrideAllowedDurations,
			tags
		]
	);
	return {
		state,
		actions: {
			setCategory,
			setName,
			setDescription,
			setTags,
			setApprovalAlgorithm,
			setBundleItems,
			setAllowedDurations,
			setOverrideAllowedDurations,
			addNewBundleItem,
			removeBundleItem
		}
	};
};
