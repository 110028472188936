import React, { useMemo } from "react";
import { UserLabel, UserWithoutEmailLabel } from "components/ui/selectLabels/UserLabel";
import { useUser } from "hooks/useUser";
import { UserModel } from "models/UserModel";
import { useVirtualTableContext } from "../../virtualTableContext";
import type { IRowOptions } from "../../types";

interface IProps {
	user: string | UserModel | null;
	emptyState?: React.ReactNode;
}

const UserCellLabel: FC<IProps & IRowOptions> = ({ user, emptyState = null, disabled }) => {
	const {
		state: { compact }
	} = useVirtualTableContext();

	const fetchedUser = useUser(typeof user === "string" ? user : "");
	const userModel = useMemo(() => (user instanceof UserModel ? user : fetchedUser), [user, fetchedUser]);

	if (!user) {
		return emptyState;
	}
	if (!userModel) return null;

	return compact ? (
		<UserWithoutEmailLabel value={userModel} disabled={disabled} />
	) : (
		<UserLabel value={userModel} disabled={disabled} />
	);
};

const UserCellLabelMemo = React.memo(UserCellLabel) as typeof UserCellLabel;

export { UserCellLabelMemo as UserCellLabel };
