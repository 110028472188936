import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	content: {
		background: "var(--color-white)",
		borderRadius: "8px",
		boxShadow: "var(--shadow-bottom)",
		display: "flex",
		flexDirection: "column",
		minHeight: "fit-content",
		minWidth: "520px",
		gap: "20px",
		padding: "20px",
		zIndex: 100
	},
	actions: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-end"
	},
	templateSelect: {
		display: "flex",
		flexDirection: "row",
		gap: "var(--spacing-x3, 12px)",
		justifyContent: "flex-start",
		alignItems: "flex-end"
	},
	tooltip: {
		zIndex: 998
	}
});
