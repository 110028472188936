import React, { useCallback } from "react";
import {
	IntegrationInheritWorkflowLabel,
	ResourceInheritWorkflowLabel,
	WorkflowLabel
} from "components/ui/selectLabels/WorkflowLabel";
import { WorkflowOption } from "components/ui/selectOptions/WorkflowOption";
import { DropdownCellContent } from "components/ui/VirtualTable/components";
import { useStaticOptions } from "hooks/useStaticOptions";
import { ApprovalAlgorithmModel } from "models/ApprovalAlgorithmModel";
import { IntegrationModel } from "models/IntegrationModel";
import { INHERIT, isInheritProps, type TInherit, type TLabelComponentProps } from "utils/ui/select";
import type { IntegrationResourceModel } from "models/IntegrationResourceModel";

type TWorkflowProps = {
	defaultValue: string;
	integration?: IntegrationModel | null;
	integrationResource?: IntegrationResourceModel | null;
	options: (ApprovalAlgorithmModel | TInherit)[];
	value?: TInherit | ApprovalAlgorithmModel | null;
	disabled?: boolean;
	onChange: (value: ApprovalAlgorithmModel | TInherit | null) => Promise<void>;
};

const getOptionKey = (option: ApprovalAlgorithmModel | TInherit) => (option === INHERIT ? INHERIT : option.id);
const STATIC_OPTIONS: TInherit[] = [INHERIT];
export const WorkflowCell: FC<TWorkflowProps> = ({
	defaultValue,
	integration,
	integrationResource,
	options: propOptions,
	value,
	disabled,
	onChange
}) => {
	const { options, groupBy } = useStaticOptions(propOptions, getOptionKey, STATIC_OPTIONS, false);

	const getApprovalAlgorithmOptionLabel = useCallback(
		(option: ApprovalAlgorithmModel | TInherit) => (option === INHERIT ? defaultValue : option.name),
		[defaultValue]
	);

	const renderApprovalAlgorithmOptionLabel = useCallback(
		(props: TLabelComponentProps<TInherit | ApprovalAlgorithmModel>) => {
			if (isInheritProps(props)) {
				return integration !== undefined ? (
					<IntegrationInheritWorkflowLabel {...props} integration={integration ?? undefined} />
				) : (
					<ResourceInheritWorkflowLabel {...props} integrationResource={integrationResource ?? undefined} />
				);
			}
			return <WorkflowLabel {...(props as TLabelComponentProps<ApprovalAlgorithmModel>)} />;
		},
		[integration, integrationResource]
	);

	const cellOnChange = useCallback(
		(value: (ApprovalAlgorithmModel | TInherit) | null) => {
			void onChange(value);
		},
		[onChange]
	);

	return (
		<DropdownCellContent
			disabled={disabled}
			getOptionLabel={getApprovalAlgorithmOptionLabel}
			noClear
			onChange={cellOnChange}
			groupBy={groupBy}
			renderOption={WorkflowOption}
			options={options}
			renderLabel={renderApprovalAlgorithmOptionLabel}
			sort={null}
			value={value}
		/>
	);
};
