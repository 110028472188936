import uniqueId from "lodash/uniqueId";
import React, { useCallback, useMemo, useState } from "react";
import { Input } from "components/ui/Input";
import { SelectItemList } from "components/ui/SelectItemList";
import { TOOLTIP_AVAILABLE_TO_ATTACH_COMPONENTS } from "components/ui/Tooltip";
import { useOnClickOutside } from "hooks/useOnClickOutside";
import { useStopPropagation } from "hooks/useStopPropagation";
import { useTooltip } from "hooks/useTooltip";
import { sortOptions, type TOptionRenderer } from "utils/ui/select";
import type { Placement } from "@popperjs/core";

export interface IFloatingSelectOption {
	label: string;
	value: string;
}
interface IProps<TOption extends IFloatingSelectOption> {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-redundant-type-constituents
	children: React.ReactElement<any & { innerRef?: React.Ref<any> }, any>;
	filter?: boolean;
	onClose: () => void;
	onSelect: (value: string) => void;
	onInputChange?: (value: string) => void;
	inputValue?: string;
	open: boolean;
	options: TOption[];
	position?: Placement;
	placeholder?: string;
	optionsClassName?: string;
	sort?: ((options: TOption[]) => TOption[]) | null; // default: undefined. set undefined for default sort. set to null if the options need no sort. set to function if options need to be sorted by the function.
	renderOption: TOptionRenderer<TOption>;
}

export const FloatingSelect = <TOption extends IFloatingSelectOption>({
	children,
	filter,
	onClose,
	onSelect,
	onInputChange,
	inputValue,
	open,
	options,
	position = "auto",
	placeholder,
	renderOption: propRenderOption,
	sort = sortOptions as NonNullable<IProps<TOption>["sort"]>
}: TProps<IProps<TOption>>) => {
	const inputId = useMemo(() => uniqueId("floating-select-input-"), []);
	const useTooltipProps = useMemo(() => ({ visible: open, placement: position }), [position, open]);
	const [filterValue, setFilterValue] = useState("");

	const onFilterChange = useCallback(
		(value: string) => {
			setFilterValue(value);
			onInputChange?.(value);
		},
		[onInputChange]
	);
	const { visible, setTooltipRef, getTooltipProps, setTriggerRef, tooltipRef } = useTooltip(useTooltipProps);
	const childrenRef = useMemo(() => {
		return typeof children.type === "string" && TOOLTIP_AVAILABLE_TO_ATTACH_COMPONENTS.includes(children.type)
			? { ref: setTriggerRef }
			: { innerRef: setTriggerRef };
	}, [children, setTriggerRef]);

	useOnClickOutside(tooltipRef || undefined, onClose);

	const clonedChildren = useMemo(() => {
		return React.cloneElement(children, { ...children.props, ...childrenRef });
	}, [children, childrenRef]);

	const sortedOptions = useMemo(() => {
		if (sort !== null) {
			return sort(options) || options;
		}
		return options;
	}, [options, sort]);

	const getOptionKey = useCallback((option: TOption) => option.value, []);
	const getOptionLabel = useCallback((option: TOption) => option.label, []);

	const filterOptions = useCallback(
		(option: { label: string; value: string }) => {
			if (!filterValue) return true;

			return option.label.toLowerCase().includes(filterValue.toLowerCase());
		},
		[filterValue]
	);

	const filteredOptions = useMemo(() => {
		return (
			sortedOptions
				.filter(filterOptions)
				// too much can crush the page
				.slice(0, 200)
		);
	}, [sortedOptions, filterOptions]);

	const stopPropagation = useStopPropagation();
	const onSelectItem = useCallback(
		(event: React.SyntheticEvent, value: IFloatingSelectOption) => {
			stopPropagation(event as unknown as React.MouseEvent<HTMLElement>);
			onSelect(value.value);
		},
		[onSelect, stopPropagation]
	);

	return (
		<>
			{clonedChildren}
			{visible && (
				<SelectItemList
					highlightedIndex={-1}
					filteredOptions={filteredOptions}
					getTooltipProps={getTooltipProps}
					getOptionKey={getOptionKey}
					getOptionLabel={getOptionLabel}
					hasMore={false}
					input={
						filter ? (
							<Input
								autoFocus
								variant="search"
								id={inputId}
								onValueChange={onFilterChange}
								placeholder={placeholder}
								value={inputValue}
							/>
						) : null
					}
					inputValue={inputValue}
					onSelect={onSelectItem}
					renderOption={propRenderOption}
					setTooltipRef={setTooltipRef}
				/>
			)}
		</>
	);
};
