import classNames from "classnames";
import React, { useMemo } from "react";
import { TooltipOnOverflow } from "components/ui/TooltipOnOverflow";
import { Typography } from "components/ui/Typography";
import { requirePropertyOf } from "utils/security";
import { useStyles } from "../styles";
import type { TBaseEntityNodeProps } from "../types";

export const EntityNodeContent: FC<
	TBaseEntityNodeProps & {
		text: React.ReactNode;
		icon?: React.ReactNode;
		withIcon?: boolean;
		inactive?: boolean;
		tooltipOnOverflow?: boolean;
		iconClassName?: string;
	}
> = ({
	text,
	withIcon,
	inactive,
	size,
	icon,
	className,
	innerRef,
	id,
	variant = "standard",
	noWrap = true,
	relative,
	textClassName,
	iconClassName,
	tooltipOnOverflow,
	delayShowTooltip
}) => {
	const classes = useStyles();
	const sizeClassName = useMemo(() => requirePropertyOf(classes, size), [classes, size]);

	const typographyVariant = useMemo(() => {
		switch (size) {
			case "large":
				switch (variant) {
					case "bold":
					case "link":
						return "title_sb";
					default:
						return "title_med";
				}
			case "medium":
				switch (variant) {
					case "bold":
					case "link":
						return "body_sb";
					default:
						return "body_reg";
				}
			case "small":
				switch (variant) {
					case "bold":
					case "link":
						return "text_title_sb";
					default:
						return "text_reg";
				}
			case "tiny":
				switch (variant) {
					case "bold":
					case "link":
						return "text_sm_sb";
					default:
						return "text_sm_reg";
				}
			default:
				return undefined;
		}
	}, [variant, size]);

	const iconComponent = useMemo(
		() => (withIcon ? <div className={classNames(classes.icon, sizeClassName, iconClassName)}>{icon}</div> : null),
		[withIcon, classes.icon, sizeClassName, iconClassName, icon]
	);

	return (
		<div
			id={id}
			ref={innerRef}
			className={classNames(
				classes.labelTextContent,
				sizeClassName,
				{ [classes.inactive]: inactive, [classes.wrap]: !noWrap },
				className
			)}>
			{iconComponent}
			{tooltipOnOverflow ? (
				<TooltipOnOverflow
					delayShow={delayShowTooltip}
					className={textClassName}
					textVariant={typographyVariant}
					content={text}
					multiline={!noWrap}
					relative={relative}
				/>
			) : (
				<Typography className={textClassName} variant={typographyVariant} noWrap={noWrap} relative={relative}>
					{text}
				</Typography>
			)}
		</div>
	);
};
