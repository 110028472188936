import { List } from "immutable";
import uniq from "lodash/uniq";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { RoleEntity } from "components/common/entities";
import { RequestNumberChip } from "components/common/RequestNumberChip";
import { ExpirationCell } from "components/pages/UserPage/components/UserPermissionsSection/components/UserPermissionsTable/components/ExpirationCell";
import { IconButton } from "components/ui/IconButton";
import { AccountIcon } from "components/ui/Icons/AccountIcon";
import { DurationIcon } from "components/ui/Icons/DurationIcon";
import { ExpiredIcon } from "components/ui/Icons/ExpiredIcon";
import { InfoIcon } from "components/ui/Icons/InfoIcon";
import { RequestsIcon } from "components/ui/Icons/RequestsIcon";
import { RoleIcon } from "components/ui/Icons/RoleIcon";
import { UserCircleIcon } from "components/ui/Icons/UserCircleIcon";
import { Tooltip } from "components/ui/Tooltip";
import { Typography } from "components/ui/Typography";
import { TColumn, VirtualTable } from "components/ui/VirtualTable";
import {
	ChipContainerCellContent,
	HeaderCellContent,
	TextCellContent,
	UserCellContent
} from "components/ui/VirtualTable/components";
import { formatDate } from "i18n";
import { notEmpty } from "utils/comparison";
import { SourceCell } from "./components/SourecCell";
import type { IEntitlement } from "components/pages/IntegrationResourcePage/utils";
import type { IntegrationResourceModel } from "models/IntegrationResourceModel";
import type { IntegrationResourceRoleModel } from "models/IntegrationResourceRoleModel";

type TUserEntitlement = {
	userId: string | null;
	entitlement: IEntitlement;
};

interface IProps {
	entitlementsByUser: List<TUserEntitlement>;
	isLoading: boolean;
}

const COLUMNS_WIDTHS = {
	user: "minmax(168px, 1fr)",
	account: "minmax(168px, 1fr)",
	role: "minmax(168px, 1fr)",
	permissionType: "188px",
	requestNumber: "192px",
	expiration: "180px",
	lastUsed: "200px"
};
const USERS_PAGE_TRANSLATION_PREFIX = "pages.user.entitlements";

export const PermissionsTable: FC<IProps> = ({ className, innerRef, entitlementsByUser, isLoading }) => {
	const { t } = useTranslation();
	const [searchParams, setSearchParams] = useSearchParams();
	const userEntitlements = useMemo(() => entitlementsByUser.toArray(), [entitlementsByUser]);

	const renderRequestNumber = useCallback(
		(entitlement: IEntitlement, disabled = false) => {
			const tickets = uniq(
				(entitlement.ticketPermissions?.toArray() || [])
					.map(ticketPermission => ticketPermission.ticket)
					.filter(notEmpty)
			);

			const onChipClick = (ticketId: string) => {
				searchParams.set("ticketId", ticketId);
				setSearchParams(searchParams);
			};

			return tickets.map(ticket => (
				<RequestNumberChip
					key={ticket.id}
					requestNumber={ticket.number}
					onClick={!disabled ? () => onChipClick(ticket.id) : undefined}
				/>
			));
		},
		[searchParams, setSearchParams]
	);

	const columns = useMemo(
		() =>
			[
				{
					renderCell: UserCellContent,
					getProps: (row: TUserEntitlement) => ({ user: row.userId }),
					header: <HeaderCellContent text={t(`common.tables.permissions.headers.user`)} icon={<UserCircleIcon />} />,
					key: "user",
					width: COLUMNS_WIDTHS.user
				},
				{
					renderCell: TextCellContent,
					getProps: (row: TUserEntitlement) => ({ text: row.entitlement.actor.displayName }),
					header: <HeaderCellContent text={t(`common.tables.permissions.headers.account`)} icon={<AccountIcon />} />,
					key: "account",
					width: COLUMNS_WIDTHS.account
				},
				{
					renderCell: (row: TUserEntitlement) => {
						const role = row.entitlement.role;
						if (!role) {
							return <TextCellContent text="" />;
						}

						return (
							<RoleEntity
								size="medium"
								variant={role.integrationResource ? "link" : "standard"}
								role={
									role as IntegrationResourceRoleModel & {
										integrationResource: IntegrationResourceModel;
									}
								}
							/>
						);
					},
					header: <HeaderCellContent text={t(`common.tables.permissions.headers.role`)} icon={<RoleIcon />} />,
					key: "role",
					width: COLUMNS_WIDTHS.role
				},
				{
					renderCell: (row: TUserEntitlement) => <SourceCell entitlement={row.entitlement} />,

					header: (
						<HeaderCellContent
							text={t(`common.tables.permissions.headers.permissionType`)}
							suffixIcon={
								<Tooltip
									placement="top-start"
									content={<Typography variant="text_sm_reg">{t("common.tables.permissions.infoText")}</Typography>}>
									<IconButton>
										<InfoIcon />
									</IconButton>
								</Tooltip>
							}
						/>
					),
					key: "permissionType",
					width: COLUMNS_WIDTHS.permissionType
				},
				{
					renderCell: ChipContainerCellContent,
					getProps: (row: TUserEntitlement, options) => ({
						chips: row.entitlement.role?.managed ? renderRequestNumber(row.entitlement, options.disabled || false) : [],
						size: "medium"
					}),
					header: (
						<HeaderCellContent text={t(`common.tables.permissions.headers.requestNumber`)} icon={<RequestsIcon />} />
					),
					key: "requestNumber",
					width: COLUMNS_WIDTHS.requestNumber
				},
				{
					renderCell: (row: TUserEntitlement) => <ExpirationCell entitlement={row.entitlement} />,
					header: <HeaderCellContent text={t(`common.tables.permissions.headers.expiration`)} icon={<ExpiredIcon />} />,
					key: "expiration",
					width: COLUMNS_WIDTHS.expiration
				},
				{
					renderCell: (row: TUserEntitlement) =>
						row.entitlement.actor.lastUsed
							? formatDate(
									row.entitlement.actor.lastUsed instanceof Date
										? row.entitlement.actor.lastUsed
										: new Date(row.entitlement.actor.lastUsed)
								)
							: "",
					header: <HeaderCellContent text={t(`common.tables.permissions.headers.lastUsed`)} icon={<DurationIcon />} />,
					key: "lastUser",
					width: COLUMNS_WIDTHS.lastUsed
				}
			] as TColumn<TUserEntitlement>[],
		[renderRequestNumber, t]
	);

	return (
		<VirtualTable
			className={className}
			innerRef={innerRef}
			rows={userEntitlements}
			totalRows={userEntitlements.length}
			columns={columns}
			emptyTableMessage={t(`${USERS_PAGE_TRANSLATION_PREFIX}.permissionsTable.noPermissions`)}
			isLoading={isLoading}
		/>
	);
};
