import classNames from "classnames";
import React, { useCallback, useMemo, useRef } from "react";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";
import { IconButton } from "components/ui/IconButton";
import { CloseIcon } from "components/ui/Icons/CloseIcon";
import { useSystemOverlays } from "context/overlaysContext";
import { useOnEscKeyDownEvent } from "hooks/useOnEscKeyDownEvent";
import { useStopPropagation } from "hooks/useStopPropagation";
import { MODAL_FIXED_CLASS, useStyles } from "./styles";

export interface IModalBaseProps {
	noTopCloseButton?: boolean; // Will not show the close button in the top right corner default: false
	parentElement?: HTMLElement; // default: document.body
}

interface IModalProps extends IModalBaseProps {
	actions?: React.ReactNode;
	content?: React.ReactNode;
	isOpen: boolean;
	onClose?: () => void;
}

type TPortalContentProps = Omit<IModalProps, "isOpen" | "parentElement">;

const PortalContent: FC<TPortalContentProps> = ({
	actions = null,
	className,
	content = null,
	innerRef,
	noTopCloseButton = false,
	onClose
}) => {
	const wrapperRef = useRef<HTMLDivElement>(null);
	const { t } = useTranslation();
	const classes = useStyles();

	const stopPropagation = useStopPropagation();

	const stopPropagationAndClose = useStopPropagation(onClose);

	const wrapperOnClick = useCallback(
		(e: React.MouseEvent<HTMLDivElement>) => {
			if (e.target === wrapperRef.current) {
				stopPropagationAndClose(e);
			}
		},
		[stopPropagationAndClose]
	);

	const wrapperOnMouseDown = useCallback(
		(e: React.MouseEvent<HTMLDivElement>) => {
			if (e.target === wrapperRef.current) {
				stopPropagation(e);
			}
		},
		[stopPropagation]
	);

	useOnEscKeyDownEvent(onClose);

	const topCloseButton = noTopCloseButton ? null : (
		<IconButton size="medium" tooltip={t("buttons.close")} onClick={onClose}>
			<CloseIcon />
		</IconButton>
	);

	return (
		<div ref={wrapperRef} className={classes.wrapper} onClick={wrapperOnClick} onMouseDown={wrapperOnMouseDown}>
			<div className={classNames(classes.container, className, MODAL_FIXED_CLASS)} ref={innerRef}>
				<div className={classes.top}>{topCloseButton}</div>
				<div className={classes.content}>{content}</div>
				<div className={classes.actions}>{actions}</div>
			</div>
		</div>
	);
};

const Modal: FC<Omit<IModalProps, "isOpen">> = ({ parentElement, ...restProps }) => {
	const portalTarget = parentElement ?? document.body;
	return createPortal(<PortalContent {...restProps} />, portalTarget);
};

const ModalWrapper: FC<IModalProps> = ({ isOpen, ...restProps }) => {
	const systemOverlays = useSystemOverlays();
	const parentElement = useMemo(() => {
		const chosenElement = restProps.parentElement ?? systemOverlays;
		if (chosenElement instanceof HTMLElement) return chosenElement;
		return undefined;
	}, [restProps.parentElement, systemOverlays]);

	return isOpen ? <Modal {...restProps} parentElement={parentElement} /> : null;
};

export { ModalWrapper as Modal, Modal as UnWrappedModal };
