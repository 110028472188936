import React, { useCallback, useMemo, useState } from "react";
import { UserChip } from "components/ui/chips/UserChip";
import { IRenderChipParams, MultipleSelect } from "components/ui/MultipleSelect";
import { UserNodeOption } from "components/ui/selectOptions/UserNodeOption";
import { useUsersSelect } from "hooks/useUsersSelect";
import { UserModel } from "models/UserModel";
import { sortByFullName } from "utils/sortUtils";
import type { TTargetValue } from "components/ui/Select";

interface IProps {
	label: string;
	onChange: (value: string[]) => void;
	placeholder?: string;
	renderChip?: (params: IRenderChipParams<UserModel>) => React.JSX.Element;
	selectedIds: string[];
}

const getLabel = (user: UserModel) => user?.fullName;
const equality = (option: UserModel, value: UserModel) => option?.id === value?.id;
const renderChip = (params: IRenderChipParams<UserModel>) => {
	return <UserChip selected onDelete={params.onRemove} variant="regular" value={params.option} />;
};

export const UsersSelectInput: FC<IProps> = ({
	className,
	label,
	onChange: propsOnChange,
	renderChip: propsRenderChip,
	placeholder,
	selectedIds
}) => {
	const [query, setQuery] = useState("");
	const { items: users, selectedItems, isLoading } = useUsersSelect(query, { selectedIds });

	const userList = useMemo(() => users.toArray(), [users]);
	const selectedUsers = useMemo(() => selectedItems.toArray(), [selectedItems]);

	const onInputChange = useCallback(
		(event: TTargetValue | React.ChangeEvent<HTMLInputElement>) => setQuery(event.target.value),
		[]
	);

	const onChange = useCallback(
		(value: UserModel[] | null) => propsOnChange(value?.map(({ id }) => id) || []),
		[propsOnChange]
	);

	return (
		<MultipleSelect
			className={className}
			filter={null}
			getOptionLabel={getLabel}
			isOptionEqualToValue={equality}
			label={label}
			limitChipType="user"
			loading={isLoading}
			onChange={onChange}
			onInputChange={onInputChange}
			renderOption={UserNodeOption}
			options={userList}
			placeholder={placeholder}
			renderChip={propsRenderChip ?? renderChip}
			sort={sortByFullName}
			value={selectedUsers}
		/>
	);
};
