import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	titleContainer: {
		display: "flex",
		flexDirection: "row",
		flexGrow: 1,
		justifyContent: "space-between"
	},
	detailsContainer: {
		padding: "0 2rem 1rem"
	},
	description: {
		width: "100%",
		paddingBottom: "1rem",
		display: "flex",
		flexDirection: "column",
		gap: "0.3rem"
	},
	clauseRow: {
		"&:first-child": {
			borderTop: "var(--block-border)"
		},
		borderBottom: "var(--block-border)"
	},
	loading: {
		margin: "auto"
	},
	icon: {
		gap: "var(--spacing-x2, 8px)"
	}
});
