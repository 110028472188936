import React from "react";
import { TooltipOnOverflow } from "components/ui/TooltipOnOverflow";
import { useStyles } from "./styles";

export const NameWithEmail: FC<{ delayShowTooltip?: number; email: string; name: string }> = ({
	delayShowTooltip,
	email,
	name
}) => {
	const classes = useStyles();
	return (
		<div className={classes.nameAndEmailContainer}>
			<TooltipOnOverflow delayShow={delayShowTooltip} textVariant="body_med" content={name} />
			<TooltipOnOverflow delayShow={delayShowTooltip} textVariant="text_sm_reg" content={email} />
		</div>
	);
};
