import React, { useMemo } from "react";
import { IntegrationEntity } from "components/common/entities";
import { useSelectLabel } from "../useSelectLabel";
import type { ApplicationModel } from "models/ApplicationModel";
import type { TLabelComponent } from "utils/ui/select";

const ApplicationLabel: TLabelComponent<ApplicationModel | string> = ({ disabled = false, value, withIcon }) => {
	const entityProps = useSelectLabel(disabled, withIcon);
	const integration = useMemo(() => (typeof value === "string" ? { name: value } : value), [value]);
	return <IntegrationEntity integration={integration} {...entityProps} />;
};

ApplicationLabel.isSelectLabel = true;

export { ApplicationLabel };
