import React from "react";
import { DirectoryGroupEntity } from "components/common/entities/DirectoryGroupEntity";
import { useSelectLabel } from "../useSelectLabel";
import type { DirectoryGroupModel } from "models/DirectoryGroupModel";
import type { TLabelComponent } from "utils/ui/select";

const DirectoryGroupLabel: TLabelComponent<DirectoryGroupModel> = ({ disabled = false, value, withIcon }) => {
	const entityProps = useSelectLabel(disabled, withIcon);

	return <DirectoryGroupEntity directoryGroup={value} {...entityProps} />;
};

DirectoryGroupLabel.isSelectLabel = true;

export { DirectoryGroupLabel };
