import { Record } from "immutable";
import type { IntegrationModel } from "models/IntegrationModel";
import type { IntegrationResourceModel } from "models/IntegrationResourceModel";
import type { IntegrationResourceRoleModel } from "models/IntegrationResourceRoleModel";

const defaults = {
	integration: null as IntegrationModel | null,
	resource: null as IntegrationResourceModel | null,
	role: null as IntegrationResourceRoleModel | null,
	id: undefined as string | undefined
};

export class FormBundleItemModel extends Record(defaults) {
	static createEmptyItem() {
		return new FormBundleItemModel({ integration: null, resource: null, role: null, id: undefined });
	}
}
