import { createUseStyles } from "react-jss";

const DROPDOWN_DEFAULT_MAX_HEIGHT = 288;
export const useStyles = createUseStyles({
	groupContainer: {
		boxSizing: "border-box",
		display: "flex",
		flexDirection: "column",
		width: "100%"
	},
	groupLabel: {
		fontWeight: "var(--font-weight-bold)",
		padding: "var(--spacing-x5, 20px) var(--spacing-x4, 16px) 0 var(--spacing-x4, 16px)",
		pointerEvents: "none"
	},
	optionsContainer: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "flex-start",
		alignItems: "flex-start",
		width: "300px"
	},
	searchBarContainer: {
		display: "flex",
		width: "100%",
		justifyContent: "flex-start",
		alignItems: "center",
		padding: "var(--spacing-x4, 16px)",
		backgroundColor: "var(--color-purple-50, #FBF9FB)",
		gap: "var(--spacing-x1, 4px)",
		borderBottom: "2px solid var(--color-purple-400, #BFA5C9)",
		borderRadius: "var(--border-radius-semi-rounded) var(--border-radius-semi-rounded) 0 0"
	},
	optionsListContainer: {
		width: "100%",
		paddingTop: "var(--spacing-x3, 12px)",
		paddingBottom: "var(--spacing-x4, 16px)"
	},
	optionsListOverflow: {
		display: "flex",
		flexDirection: "column",
		width: "100%",
		justifyContent: "flex-start",
		alignItems: "flex-start",
		overflowY: "auto",
		maxHeight: DROPDOWN_DEFAULT_MAX_HEIGHT
	},
	searchForMore: {
		alignItems: "center",
		display: "flex",
		justifyContent: "center",
		padding: "var(--spacing-x4, 12px) var(--spacing-x3, 16px) 0 var(--spacing-x4, 12px)",
		color: "var(--color-grey-600, #838589)",
		width: "100%"
	},
	selectItemsContainer: {
		zIndex: "999",
		backgroundColor: "var(--color-white)",
		boxSizing: "border-box",
		boxShadow: "var(--surface-popup-box-shadow)",
		borderRadius: "var(--border-radius-semi-rounded)",
		display: "flex",
		flexDirection: "column"
	},
	messageListContent: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		height: DROPDOWN_DEFAULT_MAX_HEIGHT,
		cursor: "default",
		paddingLeft: "var(--spacing-x5, 20px)",
		"&$loading": {
			alignItems: "center"
		},
		"&$noResults": {
			color: "var(--color-grey-700, #5F6066)",
			height: "180px"
		}
	},
	loading: {},
	noResults: {}
});
