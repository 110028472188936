import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	searchBar: {
		display: "flex",
		width: "320px",
		justifyContent: "flex-end",
		alignItems: "flex-end"
	},
	content: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "flex-start",
		alignItems: "flex-end",
		gap: "var(--spacing-x3, 12px)",
		height: "100%"
	},
	autoAssignHelpBlock: {
		display: "inline-block"
	},
	titleContainer: {
		display: "flex",
		flexDirection: "row",
		flexGrow: 1,
		justifyContent: "space-between",
		gap: "var(--spacing-x2, 8px)",
		maxWidth: "100%"
	},
	titlePart: {
		alignItems: "center",
		display: "flex",
		flexDirection: "row",
		gap: "var(--spacing-x1, 4px)"
	},
	separator: {
		margin: "0 var(--spacing-x2, 8px)"
	},
	lighterText: {
		color: "var(--color-grey-dark)"
	},
	childrenCount: {
		minWidth: "fit-content"
	},
	resourceActions: {
		flexWrap: "wrap",
		justifyContent: "flex-end",
		width: "fit-content",
		minWidth: 0
	},
	resourceLabel: {
		maxWidth: "100%",
		minWidth: "max-content"
	},
	icon: {
		gap: "var(--spacing-x2, 8px)"
	}
});
