import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { WorkflowEntity } from "components/common/entities/WorkflowEntity";
import { TitleTooltip } from "components/ui/TitleTooltip";
import { Typography } from "components/ui/Typography";
import { useApprovalAlgorithms } from "hooks/useApprovalAlgorithms";
import { useIntegrations } from "hooks/useIntegrations";
import { devLog } from "utils/devtools/devLogging";
import { TextLabel } from "../TextLabel";
import { useSelectLabel } from "../useSelectLabel";
import type { ApprovalAlgorithmModel } from "models/ApprovalAlgorithmModel";
import type { IntegrationModel } from "models/IntegrationModel";
import type { IntegrationResourceModel } from "models/IntegrationResourceModel";
import type { TInherit, TLabelComponent } from "utils/ui/select";

const WorkflowLabel: TLabelComponent<ApprovalAlgorithmModel> = ({ disabled = false, value, withIcon }) => {
	const entityProps = useSelectLabel(disabled, withIcon);

	return <WorkflowEntity approvalAlgorithm={value} {...entityProps} />;
};

WorkflowLabel.isSelectLabel = true;

const InheritWorkflowLabel: FC<{
	approvalAlgorithm?: string | ApprovalAlgorithmModel;
	inheritText: string;
	disabled?: boolean;
}> = ({ disabled, approvalAlgorithm, inheritText }) => {
	const { t } = useTranslation();
	const approvalAlgorithms = useApprovalAlgorithms();
	const approvalAlgorithmModel = useMemo(() => {
		if (!approvalAlgorithm) return;
		if (typeof approvalAlgorithm === "string") return approvalAlgorithms?.get(approvalAlgorithm);
		return approvalAlgorithm;
	}, [approvalAlgorithm, approvalAlgorithms]);

	if (!approvalAlgorithmModel) {
		devLog({
			message: "InheritWorkflowLabel: approvalAlgorithmModel was not provided (this should not happen)",
			level: "error"
		});
	}

	return (
		<TitleTooltip
			header={<Typography variant="body_sb">{inheritText}</Typography>}
			body={
				approvalAlgorithmModel ? (
					<WorkflowEntity withIcon size="medium" approvalAlgorithm={approvalAlgorithmModel} />
				) : (
					<Typography variant="text_sm_reg">{t("shared.unableToRetrieveData")}</Typography>
				)
			}>
			<TextLabel variant="standard" disabled={disabled} value={inheritText} />
		</TitleTooltip>
	);
};

const ResourceInheritWorkflowLabel: TLabelComponent<
	TInherit,
	{ integrationResource?: IntegrationResourceModel; inheritWorkflow?: ApprovalAlgorithmModel }
> = ({ disabled = false, integrationResource, inheritWorkflow }) => {
	const { t } = useTranslation();
	const integrations = useIntegrations();
	const approvalAlgorithm = useMemo(() => {
		if (inheritWorkflow) return inheritWorkflow;
		if (!integrationResource) return;
		if (integrationResource.approvalAlgorithmId) return integrationResource.approvalAlgorithmId;

		const integration = integrationResource.integration?.defaultApprovalAlgorithm.id
			? integrationResource.integration
			: integrations?.get(integrationResource.integrationId);
		return integration?.defaultApprovalAlgorithm;
	}, [inheritWorkflow, integrationResource, integrations]);

	return (
		<InheritWorkflowLabel
			approvalAlgorithm={approvalAlgorithm}
			disabled={disabled}
			inheritText={t("shared.resourceDefault")}
		/>
	);
};
ResourceInheritWorkflowLabel.isSelectLabel = true;

const IntegrationInheritWorkflowLabel: TLabelComponent<
	TInherit,
	{ integration?: IntegrationModel | string; inheritWorkflow?: ApprovalAlgorithmModel }
> = ({ disabled = false, integration, inheritWorkflow }) => {
	const { t } = useTranslation();
	const integrations = useIntegrations();
	const integrationModel = useMemo(() => {
		if (!integration) return;
		if (typeof integration === "string") {
			return integrations?.get(integration);
		}
		return integration.ownerId ? integration : integrations?.get(integration.id);
	}, [integrations, integration]);

	return (
		<InheritWorkflowLabel
			approvalAlgorithm={inheritWorkflow ?? integrationModel?.defaultApprovalAlgorithm}
			disabled={disabled}
			inheritText={t("shared.integrationDefault")}
		/>
	);
};
IntegrationInheritWorkflowLabel.isSelectLabel = true;

export { WorkflowLabel, ResourceInheritWorkflowLabel, IntegrationInheritWorkflowLabel };
