import { useCallback, useMemo } from "react";

export const useStaticOptions = <Option>(
	options: Option[],
	getKey: (option: Option) => string,
	staticOptions?: Option[],
	addStaticOptions = true
) => {
	const results = useMemo(() => {
		if (staticOptions?.length && addStaticOptions) {
			return [...staticOptions, ...options];
		}
		return options;
	}, [staticOptions, options, addStaticOptions]);

	const staticOptionsIds = useMemo(() => new Set(staticOptions?.map(getKey) ?? []), [staticOptions, getKey]);

	const groupBy = useCallback(
		(option: Option) => {
			if (staticOptionsIds.has(getKey(option))) {
				return { label: "", key: "[static]", order: 0 };
			}
			return { label: "", key: "[regular]", order: 1 };
		},
		[staticOptionsIds, getKey]
	);

	return { options: results, groupBy };
};
