import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	autoAssignHelpBlock: {
		display: "inline-block"
	},
	titleContainer: {
		display: "flex",
		flexDirection: "row",
		flexGrow: 1,
		justifyContent: "space-between"
	},
	titlePart: {
		alignItems: "center",
		display: "flex",
		flexDirection: "row",
		gap: "var(--spacing-x1, 4px)"
	},
	separator: {
		margin: "0 var(--spacing-x2, 8px)"
	},
	lighterText: {
		color: "var(--color-grey-dark)"
	},
	accordion: {
		width: "100%",
		height: ({
			numberOfRows,
			defaultRowHeight,
			defaultAccordionHeight
		}: {
			numberOfRows: number;
			defaultRowHeight: number;
			defaultAccordionHeight: number;
		}) => `${defaultAccordionHeight + defaultRowHeight * numberOfRows}px`
	}
});
