import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AreYouSureModal } from "components/common/AreYouSureModal";
import { PageTitleContent } from "components/templates/PageTitleContentTemplate";
import { Button } from "components/ui/Button";
import { ErrorModal } from "components/ui/ErrorModal";
import { AddIcon } from "components/ui/Icons/AddIcon";
import { LoadingDots } from "components/ui/LoadingDots";
import { usePoliciesContext } from "context/policiesContext";
import useErrorModalState from "hooks/useErrorModalState";
import { useOpenGlobalErrorModal } from "hooks/useGlobalError";
import { useIsOpenState } from "hooks/useIsOpenState";
import { PolicyConflictsModel } from "models/PolicyConflictsModel";
import { PolicyModel } from "models/PolicyModel";
import { EditPolicyModal } from "./components/EditPolicyModal";
import { PolicyConflictsModal } from "./components/PolicyConflictsModal";
import { PolicyTableSection } from "./components/PolicyTableSection";
import { useStyles } from "./styles";
import type { ICreatePolicyParams } from "api/policies";

export const PoliciesPage: FC = ({ className }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const [policyToUpdate, setPolicyToUpdate] = useState<PolicyModel | null>(null);
	const [policyConflicts, setPolicyConflicts] = useState<PolicyConflictsModel | null>(null);
	const {
		state: { policies, lastSortOrder },
		actions: { loadPolicies, deletePolicy, createPolicy, updatePolicy }
	} = usePoliciesContext();
	const openErrorModal = useOpenGlobalErrorModal();
	const { isOpen: areYouSureModalIsOpen, open: openAreYouSureModal, close: closeAreYouSureModal } = useIsOpenState();
	const {
		errorModalSetError,
		errorModalIsOpen,
		errorModalError,
		errorModalClose: closeErrorModal
	} = useErrorModalState();

	const [idToRemove, setIdToRemove] = useState<string | null>(null);

	const onDeletePolicyClick = useCallback(
		(policyId: string) => {
			setIdToRemove(policyId);
			openAreYouSureModal();
		},
		[openAreYouSureModal]
	);

	const removePolicy = useCallback(async () => {
		if (!idToRemove) return;
		try {
			await deletePolicy(idToRemove);
		} catch (error) {
			errorModalSetError(error as Error);
		} finally {
			closeAreYouSureModal();
		}
	}, [idToRemove, deletePolicy, errorModalSetError, closeAreYouSureModal]);

	const openNewPolicyModal = useCallback(() => {
		setPolicyToUpdate(new PolicyModel({ sortOrder: lastSortOrder + 1 }));
	}, [lastSortOrder]);

	const openEditPolicyModal = useCallback((policy: PolicyModel) => {
		setPolicyToUpdate(policy);
	}, []);

	const closeEditPolicyModal = useCallback(() => {
		setPolicyToUpdate(null);
	}, []);
	const closePolicyConflictsModal = useCallback(() => {
		setPolicyConflicts(null);
	}, []);

	useEffect(() => {
		void loadPolicies();
	}, [loadPolicies]);

	const savePolicy = useCallback(
		async (body: ICreatePolicyParams) => {
			if (!policyToUpdate) return;
			if (!body.directoryGroupIds.length && !body.onCallIntegrationScheduleIds.length) {
				openErrorModal(new Error(t("pages.policies.form.errors.noDirectoryGroups")));
				return;
			}
			if (body.integrationResourceRoleIds.length + body.bundleIds.length === 0) {
				openErrorModal(new Error(t("pages.policies.form.errors.noPermissions")));
				return;
			}
			const policy = await (policyToUpdate.id ? updatePolicy(policyToUpdate.id, body) : createPolicy(body));
			if (policy?.conflicts) {
				closeEditPolicyModal();
				setPolicyConflicts(policy.conflicts);
			}
		},
		[policyToUpdate, updatePolicy, createPolicy, openErrorModal, t, closeEditPolicyModal]
	);

	return (
		<PageTitleContent className={className} noTransition>
			<PageTitleContent.Header>
				<PageTitleContent.Header.Bottom>
					<PageTitleContent.Header.Title title={t("pages.policies.title")} subtitle={t("pages.policies.subtitle")} />
					<Button size="large" onClick={openNewPolicyModal} prefix={<AddIcon />}>
						{t("pages.policies.addPolicy")}
					</Button>
				</PageTitleContent.Header.Bottom>
			</PageTitleContent.Header>
			<PageTitleContent.Body className={classes.templateBody}>
				<ErrorModal error={errorModalError} isOpen={errorModalIsOpen} onClose={closeErrorModal} />
				<EditPolicyModal
					isOpen={!!policyToUpdate}
					policy={policyToUpdate}
					closeModal={closeEditPolicyModal}
					savePolicy={savePolicy}
				/>
				<PolicyConflictsModal
					isOpen={!!policyConflicts}
					conflicts={policyConflicts}
					closeModal={closePolicyConflictsModal}
				/>
				<AreYouSureModal
					onClose={closeAreYouSureModal}
					isOpen={areYouSureModalIsOpen}
					onAction={removePolicy}
					actionLabel={t("pages.policies.deletePolicy.deletePolicy")}
					title={t("pages.policies.deletePolicy.title")}
					content={t("pages.policies.deletePolicy.content")}
				/>
				{policies ? (
					<PolicyTableSection onEdit={openEditPolicyModal} onRemove={onDeletePolicyClick} />
				) : (
					<LoadingDots center />
				)}
			</PageTitleContent.Body>
		</PageTitleContent>
	);
};
