import classNames from "classnames";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { GroupCard } from "components/common/GroupCard";
import { UserCard } from "components/common/UserCard";
import { FilterHeader } from "components/ui/filters/FilterExpression/components/FilterHeader";
import { Input } from "components/ui/Input";
import { Select } from "components/ui/Select";
import { DirectoryGroupNodeOption } from "components/ui/selectOptions/DirectoryGroupNodeOption";
import { UserNodeOption } from "components/ui/selectOptions/UserNodeOption";
import { useCustomerEntitiesSelect } from "hooks/useCustomerEntitiesSelect";
import { UserModel } from "models/UserModel";
import { getLabel, type TOptionComponentProps } from "utils/ui/select";
import { useStyles } from "./styles";
import type { DirectoryGroupModel } from "models/DirectoryGroupModel";
import type { TRoleOrResourceUpdate, TUpdateMaintainer } from "models/RuleModel";

const isUserUpdateMaintainer = (maintainer: TUpdateMaintainer): maintainer is { userId: string } =>
	"userId" in maintainer;

type TMaintainersSelectAttributeExpressionProps = {
	setUpdatesAttribute: (attribute: "maintainers", value: TRoleOrResourceUpdate["maintainers"] | undefined) => void;
	onRemoveAttribute: () => void;
	selected: TUpdateMaintainer[];
};

type TMaintainerModelTypes = UserModel | DirectoryGroupModel;

export function MaintainersSelectAttributeExpression({
	className,
	id,
	innerRef,
	setUpdatesAttribute,
	onRemoveAttribute,
	selected = []
}: TProps<TMaintainersSelectAttributeExpressionProps>) {
	const classes = useStyles();
	const { t } = useTranslation();
	const [query, setQuery] = useState("");
	const [selectedUserIds, selectedDirectoryGroupsIds] = useMemo(
		() =>
			selected.reduce<[string[], string[]]>(
				([users, groups], maintainer) => {
					if (isUserUpdateMaintainer(maintainer)) {
						return [users.concat(maintainer.userId), groups];
					}
					return [users, groups.concat(maintainer.directoryGroupId)];
				},
				[[], []]
			),
		[selected]
	);

	const { items, isLoading } = useCustomerEntitiesSelect<TMaintainerModelTypes>(query, {
		entities: ["directoryGroup", "user"],
		selectedIdsByType: { directoryGroup: selectedDirectoryGroupsIds, user: selectedUserIds }
	});

	const updateSelected = useCallback(
		(updateMaintainer: TUpdateMaintainer) => {
			const id = isUserUpdateMaintainer(updateMaintainer) ? updateMaintainer.userId : updateMaintainer.directoryGroupId;
			const toRemove = selected.some(maintainer =>
				isUserUpdateMaintainer(maintainer) ? maintainer.userId === id : maintainer.directoryGroupId === id
			);
			const newSelected = toRemove
				? selected.filter(maintainer =>
						isUserUpdateMaintainer(maintainer) ? maintainer.userId !== id : maintainer.directoryGroupId !== id
					)
				: selected.concat(updateMaintainer);
			setUpdatesAttribute("maintainers", newSelected);
		},
		[selected, setUpdatesAttribute]
	);

	const handleValueChange = useCallback(
		(option: TMaintainerModelTypes | null) => {
			if (option) {
				const newUpdateMaintainer =
					option instanceof UserModel ? { userId: option.id } : { directoryGroupId: option.id };
				updateSelected(newUpdateMaintainer);
			}
		},
		[updateSelected]
	);

	const onInputChange = useCallback((event: { target: { value: string } }) => {
		setQuery(event.target.value);
	}, []);

	const getOptionRenderer = useCallback((props: TOptionComponentProps<TMaintainerModelTypes>) => {
		return props.option instanceof UserModel ? (
			<UserNodeOption {...(props as TOptionComponentProps<UserModel>)} />
		) : (
			<DirectoryGroupNodeOption {...(props as TOptionComponentProps<DirectoryGroupModel>)} />
		);
	}, []);

	const renderSelected = useCallback(
		(option: TUpdateMaintainer) => {
			const isUser = isUserUpdateMaintainer(option);
			const id = isUser ? option.userId : option.directoryGroupId;
			if (isUser) {
				return <UserCard key={id} user={id} selected onDelete={() => updateSelected({ userId: id })} />;
			}
			return <GroupCard key={id} group={id} selected onDelete={() => updateSelected({ directoryGroupId: id })} />;
		},
		[updateSelected]
	);

	const onReset = useCallback(() => {
		setUpdatesAttribute("maintainers", undefined);
	}, [setUpdatesAttribute]);

	const shouldShowAsSelected = useCallback(
		(option: TMaintainerModelTypes) => {
			return selected.some(maintainer =>
				isUserUpdateMaintainer(maintainer) ? maintainer.userId === option.id : maintainer.directoryGroupId === option.id
			);
		},
		[selected]
	);

	return (
		<div className={classNames(classes.container, className)} id={id} ref={innerRef}>
			<FilterHeader
				title={t("pages.rules.updates.resources.maintainers")}
				onRemoveFilter={onRemoveAttribute}
				onReset={onReset}
				innerRef={innerRef}
				hasSelection={selected !== undefined}
				inputs={
					<>
						<Input
							readonly
							className={classNames(classes.select, classes.readonlySelect)}
							value={t("pages.rules.updates.maintainers.operations.set")}
						/>
						<Select
							className={classes.select}
							filter={null}
							getOptionLabel={getLabel}
							noClear
							isOptionEqualToValue={shouldShowAsSelected}
							loading={isLoading}
							onChange={handleValueChange}
							onInputChange={onInputChange}
							options={items.toArray()}
							placeholder={t("pages.rules.updates.placeholders.maintainers")}
							hideSelected
							sort={null}
							value={null}
							renderOption={getOptionRenderer}
						/>
					</>
				}
			/>
			<div className={classes.content}>
				{selected.length > 0 ? selected.map(renderSelected) : <div className={classes.emptyState} />}
			</div>
		</div>
	);
}
