import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { routes } from "routes/routes";

export const Homepage: React.FC = () => {
	const navigate = useNavigate();

	useEffect(() => {
		navigate(routes.requests.main);
	}, [navigate]);

	return null;
};
