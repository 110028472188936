import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	tooltipContainer: {
		"&$tooltipContainer&$tooltipContainer": {
			maxWidth: "836px",
			padding: "unset"
		}
	},
	tooltipContentContainer: {
		boxShadow: "unset"
	}
});
