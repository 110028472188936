import { List } from "immutable";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { FixedHeightCommonAccordion } from "components/common/FixedHeightCommonAccordion";
import { MaintainersTable } from "components/common/MaintainersTable";
import { HelpBlock } from "components/ui/Block";
import { Button } from "components/ui/Button";
import { IconPrefix } from "components/ui/IconPrefix";
import { AddIcon } from "components/ui/Icons/AddIcon";
import { MaintainersIcon } from "components/ui/Icons/MaintainersIcon";
import { Typography } from "components/ui/Typography";
import { IntegrationModel } from "models/IntegrationModel";
import { IntegrationResourceModel } from "models/IntegrationResourceModel";
import { useStyles } from "./styles";

const MAX_ROWS = 15;
const MIN_ROWS = 3;

const DEFAULT_ROW_HEIGHT_PX = 60;
const DEFAULT_ACCORDION_HEIGHT_PX = 152;

interface IProps {
	autoAssigned?: boolean;
	entity: IntegrationModel | IntegrationResourceModel;
	maintainers: List<TMaintainerModel> | null;
	onUpdate: (maintainers: List<TMaintainerModel>) => Promise<void>;
}

export type TMaintainer = TMaintainerModel | { isMaintainerNew: true };

export const MaintainersSection: FC<IProps> = ({ autoAssigned, maintainers, onUpdate }) => {
	const { t } = useTranslation();
	const [expanded, setExpanded] = useState(false);
	const [currentMaintainers, setCurrentMaintainers] = useState<List<TMaintainer> | null>(maintainers);
	const [isMaintainerNew, setIsMaintainerNew] = useState(false);
	const classes = useStyles();

	const showAddMaintainer = useCallback(
		(event: React.MouseEvent<HTMLButtonElement>) => {
			event.stopPropagation();
			if (!expanded) {
				setExpanded(true);
			}
			setIsMaintainerNew(true);
			setCurrentMaintainers(current =>
				current ? current.unshift({ isMaintainerNew: true }) : List([{ isMaintainerNew: true }])
			);
		},
		[expanded]
	);

	useEffect(() => {
		setCurrentMaintainers(maintainers);
	}, [maintainers]);

	const titleContent = useMemo(
		() => (
			<div className={classes.titleContainer}>
				<div className={classes.titlePart}>
					<IconPrefix Icon={MaintainersIcon} content={t("pages.integration.maintainers")} className={classes.icon} />
					<Typography variant="body_reg">{`( ${t("number", { value: maintainers?.size || 0 })} )`}</Typography>
				</div>
				<Button
					variant="secondary"
					size="medium"
					prefix={<AddIcon />}
					onClick={showAddMaintainer}
					disabled={isMaintainerNew}>
					{t("buttons.add")}
				</Button>
			</div>
		),
		[classes.icon, classes.titleContainer, classes.titlePart, isMaintainerNew, maintainers?.size, showAddMaintainer, t]
	);

	return (
		<FixedHeightCommonAccordion
			maxRows={MAX_ROWS}
			minRows={MIN_ROWS}
			totalResults={currentMaintainers?.size || 0}
			titleContent={titleContent}
			defaultRowHeight={DEFAULT_ROW_HEIGHT_PX}
			defaultAccordionHeight={DEFAULT_ACCORDION_HEIGHT_PX}
			expanded={expanded}
			setExpanded={setExpanded}>
			{autoAssigned ? (
				<HelpBlock className={classes.autoAssignHelpBlock}>
					<Typography>{t("pages.integrationResource.maintainersAutoAssigned")}</Typography>
				</HelpBlock>
			) : null}
			<MaintainersTable
				maintainers={currentMaintainers}
				onUpdateMaintainers={onUpdate}
				setIsMaintainerNew={setIsMaintainerNew}
				setMaintainers={setCurrentMaintainers}
			/>
		</FixedHeightCommonAccordion>
	);
};
