import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { StaticChip } from "components/ui/chips/StaticChip";
import { IconButton } from "components/ui/IconButton";
import { DeleteIcon } from "components/ui/Icons/DeleteIcon";
import { EditIcon } from "components/ui/Icons/EditIcon";
import { PoliciesIcon } from "components/ui/Icons/PoliciesIcon";
import { TitleBody } from "components/ui/TitleBody";
import { Tooltip } from "components/ui/Tooltip";
import { PolicyModel } from "models/PolicyModel";
import { useStyles } from "./styles";

type TPolicyActionsCellProps = {
	policy: PolicyModel;
	onDelete: (policyId: string) => void;
	onEdit: (policy: PolicyModel) => void;
};

const PolicyInfo: FC<{ policy: PolicyModel }> = React.memo(function PolicyInfo({ policy }) {
	const { t } = useTranslation("translation", { keyPrefix: "pages.policies" });
	return <TitleBody title={t("policyNumber")} body={policy.number} />;
});

const PolicyNumberContent: FC<{ policy: PolicyModel }> = React.memo(function PolicyNumberContent({ policy, children }) {
	const classes = useStyles();

	return (
		<div className={classes.policyActionsCellContainer}>
			{policy.id ? (
				<Tooltip content={<PolicyInfo policy={policy} />}>
					<StaticChip size="small" variant="regular" PrefixIcon={PoliciesIcon}>
						{policy.number}
					</StaticChip>
				</Tooltip>
			) : undefined}
			{children}
		</div>
	);
});

export const PolicyActionsCell: FC<TPolicyActionsCellProps> = ({ policy, onDelete, onEdit }) => {
	const classes = useStyles();
	const { t } = useTranslation("translation", { keyPrefix: "pages.policies.table" });

	const handleDelete = useCallback(() => onDelete(policy.id), [onDelete, policy.id]);

	const handleEdit = useCallback(() => onEdit(policy), [onEdit, policy]);

	return (
		<PolicyNumberContent policy={policy}>
			<div className={classes.policyActionsContainer}>
				<IconButton size="medium" tooltip={t("row.editPolicy")} onClick={handleEdit}>
					<EditIcon />
				</IconButton>
				<IconButton size="medium" tooltip={t("row.deletePolicy")} onClick={handleDelete}>
					<DeleteIcon />
				</IconButton>
			</div>
		</PolicyNumberContent>
	);
};
