import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ApprovalAlgorithm } from "components/common/ApprovalAlgorithm";
import { FloatingSelect, type IFloatingSelectOption } from "components/common/FloatingSelect";
import { Button } from "components/ui/Button";
import { LoadingSpinner } from "components/ui/LoadingSpinner";
import { WorkflowOption } from "components/ui/selectOptions/WorkflowOption";
import { useApprovalAlgorithms, useApprovalAlgorithmsList } from "hooks/useApprovalAlgorithms";
import { useApprovalFlows } from "hooks/useApprovalFlows";
import { ApprovalAlgorithmModel } from "models/ApprovalAlgorithmModel";
import { sortOptions, type TRenderOptionProps } from "utils/ui/select";
import { useStyles } from "./styles";
import { CommonAccordion } from "../CommonAccordion";
import { WorkflowEntity } from "../entities/WorkflowEntity";

export const INHERIT_FROM_PARENT_ID = "INHERIT";

const sortApprovalAlgorithmOptions = (options: { label: string; value: string }[]) => {
	const optionsSorted = sortOptions(options) as { label: string; value: string }[];
	const inheritOptionIndex = optionsSorted.findIndex(option => option.value === INHERIT_FROM_PARENT_ID);
	if (inheritOptionIndex === -1) return optionsSorted;
	return [...optionsSorted.splice(inheritOptionIndex, 1), ...optionsSorted];
};

interface IApprovalAlgorithmNameProps {
	approvalAlgorithm: ApprovalAlgorithmModel;
}

const ApprovalAlgorithmName: FC<IApprovalAlgorithmNameProps> = ({ approvalAlgorithm }) => {
	return <WorkflowEntity tooltipOnOverflow size="medium" withIcon approvalAlgorithm={approvalAlgorithm} />;
};

interface IProps {
	approvalAlgorithmId: string;
	rounded?: boolean;
	onChangeAlgorithm?: (approvalAlgorithmId: string) => Promise<void> | void;
	shouldExpand?: boolean;
	inheritOptionText?: string;
}

export const ApprovalAlgorithmBlock: FC<IProps> = ({
	approvalAlgorithmId,
	rounded,
	onChangeAlgorithm,
	inheritOptionText,
	shouldExpand = false
}) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const [selectOpen, setSelectOpen] = useState(false);
	const toggleSelectOpen = useCallback((e: React.MouseEvent) => {
		e.stopPropagation();
		setSelectOpen(current => !current);
	}, []);

	const closeSelect = useCallback(() => setSelectOpen(false), []);

	const approvalFlows = useApprovalFlows();
	const approvalAlgorithms = useApprovalAlgorithmsList();
	const approvalAlgorithmMap = useApprovalAlgorithms();

	const approvalAlgorithm = approvalAlgorithms?.find(approvalAlgorithm => approvalAlgorithm.id === approvalAlgorithmId);
	const approvalAlgorithmOptions = useMemo(() => {
		const approvalAlgorithmsArray = approvalAlgorithms?.toList()?.toArray();

		let options = [];
		if (inheritOptionText) {
			options.push(new ApprovalAlgorithmModel({ id: INHERIT_FROM_PARENT_ID, name: inheritOptionText }));
		}
		if (approvalAlgorithmsArray?.length) {
			options = options.concat(approvalAlgorithmsArray);
		}
		return options.map(approvalAlgorithm => ({
			value: approvalAlgorithm.id,
			label: approvalAlgorithm.name
		}));
	}, [approvalAlgorithms, inheritOptionText]);

	const renderOption = useCallback(
		({ option, getTextContent, onSelect, ...other }: TRenderOptionProps<IFloatingSelectOption>) => {
			const approvalAlgorithm = approvalAlgorithmMap?.get(option.value);

			return approvalAlgorithm ? (
				<WorkflowOption
					{...other}
					isSelected={option.value === approvalAlgorithmId}
					onSelect={ev => onSelect(ev, option)}
					option={approvalAlgorithm}
				/>
			) : null;
		},
		[approvalAlgorithmMap, approvalAlgorithmId]
	);
	const onChange = useCallback(
		(approvalAlgorithmId: string) => {
			const change = async () => {
				await onChangeAlgorithm?.(approvalAlgorithmId);
				closeSelect();
			};
			void change();
		},
		[closeSelect, onChangeAlgorithm]
	);

	if (!(approvalAlgorithm && approvalFlows)) {
		return <LoadingSpinner className={classes.spinner} />;
	}

	const title = onChangeAlgorithm ? (
		<div className={classes.title}>
			<ApprovalAlgorithmName approvalAlgorithm={approvalAlgorithm} />
			<FloatingSelect
				filter
				sort={sortApprovalAlgorithmOptions}
				onClose={closeSelect}
				onSelect={onChange}
				open={selectOpen}
				options={approvalAlgorithmOptions}
				position="bottom-start"
				placeholder={t("common.floatingSelect.searchWorkflow")}
				renderOption={renderOption}>
				<Button variant="secondary" size="medium" onClick={toggleSelectOpen}>
					{t("buttons.changeWorkflow")}
				</Button>
			</FloatingSelect>
		</div>
	) : (
		<ApprovalAlgorithmName approvalAlgorithm={approvalAlgorithm} />
	);

	return (
		<CommonAccordion rounded={rounded} title={title} defaultExpanded={shouldExpand}>
			<div className={classes.approvalAlgorithmContainer}>
				<ApprovalAlgorithm algorithm={approvalAlgorithm} approvalFlows={approvalFlows} contentOnly />
			</div>
		</CommonAccordion>
	);
};
