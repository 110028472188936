import { matchSorter } from "match-sorter";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { UserEntity } from "components/common/entities/UserEntity";
import { Button } from "components/ui/Button";
import { Select } from "components/ui/Select";
import { UserLabel } from "components/ui/selectLabels/UserLabel";
import { UserAvatarOption } from "components/ui/selectOptions/UserAvatarOption";
import { Table } from "components/ui/Table";
import { useAuthenticatedUser } from "hooks/useAuthenticatedUser";
import { useOpenGlobalErrorModal } from "hooks/useGlobalError";
import { useLoadingState } from "hooks/useLoadingState";
import { useRankedSort } from "hooks/useRankedSort";
import { useUsersSelect } from "hooks/useUsersSelect";
import { useStyles } from "./styles";
import type { TAdminUser, UserModel } from "models/UserModel";

interface IProps {
	admin?: TAdminUser;
	create?: boolean;
	createHandler?: (userId: string) => Promise<void>;
	removeHandler?: (userId: string) => Promise<void>;
	closeInput?: () => void;
}

const getLabel = (user: UserModel) => user.fullName;
const equality = (option: UserModel, value: UserModel) => option.id === value.id;

const SORT_OPTIONS = {
	keys: [{ key: (item: UserModel) => item.fullName, threshold: matchSorter.rankings.MATCHES }]
};

export const AdminRow: FC<IProps> = ({ admin, create = false, createHandler, removeHandler, closeInput }) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const [selectedUser, setSelectedUser] = useState<UserModel | null>(null);
	const { withLoader, isLoading } = useLoadingState();
	const { sort, query, onInputChange } = useRankedSort<UserModel>(SORT_OPTIONS);

	const { user } = useAuthenticatedUser();
	const { items: users, isLoading: isLoadingUsers } = useUsersSelect(query, { includeDeleted: false });

	const handleError = useOpenGlobalErrorModal();

	const onAction = useCallback(async () => {
		try {
			if (create && createHandler) {
				if (selectedUser) {
					await withLoader(createHandler(selectedUser.id));
				}
			} else if (removeHandler) {
				if (admin) {
					await withLoader(removeHandler(admin.id));
				}
			}
		} catch (error) {
			handleError(error as Error);
		}
	}, [create, createHandler, removeHandler, selectedUser, withLoader, admin, handleError]);

	const userList = useMemo(() => users.toArray(), [users]);

	return (
		<Table.Row>
			<Table.Cell>
				{create ? (
					<Select
						className={classes.selectInput}
						filter={null}
						getOptionLabel={getLabel}
						isOptionEqualToValue={equality}
						loading={isLoadingUsers}
						onChange={setSelectedUser}
						onInputChange={onInputChange}
						renderOption={UserAvatarOption}
						options={userList}
						renderLabel={UserLabel}
						sort={sort}
						value={selectedUser}
						variant="table"
					/>
				) : (
					admin && <UserEntity withIcon user={admin} />
				)}
			</Table.Cell>
			<Table.Cell className={classes.actionsContainer}>
				{create && closeInput ? (
					<>
						<Button disabled={isLoading || !selectedUser} onClick={onAction} variant="text" size="small">
							{t("buttons.save")}
						</Button>
						<Button disabled={isLoading} onClick={closeInput} variant="text" size="small">
							{t("buttons.cancel")}
						</Button>
					</>
				) : (
					admin?.id !== user?.id && (
						<Button disabled={isLoading} onClick={onAction} variant="text" size="small">
							{t("buttons.remove")}
						</Button>
					)
				)}
			</Table.Cell>
		</Table.Row>
	);
};
