import { createUseStyles } from "react-jss";
import { REQUEST_TARGET_ACCOUNT_SELECT_OFFSET_WIDTH_PX } from "./components/RequestTarget/styles";

export const useStyles = createUseStyles({
	titleContainer: {
		display: "flex",
		alignItems: "center",
		gap: "var(--spacing-x3, 12px)"
	},
	titleChipContainer: {
		display: "flex",
		alignItems: "center",
		gap: "var(--spacing-x2, 8px)"
	},
	targets: {
		alignItems: "flex-start",
		display: "flex",
		flex: "1 0 0",
		flexDirection: "column",
		gap: "var(--spacing-x2, 8px)",
		maxHeight: "60vh",
		overflowY: "auto"
	},
	targetsHeader: {
		display: "flex",
		width: "100%"
	},
	rolesTitle: {
		whiteSpace: "nowrap"
	},
	accountsTitle: {
		width: `calc(100% - ${REQUEST_TARGET_ACCOUNT_SELECT_OFFSET_WIDTH_PX}px)`,
		textAlign: "right",
		minWidth: "646px",
		"&$hasBundles": {
			minWidth: "930px"
		}
	},
	hasBundles: {}
});
