import React from "react";
import { useTranslation } from "react-i18next";
import { EditorSuccessModal } from "components/common/EditorSuccessModal";
import { ErrorModal } from "components/ui/ErrorModal";
import { Section } from "components/ui/Section";
import useErrorModalState from "hooks/useErrorModalState";
import { useIsOpenState } from "hooks/useIsOpenState";
import { useUpdateUser } from "hooks/useUpdateUser";
import { UserModel } from "models/UserModel";
import { IntegrationActorsTable } from "./IntegrationActorsTable";
import { PersonalAccessTokensTableSection } from "./PersonalAccessTokensTableSection";

export const ProfileSections: FC<{ user: UserModel }> = ({ className, user }) => {
	const { t } = useTranslation();
	const updateUser = useUpdateUser();
	const { isOpen: successModalIsOpen, open: openSuccessModal, close: closeSuccessModal } = useIsOpenState();

	const {
		errorModalSetError,
		errorModalIsOpen,
		errorModalError,
		errorModalClose: closeErrorModal
	} = useErrorModalState();

	return (
		<>
			<Section title="">
				<IntegrationActorsTable
					user={user}
					onSuccess={openSuccessModal}
					onUpdate={updateUser}
					setError={errorModalSetError}
				/>
			</Section>
			<PersonalAccessTokensTableSection />
			<ErrorModal error={errorModalError} isOpen={errorModalIsOpen} onClose={closeErrorModal} />
			<EditorSuccessModal
				state={"edit"}
				isOpen={successModalIsOpen}
				editTitle={t("pages.profile.editSuccessModalTitle")}
				createTitle={t("pages.profile.editSuccessModalTitle")}
				content={t("pages.profile.successModalInformation")}
				onClose={closeSuccessModal}
			/>
		</>
	);
};
