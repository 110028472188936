import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { FilterExpressionEmptyState } from "components/common/FilterExpressionEmptyState";
import { UserCard } from "components/common/UserCard";
import { Chip } from "components/ui/chips/Chip";
import { FilterExpression } from "components/ui/filters/FilterExpression";
import { UsersIcon } from "components/ui/Icons/UsersIcon";
import { UserNodeOption } from "components/ui/selectOptions/UserNodeOption";
import { IntegrationOwnerFilter } from "filters/integration";
import { IntegrationResourceOwnerFilter } from "filters/integrationResource";
import { useStaticOptions } from "hooks/useStaticOptions";
import { useUsersSelect } from "hooks/useUsersSelect";
import { notEmpty } from "utils/comparison";
import { INHERIT, type TInherit } from "utils/ui/select";
import { useFilterFormExpression } from "./filter.hooks";
import type { UserModel } from "models/UserModel";
import type { TFilterOperator } from "types/filters";
import type { Constructor } from "types/utilTypes";

type TOwnerFilters = IntegrationOwnerFilter | IntegrationResourceOwnerFilter;

type TOwnerFilterProps = {
	filter: TOwnerFilters;
	onChange: (filter: TOwnerFilters | undefined, isValid: boolean) => void;
};

function getFilter(filterName: TOwnerFilters["name"]): Constructor<TOwnerFilters> {
	switch (filterName) {
		case IntegrationOwnerFilter.filterName:
			return IntegrationOwnerFilter;
		default:
			return IntegrationResourceOwnerFilter;
	}
}

const isIntegrationOwnerFilter = (filter: TOwnerFilters): filter is IntegrationOwnerFilter => {
	return filter.name === IntegrationOwnerFilter.filterName;
};

const isValidOperator = (operator: TFilterOperator): operator is TOwnerFilters["operator"] =>
	operator === "is" || operator === "isNot";

type TOwnerOption = UserModel | TInherit;

const OPERATORS = ["is", "isNot"] as TFilterOperator[];

const STATIC_OPTIONS = [INHERIT] as TOwnerOption[];
const getOwnerKey = (owner: UserModel | TInherit) => (typeof owner === "string" ? owner : owner.id);

export const OwnerFilterExpression: FC<TOwnerFilterProps> = ({ className, innerRef, filter, onChange }) => {
	const { t } = useTranslation("translation", { keyPrefix: "pages.bulkActions.filters" });
	const withInherit = useMemo(() => filter.name !== IntegrationOwnerFilter.filterName, [filter.name]);
	const [query, setQuery] = useState<string>();
	const ids = useMemo(() => filter.value.filter(notEmpty) as string[], [filter.value]);
	const { items: users, isLoading, selectedItems } = useUsersSelect(query || "", { selectedIds: ids });

	const ownerOptions = useMemo(() => {
		const inheritOption = withInherit ? [INHERIT] : [];
		return (inheritOption as TOwnerOption[]).concat(users?.toArray() || []);
	}, [users, withInherit]);

	const { options, groupBy } = useStaticOptions(ownerOptions, getOwnerKey, STATIC_OPTIONS, false);

	const selectedOwners = useMemo(() => {
		const result = selectedItems.toArray() as TOwnerOption[];
		if (filter.value.filter(id => !id).length) {
			result.push(INHERIT);
		}
		return result;
	}, [filter.value, selectedItems]);

	const { clearFilter, removeFilter } = useFilterFormExpression<TOwnerFilters>({
		filterName: filter.name,
		onChange,
		getFilter
	});

	const onOperatorSelect = useCallback(
		(operator: TFilterOperator) => {
			if (!filter) return;
			if (!isValidOperator(operator)) return;
			onChange(filter.set("operator", operator), filter.value.length > 0);
		},
		[filter, onChange]
	);

	const onOptionSelect = useCallback(
		(option: TOwnerOption) => {
			if (!filter || !option) return;
			const currentValue = filter.value;
			let newValue = currentValue;
			if (option === INHERIT) {
				newValue = currentValue.find(value => value === null)
					? currentValue.filter(value => value !== null)
					: [...currentValue, null];
			} else {
				newValue = currentValue.includes(option.id)
					? currentValue.filter(id => id !== option.id)
					: [...currentValue, option.id];
			}
			if (isIntegrationOwnerFilter(filter)) {
				const nonEmptyValue = newValue.filter(notEmpty);
				onChange(filter.set("value", nonEmptyValue), nonEmptyValue.length > 0);
			} else {
				onChange(filter.set("value", newValue), newValue.length > 0);
			}
		},
		[filter, onChange]
	);

	const getOptionLabel = useCallback(
		(option: TOwnerOption) => {
			return option === INHERIT ? t("values.inheritIntegration") : option.fullName;
		},
		[t]
	);

	const renderSelected = useCallback(
		(option: TOwnerOption) => {
			return option === INHERIT ? (
				<Chip size="large" selected onDelete={() => onOptionSelect(option)}>
					{t("values.inheritIntegration")}
				</Chip>
			) : (
				<UserCard user={option} selected onDelete={() => onOptionSelect(option)} />
			);
		},
		[onOptionSelect, t]
	);

	return (
		<FilterExpression
			className={className}
			emptyState={<FilterExpressionEmptyState variant="node" text={t("values.emptyUsers")} Icon={UsersIcon} />}
			filter={null}
			getMoreOptions={setQuery}
			getOptionLabel={getOptionLabel}
			groupBy={groupBy}
			innerRef={innerRef}
			inputPlaceholder={t("placeholders.user")}
			isLoading={isLoading}
			onOperatorSelect={onOperatorSelect}
			onOptionSelect={onOptionSelect}
			onRemoveFilter={removeFilter}
			onReset={clearFilter}
			operators={OPERATORS}
			optionRenderer={UserNodeOption}
			options={options}
			renderSelected={renderSelected}
			selected={selectedOwners}
			selectedOperator={filter.operator}
			title={t(`title.${filter.name}`)}
			type="multiSelect"
		/>
	);
};
