import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "components/ui/Button";
import { IconPrefix } from "components/ui/IconPrefix";
import { WarningIcon } from "components/ui/Icons/WarningIcon";
import { Modal } from "components/ui/Modal";
import { TextAreaInput } from "components/ui/TextAreaInput";
import { useIsOpenState } from "hooks/useIsOpenState";
import { useStyles } from "./styles";

export const ErrorContent: FC<{ error: string }> = ({ error }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	return (
		<div className={classes.modalContent}>
			<IconPrefix size="large" semibold Icon={WarningIcon} content={t("common.viewError.modalTitle")} />
			<TextAreaInput textAreaClassName={classes.textArea} readonly disabled value={error} autoResize />
		</div>
	);
};

export const ViewErrorButton: FC<{ error: string }> = ({ error }) => {
	const { t } = useTranslation();
	const { isOpen, open, close } = useIsOpenState();
	const classes = useStyles();

	return (
		<>
			<Modal isOpen={isOpen} onClose={close} content={<ErrorContent error={error} />} />
			<Button variant="text" size="small" className={classes.viewError} onClick={open}>
				{t("common.viewError.buttonLabel")}
			</Button>
		</>
	);
};
