import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { MicrosoftLogo } from "components/ui/systemLogos/MicrosoftLogo";
import { compiledClientConfig } from "config";
import { useOpenGlobalErrorModal } from "hooks/useGlobalError";
import { openCenteredPopup } from "utils/ui/openCenteredPopup";
import { BaseLoginButton } from "../BaseLoginButton";

const LOGIN_URL = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${compiledClientConfig.microsoftLoginClientId}&scope=User.Read&redirect_uri=${window.location.origin}/api/v1/signIn/microsoft&response_type=code&state=popup`;

export const MicrosoftLoginButton: FC = () => {
	const { t } = useTranslation();
	const openGlobalErrorModal = useOpenGlobalErrorModal();

	const login = useCallback(() => {
		const popupWindow = openCenteredPopup({
			url: LOGIN_URL,
			title: t("pages.signIn.sso.google.signInWith")
		});

		if (!popupWindow) {
			openGlobalErrorModal(new Error("Cannot open popup window for microsoft login"));
		}
	}, [t, openGlobalErrorModal]);

	return <BaseLoginButton onClick={login} text={t("pages.signIn.sso.microsoft.signInWith")} Logo={MicrosoftLogo} />;
};
