import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { RoleEntity } from "components/common/entities";
import {
	TRoleWithResource,
	useIntegrationResourceRolesTable
} from "components/common/tables/IntegrationResourceRolesTable";
import { WorkflowCell } from "components/common/tables/WorkflowCell";
import { OwnerIcon } from "components/ui/Icons/OwnerIcon";
import { RequestsIcon } from "components/ui/Icons/RequestsIcon";
import { ResourcesIcon } from "components/ui/Icons/ResourcesIcon";
import { WorkflowsIcon } from "components/ui/Icons/WorkflowsIcon";
import { Typography } from "components/ui/Typography";
import { PaginatedVirtualTable, TColumn } from "components/ui/VirtualTable";
import { HeaderCellContent, ToggleSwitchCellContent } from "components/ui/VirtualTable/components";
import { INHERIT } from "utils/ui/select";
import type { TFullIntegrationResourceRole } from "api/integrationResourceRoles";

interface IProps {
	roles: (TRoleWithResource | undefined)[];
	onRoleUpdate: (
		role: Partial<TRoleWithResource> & {
			id: string;
		}
	) => void;
	isLoading: boolean;
	totalResults: number;
	perPage?: number;
	loadRolesPage: (page: number) => Promise<void>;
}

const DEFAULT_PER_PAGE = 15;

export const RolesTable: FC<IProps> = ({
	className,
	innerRef,
	roles,
	onRoleUpdate,
	isLoading,
	totalResults,
	loadRolesPage,
	perPage
}) => {
	const { t } = useTranslation("translation", { keyPrefix: "common.tables.integrationResourceRoles" });

	const {
		approvalAlgorithmOptions,
		approvalAlgorithms,
		getIntegration,
		getIsDisabled,
		onUpdateAllowsRequests,
		onUpdateApprovalAlgorithm
	} = useIntegrationResourceRolesTable({ disabled: false, onRoleUpdate });

	const COLUMNS_WIDTHS = {
		role: "minmax(168px, 1fr)",
		workflow: "minmax(168px, 1fr)",
		requestDuration: "minmax(168px, 1fr)",
		requestable: "180px"
	};

	const renderRequestableCell = useCallback(
		(role: TFullIntegrationResourceRole) => {
			return role.managed ? (
				<ToggleSwitchCellContent
					disabled={getIsDisabled(role.id)}
					checked={role.allowsRequests}
					onChange={(checked: boolean) => void onUpdateAllowsRequests(role.id, checked)}
				/>
			) : null;
		},
		[getIsDisabled, onUpdateAllowsRequests]
	);

	const columns = useMemo(
		() =>
			[
				{
					renderCell: integrationResourceRole => (
						<RoleEntity role={integrationResourceRole} size="medium" variant="link" />
					),
					header: <HeaderCellContent text={t("headers.integrationResourceRole")} icon={<ResourcesIcon />} />,
					key: "role",
					width: COLUMNS_WIDTHS.role
				},
				{
					renderCell: (integrationResourceRole, options) =>
						integrationResourceRole.managed ? (
							<WorkflowCell
								integration={getIntegration(integrationResourceRole)}
								defaultValue={t("selectNullValue")}
								options={approvalAlgorithmOptions}
								value={
									integrationResourceRole.approvalAlgorithmId
										? approvalAlgorithms?.get(integrationResourceRole.approvalAlgorithmId)
										: INHERIT
								}
								disabled={options.disabled || getIsDisabled(integrationResourceRole.id)}
								onChange={async newApprovalAlgorithm => {
									if (newApprovalAlgorithm) {
										await onUpdateApprovalAlgorithm(integrationResourceRole.id, newApprovalAlgorithm);
									}
								}}
							/>
						) : null,
					header: <HeaderCellContent text={t("headers.approvalAlgorithm")} icon={<WorkflowsIcon />} />,
					key: "workflow",
					width: COLUMNS_WIDTHS.workflow,
					overflow: true
				},
				{
					renderCell: (role: TFullIntegrationResourceRole) => {
						return role.managed ? (
							!role.allowedDurations ? (
								<Typography variant="body_reg"> {t("selectNullValue")}</Typography>
							) : (
								<Typography variant="body_sb"> {t("custom")}</Typography>
							)
						) : null;
					},
					header: <HeaderCellContent text={t("headers.owner")} icon={<OwnerIcon />} />,
					key: "requestDuration",
					width: COLUMNS_WIDTHS.requestDuration
				} as TColumn<TRoleWithResource>,
				{
					renderCell: (role: TFullIntegrationResourceRole) => renderRequestableCell(role),
					header: <HeaderCellContent text={t("headers.requestable")} icon={<RequestsIcon />} />,
					key: "requestable",
					width: COLUMNS_WIDTHS.requestable
				} as TColumn<TRoleWithResource>
			] as TColumn<TRoleWithResource>[],
		[
			COLUMNS_WIDTHS.requestDuration,
			COLUMNS_WIDTHS.requestable,
			COLUMNS_WIDTHS.role,
			COLUMNS_WIDTHS.workflow,
			approvalAlgorithmOptions,
			approvalAlgorithms,
			getIntegration,
			getIsDisabled,
			onUpdateApprovalAlgorithm,
			renderRequestableCell,
			t
		]
	);

	return (
		<PaginatedVirtualTable
			isLoading={isLoading}
			className={className}
			innerRef={innerRef}
			columns={columns}
			rows={roles || []}
			totalRows={totalResults}
			perPage={perPage || DEFAULT_PER_PAGE}
			fetchPage={loadRolesPage}
			emptyTableMessage={t("noRoles")}
			limitedHeight
		/>
	);
};
