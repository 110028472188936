import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { IntegrationSelectInput } from "components/common/IntegrationSelectInput";
import { ResourceSelectInput } from "components/common/ResourceSelectInput";
import { RoleSelectInput } from "components/common/RoleSelectInput";
import { Button } from "components/ui/Button";
import { Modal } from "components/ui/Modal";
import { TitleBody } from "components/ui/TitleBody";
import { useIntegrations } from "hooks/useIntegrations";
import { IntegrationModel } from "models/IntegrationModel";
import { IntegrationResourceModel } from "models/IntegrationResourceModel";
import { IntegrationResourceRoleModel } from "models/IntegrationResourceRoleModel";
import { useStyles } from "./styles";

interface IProps {
	addResourcePermission: (roleId: string, resourceId: string) => Promise<void>;
	originResourceId: string;
	isLoading?: boolean;
	isOpen?: boolean;
	onClose: () => void;
}

export const HasAccessModal: FC<IProps> = ({
	addResourcePermission,
	originResourceId,
	isLoading,
	isOpen = false,
	onClose
}) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const [integration, setIntegration] = useState<IntegrationModel | null>(null);
	const [integrationResource, setIntegrationResource] = useState<IntegrationResourceModel | null>(null);
	const [integrationResourceRole, setIntegrationResourceRole] = useState<IntegrationResourceRoleModel | null>(null);
	const integrations = useIntegrations();

	const resetForm = useCallback(() => {
		setIntegration(null);
		setIntegrationResource(null);
		setIntegrationResourceRole(null);
	}, []);

	const onSubmit = useCallback(async () => {
		if (!integrationResourceRole || !integrationResource) return;
		await addResourcePermission(integrationResourceRole.id, integrationResource.id);
		onClose();
		resetForm();
	}, [integrationResourceRole, integrationResource, addResourcePermission, onClose, resetForm]);

	const onChangeIntegration = useCallback((newIntegration: IntegrationModel | null) => {
		setIntegration(newIntegration);
		setIntegrationResource(null);
	}, []);

	const onChangeResource = useCallback((newResource: IntegrationResourceModel | null) => {
		setIntegrationResource(newResource);
	}, []);

	useEffect(() => {
		resetForm();
	}, [resetForm]);

	const actions = useMemo(
		() => (
			<Button onClick={onSubmit} loading={isLoading}>
				{t("buttons.save")}
			</Button>
		),
		[isLoading, onSubmit, t]
	);

	const content = useMemo(
		() => (
			<div className={classes.modalContent}>
				<TitleBody
					size="large"
					title={t("pages.integrationResource.hasAccessFirstSectionTitle")}
					body={t("pages.integrationResource.hasAccessFirstSectionSubtitle")}
				/>
				<div className={classes.resourcePermissionForm}>
					<IntegrationSelectInput
						className={classes.input}
						label={t("common.integrationSelectInput.label")}
						onChange={onChangeIntegration}
						options={integrations}
						placeholder={t("common.integrationSelectInput.placeholder")}
						required
						value={integration}
					/>
					<ResourceSelectInput
						className={classes.input}
						inputLabel={t("common.resourceSelectInput.label")}
						onChange={onChangeResource}
						placeholder={t("common.resourceSelectInput.placeholder")}
						required
						selected={integrationResource}
						selectedIntegrationId={integration?.id || ""}
					/>
				</div>
				<TitleBody
					size="large"
					title={t("pages.integrationResource.hasAccessSecondSectionTitle")}
					body={t("pages.integrationResource.hasAccessSecondSectionSubtitle")}
				/>
				<RoleSelectInput
					className={classes.input}
					inputLabel={t("common.roleSelectInput.label")}
					integrationResourceId={originResourceId}
					onChange={setIntegrationResourceRole}
					placeholder={t("common.roleSelectInput.placeholder")}
					required
					selectedRole={integrationResourceRole}
				/>
			</div>
		),
		[
			classes.input,
			classes.modalContent,
			classes.resourcePermissionForm,
			integration,
			integrationResource,
			integrationResourceRole,
			integrations,
			onChangeIntegration,
			onChangeResource,
			originResourceId,
			t
		]
	);

	return (
		<Modal isOpen={isOpen} onClose={onClose} actions={actions} className={classes.modalContainer} content={content} />
	);
};
