import classNames from "classnames";
import { Set, Map } from "immutable";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ResourceTypesInput } from "components/pages/NewTicketPage/components/NewTicketForm/components/NewTicketByResourceForm/components/ResourceTypesInput";
import { IconButton } from "components/ui/IconButton";
import { IconPrefix } from "components/ui/IconPrefix";
import { ExpandIcon } from "components/ui/Icons/ExpandIcon";
import { ResourcesIcon } from "components/ui/Icons/ResourcesIcon";
import { RoleIcon } from "components/ui/Icons/RoleIcon";
import { Input } from "components/ui/Input";
import { LoadingSpinner } from "components/ui/LoadingSpinner";
import { Typography } from "components/ui/Typography";
import { useDebounceFn } from "hooks/useDebounce";
import { useStyles } from "./styles";
import { getIntegrationIcon } from "../IntegrationImage";
import { ResourceCard } from "../ResourceCard";
import type { IInputState } from "components/ui/fieldHelpers/types";
import type { IntegrationModel } from "models/IntegrationModel";
import type { IntegrationResourceModel } from "models/IntegrationResourceModel";
import type { IntegrationResourceRoleModel } from "models/IntegrationResourceRoleModel";

type TProps = {
	integration: IntegrationModel;
	onIntegrationResourceSearch: (search: string | undefined, resourceTypes?: string[]) => void;
	onResourceRoleSearch: (resourceId: string, search?: string) => Promise<void>;
	onSelectRole: (resourceId: string, roleId: string) => void;
	resources?: IntegrationResourceModel[];
	roleOptions: Map<string, IntegrationResourceRoleModel[]>; // Map of resource id -> role options
	selectedRoleIds: Map<string, Set<string>>; // Map of resource id -> selected role ids
	onCollapse?: () => void;
	receiverUserId?: string;
	resourceCardsRef?: React.RefObject<HTMLDivElement>;
	isLoading?: boolean;
	searchValue: string;
	selectedResourceTypes: string[];
};

type TSelectionResourceCardProps = Omit<
	TProps,
	| "integration"
	| "resources"
	| "onIntegrationResourceSearch"
	| "receiverUserId"
	| "searchValue"
	| "selectedResourceTypes"
> & {
	expandedResources: Set<string>;
	resource: IntegrationResourceModel;
	toggleResourceExpand: (resourceId: string) => void;
};

const SelectableResourceCard: FC<TSelectionResourceCardProps> = ({
	expandedResources,
	onResourceRoleSearch,
	onSelectRole,
	resource,
	roleOptions,
	selectedRoleIds,
	toggleResourceExpand
}) => {
	const isExpanded = expandedResources.has(resource.id);
	const toggleExpand = useCallback(() => toggleResourceExpand(resource.id), [resource.id, toggleResourceExpand]);
	const onSearch = useCallback(
		(search: string) => onResourceRoleSearch(resource.id, search),
		[onResourceRoleSearch, resource.id]
	);
	const onSelect = useCallback((roleId: string) => onSelectRole(resource.id, roleId), [onSelectRole, resource.id]);
	const resourceRoleOptions = roleOptions.get(resource.id) || [];
	const selectedRoles = selectedRoleIds.get(resource.id) || Set();
	const totalRoles = resource.rolesCount || 0;
	return isExpanded ? (
		<ResourceCard
			expanded
			multiRole={resource.multirole}
			onClick={toggleExpand}
			onSearch={onSearch}
			onSelect={onSelect}
			resource={resource}
			roleOptions={resourceRoleOptions}
			selectedRoleIds={selectedRoles}
			totalRoles={totalRoles}
		/>
	) : (
		<ResourceCard resource={resource} onClick={toggleExpand} totalRoles={totalRoles} selectedRoleIds={selectedRoles} />
	);
};

const DEBOUNCE_WAIT_MS = 150;

export const ResourceSelection: FC<TProps> = ({
	className,
	innerRef,
	resourceCardsRef,
	integration,
	onIntegrationResourceSearch,
	onResourceRoleSearch,
	onSelectRole,
	resources,
	roleOptions,
	receiverUserId,
	selectedRoleIds,
	onCollapse,
	isLoading,
	searchValue,
	selectedResourceTypes
}) => {
	const { t } = useTranslation("translation");
	const classes = useStyles();
	const debouncedSearch = useDebounceFn(onIntegrationResourceSearch, DEBOUNCE_WAIT_MS);
	const [expandedResources, setExpandedResources] = useState<Set<string>>(Set());
	const [isExpansionDirty, setIsExpansionDirty] = useState(false);
	const [isSearchDirty, setIsSearchDirty] = useState(false);

	const toggleResourceExpand = useCallback(
		(resourceId: string) => {
			const current = expandedResources.has(resourceId);
			setIsExpansionDirty(true);
			setExpandedResources(current ? expandedResources.remove(resourceId) : expandedResources.add(resourceId));
			if (!current) {
				void onResourceRoleSearch(resourceId);
			}
		},
		[expandedResources, onResourceRoleSearch]
	);

	useEffect(() => {
		if (resources && resources.length === 1 && !isSearchDirty) {
			const resourceId = resources[0].id;
			void onResourceRoleSearch(resourceId);
			setExpandedResources(Set([resourceId]));
		}
	}, [resources, isSearchDirty, onResourceRoleSearch]);

	useEffect(() => {
		if (isExpansionDirty || !resources || !selectedRoleIds) return;
		const shouldBeOpened = resources.filter(resource => {
			const selectedRoles = selectedRoleIds.get(resource.id);
			return selectedRoles && selectedRoles.size > 0;
		});
		setExpandedResources(Set(shouldBeOpened.map(resource => resource.id)));
	}, [isExpansionDirty, resources, selectedRoleIds]);

	const totalSelectedRoles = useMemo(() => {
		return selectedRoleIds.reduce((acc, selectedRoles) => acc + selectedRoles.size, 0);
	}, [selectedRoleIds]);

	const onInputStateChange = useCallback((state: Partial<IInputState>) => {
		if (state.dirty !== undefined) setIsSearchDirty(state.dirty);
	}, []);

	const onIntegrationResourceSearchValueChange = useCallback(
		(search: string) => {
			setIsExpansionDirty(false);
			debouncedSearch(search);
		},
		[debouncedSearch]
	);

	const onIntegrationResourceTypeChange = useCallback(
		(resourceTypes: string[] | null) => {
			setIsExpansionDirty(false);
			onIntegrationResourceSearch(searchValue, resourceTypes ?? undefined);
		},
		[onIntegrationResourceSearch, searchValue]
	);
	return (
		<div className={classNames(classes.container, className)} ref={innerRef}>
			<div className={classes.containerHeader}>
				<div className={classNames(classes.headerTop, { [classes.clickable]: !!onCollapse })} onClick={onCollapse}>
					<div className={classes.headerTopTitleDescription}>
						<IconPrefix size="huge" semibold Icon={getIntegrationIcon(integration)} content={integration.name} />
					</div>
					<div className={classes.headerTopActions}>
						<IconButton size="medium" onClick={onCollapse}>
							<ExpandIcon />
						</IconButton>
					</div>
				</div>
				<div className={classes.headerBottom}>
					<div className={classes.headerBottomInfo}>
						<div className={classes.headerBottomInfoCountLine}>
							<IconPrefix
								size="small"
								Icon={ResourcesIcon}
								content={t("entities.plural.IntegrationResource", { context: "capital" })}
							/>
							<Typography variant="text_title_sb">{t("number", { value: integration.resourcesCount || 0 })}</Typography>
						</div>
						<div className={classes.headerBottomInfoCountLine}>
							<IconPrefix size="small" Icon={RoleIcon} content={t("common.resourceSelection.selectedRoles")} />
							<Typography variant="text_title_sb">{totalSelectedRoles}</Typography>
						</div>
					</div>
					<div className={classes.headerBottomActions}>
						<Input
							className={classes.searchInput}
							onStateChange={onInputStateChange}
							onValueChange={onIntegrationResourceSearchValueChange}
							placeholder={t("common.resourceSelection.searchResources")}
							variant="search"
						/>
						{receiverUserId && (
							<ResourceTypesInput
								className={classes.resourceTypesSelect}
								inputClassName={classes.resourceTypesInput}
								resourceTypes={selectedResourceTypes}
								setResourceTypes={onIntegrationResourceTypeChange}
								integrationIds={[integration.id]}
								userId={receiverUserId}
							/>
						)}
					</div>
				</div>
			</div>
			<div
				className={classNames(classes.containerBody, { [classes.isLoading]: !resources?.length })}
				ref={resourceCardsRef}>
				{resources?.length
					? resources.map(resource => (
							<SelectableResourceCard
								expandedResources={expandedResources}
								key={resource.id}
								onResourceRoleSearch={onResourceRoleSearch}
								onSelectRole={onSelectRole}
								resource={resource}
								roleOptions={roleOptions}
								selectedRoleIds={selectedRoleIds}
								toggleResourceExpand={toggleResourceExpand}
							/>
						))
					: null}
				{isLoading ? <LoadingSpinner /> : null}
			</div>
		</div>
	);
};
