import React from "react";
import { useTranslation } from "react-i18next";
import { TicketingIntegrationTicketChip } from "components/common/TicketingIntegrationTicketChip";
import { TooltipOnOverflow } from "components/ui/TooltipOnOverflow";
import { UserEntitlementModel } from "models/UserEntitlementModel";
import { getTicketExpirationDate } from "utils/tickets/ticketExpirationDate";
import type { IEntitlement } from "components/pages/IntegrationResourcePage/utils";

type TProps = {
	entitlement: UserEntitlementModel | IEntitlement;
};

export const ExpirationCell: FC<TProps> = ({ entitlement }) => {
	const { t } = useTranslation();

	const expiresAt = getTicketExpirationDate(entitlement.ticketPermissions);

	return (
		<>
			<TooltipOnOverflow
				content={
					entitlement.role?.managed
						? expiresAt
							? t("dateTime.date", { date: expiresAt })
							: t("shared.ticketExpiration.never")
						: ""
				}
			/>
			{entitlement.ticketPermissions?.map(ticketPermission =>
				ticketPermission.ticket?.ticketingIntegrationTicket ? (
					<TicketingIntegrationTicketChip
						key={ticketPermission.ticket?.id}
						ticketingIntegrationTicket={ticketPermission.ticket?.ticketingIntegrationTicket}
					/>
				) : null
			)}
		</>
	);
};
