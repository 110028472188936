import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { BasicRoleBar } from "components/common/BasicRoleBar";
import { IconButton } from "components/ui/IconButton";
import { CloseIcon } from "components/ui/Icons/CloseIcon";
import { LoadingSpinner } from "components/ui/LoadingSpinner";
import { useOpenGlobalErrorModal } from "hooks/useGlobalError";
import { useIntegrations } from "hooks/useIntegrations";
import { IntegrationResourceRoleModel } from "models/IntegrationResourceRoleModel";
import { useStyles } from "./styles";

interface IProps {
	role: IntegrationResourceRoleModel;
	manual?: boolean;
	onDelete?: (roleId: string) => Promise<void>;
}

export const GivesAccessBar: FC<IProps> = ({ role, manual = false, onDelete }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const openGlobalErrorModal = useOpenGlobalErrorModal();
	const [deletedRoleId, setDeletedRoleId] = useState<string | null>(null);
	const integrations = useIntegrations();

	const handleRoleDelete = useCallback(async () => {
		if (onDelete) {
			try {
				setDeletedRoleId(role.id);
				await onDelete(role.id);
			} catch (err) {
				openGlobalErrorModal(err as Error);
			} finally {
				setDeletedRoleId(null);
			}
		}
	}, [onDelete, openGlobalErrorModal, role.id]);

	const wantedIntegration = integrations?.get(role.integrationResource?.integrationId || "");

	return (
		<div className={classes.container}>
			<BasicRoleBar
				key={role.id}
				role={role}
				resource={role.integrationResource || undefined}
				integration={wantedIntegration || undefined}
				selected
				linkable
				noInteractions
			/>
			{manual ? (
				role.id !== deletedRoleId ? (
					<IconButton onClick={handleRoleDelete} tooltip={t("pages.integrationResource.deleteGiverRole")}>
						<CloseIcon />
					</IconButton>
				) : (
					<div className={classes.spinner}>
						<LoadingSpinner size={20} />
					</div>
				)
			) : null}
		</div>
	);
};
