import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	autoAssignHelpBlock: {
		display: "inline-block"
	},
	titleContainer: {
		display: "flex",
		flexDirection: "row",
		flexGrow: 1,
		justifyContent: "space-between"
	},
	titlePart: {
		alignItems: "center",
		display: "flex",
		flexDirection: "row",
		gap: "var(--spacing-x1, 4px)"
	},
	icon: {
		gap: "var(--spacing-x2, 8px)"
	}
});
