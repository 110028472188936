import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { FilterExpressionEmptyState } from "components/common/FilterExpressionEmptyState";
import { Chip } from "components/ui/chips/Chip";
import { FilterExpression } from "components/ui/filters/FilterExpression";
import { WorkflowsIcon } from "components/ui/Icons/WorkflowsIcon";
import { WorkflowOption } from "components/ui/selectOptions/WorkflowOption";
import { IntegrationWorkflowFilter } from "filters/integration";
import { IntegrationResourceWorkflowFilter } from "filters/integrationResource";
import { IntegrationResourceRoleWorkflowFilter } from "filters/integrationResourceRole";
import { useApprovalAlgorithms } from "hooks/useApprovalAlgorithms";
import { useStaticOptions } from "hooks/useStaticOptions";
import { notEmpty } from "utils/comparison";
import { INHERIT, type TInherit } from "utils/ui/select";
import { useFilterFormExpression } from "./filter.hooks";
import type { ApprovalAlgorithmModel } from "models/ApprovalAlgorithmModel";
import type { TFilterOperator } from "types/filters";
import type { Constructor } from "types/utilTypes";

type TWorkflowFilters =
	| IntegrationWorkflowFilter
	| IntegrationResourceWorkflowFilter
	| IntegrationResourceRoleWorkflowFilter;

type TWorkflowFilterProps = {
	filter: TWorkflowFilters;
	onChange: (filter: TWorkflowFilters | undefined, isValid: boolean) => void;
};

function getFilter(filterName: TWorkflowFilters["name"]): Constructor<TWorkflowFilters> {
	switch (filterName) {
		case IntegrationWorkflowFilter.filterName:
			return IntegrationWorkflowFilter;
		case IntegrationResourceWorkflowFilter.filterName:
			return IntegrationResourceWorkflowFilter;
		default:
			return IntegrationResourceRoleWorkflowFilter;
	}
}

const isIntegrationWorkflowFilter = (filter: TWorkflowFilters): filter is IntegrationWorkflowFilter => {
	return filter.name === IntegrationWorkflowFilter.filterName;
};

const isValidOperator = (operator: TFilterOperator): operator is TWorkflowFilters["operator"] =>
	operator === "is" || operator === "isNot";

type TWorkflowOption = ApprovalAlgorithmModel | TInherit;

const OPERATORS = ["is", "isNot"] as TFilterOperator[];

const STATIC_OPTIONS = [INHERIT] as TWorkflowOption[];
const getWorkflowKey = (workflow: ApprovalAlgorithmModel | TInherit) =>
	typeof workflow === "string" ? workflow : workflow.id;

export const WorkflowFilterExpression: FC<TWorkflowFilterProps> = ({ className, innerRef, filter, onChange }) => {
	const { t } = useTranslation("translation", { keyPrefix: "pages.bulkActions.filters" });
	const withInherit = useMemo(() => filter.name !== IntegrationWorkflowFilter.filterName, [filter.name]);
	const [query, setQuery] = useState<string>();

	const approvalAlgorithms = useApprovalAlgorithms();

	const workflowOptions = useMemo(() => {
		if (query && approvalAlgorithms) {
			return approvalAlgorithms
				.filter(algorithm => algorithm.name.toLowerCase().trim().includes(query.toLowerCase().trim()))
				.toList()
				.toArray();
		}

		const inheritOption = withInherit ? [INHERIT] : [];
		return (inheritOption as TWorkflowOption[]).concat(approvalAlgorithms?.toList().toArray() || []);
	}, [approvalAlgorithms, query, withInherit]);

	const { options, groupBy } = useStaticOptions(workflowOptions, getWorkflowKey, STATIC_OPTIONS, false);

	const selectedWorkflows = useMemo(() => {
		if (!filter || !approvalAlgorithms) return [];
		return filter.value
			.map(id => (id !== null ? approvalAlgorithms.get(id) : INHERIT))
			.filter(notEmpty) as TWorkflowOption[];
	}, [approvalAlgorithms, filter]);

	const { clearFilter, removeFilter } = useFilterFormExpression<TWorkflowFilters>({
		filterName: filter.name,
		onChange,
		getFilter
	});

	const onOperatorSelect = useCallback(
		(operator: TFilterOperator) => {
			if (!filter) return;
			if (!isValidOperator(operator)) return;
			onChange(filter.set("operator", operator), filter.value.length > 0);
		},
		[filter, onChange]
	);

	const onOptionSelect = useCallback(
		(option: TWorkflowOption) => {
			if (!filter || !option) return;
			const currentValue = filter.value;
			let newValue = currentValue;
			if (option === INHERIT) {
				newValue = currentValue.find(value => value === null)
					? currentValue.filter(value => value !== null)
					: [...currentValue, null];
			} else {
				newValue = currentValue.includes(option.id)
					? currentValue.filter(id => id !== option.id)
					: [...currentValue, option.id];
			}
			if (isIntegrationWorkflowFilter(filter)) {
				const nonEmptyValue = newValue.filter(notEmpty);
				onChange(filter.set("value", nonEmptyValue), nonEmptyValue.length > 0);
			} else {
				onChange(filter.set("value", newValue), newValue.length > 0);
			}
		},
		[filter, onChange]
	);

	const getOptionLabel = useCallback(
		(option: TWorkflowOption) => {
			return option === INHERIT
				? t(
						`values.${filter.name === IntegrationResourceWorkflowFilter.filterName ? "inheritIntegration" : "inheritResource"}`
					)
				: option.name;
		},
		[filter.name, t]
	);

	const renderSelected = useCallback(
		(option: TWorkflowOption) => {
			const text = getOptionLabel(option);
			return (
				<Chip
					size="large"
					PrefixIcon={option !== INHERIT ? WorkflowsIcon : undefined}
					selected
					onDelete={() => onOptionSelect(option)}>
					{text}
				</Chip>
			);
		},
		[getOptionLabel, onOptionSelect]
	);

	return (
		<FilterExpression
			className={className}
			emptyState={<FilterExpressionEmptyState text={t("values.emptyWorkflows")} Icon={WorkflowsIcon} />}
			filter={null}
			getOptionLabel={getOptionLabel}
			getMoreOptions={setQuery}
			groupBy={groupBy}
			innerRef={innerRef}
			inputPlaceholder={t("placeholders.workflow")}
			isLoading={!approvalAlgorithms}
			onOperatorSelect={onOperatorSelect}
			onOptionSelect={onOptionSelect}
			onRemoveFilter={removeFilter}
			onReset={clearFilter}
			operators={OPERATORS}
			optionRenderer={WorkflowOption}
			options={options}
			renderSelected={renderSelected}
			selected={selectedWorkflows}
			selectedOperator={filter.operator}
			title={t(`title.${filter.name}`)}
			type="multiSelect"
		/>
	);
};
