import classNames from "classnames";
import React, { MouseEventHandler, ReactNode, useMemo, useRef } from "react";
import { createPortal } from "react-dom";
import { useOnClickOutside } from "hooks/useOnClickOutside";
import { useTooltip } from "hooks/useTooltip";
import { useStyles } from "./styles";
import { Tooltip } from "../Tooltip";
import type { Placement } from "@popperjs/core";
import type { TIconButtonSize, TIconButtonVariant } from "../IconButton";

const ICON_SIZE_TO_PX = new Map<TIconButtonSize, number>([
	["tiny", 12],
	["xs", 12],
	["small", 16],
	["medium", 20],
	["large", 24],
	["huge", 32]
]);

type TDropdownIconButtonProps = {
	Icon: IconComponent;
	disabled?: boolean;
	dropdown: ReactNode;
	loading?: boolean;
	onClick?: MouseEventHandler;
	onClose: () => void;
	open: boolean;
	position?: Placement;
	tooltip?: ReactNode;
	size?: TIconButtonSize;
	variant?: TIconButtonVariant;
};

const useButtonClasses = (props: {
	active?: boolean;
	className?: string;
	disabled?: boolean;
	loading?: boolean;
	size: TIconButtonSize;
	variant: TIconButtonVariant;
}) => {
	const { active, size, variant, loading, disabled, className } = props;
	const classes = useStyles();
	const sizeClassName = useMemo(() => {
		switch (size) {
			case "tiny":
				return classes.tiny;
			case "xs":
				return classes.xs;
			case "small":
				return classes.small;
			case "medium":
				return classes.medium;
			case "large":
				return classes.large;
			case "huge":
				return classes.huge;
		}
	}, [classes, size]);

	const variantClassName = useMemo(() => {
		switch (variant) {
			case "primary":
				return classes.primary;
			case "secondary":
				return classes.secondary;
			case "reverse":
				return classes.reverse;
		}
	}, [classes, variant]);

	const buttonClassName = classNames(
		classes.iconButton,
		sizeClassName,
		variantClassName,
		{ [classes.disabled]: disabled, [classes.loading]: loading, [classes.active]: active },
		className
	);

	return { buttonClassName, sizeClassName, variantClassName };
};

export const DropdownIconButton: FC<TDropdownIconButtonProps> = props => {
	const {
		className,
		disabled = false,
		dropdown,
		loading = false,
		Icon,
		onClick,
		onClose,
		open,
		position = "auto",
		tooltip,
		size = "medium",
		variant = "primary"
	} = props;
	const classes = useStyles();
	const { buttonClassName } = useButtonClasses({ className, disabled, size, variant, active: open });
	const wrapperRef = useRef<HTMLDivElement | null>(null);

	const content = useMemo(() => {
		return <Icon size={ICON_SIZE_TO_PX.get(size) || ICON_SIZE_TO_PX.get("medium")} />;
	}, [Icon, size]);

	const tooltipOptions = useMemo(() => ({ visible: open, placement: position }), [position, open]);
	const { visible, setTooltipRef, tooltipProps, setTriggerRef, tooltipRef } = useTooltip(tooltipOptions);

	useOnClickOutside(tooltipRef || undefined, onClose, wrapperRef);

	const dropdownPortal = useMemo(() => {
		return createPortal(
			<div className={classes.wrapper} ref={wrapperRef}>
				<div ref={setTooltipRef} {...tooltipProps}>
					{dropdown}
				</div>
			</div>,
			document.body
		);
	}, [classes.wrapper, dropdown, setTooltipRef, tooltipProps]);

	const buttonElement = (
		<button
			ref={setTriggerRef}
			className={buttonClassName}
			onClick={!disabled && !loading ? onClick : undefined}
			type="button">
			{content}
		</button>
	);
	return (
		<>
			{visible ? buttonElement : <Tooltip content={tooltip}>{buttonElement}</Tooltip>}
			{visible ? dropdownPortal : null}
		</>
	);
};
