import React, { useCallback } from "react";
import { OnCallIntegrationScheduleEntity } from "components/common/entities/OnCallIntegrationScheduleEntity";
import { useOnCallIntegrationSchedules } from "hooks/useOnCallIntegrationSchedules";
import { OnCallIntegrationScheduleModel } from "models/OnCallIntegrationScheduleModel";
import { SelectOption, type TEntityOverrideProps } from "../SelectOption";
import type { TEntityOption } from "utils/entityOptionType";
import type { TOptionComponentProps } from "utils/ui/select";

export const ANY_SCHEDULES_OPTION_ID = "anySchedules";

function OnCallIntegrationScheduleOption<T extends OnCallIntegrationScheduleModel | string | TEntityOption>({
	disabled = false,
	isHighlighted = false,
	isSelected = false,
	onSelect,
	option,
	optionKey
}: TOptionComponentProps<T>) {
	const isId = typeof option === "string";
	const isEntityOption = typeof option === "object" && "identifier" in option;

	const onCallScheduleId = isId ? option : isEntityOption ? option.identifier : null;

	const onCallIntegrationSchedules = useOnCallIntegrationSchedules();
	const currentOnCallIntegrationSchedule = onCallScheduleId
		? onCallIntegrationSchedules?.get(onCallScheduleId)
		: (option as OnCallIntegrationScheduleModel);

	const renderOption = useCallback(
		(entityProps: TEntityOverrideProps) =>
			currentOnCallIntegrationSchedule ? (
				<OnCallIntegrationScheduleEntity
					withIcon={currentOnCallIntegrationSchedule.id !== ANY_SCHEDULES_OPTION_ID}
					onCallIntegrationSchedule={currentOnCallIntegrationSchedule}
					{...entityProps}
				/>
			) : null,
		[currentOnCallIntegrationSchedule]
	);

	return (
		<SelectOption
			disabled={disabled}
			isHighlighted={isHighlighted}
			isSelected={isSelected}
			key={optionKey}
			onSelect={onSelect}
			value={option}
			renderOption={renderOption}
		/>
	);
}

OnCallIntegrationScheduleOption.isSelectOption = true;

export { OnCallIntegrationScheduleOption };
