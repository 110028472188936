import { List } from "immutable";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { editIntegrationResource, TIntegrationResourceBody } from "api/integrationResources";
import { ResourceHeader } from "components/common/ResourceHeader";
import { ErrorModal } from "components/ui/ErrorModal";
import { useCompany } from "hooks/useCompany";
import useErrorModalState from "hooks/useErrorModalState";
import { useIntegrations } from "hooks/useIntegrations";
import { IntegrationResourceModel } from "models/IntegrationResourceModel";
import { TTicketDuration } from "utils/durationsOptions";
import { INHERIT } from "utils/ui/select";
import { ResourceSettingsPopup } from "./ResourceSettingsPopup";
import { useStyles } from "./styles";
import type { UserModel } from "models/UserModel";
import type { TFormData } from "./ResourceSettingsPopup/useSettingsPopupState";

interface IProps {
	adapterless: boolean;
	inherited?: boolean;
	integrationResource: IntegrationResourceModel;
	onDeleteButtonClick?: () => void;
	onSave: (data: Partial<TIntegrationResourceBody>) => Promise<void>;
	owner: UserModel | null;
	type?: string | undefined;
}

export const ResourceHeaderBlock: FC<IProps> = ({
	adapterless,
	inherited = false,
	integrationResource,
	onDeleteButtonClick,
	onSave: propOnSave,
	owner: propOwner,
	type = undefined
}) => {
	const classes = useStyles();
	const company = useCompany();
	const integrations = useIntegrations();
	const integration = integrations?.get(integrationResource.integrationId || "");
	const {
		allowsRequests,
		tags,
		description,
		userDefinedDescription,
		userDefinedTags,
		allowedDurations: resourceAllowedDurations,
		type: resourceType,
		name
	} = useMemo(() => integrationResource.toObject(), [integrationResource]);

	const [resourceName, setResourceName] = useState(name);
	const resourceTags = useMemo(() => [...(tags || []), ...(userDefinedTags || [])], [tags, userDefinedTags]);
	const allowedDurations = useMemo(() => {
		if (resourceAllowedDurations) {
			return resourceAllowedDurations;
		} else if (integration?.allowedDurations) {
			return integration.allowedDurations;
		} else if (company?.allowedDurations) {
			return company.allowedDurations;
		}
		return List<TTicketDuration>();
	}, [company, integration, resourceAllowedDurations]);
	const { errorModalSetError, errorModalIsOpen, errorModalError, errorModalRetry, errorModalClose } =
		useErrorModalState();

	const onUpdateResource = useCallback(
		async (resourceData: Partial<TIntegrationResourceBody> & { id: string }) => {
			try {
				await editIntegrationResource(resourceData);
			} catch (error) {
				errorModalSetError(error as Error);
			}
		},
		[errorModalSetError]
	);

	const onResourceRename = useCallback(
		async (newName: string) => {
			setResourceName(newName);
			await onUpdateResource({ id: integrationResource.id, name: newName });
		},
		[integrationResource.id, onUpdateResource]
	);

	const onDelete = useCallback(() => {
		if (integrationResource.id && onDeleteButtonClick) {
			onDeleteButtonClick();
		}
	}, [integrationResource.id, onDeleteButtonClick]);

	const onSave = useCallback(
		async (formData: TFormData) => {
			await propOnSave({
				id: integrationResource.id,
				name: formData.name,
				allowsRequests: formData.allowsRequests,
				allowedDurations: formData.allowedDurations || null,
				ownerUserId: formData.owner === INHERIT ? null : formData.owner.id,
				type: formData.resourceType || undefined,
				description: formData.description,
				tags: formData.tags?.filter(tag =>
					integrationResource?.tags ? !integrationResource?.tags?.includes(tag) : true
				),
				...(type && { type: formData.resourceType })
			});
		},
		[integrationResource, propOnSave, type]
	);

	useEffect(() => {
		setResourceName(name);
	}, [name]);

	return (
		<div className={classes.header}>
			<div className={classes.resourceHeaderBlock}>
				<div className={classes.resourceHeaderSection}>
					<ResourceHeader
						description={userDefinedDescription || description}
						inheritOwner={inherited}
						name={resourceName}
						onRename={adapterless ? onResourceRename : undefined}
						ownerId={propOwner?.id || undefined}
						requestsDisabled={!allowsRequests}
						integrationId={integrationResource.integrationId}
						resourceType="resource"
						showOwner
						tags={resourceTags}
						type={resourceType}
					/>
				</div>
				<div className={classes.resourceHeaderBlock}>
					<ErrorModal
						isOpen={errorModalIsOpen}
						error={errorModalError}
						onRetry={errorModalRetry}
						onClose={errorModalClose}
					/>
					<ResourceSettingsPopup
						{...{
							adapterless,
							integrationResource,
							onDelete: onDeleteButtonClick ? onDelete : undefined,
							onSave,
							type,
							integration,
							initialFormData: {
								allowedDurations,
								overrideAllowedDurations: !!integrationResource.allowedDurations,
								allowsRequests,
								owner: propOwner || INHERIT,
								description: userDefinedDescription || undefined,
								tags: userDefinedTags,
								resourceType,
								name: resourceName
							}
						}}
					/>
				</div>
			</div>
		</div>
	);
};
