import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	contentContainer: {
		cursor: "text"
	},
	placeholder: {
		color: "var(--color-grey-500)",
		fontSize: "var(--typography-font-size-text)",
		fontStyle: "normal",
		fontWeight: "var(--typography-font-weight-regular)"
	},
	disabledPlaceholder: {
		extend: "placeholder",
		color: "var(--color-grey-400)"
	},
	inputHTMLComponent: {
		backgroundColor: "transparent",
		border: "none",
		fontFamily: "inherit",
		fontSize: "var(--typography-font-size-text)",
		fontStyle: "normal",
		fontWeight: "var(--typography-font-weight-semibold)",
		"&$disabled": {
			"&:placeholder": {
				extend: "disabledPlaceholder"
			},
			"&:-ms-input-placeholder": {
				extend: "disabledPlaceholder"
			},
			"&::-webkit-input-placeholder": {
				extend: "disabledPlaceholder"
			},
			"&::-moz-placeholder": {
				extend: "disabledPlaceholder"
			}
		},
		"&:placeholder": {
			extend: "placeholder",
			fontWeight: "var(--typography-font-weight-regular)"
		},
		"&:-ms-input-placeholder": {
			extend: "placeholder"
		},
		"&::-webkit-input-placeholder": {
			extend: "placeholder"
		},
		"&::-moz-placeholder": {
			extend: "placeholder"
		},
		height: "100%",
		outline: "none",
		textOverflow: "ellipsis",
		width: "100%",
		"&$hideInput": {
			width: "0"
		}
	},
	disabled: {},
	hideInput: {}
});
