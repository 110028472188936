import classNames from "classnames";
import isEqual from "lodash/isEqual";
import React, { ReactNode, memo, useCallback, useMemo, useRef } from "react";
import { Skeleton } from "components/ui/Skeleton";
import { useStyles } from "./styles";
import { useVirtualTableContext } from "../../virtualTableContext";
import type { IRowOptions, TColumn } from "../../types";

function Cell<T extends object>({
	column,
	row,
	className,
	onClick,
	disabled = false,
	resizeBorderHighlighted = false
}: TProps<{
	resizeBorderHighlighted?: boolean;
	disabled?: boolean;
	column?: TColumn<T>;
	row?: T;
	onClick?: (row: T) => void;
}>) {
	const ref = useRef<HTMLDivElement>(null);
	const classes = useStyles();
	const content = useMemo(() => {
		if (!column || !row) return <Skeleton />;
		if (column.getProps) {
			const Comp = column.renderCell as FC;
			const props = column.getProps(row, { disabled }) as Parameters<typeof Comp>[0];
			return <Comp {...props} />;
		}
		return (column.renderCell as (row: T, options: IRowOptions) => ReactNode)(row, { disabled });
	}, [column, disabled, row]);

	const onCellClick = useCallback(
		(e: React.MouseEvent) => {
			if (onClick && row) {
				if (e.target === ref.current) {
					onClick(row);
				}
			}
		},
		[onClick, row]
	);

	return (
		<div
			ref={ref}
			onClick={onCellClick}
			className={classNames(classes.cell, className, {
				[classes.resizing]: resizeBorderHighlighted,
				[classes.cellOverflow]: column?.overflow
			})}>
			{content}
		</div>
	);
}

const CellMemo = React.memo(Cell) as typeof Cell;

type TGridRowProps<T extends object> = {
	columns: TColumn<T>[];
	defaultRowHeight: number;
	index: number;
	onRowClicked?: (row: T) => void;
	onCellClicked?: (row: T) => void;
	row?: T;
	selected?: boolean;
	shouldDisableRow?: (row: T) => boolean;
	shouldHighlightRowOnHover?: boolean;
	style?: React.CSSProperties;
};

const Row = <T extends object>({
	className,
	columns,
	index,
	onRowClicked,
	onCellClicked,
	row,
	shouldDisableRow,
	selected = false,
	shouldHighlightRowOnHover = false,
	style = {}
}: TProps<TGridRowProps<T>>) => {
	const classes = useStyles();
	const handleClick = useCallback(() => {
		if (row && onRowClicked) {
			onRowClicked(row);
		}
	}, [onRowClicked, row]);

	const {
		state: { compact, selectable, gridTemplateColumns, resizeable, resizeIndex }
	} = useVirtualTableContext();

	const element = useMemo(() => {
		const isHighlighted = index % 2 === 1;
		const rowStyle = { ...style, gridTemplateColumns };
		if (!row) {
			return (
				<div
					className={classNames(classes.row, {
						[classes.compact]: compact,
						[classes.selectable]: selectable,
						[classes.highlighted]: isHighlighted
					})}
					style={rowStyle}>
					{columns.map((column, index) => (
						<CellMemo key={column.key} className={index === 0 && selected ? classes.checkboxPlaceholder : undefined} />
					))}
				</div>
			);
		}

		const disabled = shouldDisableRow && shouldDisableRow(row);
		return (
			<div
				className={classNames(classes.row, className, {
					[classes.clickable]: Boolean(onRowClicked || onCellClicked),
					[classes.disabled]: Boolean(disabled),
					[classes.compact]: compact,
					[classes.selectable]: selectable,
					[classes.selected]: selected,
					[classes.highlighted]: isHighlighted,
					[classes.highlightedOnHover]: shouldHighlightRowOnHover
				})}
				style={rowStyle}
				tabIndex={0}
				onClick={handleClick}>
				{columns.map((column, index) => (
					<CellMemo
						key={column.key}
						onClick={onCellClicked}
						column={column}
						row={row}
						disabled={disabled}
						resizeBorderHighlighted={resizeable && resizeIndex > -1 && resizeIndex + 1 === index}
					/>
				))}
			</div>
		);
	}, [
		index,
		style,
		gridTemplateColumns,
		row,
		shouldDisableRow,
		classes.row,
		classes.clickable,
		classes.disabled,
		classes.compact,
		classes.selectable,
		classes.selected,
		classes.highlighted,
		classes.highlightedOnHover,
		classes.checkboxPlaceholder,
		className,
		onRowClicked,
		compact,
		selectable,
		selected,
		shouldHighlightRowOnHover,
		handleClick,
		columns,
		onCellClicked,
		resizeable,
		resizeIndex
	]);

	return element;
};

const RowMemo = memo(Row, isEqual) as typeof Row;
export { RowMemo as TableRow };
