import classNames from "classnames";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getIntegrationResources } from "api/integrationResources";
import { FixedHeightCommonAccordion } from "components/common/FixedHeightCommonAccordion";
import { Button } from "components/ui/Button";
import { IconPrefix } from "components/ui/IconPrefix";
import { AddIcon } from "components/ui/Icons/AddIcon";
import { EditIcon } from "components/ui/Icons/EditIcon";
import { ResourcesIcon } from "components/ui/Icons/ResourcesIcon";
import { Input } from "components/ui/Input";
import { Separator } from "components/ui/Separator";
import { Typography } from "components/ui/Typography";
import { IntegrationResourceIntegrationIdFilter } from "filters/integrationResource";
import { useBreakpoint } from "hooks/useBreakpoint";
import { useDebouncedPaginatedSearch } from "hooks/useDebouncedPaginationSearch";
import { IntegrationModel } from "models/IntegrationModel";
import { IntegrationResourceModel } from "models/IntegrationResourceModel";
import { getIntegrationResourceFilters } from "utils/api/filters";
import { Bp } from "utils/ui/breakpoints";
import { ResourcesTable } from "./components/ResourcesTable";
import { useStyles } from "./styles";
import type { IPaginatedSearchOptions } from "utils/searchUtils";

const PAGE_SIZE = 15;
const MIN_ROWS = 3;
const DEFAULT_ROW_HEIGHT_PX = 60;
const DEFAULT_ACCORDION_HEIGHT_PX = 180;

interface IProps {
	integration: IntegrationModel;
	onAddResource?: () => void;
	withUnmanaged?: boolean;
}

export const ResourcesSection: FC<IProps> = ({ integration, onAddResource, withUnmanaged = false }) => {
	const { t } = useTranslation();
	const [query, setQuery] = useState("");
	const navigate = useNavigate();
	const classes = useStyles();

	const { isBiggerThan: showExtra } = useBreakpoint(Bp.TWENTY);

	const fetchResources = useCallback(
		(paginationOptions: IPaginatedSearchOptions) => {
			const queryOptions = {
				...paginationOptions.pagination,
				...paginationOptions.sort,
				withUnmanaged,
				filters: getIntegrationResourceFilters({ integrationId: [integration.id], name: [query] })
			};

			return getIntegrationResources(queryOptions);
		},
		[integration, query, withUnmanaged]
	);

	const { isLoading, totalResults, getPage, setPartialItem, itemsForVirtualTable } =
		useDebouncedPaginatedSearch<IntegrationResourceModel>(query, {
			fetchItems: fetchResources,
			perPage: PAGE_SIZE,
			initialFilter: null,
			disableSearch: false
		});

	const onInputChange = useCallback((event: { target: { value: string } }) => {
		setQuery(event.target.value);
	}, []);

	const handleBulkActionsClick = useCallback(() => {
		const integrationFilter = new IntegrationResourceIntegrationIdFilter({ value: [integration.id] });
		navigate({
			pathname: "/bulkActions",
			search: `?tab=resource&${integrationFilter.toURLSearchParams().toString()}`
		});
	}, [integration.id, navigate]);

	const titleContent = useMemo(
		() => (
			<div className={classes.titleContainer}>
				<div className={classNames(classes.titlePart, classes.resourceLabel)}>
					<IconPrefix Icon={ResourcesIcon} content={t("pages.integration.resources")} className={classes.icon} />
					<Typography
						className={classes.childrenCount}
						variant="body_reg">{`( ${t("number", { value: integration.resourcesCount || 0 })} )`}</Typography>
					{integration.lastResourcesSync && showExtra && (
						<>
							<Separator className={classes.separator} />
							<Typography relative variant="text_sm_reg" className={classes.lighterText}>
								{t("common.resourceHeader.lastSync", { date: integration.lastResourcesSync })}
							</Typography>
						</>
					)}
				</div>
				{
					<div className={classNames(classes.titlePart, classes.resourceActions)}>
						<Button
							prefix={<EditIcon size="small" />}
							size="medium"
							variant="secondary"
							onClick={handleBulkActionsClick}>
							{t("pages.integration.bulkActions")}
						</Button>

						{onAddResource && (
							<Button variant="secondary" size="medium" prefix={<AddIcon />} onClick={onAddResource}>
								{t("buttons.add")}
							</Button>
						)}
					</div>
				}
			</div>
		),
		[
			classes.titleContainer,
			classes.titlePart,
			classes.resourceLabel,
			classes.icon,
			classes.childrenCount,
			classes.separator,
			classes.lighterText,
			classes.resourceActions,
			t,
			integration.resourcesCount,
			integration.lastResourcesSync,
			showExtra,
			handleBulkActionsClick,
			onAddResource
		]
	);

	return (
		<FixedHeightCommonAccordion
			maxRows={PAGE_SIZE}
			minRows={MIN_ROWS}
			totalResults={totalResults}
			titleContent={titleContent}
			defaultRowHeight={DEFAULT_ROW_HEIGHT_PX}
			defaultAccordionHeight={DEFAULT_ACCORDION_HEIGHT_PX}>
			<div className={classes.content}>
				<Input
					className={classes.searchBar}
					onChange={onInputChange}
					placeholder={t("pages.integration.resource.search.placeholder")}
					value={query}
					variant="search"
					size="medium"
				/>
				<ResourcesTable
					fetchIntegrationResources={getPage as (page: number) => Promise<void>}
					integrationResources={itemsForVirtualTable}
					onResourceUpdate={setPartialItem}
					totalIntegrationResources={totalResults}
					isLoading={isLoading}
				/>
			</div>
		</FixedHeightCommonAccordion>
	);
};
