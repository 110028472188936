import { createUseStyles } from "react-jss";

const COLUMN_HEIGHT_PX = 40;

export const useStyles = createUseStyles({
	columnContent: {
		height: `${COLUMN_HEIGHT_PX}px`,
		maxWidth: "100%",
		display: "flex",
		alignItems: "center",
		gap: "var(--spacing-x2, 8px)"
	},
	image: {
		padding: 0,
		height: "100%"
	},
	accessRow: {
		display: "flex",
		alignItems: "flex-start",
		gap: "var(--spacing-x4, 16px)",
		width: "100%",
		flexDirection: "column",
		height: "100%"
	},
	container: {
		display: "flex",
		width: "100%",
		alignItems: "flex-start",
		gap: "var(--spacing-x2, 8px)",
		flexDirection: "column",
		height: "100%",
		overflow: "auto"
	},
	row: {
		display: "flex",
		width: "100%",
		flexDirection: "row",
		alignItems: "center",
		gap: "var(--spacing-x5, 20px)",
		height: "64px",
		minWidth: "1000px"
	},
	rowColumn: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		width: "100%",
		gap: "var(--spacing-x2, 8px)"
	},
	title: {
		width: "50%"
	},
	textRow: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "flex-start",
		width: "100%",
		minWidth: "1500px"
	},
	spinner: {
		padding: "var(--spacing-x1, 4px)",
		borderRadius: "var(--spacing-x1, 4px)"
	}
});
