import React, { useMemo } from "react";
import { ResourceEntity } from "components/common/entities";
import { getDynamicSizeIcon } from "components/ui/dynamicSizeIcon";
import { ResourcesIcon } from "components/ui/Icons/ResourcesIcon";
import { IntegrationResourceModel } from "models/IntegrationResourceModel";
import { OPTION_TOOLTIP_DELAY, SelectOption } from "../SelectOption";
import type { TOptionComponent } from "utils/ui/select";

const ResourceOption: TOptionComponent<IntegrationResourceModel> = ({
	disabled = false,
	isHighlighted = false,
	isSelected = false,
	onSelect,
	option,
	optionKey
}) => {
	const Icon = useMemo(() => {
		return option.integration?.imageUrl
			? getDynamicSizeIcon(<img src={option.integration?.imageUrl} />)
			: ResourcesIcon;
	}, [option.integration?.imageUrl]);

	return (
		<SelectOption
			PrefixIcon={Icon}
			disabled={disabled}
			isHighlighted={isHighlighted}
			isSelected={isSelected}
			key={optionKey}
			onSelect={onSelect}
			value={option}>
			<ResourceEntity
				delayShowTooltip={OPTION_TOOLTIP_DELAY}
				variant={isSelected ? "bold" : undefined}
				noWrap
				relative
				size="medium"
				resource={option}
			/>
		</SelectOption>
	);
};

ResourceOption.isSelectOption = true;

export { ResourceOption };
