import { List } from "immutable";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ExpiredIcon } from "components/ui/Icons/ExpiredIcon";
import { TokenIcon } from "components/ui/Icons/TokenIcon";
import { TColumn, VirtualTable } from "components/ui/VirtualTable";
import { HeaderCellContent } from "components/ui/VirtualTable/components";
import { usePersonalAccessTokensContext } from "context/personalAccessTokensContext";
import { useOpenGlobalErrorModal } from "hooks/useGlobalError";
import { ActionsCell } from "./components/ActionsCell";
import { ExpirationCell } from "./components/ExpirationCell";
import { TokenCell } from "./components/TokenCell";
import { TokenNameCell } from "./components/TokenNameCell";
import { checkIfTokenIsNew } from "../utils";
import type { TPersonalAccessToken } from "../PersonalAccessTokensTableSection";

type TPersonalAccessTokensTableProps = {
	personalAccessTokens?: TPersonalAccessToken[];
	setPersonalAccessTokens: (tokens: List<TPersonalAccessToken>) => void;
	setIsTokenNew: (isNew: boolean) => void;
	isTokenNew?: boolean;
};

const COLUMNS_WIDTHS = {
	name: "minmax(152px, 280px)",
	expiration: "182px",
	token: "minmax(124px, 1fr)",
	actions: "100px"
};
const PROFILE_PAGE_TRANSLATION_PREFIX = "pages.profile.changeProfileForm.personalAccessTokens";

export const PersonalAccessTokensTable: FC<TPersonalAccessTokensTableProps> = ({
	className,
	innerRef,
	personalAccessTokens,
	setIsTokenNew,
	setPersonalAccessTokens
}) => {
	const { t } = useTranslation();
	const [duration, setDuration] = useState<number | null>(null);
	const [newTokenName, setNewTokenName] = useState("");
	const [newTokenValue, setNewTokenValue] = useState<string>();
	const [error, setError] = useState(false);
	const openGlobalErrorModal = useOpenGlobalErrorModal();

	const {
		state: { isLoading },
		actions: { createToken }
	} = usePersonalAccessTokensContext();

	const resetNewTokenInputs = useCallback(() => {
		setNewTokenName("");
		setDuration(null);
		setIsTokenNew(false);
	}, [setIsTokenNew]);

	const handleTokenCreation = useCallback(async () => {
		if (newTokenName && duration) {
			try {
				const newToken = await createToken(newTokenName, duration > 0 ? duration : null);
				setNewTokenValue(newToken.value || "");
				resetNewTokenInputs();
			} catch (error) {
				openGlobalErrorModal(error as Error);
			}
		}
	}, [createToken, duration, newTokenName, openGlobalErrorModal, resetNewTokenInputs]);

	const handleDeleteNewRow = useCallback(() => {
		setPersonalAccessTokens(List(personalAccessTokens?.filter(token => !checkIfTokenIsNew(token))));
		resetNewTokenInputs();
	}, [personalAccessTokens, resetNewTokenInputs, setPersonalAccessTokens]);

	const setTokenNameError = useCallback((error: Error | null) => {
		setError(Boolean(error));
	}, []);

	const columns = useMemo(
		() =>
			[
				{
					renderCell: (row: TPersonalAccessToken) => (
						<TokenNameCell
							token={row}
							tokenName={checkIfTokenIsNew(row) ? newTokenName : row.name}
							setNewTokenName={setNewTokenName}
							setError={setTokenNameError}
						/>
					),
					header: <HeaderCellContent text={t(`${PROFILE_PAGE_TRANSLATION_PREFIX}.table.columns.tokenName`)} />,
					key: "name",
					width: COLUMNS_WIDTHS.name
				},
				{
					renderCell: (row: TPersonalAccessToken) => (
						<ExpirationCell token={row} duration={duration} setDuration={setDuration} />
					),

					header: (
						<HeaderCellContent
							text={t(`${PROFILE_PAGE_TRANSLATION_PREFIX}.table.columns.Expiration`)}
							icon={<ExpiredIcon />}
						/>
					),
					key: "expiration",
					width: COLUMNS_WIDTHS.expiration,
					overflow: true
				},
				{
					renderCell: (row: TPersonalAccessToken) => (
						<TokenCell token={row} newTokenValue={newTokenValue} setNewTokenValue={setNewTokenValue} />
					),
					header: (
						<HeaderCellContent
							text={t(`${PROFILE_PAGE_TRANSLATION_PREFIX}.table.columns.token`)}
							icon={<TokenIcon />}
						/>
					),
					key: "token",
					width: COLUMNS_WIDTHS.token
				},
				{
					renderCell: (row: TPersonalAccessToken) => (
						<ActionsCell
							token={row}
							handleDeleteNewRow={handleDeleteNewRow}
							handleTokenCreation={handleTokenCreation}
							setIsTokenNew={setIsTokenNew}
							disabled={!duration || !newTokenName || error}
						/>
					),
					key: "actions",
					width: COLUMNS_WIDTHS.actions
				}
			] as TColumn<TPersonalAccessToken>[],
		[
			duration,
			error,
			newTokenName,
			newTokenValue,
			handleDeleteNewRow,
			handleTokenCreation,
			t,
			setIsTokenNew,
			setTokenNameError
		]
	);

	return (
		<VirtualTable
			className={className}
			innerRef={innerRef}
			rows={personalAccessTokens || []}
			totalRows={personalAccessTokens?.length || 0}
			columns={columns}
			limitedHeight
			emptyTableMessage={t("pages.settings.tokens.noTokens")}
			isLoading={isLoading}
		/>
	);
};
