import { useMemo } from "react";
import type { TBaseEntityNodeProps } from "components/common/entities/types";

export type TEntityOverrideProps = {
	size: "medium" | "small";
	variant?: Exclude<TBaseEntityNodeProps["variant"], "link">;
	inactive?: boolean;
	withIcon: boolean;
};

export const useSelectLabel = <TSize extends "medium" | "small" | undefined>(
	disabled?: boolean,
	withIcon?: boolean,
	size?: TSize
) => {
	return useMemo<TEntityOverrideProps>(
		() => ({
			size: size ?? "medium",
			variant: "bold" as const,
			inactive: disabled,
			withIcon: withIcon ?? true
		}),
		[disabled, size, withIcon]
	);
};
