import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	centeredCellContent: {
		display: "flex",
		overflow: "hidden",
		gap: "var(--spacing-x2)",
		justifyContent: "center",
		alignItems: "center",
		width: "100%",
		height: "100%"
	}
});
