import React, { useMemo } from "react";
import { DirectoryGroupEntity } from "components/common/entities/DirectoryGroupEntity";
import { UserEntity } from "components/common/entities/UserEntity";
import { useDirectoryGroup } from "hooks/useDirectoryGroup";
import { useUser } from "hooks/useUser";
import { DirectoryGroupMaintainerModel } from "models/DirectoryGroupMaintainerModel";
import { DirectoryGroupModel } from "models/DirectoryGroupModel";
import { UserMaintainerModel } from "models/UserMaintainerModel";
import { UserModel } from "models/UserModel";
import { checkIfMaintainerIsNew } from "../../utils";
import { MaintainerSelect } from "../MaintainersSelect";
import type { TMaintainer } from "components/pages/IntegrationPage/components/MaintainersSection";

type TProps = {
	maintainer: TMaintainer;
	handleCreateMaintainer: (maintainer: UserModel | DirectoryGroupModel) => Promise<void>;
};

export const MaintainerCell: FC<TProps> = ({ maintainer, handleCreateMaintainer }) => {
	const isDirectoryGroupMaintainer = maintainer instanceof DirectoryGroupMaintainerModel;
	const isUserMaintainer = maintainer instanceof UserMaintainerModel;

	const directoryGroup = useDirectoryGroup(isDirectoryGroupMaintainer ? maintainer.directoryGroupId : "");
	const user = useUser(isUserMaintainer ? maintainer.userId : "");

	const maintainerElement = useMemo(() => {
		if (user) {
			return <UserEntity user={user} size="medium" withIcon />;
		} else if (directoryGroup) {
			return <DirectoryGroupEntity directoryGroup={directoryGroup} size="medium" withIcon />;
		}
		return null;
	}, [directoryGroup, user]);

	return !checkIfMaintainerIsNew(maintainer) ? (
		maintainerElement
	) : (
		<MaintainerSelect handleCreateMaintainer={handleCreateMaintainer} />
	);
};
