import { createUseStyles } from "react-jss";

export const DROPDOWN_DEFAULT_MAX_HEIGHT = 288;
export const useStyles = createUseStyles({
	container: {
		flexGrow: 1,
		width: "100%"
	},
	input: {
		"&:hover": {
			"& $suffixClear": {
				opacity: 1
			}
		}
	},
	labelContainer: {
		alignItems: "center",
		cursor: "pointer",
		display: "flex",
		flexDirection: "row",
		width: "100%",
		minWidth: "0",
		height: "100%"
	},
	maxHeight: {
		maxHeight: `${DROPDOWN_DEFAULT_MAX_HEIGHT}px`
	},
	suffix: {
		alignItems: "center",
		display: "flex",
		flexDirection: "row",
		gap: "var(--spacing-x1, 4px)",
		height: "100%",
		justifyContent: "center"
	},
	suffixClear: {},
	emptyDiv: {
		"&$emptyDiv": {
			color: "var(--color-grey-500)",
			"&$disabled": {
				color: "var(--color-grey-450)"
			}
		}
	},
	top: {},
	bottom: {},
	disabled: {}
});
