import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { GroupCard } from "components/common/GroupCard";
import { UserCard } from "components/common/UserCard";
import { Chip } from "components/ui/chips/Chip";
import { FilterExpression } from "components/ui/filters/FilterExpression";
import { GroupIcon } from "components/ui/Icons/GroupIcon";
import { UsersIcon } from "components/ui/Icons/UsersIcon";
import { DirectoryGroupNodeOption } from "components/ui/selectOptions/DirectoryGroupNodeOption";
import { TextOption } from "components/ui/selectOptions/TextOption";
import { UserNodeOption } from "components/ui/selectOptions/UserNodeOption";
import { IntegrationMaintainerFilter } from "filters/integration";
import { IntegrationResourceMaintainerFilter } from "filters/integrationResource";
import { useCustomerEntitiesSelect } from "hooks/useCustomerEntitiesSelect";
import { getLabel, type TOptionComponentProps } from "utils/ui/select";
import { useFilterFormExpression } from "./filter.hooks";
import type { DirectoryGroupModel } from "models/DirectoryGroupModel";
import type { UserModel } from "models/UserModel";
import type { TFilterOperator } from "types/filters";
import type { Constructor } from "types/utilTypes";

type TMaintainerFilters = IntegrationMaintainerFilter | IntegrationResourceMaintainerFilter;

type TMaintainerFilterProps = {
	defaultHasValue?: boolean;
	filter: TMaintainerFilters;
	onChange: (filter: TMaintainerFilters | undefined, isValid: boolean) => void;
};

function getFilter(filterName: TMaintainerFilters["name"]): Constructor<TMaintainerFilters> {
	switch (filterName) {
		case IntegrationMaintainerFilter.filterName:
			return IntegrationMaintainerFilter;
		default:
			return IntegrationResourceMaintainerFilter;
	}
}

const isValidOperator = (operator: TFilterOperator): operator is TMaintainerFilters["operator"] =>
	operator === "is" || operator === "isNot" || operator === "contains" || operator === "notContains";

const isSingleOperator = (operator: TFilterOperator): operator is "is" | "isNot" =>
	operator === "is" || operator === "isNot";

type TMaintainerOption = UserModel | DirectoryGroupModel;

const ANY_OPTION = "any";
const NONE_OPTION = "none";
const SINGLE_EXPRESSION_OPTIONS = [ANY_OPTION, NONE_OPTION] as (typeof ANY_OPTION | typeof NONE_OPTION)[];

type TSelectOption = TMaintainerOption | (typeof SINGLE_EXPRESSION_OPTIONS)[number];

const isUserModel = (option: TMaintainerOption): option is UserModel => "role" in option;

const OPERATORS = ["contains", "notContains", "is"] as const satisfies TFilterOperator[];

const cleanName = (name: string) => name.trim().toLowerCase();

export const MaintainerFilterExpression: FC<TMaintainerFilterProps> = ({
	className,
	innerRef,
	filter,
	onChange,
	defaultHasValue = false
}) => {
	const { t } = useTranslation("translation", { keyPrefix: "pages.bulkActions.filters" });
	const [hasValue, setHasValue] = useState(defaultHasValue);
	const [query, setQuery] = useState<string>();

	const { clearFilter, removeFilter } = useFilterFormExpression<TMaintainerFilters>({
		filterName: filter.name,
		onChange,
		getFilter
	});

	const { items, selectedItems, isLoading } = useCustomerEntitiesSelect(query || "", {
		entities: ["user", "directoryGroup"],
		selectedIdsByType: { user: filter.value || [], directoryGroup: filter.value || [] }
	});

	const maintainerOptions = useMemo(() => {
		return (items.toArray() as TMaintainerOption[]).filter(option =>
			cleanName(isUserModel(option) ? option.fullName || option.email : option.name).includes(cleanName(query || ""))
		);
	}, [items, query]);

	const selectedMaintainers = useMemo(() => selectedItems.toArray() as TMaintainerOption[], [selectedItems]);

	const onClearFilter = useCallback(() => {
		clearFilter(() => setHasValue(false));
	}, [clearFilter]);

	const onRemoveFilter = useCallback(() => {
		removeFilter(() => setHasValue(false));
	}, [removeFilter]);

	const onOperatorSelect = useCallback(
		(operator: TFilterOperator) => {
			if (!filter) return;
			if (!isValidOperator(operator)) return;
			if (isSingleOperator(operator) && !isSingleOperator(filter.operator)) {
				setHasValue(false);
				onChange(filter.set("operator", operator).set("value", null), false);
			} else if (isSingleOperator(filter.operator) && !isSingleOperator(operator)) {
				setHasValue(false);
				onChange(filter.set("operator", operator).set("value", []), false);
			} else {
				setHasValue(true);
				onChange(filter.set("operator", operator), filter.value === null || filter.value.length > 0);
			}
		},
		[filter, onChange]
	);

	const onOptionSelect = useCallback(
		(option: TSelectOption) => {
			if (!filter || typeof option === "undefined" || option === null) return;
			if (typeof option === "string") {
				const asOperator = option === ANY_OPTION ? "isNot" : "is";
				const didRemoveValue = hasValue && filter.value === null && filter.operator === asOperator;
				setHasValue(!didRemoveValue);
				const newFilter = filter.set("value", null).set("operator", asOperator);
				onChange(newFilter, !didRemoveValue);
			} else {
				const currentValue = filter.value || [];
				const newValue = currentValue.includes(option.id)
					? currentValue.filter(id => id !== option.id)
					: [...currentValue, option.id];
				setHasValue(!!newValue.length);
				onChange(filter.set("value", newValue), !!newValue.length);
			}
		},
		[filter, hasValue, onChange]
	);

	const getOptionRenderer = useCallback(
		(props: TOptionComponentProps<TSelectOption>) => {
			const option = props.option;
			if (typeof option === "string") {
				const getTextContent = () => t(`values.${option === ANY_OPTION ? "anyMaintainers" : "noMaintainers"}`);
				return <TextOption {...props} getTextContent={getTextContent} />;
			} else {
				return isUserModel(option) ? (
					<UserNodeOption {...(props as TOptionComponentProps<UserModel>)} />
				) : (
					<DirectoryGroupNodeOption {...(props as TOptionComponentProps<DirectoryGroupModel>)} />
				);
			}
		},
		[t]
	);

	const renderSelected = useCallback(
		(option: TSelectOption) => {
			if (typeof option === "string") {
				return (
					<Chip size="large" onDelete={() => onOptionSelect(option)} selected>
						{t(`values.${option === ANY_OPTION ? "anyMaintainers" : "noMaintainers"}`)}
					</Chip>
				);
			} else {
				return isUserModel(option) ? (
					<UserCard user={option} selected onDelete={() => onOptionSelect(option)} />
				) : (
					<GroupCard group={option} selected onDelete={() => onOptionSelect(option)} />
				);
			}
		},
		[onOptionSelect, t]
	);

	const filterProps = useMemo(() => {
		const isSingleSelect = isSingleOperator(filter.operator);
		const inputPlaceholder = t(`placeholders.${isSingleSelect ? "select" : "user"}`);
		const commonProps = {
			className,
			filter: null,
			getOptionLabel: getLabel,
			innerRef,
			inputPlaceholder,
			isLoading,
			onOperatorSelect,
			onOptionSelect,
			onRemoveFilter: onRemoveFilter,
			onReset: onClearFilter,
			operators: OPERATORS,
			getOperatorLabel: (operator: TFilterOperator) =>
				t(`operators.maintainers.${operator as (typeof OPERATORS)[number]}`),
			renderSelected,
			selectedOperator: (isSingleSelect ? "is" : filter.operator) as TFilterOperator,
			title: t(`title.${filter.name}`)
		};

		if (isSingleSelect) {
			const selected: TSelectOption | null = hasValue ? (filter.operator !== "is" ? ANY_OPTION : NONE_OPTION) : null;
			return {
				...commonProps,
				options: SINGLE_EXPRESSION_OPTIONS,
				hideInput: true,
				selected,
				type: "singleSelect" as const
			};
		} else {
			return {
				...commonProps,
				getIconForGroup: (groupName: string) => (groupName === t("values.users") ? UsersIcon : GroupIcon),
				getMoreOptions: setQuery,
				options: maintainerOptions,
				selected: selectedMaintainers,
				optionRenderer: getOptionRenderer,
				type: "multiSelect" as const,
				groupBy: (option: TSelectOption) =>
					typeof option !== "string" ? t(`values.${isUserModel(option) ? "users" : "groups"}`) : ""
			};
		}
	}, [
		filter.operator,
		filter.name,
		t,
		className,
		innerRef,
		isLoading,
		onOperatorSelect,
		onOptionSelect,
		onRemoveFilter,
		onClearFilter,
		renderSelected,
		hasValue,
		maintainerOptions,
		selectedMaintainers,
		getOptionRenderer
	]);

	return <FilterExpression {...filterProps} />;
};
