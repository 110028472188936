import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	labelContainer: {
		alignItems: "center",
		cursor: "pointer",
		display: "flex",
		flexDirection: "row",
		width: "100%",
		minWidth: "0",
		height: "100%",
		gap: "var(--spacing-x1, 4px)"
	},
	container: {
		flexGrow: 1
	},
	input: {
		"&:hover": {
			"& $suffixClear": {
				opacity: 1
			}
		}
	},
	inputContainer: {
		display: "grid",
		gridTemplateColumns: "auto minmax(40px, 1fr) auto"
	},
	selectItemsContainer: {
		backgroundColor: "var(--color-white)",
		borderRadius: "var(--border-radius-semi-rounded)",
		boxShadow: "var(--surface-popup-box-shadow)",
		boxSizing: "border-box",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		overflow: "auto",
		zIndex: "999"
	},
	suffix: {
		alignItems: "center",
		display: "flex",
		flexDirection: "row",
		gap: "var(--spacing-x1, 4px)",
		height: "100%",
		justifyContent: "center"
	},
	suffixClear: {
		opacity: 0,
		transition: "opacity 0.1s",
		willChange: "opacity"
	},
	emptyDiv: {
		"&$emptyDiv": {
			color: "var(--color-grey-500)",
			"&$disabled": {
				color: "var(--color-grey-450)"
			}
		}
	},
	disabled: {}
});
