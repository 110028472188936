import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IntegrationSelectInput } from "components/common/IntegrationSelectInput";
import { ResourceSelectInput } from "components/common/ResourceSelectInput";
import { RoleSelectInput } from "components/common/RoleSelectInput";
import { Button } from "components/ui/Button";
import { Modal } from "components/ui/Modal";
import { TitleBody } from "components/ui/TitleBody";
import { useIntegrations } from "hooks/useIntegrations";
import { IntegrationModel } from "models/IntegrationModel";
import { IntegrationResourceModel } from "models/IntegrationResourceModel";
import { IntegrationResourceRoleModel } from "models/IntegrationResourceRoleModel";
import { useStyles } from "./styles";

interface IProps {
	addResourcePermission: (roleId: string, resourceId: string) => Promise<void>;
	originResourceId: string;
	isLoading?: boolean;
	isOpen?: boolean;
	onClose: () => void;
}

export const GiveAccessModal: FC<IProps> = ({
	addResourcePermission,
	originResourceId,
	isLoading,
	isOpen = false,
	onClose
}) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const [integration, setIntegration] = useState<IntegrationModel | null>(null);
	const [integrationResource, setIntegrationResource] = useState<IntegrationResourceModel | null>(null);
	const [integrationResourceRole, setIntegrationResourceRole] = useState<IntegrationResourceRoleModel | null>(null);
	const integrations = useIntegrations();

	const resetForm = useCallback(() => {
		setIntegration(null);
		setIntegrationResource(null);
		setIntegrationResourceRole(null);
	}, []);

	const onSubmit = useCallback(async () => {
		if (!integrationResourceRole || !integrationResource) return;
		await addResourcePermission(integrationResourceRole.id, originResourceId);
		onClose();
		resetForm();
	}, [integrationResourceRole, integrationResource, addResourcePermission, originResourceId, onClose, resetForm]);

	const onChangeIntegration = useCallback((newIntegration: IntegrationModel | null) => {
		setIntegration(newIntegration);
		setIntegrationResource(null);
		setIntegrationResourceRole(null);
	}, []);

	const onChangeResource = useCallback((newResource: IntegrationResourceModel | null) => {
		setIntegrationResource(newResource);
		setIntegrationResourceRole(null);
	}, []);

	useEffect(() => {
		resetForm();
	}, [resetForm]);

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			actions={
				<Button onClick={onSubmit} loading={isLoading}>
					{t("buttons.save")}
				</Button>
			}
			className={classes.modalContainer}
			content={
				<div className={classes.modalContent}>
					<TitleBody
						size="large"
						title={t("pages.integrationResource.givesAccessModalTitle")}
						body={t("pages.integrationResource.givesAccessModalSubtitle")}
					/>
					<div className={classes.resourcePermissionForm}>
						<IntegrationSelectInput
							className={classes.input}
							label={t("common.integrationSelectInput.label")}
							onChange={onChangeIntegration}
							options={integrations}
							placeholder={t("common.integrationSelectInput.placeholder")}
							required
							value={integration}
						/>
						<ResourceSelectInput
							className={classes.input}
							inputLabel={t("common.resourceSelectInput.label")}
							onChange={onChangeResource}
							placeholder={t("common.resourceSelectInput.placeholder")}
							required
							selected={integrationResource}
							selectedIntegrationId={integration?.id || ""}
						/>
						<RoleSelectInput
							className={classes.input}
							disabled={!integration || !integrationResource}
							inputLabel={t("common.roleSelectInput.label")}
							integrationResourceId={integrationResource?.id}
							onChange={setIntegrationResourceRole}
							placeholder={t("common.roleSelectInput.placeholder")}
							required
							selectedRole={integrationResourceRole}
						/>
					</div>
				</div>
			}
		/>
	);
};
