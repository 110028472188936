import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	tooltip: {
		minWidth: "500px"
	},
	openMissingAccount: {
		color: "var(--color-grey-500)"
	},
	hide: {
		visibility: "hidden",
		opacity: 0
	}
});
