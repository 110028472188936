import React, { useCallback, useMemo } from "react";
import { AuditLog } from "components/pages/AuditLogsPage/components/AuditLog";
import { VirtualScroll } from "components/ui/VirtualScroll";
import { IntegrationAuditLogModel } from "models/auditLogs";

type TProps = {
	auditLogs: Immutable.List<IntegrationAuditLogModel> | null;
	fetchAuditLogs: () => Promise<void>;
	lastPageNumber: number;
	totalPages: number;
};

const AUDIT_LOG_ESTIMATED_HEIGHT = 90;

export const AuditLogsTable: FC<TProps> = ({
	className,
	innerRef,
	auditLogs,
	lastPageNumber,
	totalPages,
	fetchAuditLogs
}) => {
	const renderAuditLog = useCallback(
		(auditLog: IntegrationAuditLogModel) => <AuditLog key={auditLog.id} auditLog={auditLog} timezone="local" />,
		[]
	);

	const auditLogArray = useMemo(() => auditLogs?.toArray() || [], [auditLogs]);

	return (
		<VirtualScroll
			className={className}
			innerRef={innerRef}
			estimateSize={AUDIT_LOG_ESTIMATED_HEIGHT}
			rows={auditLogArray}
			renderRow={renderAuditLog}
			canFetchMore={lastPageNumber < totalPages}
			fetchMore={fetchAuditLogs}
		/>
	);
};
