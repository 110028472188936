import isNil from "lodash/isNil";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { InheritWorkflowChip, WorkflowChip } from "components/ui/chips/WorkflowChip/WorkflowChip";
import { WorkflowOption } from "components/ui/selectOptions/WorkflowOption";
import { useApprovalAlgorithms } from "hooks/useApprovalAlgorithms";
import { useStaticOptions } from "hooks/useStaticOptions";
import { ApprovalAlgorithmModel } from "models/ApprovalAlgorithmModel";
import { INHERIT, type TInherit } from "utils/ui/select";
import { useStyles } from "./styles";
import { TUpdateExpressionProps } from "../../updates.types";
import { SingleSelectAttributeExpression } from "../SingleSelectAttributeExpression";

const ATTRIBUTE = "approvalAlgorithmId";

type TOption = ApprovalAlgorithmModel | TInherit;

const getWorkflowKey = (workflow: ApprovalAlgorithmModel | TInherit): string =>
	workflow === INHERIT ? INHERIT : workflow.id;
const STATIC_OPTIONS = [INHERIT as TInherit];

export const WorkflowAttributeExpression: FC<TUpdateExpressionProps<"approvalAlgorithmId">> = ({
	innerRef,
	selected,
	ruleType,
	onRemoveAttribute,
	setUpdatesAttribute
}) => {
	const classes = useStyles();
	const { t } = useTranslation("translation", { keyPrefix: "pages.rules.updates" });
	const { t: sharedTranslation } = useTranslation("translation", { keyPrefix: "shared" });

	const onReset = useCallback(() => {
		setUpdatesAttribute(ATTRIBUTE, undefined);
	}, [setUpdatesAttribute]);

	const onOptionSelect = useCallback(
		(value: TOption) => setUpdatesAttribute(ATTRIBUTE, value === INHERIT ? null : value.id),
		[setUpdatesAttribute]
	);

	const renderSelected = useCallback(
		(value: TOption) =>
			value === INHERIT ? (
				<InheritWorkflowChip
					noTooltip
					size="large"
					variant="regular"
					selected
					onDelete={onReset}
					from={ruleType === "resources" ? "integration" : "resource"}
				/>
			) : (
				<WorkflowChip size="large" variant="regular" value={value} selected onDelete={onReset} />
			),
		[onReset, ruleType]
	);

	const approvalAlgorithms = useApprovalAlgorithms();

	const approvalAlgorithmsOptions = useMemo(() => approvalAlgorithms?.valueSeq().toArray() ?? [], [approvalAlgorithms]);
	const { options, groupBy } = useStaticOptions(approvalAlgorithmsOptions, getWorkflowKey, STATIC_OPTIONS);

	const filterOptions = useCallback(
		(options: TOption[], inputValue: string) =>
			options.filter(option => option !== INHERIT && option.name.toLowerCase().includes(inputValue.toLowerCase())),
		[]
	);

	const selectedValue = useMemo(
		() =>
			isNil(selected) ? (selected === null ? INHERIT : undefined) : (approvalAlgorithms?.get(selected) ?? undefined),
		[selected, approvalAlgorithms]
	);

	const getOptionLabel = useCallback(
		(option: ApprovalAlgorithmModel | TInherit): string => {
			if (option !== INHERIT) return option.name;
			return sharedTranslation(ruleType === "resources" ? "integrationDefault" : "resourceDefault");
		},
		[ruleType, sharedTranslation]
	);

	return (
		<SingleSelectAttributeExpression
			emptyState={<div className={classes.emptyState} />}
			filter={filterOptions}
			getOptionLabel={getOptionLabel}
			groupBy={groupBy}
			innerRef={innerRef}
			inputPlaceholder={t(`placeholders.${ATTRIBUTE}`)}
			onOptionSelect={onOptionSelect}
			onRemoveAttribute={onRemoveAttribute}
			onReset={onReset}
			options={options}
			optionRenderer={WorkflowOption}
			renderSelected={renderSelected}
			selected={selectedValue}
			sort={null}
			title={t(`${ruleType}.${ATTRIBUTE}`)}
		/>
	);
};
