import classNames from "classnames";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "components/ui/Button";
import { Divider } from "components/ui/Divider";
import { LoadingSpinner } from "components/ui/LoadingSpinner";
import { Modal } from "components/ui/Modal";
import { TitleBody } from "components/ui/TitleBody";
import { VirtualScroll } from "components/ui/VirtualScroll";
import { useIntegrations } from "hooks/useIntegrations";
import { useLoadingState } from "hooks/useLoadingState";
import { useStyles } from "./styles";
import { AreYouSureModal } from "../AreYouSureModal";
import { AccountEntity } from "../entities/AccountEntity";
import { RoleBar, type TRoleBarColumn } from "../RoleBar";
import { RoleBarIntegration, ROLE_BAR_INTEGRATION_WIDTH } from "../RoleBar/components/RoleBarIntegration";
import { RoleBarResource, ROLE_BAR_RESOURCE_WIDTH } from "../RoleBar/components/RoleBarResource";
import { RoleBarRole, ROLE_BAR_ROLE_WIDTH } from "../RoleBar/components/RoleBarRole";
import type { List } from "immutable";
import type { TResourcePermission } from "context/resourcePermissionReviewContext";
import type { TSubordinatePermission } from "context/subordinatePermissionReviewContext";
import type { TAccessReviewPermissionModel } from "models/AccessReviewPermissionModel";

type TPermission = TResourcePermission | TSubordinatePermission | TAccessReviewPermissionModel;

interface IProps {
	isOpen: boolean;
	paginationOptions?: {
		lastPageNumber: number;
		isLoading: boolean;
		totalPages: number;
		getPage: (page: number) => Promise<void>;
	};
	permissions: List<TPermission>;
	managedPermissionsCount?: number;
	unmanagedPermissionsCount?: number;
	onClose: () => void;
	onAction: () => Promise<void>;
}

const ROLE_BAR_ACCOUNT_WIDTH = "minmax(164px, 400px)";

const PermissionRoleBar: FC<{ permission: TPermission }> = ({ permission }) => {
	const integrations = useIntegrations();
	// Override attributes in order to keep the access review permissions "frozen" to the access review creation date state
	const role = useMemo(
		() =>
			permission
				.integrationActorPermission!.integrationResourceRole!.set("managed", permission.roleManaged)
				.set("name", permission.roleName),
		[permission.integrationActorPermission, permission.roleManaged, permission.roleName]
	);
	const actor = permission.integrationActorPermission!.integrationActor!;
	const resource = role.integrationResource!;
	const integration = integrations?.get(resource.integrationId || "");

	const roleBarColumns = useMemo(() => {
		const columns = [
			{
				content: (
					<RoleBarResource
						name={resource.name}
						euid={resource.euid}
						description={resource.description || undefined}
						tags={resource.calculatedTags}
						type={resource.type}
					/>
				),
				width: ROLE_BAR_RESOURCE_WIDTH
			},
			{
				content: <RoleBarRole role={role} />,
				width: ROLE_BAR_ROLE_WIDTH
			},
			{
				content: actor ? <AccountEntity actor={actor} size="medium" withIcon /> : null,
				width: ROLE_BAR_ACCOUNT_WIDTH
			}
		] as TRoleBarColumn[];

		if (integration) {
			columns.unshift({
				content: <RoleBarIntegration name={integration.name} imageUrl={integration.imageUrl} />,
				width: ROLE_BAR_INTEGRATION_WIDTH
			});
		}
		return columns;
	}, [
		actor,
		integration,
		resource.calculatedTags,
		resource.description,
		resource.euid,
		resource.name,
		resource.type,
		role
	]);

	return <RoleBar key={permission.id} columns={roleBarColumns} noInteraction />;
};

const TRANSLATION_PREFIX = "common.revokeModal";
const ROLE_BAR_ESTIMATED_HEIGHT = 55;
const MAXIMUM_SHOWN_PERMISSIONS = 6.5;

export const RevokeModal: FC<IProps> = ({
	className,
	isOpen,
	paginationOptions,
	onClose,
	onAction: propOnAction,
	permissions,
	managedPermissionsCount: propManagedPermissionsCount,
	unmanagedPermissionsCount: propUnmanagedPermissionsCount
}) => {
	const { t } = useTranslation("translation", { keyPrefix: TRANSLATION_PREFIX });
	const { t: globalTranslation } = useTranslation();
	const { isLoading, withLoader } = useLoadingState();
	const { isLoading: isLoadingOnAction, withLoader: withLoaderOnAction } = useLoadingState();

	const [unmanagedPermissions, managedPermissions] = useMemo(
		() => permissions.partition(permission => permission.roleManaged),
		[permissions]
	);

	const managedPermissionsCount = propManagedPermissionsCount || managedPermissions.size;
	const unmanagedPermissionsCount = propUnmanagedPermissionsCount || unmanagedPermissions.size;
	const isOnlyUnmanagedPermissions = managedPermissionsCount + unmanagedPermissionsCount === unmanagedPermissionsCount;
	const isSingleManagedPermission = managedPermissionsCount === 1 && unmanagedPermissionsCount === 0;
	const isMixedPermissions = managedPermissionsCount && unmanagedPermissionsCount;

	const unmanagedPermissionsArray = useMemo(() => unmanagedPermissions.toArray(), [unmanagedPermissions]);
	const unmanagedRoleListHeight =
		ROLE_BAR_ESTIMATED_HEIGHT * Math.min(MAXIMUM_SHOWN_PERMISSIONS, unmanagedPermissionsArray.length);

	const classes = useStyles({ unmanagedRoleListHeight });

	const renderPermissionRoleBar = useCallback(
		(permission: TPermission) => <PermissionRoleBar key={permission.id} permission={permission} />,
		[]
	);

	const isLoadingPage = paginationOptions?.isLoading || isLoading;
	const lastPageNumber = paginationOptions?.lastPageNumber || 0;
	const totalPages = paginationOptions?.totalPages || 0;

	const getNextPage = useCallback(() => {
		if (isLoadingPage || !paginationOptions) return;

		void withLoader(paginationOptions.getPage(lastPageNumber + 1));
	}, [isLoadingPage, lastPageNumber, paginationOptions, withLoader]);

	const onAction = useCallback(async () => {
		await withLoaderOnAction(propOnAction());
	}, [propOnAction, withLoaderOnAction]);

	const modalContent = useMemo(() => {
		if (isSingleManagedPermission) return;
		return (
			<div className={classes.content}>
				{managedPermissionsCount > 0 && (
					<div>
						<TitleBody
							size="large"
							title={t("permissionsRevokeTitle", { count: managedPermissionsCount })}
							body={t("permissionsRevokeContent")}
						/>
					</div>
				)}
				{unmanagedPermissionsCount > 0 && (
					<>
						{managedPermissionsCount > 0 && <Divider className={classes.divider} horizontal />}
						<TitleBody
							size="large"
							title={t("title", { count: unmanagedPermissionsCount })}
							body={t("content", { count: unmanagedPermissionsCount })}
						/>
						<div className={classes.unmanagedRolesWrapper}>
							{unmanagedPermissionsArray.length > 0 && (
								<VirtualScroll
									className={classes.unmanagedRolesList}
									estimateSize={ROLE_BAR_ESTIMATED_HEIGHT}
									rows={unmanagedPermissionsArray}
									renderRow={renderPermissionRoleBar}
									canFetchMore={lastPageNumber < totalPages}
									fetchMore={getNextPage}
								/>
							)}
							{isLoadingPage && <LoadingSpinner className={classes.loading} />}
						</div>
					</>
				)}
			</div>
		);
	}, [
		isSingleManagedPermission,
		classes.content,
		classes.divider,
		classes.unmanagedRolesWrapper,
		classes.unmanagedRolesList,
		classes.loading,
		managedPermissionsCount,
		t,
		unmanagedPermissionsCount,
		unmanagedPermissionsArray,
		renderPermissionRoleBar,
		lastPageNumber,
		totalPages,
		getNextPage,
		isLoadingPage
	]);

	const modalActions = useMemo(() => {
		if (isSingleManagedPermission) return;
		return (
			<>
				<Button variant="secondary" size="medium" onClick={onClose}>
					{globalTranslation("buttons.cancel")}
				</Button>
				<Button size="medium" onClick={onAction} loading={isLoadingOnAction}>
					{isOnlyUnmanagedPermissions
						? t("continueReview")
						: isMixedPermissions
							? t("revokeAndCompleteReview")
							: t("revokePermissions")}
				</Button>
			</>
		);
	}, [
		isSingleManagedPermission,
		globalTranslation,
		onAction,
		onClose,
		isLoadingOnAction,
		isOnlyUnmanagedPermissions,
		t,
		isMixedPermissions
	]);

	return isSingleManagedPermission ? (
		<AreYouSureModal
			onClose={onClose}
			isOpen={isOpen}
			onAction={propOnAction}
			actionLabel={globalTranslation("buttons.revoke")}
			title={t("immediateRevokeTitle")}
			content={t("immediateRevokeContent")}
		/>
	) : (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			className={classNames(classes.modal, className, { [classes.withoutUnmanaged]: !unmanagedPermissionsCount })}
			actions={modalActions}
			content={modalContent}
		/>
	);
};
