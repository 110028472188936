import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Select } from "components/ui/Select";
import { TextOption } from "components/ui/selectOptions/TextOption";
import useMountState from "hooks/useMountState";
import { API_TOKEN_DURATION_OPTIONS, TApiTokenDuration } from "utils/tokenDurations";

interface IProps {
	onChange: (value: number | null) => void;
	value: number | string | null;
}

const toDurationNumber = (value: number | string | null): number => {
	if (typeof value === "number") {
		return value;
	}
	if (typeof value === "string") {
		return parseInt(value, 10);
	}
	return -1;
};

export const TokenDurationSelectInput: FC<IProps> = ({ onChange, className, value }) => {
	const { t } = useTranslation();
	const [_, ifMounted] = useMountState();

	const getLabel = useCallback((option: TApiTokenDuration) => t(`common.durations.${option}`), [t]);
	const handleChange = useCallback(
		(option: TApiTokenDuration | string | null) => {
			if (option) {
				onChange(toDurationNumber(option));
			}
		},
		[onChange]
	);

	const options = useMemo(() => API_TOKEN_DURATION_OPTIONS.sort((a, b) => a - b) || [], []);

	const duration = useMemo(
		() => API_TOKEN_DURATION_OPTIONS.find(duration => duration === toDurationNumber(value)) || null,
		[value]
	);

	useEffect(() => {
		if (options.length === 1) {
			ifMounted(() => handleChange(options[0]));
		}
	}, [handleChange, ifMounted, options]);

	return (
		<Select
			className={className}
			disabled={options.length === 1}
			noClear
			getOptionLabel={getLabel}
			onChange={handleChange}
			renderOption={TextOption}
			options={options}
			placeholder={t("pages.settings.tokens.durationPlaceholder")}
			required
			value={duration}
			variant="table"
		/>
	);
};
