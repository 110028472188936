import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	settingsModal: {
		maxWidth: "50rem",
		maxHeight: "55rem"
	},
	deleteButtonContainer: {
		marginTop: "var(--spacing-x4, 16px)"
	},
	deleteButtonDivider: {
		marginTop: "var(--spacing-x4, 16px)"
	}
});
