import classNames from "classnames";
import { List } from "immutable";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { CommonAccordion } from "components/common/CommonAccordion";
import { Button } from "components/ui/Button";
import { IconButton } from "components/ui/IconButton";
import { IconPrefix } from "components/ui/IconPrefix";
import { AddIcon } from "components/ui/Icons/AddIcon";
import { GiveAccessToIcon } from "components/ui/Icons/GiveAccessToIcon";
import { InfoIcon } from "components/ui/Icons/InfoIcon";
import { Tooltip } from "components/ui/Tooltip";
import { Typography } from "components/ui/Typography";
import { IntegrationResourceRoleModel } from "models/IntegrationResourceRoleModel";
import { GivesAccessBar } from "./components/GivesAccessBar";
import { useStyles } from "./styles";

interface IProps {
	givesAccessRoles: List<IntegrationResourceRoleModel> | null;
	openGivesAccessToModal?: (e: React.MouseEvent<HTMLElement>) => void;
	manual?: boolean;
	onDelete?: (roleId: string) => Promise<void>;
}

export const GiveAccessSection: FC<IProps> = ({
	givesAccessRoles,
	openGivesAccessToModal,
	manual = false,
	onDelete
}) => {
	const classes = useStyles();
	const { t } = useTranslation();

	const title = useMemo(
		() => (
			<div className={classes.titleContainer}>
				<div className={classNames(classes.titlePart, classes.titleHeaderPart)}>
					<IconPrefix Icon={GiveAccessToIcon} content={t("pages.integrationResource.giveAccessAutomatically")} />
					<Typography
						className={classes.childrenCount}
						variant="body_reg">{`( ${t("number", { value: givesAccessRoles?.size || 0 })} )`}</Typography>
					<Tooltip
						placement="top-start"
						content={
							<Typography variant="text_sm_reg">{t(`pages.integrationResource.givesAccessTooltipText`)}</Typography>
						}>
						<IconButton>
							<InfoIcon />
						</IconButton>
					</Tooltip>
				</div>
				{manual ? (
					<div className={classes.titlePart}>
						<Button onClick={openGivesAccessToModal} prefix={<AddIcon />} variant="secondary" size="medium">
							{t("buttons.add")}
						</Button>
					</div>
				) : null}
			</div>
		),
		[
			classes.childrenCount,
			classes.titleContainer,
			classes.titlePart,
			classes.titleHeaderPart,
			givesAccessRoles?.size,
			manual,
			openGivesAccessToModal,
			t
		]
	);

	return (
		<CommonAccordion title={title}>
			<div className={classes.roleContainer}>
				{givesAccessRoles && givesAccessRoles?.size > 0 && (
					<Typography variant="body_sb">{t("common.tables.integrationResources.giveAccessRoleTitle")}</Typography>
				)}
				{givesAccessRoles?.map(role => (
					<GivesAccessBar key={role.id} role={role} manual={manual} onDelete={onDelete} />
				))}
			</div>
		</CommonAccordion>
	);
};
