import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	header: {
		alignItems: "flex-end",
		display: "flex",
		flexDirection: "row",
		gap: "12px",
		justifyContent: "flex-start",
		marginTop: "20px"
	},
	headerForm: {
		alignItems: "center",
		display: "flex",
		flexDirection: "row",
		flexGrow: "1",
		flexWrap: "wrap",
		gap: "12px",
		padding: "0",
		"& > $field": {
			marginTop: 0,
			flex: "1 1 0"
		}
	},
	field: {},
	select: {
		maxWidth: "100%",
		minWidth: "210px",
		flexGrow: 1,
		width: "100%"
	},
	table: {
		paddingTop: "20px",
		height: "100%"
	},
	scrollable: {
		overflowX: "auto",
		"&$scrollable$scrollable$scrollable > *": {
			minWidth: "min(1386px, 100%)"
		}
	}
});
