import React from "react";
import { TooltipOnOverflow } from "components/ui/TooltipOnOverflow";
import { SelectOption } from "../SelectOption";
import type { TOptionComponentProps } from "utils/ui/select";

function TextOption<T = string>({
	PrefixIcon,
	className,
	disabled = false,
	getTextContent,
	isHighlighted = false,
	isSelected = false,
	onSelect,
	option,
	optionKey
}: TOptionComponentProps<T>) {
	const textValue = getTextContent ? getTextContent(option) : String(option);
	return (
		<SelectOption
			PrefixIcon={PrefixIcon}
			className={className}
			disabled={disabled}
			isHighlighted={isHighlighted}
			isSelected={isSelected}
			key={optionKey}
			onSelect={onSelect}
			value={option}>
			<TooltipOnOverflow textVariant={isSelected ? "body_sb" : "body_reg"} content={textValue} />
		</SelectOption>
	);
}

TextOption.isSelectOption = true;

export { TextOption };
