import classNames from "classnames";
import React, { forwardRef, useCallback, useImperativeHandle, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useResizeDetector } from "react-resize-detector";
import { Button } from "components/ui/Button";
import { Form } from "components/ui/Form";
import { IconButton } from "components/ui/IconButton";
import { CloseIcon } from "components/ui/Icons/CloseIcon";
import { SettingsIcon } from "components/ui/Icons/SettingsIcon";
import { Tooltip } from "components/ui/Tooltip";
import { useIsOpenState } from "hooks/useIsOpenState";
import { useLoadingState } from "hooks/useLoadingState";
import { useOnEscKeyDownEvent } from "hooks/useOnEscKeyDownEvent";
import { useStyles } from "./styles";

interface IProps {
	onSave: () => Promise<boolean | void>;
	onClose?: () => void;
	disable?: boolean;
}

// The approximate distance of the tooltip from the top of the screen, will be switched with the actual distance
const APPROXIMATE_HEIGHT_FROM_TOP = 160;
// Extra padding from bottom just to look nice and being able to see the shadow
const EXTRA_PADDING_FROM_BOTTOM = 10;

export type TSettingsPopupRef = {
	closePopup: () => void;
};

export const SettingsPopup: FC<IProps & { innerRef?: React.RefObject<TSettingsPopupRef> }> = forwardRef(
	function SettingsPopup({ onClose, onSave, children, className, disable = false, innerRef }) {
		const classes = useStyles();
		const { t } = useTranslation();
		const { withLoader, isLoading } = useLoadingState();
		const { close, isOpen, open } = useIsOpenState();
		const tooltipRef = useRef<HTMLDivElement>(null);
		const [distanceFromTop, setDistanceFromTop] = useState<number>(APPROXIMATE_HEIGHT_FROM_TOP);
		const maxHeight = useMemo(
			() => window.innerHeight - distanceFromTop - EXTRA_PADDING_FROM_BOTTOM,
			[distanceFromTop]
		);
		useResizeDetector({
			targetRef: tooltipRef,
			onResize: () => setDistanceFromTop(tooltipRef.current?.getBoundingClientRect().y || APPROXIMATE_HEIGHT_FROM_TOP)
		});

		const submit = useCallback(async () => {
			const result = await withLoader(onSave());
			if (result !== false) {
				close();
			}
		}, [close, onSave, withLoader]);

		const handleClose = useCallback(() => {
			onClose?.();
			close();
		}, [close, onClose]);

		useOnEscKeyDownEvent(handleClose);

		useImperativeHandle(innerRef, () => ({
			closePopup() {
				close();
			}
		}));

		const toggle = useCallback(() => {
			if (isOpen) {
				handleClose();
			} else {
				open();
			}
		}, [handleClose, isOpen, open]);

		const tooltipContent = useMemo(
			() => (
				<div className={classNames(classes.settingsPopup, className)} style={{ maxHeight }}>
					<div className={classes.actions}>
						<IconButton size="large" onClick={close}>
							<CloseIcon />
						</IconButton>
					</div>
					<div className={classes.modalContent}>
						<Form>{children}</Form>
					</div>
					<Form.Actions className={classes.actions}>
						<Button
							variant="primary"
							type="submit"
							size="medium"
							disabled={isLoading || disable}
							loading={isLoading}
							onClick={submit}>
							{t("buttons.save")}
						</Button>
					</Form.Actions>
				</div>
			),
			[
				children,
				className,
				classes.actions,
				classes.modalContent,
				classes.settingsPopup,
				close,
				disable,
				isLoading,
				maxHeight,
				submit,
				t
			]
		);

		return (
			<Tooltip
				className={classes.settingsTooltip}
				visible={isOpen}
				content={tooltipContent}
				placement="bottom-start"
				tooltipRef={tooltipRef}
				clean>
				<Button variant="secondary" size="medium" prefix={<SettingsIcon />} onClick={toggle}>
					{t("pages.integration.settings")}
				</Button>
			</Tooltip>
		);
	}
);
