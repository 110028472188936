import React, { useCallback, useRef, useEffect } from "react";
import { useThrottleFn } from "./useThrottle";

const THRESHOLD = 0.8;

type TInfiniteScrollOptions = {
	scrollableRef: React.RefObject<HTMLElement> | React.MutableRefObject<HTMLElement>;
	fetch: () => void | Promise<void>;
	canFetch: boolean;
};

export const useInfiniteScroll = ({ scrollableRef, fetch, canFetch }: TInfiniteScrollOptions) => {
	const isFetching = useRef(false);

	const wrappedFetch = useCallback(async () => {
		if (isFetching.current || !canFetch) return;
		isFetching.current = true;
		await fetch();
		isFetching.current = false;
	}, [canFetch, fetch]);

	const onScroll = useCallback(
		(event: Event) => {
			if (isFetching.current || !canFetch) return;
			const element = event.target as HTMLElement;
			if (!element || !("clientHeight" in element)) return;
			const sizeFromTop = element.clientHeight + element.scrollTop;
			const thresholdSize = THRESHOLD * element.scrollHeight;
			const reachedBottom = sizeFromTop >= thresholdSize;
			if (reachedBottom) {
				void wrappedFetch();
			}
		},
		[canFetch, wrappedFetch]
	);

	const throttledOnScroll = useThrottleFn(onScroll, 150);

	useEffect(() => {
		if (!scrollableRef || !scrollableRef.current) return;
		const scrollable = scrollableRef.current;
		if (!canFetch) {
			scrollable.removeEventListener("scroll", throttledOnScroll);
		} else {
			scrollable.addEventListener("wheel", throttledOnScroll);
		}

		return () => {
			scrollable.removeEventListener("wheel", throttledOnScroll);
		};
	}, [canFetch, scrollableRef, throttledOnScroll]);
};
