import constate from "constate";
import { List, Map } from "immutable";
import { useCallback, useMemo, useState } from "react";
import { useAuthenticatedUser } from "hooks/useAuthenticatedUser";
import { useAvailableDurations } from "hooks/useAvailableDurations";
import { useUser } from "hooks/useUser";
import { UserModel } from "models/UserModel";
import { devLog } from "utils/devtools/devLogging";
import { safeMerge } from "utils/security";
import type { TTicketDuration } from "utils/durationsOptions";
import type { TRequestTarget, TPrePopulatedFormData } from "./types";

const useNewRequestForm = () => {
	const { user: currentUser } = useAuthenticatedUser();
	const [receiverUser, setReceiverUser] = useState<UserModel | null>(currentUser);
	const fullReceiverUser = useUser(receiverUser?.id, true, true);
	const [justification, setJustification] = useState("");
	const [ticketingIntegrationTicketId, setTicketingIntegrationTicketId] = useState<string | null>(null);
	const [duration, setDuration] = useState<TTicketDuration | null>();
	const [requestTargets, setRequestTargets] = useState(List<TRequestTarget>());
	const [receiverIntegrationActors, setReceiverIntegrationActors] = useState(Map<string, string>());
	const { durations } = useAvailableDurations(requestTargets, receiverUser);

	const resetState = useCallback(
		(resetUser = true) => {
			setJustification("");
			setTicketingIntegrationTicketId(null);
			setDuration(null);
			setRequestTargets(List());
			setReceiverIntegrationActors(Map());
			if (resetUser) setReceiverUser(currentUser);
		},
		[currentUser]
	);

	const changeReceiverUser = useCallback(
		(user: UserModel | null, reset = true) => {
			setReceiverUser(user ?? currentUser);
			if (!reset) return;
			resetState(false);
		},
		[currentUser, resetState]
	);

	const changeJustification = useCallback((justification: string) => {
		setJustification(justification);
	}, []);

	const changeTicketingIntegrationTicketId = useCallback((ticketId: string | null) => {
		setTicketingIntegrationTicketId(ticketId);
	}, []);

	const changeDuration = useCallback(
		(duration: TTicketDuration | null) => {
			if (!durations.some(allowedDuration => duration === allowedDuration)) return;
			setDuration(duration);
		},
		[durations]
	);

	const addTarget = useCallback((target: TRequestTarget, resetDuration = true) => {
		setRequestTargets(current => {
			if (current.some(t => t.id === target.id)) {
				devLog({ message: "Trying to add a target that already exists", level: "warn" });
				return current;
			}
			return current.push(target);
		});
		if (resetDuration) {
			setDuration(null);
		}
	}, []);

	const removeTarget = useCallback((targetId: string) => {
		let remaining = 0;
		setRequestTargets(current => {
			const remaningTargets = current.filter(target => target.id !== targetId);
			remaining = remaningTargets.size;
			return remaningTargets;
		});
		if (!remaining) {
			setDuration(null);
		}
	}, []);

	const toggleTarget = useCallback(
		(target: TRequestTarget) => {
			if (!target) return;
			const resource = target.type === "role" && target.fullTarget.integrationResource;
			if (resource && !resource.multirole) {
				requestTargets
					.filter(target => target.type === "role" && target.resourceId === resource.id)
					.forEach(target => removeTarget(target.id));
			}
			if (requestTargets.some(currentTarget => currentTarget.id === target.id)) {
				removeTarget(target.id);
			} else {
				addTarget(target);
			}
		},
		[addTarget, removeTarget, requestTargets]
	);

	const updateTarget = useCallback((targetId: string, updateFields: Partial<TRequestTarget>) => {
		setRequestTargets(current => {
			const targetIndex = current.findIndex(target => target.id === targetId);
			if (targetIndex === -1) return current;
			const currentTarget = current.get(targetIndex)!;
			const mergedTarget = safeMerge(currentTarget, updateFields) as TRequestTarget;
			return current.set(targetIndex, mergedTarget);
		});
		setDuration(null);
	}, []);

	const addReceiverIntegrationActor = useCallback((integrationId: string, actorId: string) => {
		setReceiverIntegrationActors(current => current.set(integrationId, actorId));
	}, []);

	const removeReceiverIntegrationActor = useCallback((integrationId: string) => {
		setReceiverIntegrationActors(current => current.delete(integrationId));
	}, []);

	const isFormValid = useMemo(() => {
		return !!fullReceiverUser && !!justification && !!duration && !!requestTargets.size;
	}, [duration, fullReceiverUser, justification, requestTargets.size]);

	const prePopulateForm = useCallback(
		(form: TPrePopulatedFormData) => {
			const { receiverUser, duration, justification, ticketingIntegrationTicketId, targets } = form;
			if (receiverUser) changeReceiverUser(receiverUser, false);
			if (ticketingIntegrationTicketId) changeTicketingIntegrationTicketId(ticketingIntegrationTicketId);
			if (justification) changeJustification(justification);
			if (targets) setRequestTargets(List(targets));
			if (duration) changeDuration(duration);
		},
		[changeDuration, changeJustification, changeReceiverUser, changeTicketingIntegrationTicketId]
	);

	return useMemo(
		() => ({
			state: {
				currentUser,
				duration,
				fullReceiverUser,
				isFormValid,
				justification,
				receiverIntegrationActors,
				receiverUser,
				requestTargets,
				ticketingIntegrationTicketId
			},
			actions: {
				addReceiverIntegrationActor,
				removeReceiverIntegrationActor,
				addTarget,
				toggleTarget,
				changeDuration,
				changeJustification,
				changeReceiverUser,
				changeTicketingIntegrationTicketId,
				removeTarget,
				updateTarget,
				resetState,
				prePopulateForm
			}
		}),
		[
			currentUser,
			duration,
			fullReceiverUser,
			isFormValid,
			justification,
			receiverIntegrationActors,
			receiverUser,
			requestTargets,
			ticketingIntegrationTicketId,
			addReceiverIntegrationActor,
			removeReceiverIntegrationActor,
			addTarget,
			toggleTarget,
			changeDuration,
			changeJustification,
			changeReceiverUser,
			changeTicketingIntegrationTicketId,
			removeTarget,
			updateTarget,
			resetState,
			prePopulateForm
		]
	);
};

export const [NewRequestFormProvider, useNewRequestFormContext] = constate(useNewRequestForm);
