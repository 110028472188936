import classNames from "classnames";
import React, { useCallback, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { EditableInput } from "components/common/EditableInput";
import { LogoAvatar } from "components/common/LogoAvatar";
import { UserTooltip } from "components/common/UserTooltip";
import { InfoIcon } from "components/ui/Icons/InfoIcon";
import { Typography } from "components/ui/legacy/Typography";
import { Chips } from "components/ui/MultipleSelect/components/Chips";
import { Separator } from "components/ui/Separator";
import { Tooltip } from "components/ui/Tooltip";
import { useIntegrations } from "hooks/useIntegrations";
import { useUser } from "hooks/useUser";
import { UserModel } from "models/UserModel";
import { getNameValidators } from "utils/validation/validationUtils";
import { useStyles } from "./styles";
import { UserEntity } from "../entities/UserEntity";

interface IProps {
	applicationName?: string;
	childrenCount?: number;
	description?: string | null;
	// imageTimestamp is used to force re-rendering of the image when it is changed, as the imageUrl is the same
	imageTimestamp?: number;
	imageUrl?: string | null;
	inheritOwner?: boolean;
	integrationId?: string;
	lastSync?: Date | null;
	name: string;
	onRename?: (value: string) => Promise<void>;
	ownerId?: string | null;
	requestsDisabled?: boolean;
	resourceType: "integration" | "resource" | "role";
	showOwner?: boolean;
	tags?: string[] | null;
	type?: string | null;
	handleButtonClick?: () => void;
	onFileSelection?: (event: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
	fileInputRef?: React.RefObject<HTMLInputElement>;
}

const FILE = "file";
const INPUT_ACCEPTS = ".png, .jpeg, .gif, .bmp, .webp";

const UserWithTooltip: FC<{ user: UserModel; inherit?: boolean }> = ({ user, inherit = false }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	return (
		<UserTooltip user={user}>
			<div className={classes.ownerContainer}>
				{inherit && (
					<Typography className={classes.inheritOwner} variant="tiny">
						{t("shared.integrationDefault")}
					</Typography>
				)}
				<UserEntity size="medium" user={user} withoutEmail noWrap relative />
			</div>
		</UserTooltip>
	);
};

export const ResourceHeader: FC<IProps> = ({
	applicationName,
	childrenCount = null,
	description,
	imageTimestamp,
	imageUrl,
	inheritOwner = false,
	integrationId,
	lastSync,
	name,
	onRename,
	ownerId,
	requestsDisabled,
	resourceType,
	showOwner,
	tags,
	type,
	handleButtonClick,
	onFileSelection,
	fileInputRef
}) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const integrations = useIntegrations();
	const integration = integrations?.get(integrationId || "");

	const userId = useMemo(() => ownerId || integration?.ownerId, [integration, ownerId]);
	const owner = useUser(userId);

	const validators = useMemo(
		() => getNameValidators(`${t(`shared.resourceTypes.${resourceType}`)} ${t("shared.name")}`),
		[resourceType, t]
	);

	const ownerComponents = useMemo(() => {
		if (!showOwner || !owner) return null;
		return { user: <UserWithTooltip user={owner} inherit={inheritOwner} /> };
	}, [inheritOwner, owner, showOwner]);

	const handleFileSelection = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			void onFileSelection?.(event);
		},
		[onFileSelection]
	);

	return (
		<div className={classes.container}>
			<div className={classes.displayName}>
				<div className={classes.name}>
					{imageUrl && (
						<div onClick={handleButtonClick} className={classes.clickable}>
							<LogoAvatar key={imageTimestamp} size="large">
								<img src={imageUrl} />
								<input
									type={FILE}
									accept={INPUT_ACCEPTS}
									ref={fileInputRef}
									className={classes.hiddenInput}
									onChange={handleFileSelection}
								/>
							</LogoAvatar>
						</div>
					)}
					<EditableInput
						className={classes.editableInput}
						value={name}
						resourceType={resourceType}
						onEdit={onRename}
						inputType="name"
						validators={validators}
					/>
				</div>
				{childrenCount !== null && (
					<Typography className={classNames(classes.lighterText, classes.childrenCount)}>
						({t("number", { value: childrenCount })})
					</Typography>
				)}
			</div>
			<div className={classes.headerInfo}>
				{applicationName && (
					<div className={classes.titlePart}>
						<Typography className={classes.lighterText}>
							{t("common.resourceHeader.applicationName", { applicationName })}
						</Typography>
						{requestsDisabled || type || lastSync || ownerComponents ? <Separator /> : null}
					</div>
				)}
				{ownerComponents ? (
					<div className={classes.titlePart}>
						<div className={classNames(classes.lighterText, classes.entityContainer)}>
							<Trans t={t} i18nKey="common.resourceHeader.ownerName" components={ownerComponents} />
						</div>
						{requestsDisabled || type || lastSync ? <Separator /> : null}
					</div>
				) : null}
				{lastSync && (
					<div className={classes.titlePart}>
						<Typography className={classes.lighterText}>
							{t("common.resourceHeader.lastSync", { date: lastSync })}
						</Typography>
						{requestsDisabled || type ? <Separator /> : null}
					</div>
				)}
				{type && (
					<div className={classes.titlePart}>
						<div className={classes.typeContainer}>
							<Typography className={classes.type}>{t("common.resourceHeader.type")}</Typography>
							<Typography>{type}</Typography>
						</div>
						{requestsDisabled ? <Separator /> : null}
					</div>
				)}
				{requestsDisabled && (
					<Typography variant="tiny" className={classes.hiddenResource}>
						({t("pages.integration.requestsDisabled")}
						<Tooltip
							content={
								<Trans t={t} values={{ resourceType }} i18nKey="pages.integration.requestsDisabledDescription" />
							}>
							<InfoIcon className={classes.infoIcon} size="medium" />
						</Tooltip>
						)
					</Typography>
				)}
			</div>
			{description && (
				<div className={classes.descriptionContainer}>
					<Typography variant="h3">{description}</Typography>
				</div>
			)}
			{!!tags?.length && (
				<div className={classes.tagsContainer}>
					<Chips values={tags} limit={8} getOptionLabel={opt => opt} type="tag" size="small" />
				</div>
			)}
		</div>
	);
};
