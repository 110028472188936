import React from "react";
import { CommonAccordion } from "components/common/CommonAccordion";
import { useStyles } from "./styles";

interface IProps {
	defaultRowHeight: number;
	defaultAccordionHeight: number;
	titleContent?: React.ReactNode;
	expanded?: boolean;
	setExpanded?: (expanded: boolean) => void;
	maxRows: number;
	totalResults: number;
	minRows: number;
}

export const FixedHeightCommonAccordion: FC<IProps> = ({
	children,
	defaultRowHeight,
	defaultAccordionHeight,
	titleContent,
	expanded,
	maxRows,
	totalResults,
	minRows,
	setExpanded
}) => {
	const numberOfRows = totalResults > maxRows ? maxRows : totalResults > 0 ? totalResults : minRows;

	const classes = useStyles({ numberOfRows, defaultRowHeight, defaultAccordionHeight });

	return (
		<CommonAccordion
			detailsClassname={classes.accordion}
			title={titleContent}
			expanded={expanded}
			onChange={setExpanded}>
			{children}
		</CommonAccordion>
	);
};
