import React from "react";
import { EntityNodeContent } from "components/common/entities/components/EntityNodeContent";
import { useSelectLabel } from "../useSelectLabel";
import type { TEntityOverrideProps } from "components/ui/selectOptions/SelectOption";
import type { TLabelComponent } from "utils/ui/select";

const TextLabel: TLabelComponent<
	React.ReactNode,
	{ icon?: React.ReactNode; Icon?: IconComponent; variant?: TEntityOverrideProps["variant"] }
> = ({ disabled = false, icon, Icon, value, withIcon, variant: propVariant, innerRef }) => {
	const contentIcon = icon || (Icon && <Icon />);
	const { variant, ...entityProps } = useSelectLabel(disabled, Boolean(contentIcon) || withIcon === true);
	return (
		<EntityNodeContent
			innerRef={innerRef}
			noWrap
			tooltipOnOverflow
			text={value}
			icon={contentIcon}
			variant={propVariant ?? variant}
			{...entityProps}
		/>
	);
};

TextLabel.isSelectLabel = true;

export { TextLabel };
