import { List } from "immutable";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import {
	editIntegrationResourceRole,
	deleteIntegrationResourceRole,
	TIntegrationResourceRole
} from "api/integrationResourceRoles";
import { ApprovalAlgorithmBlock, INHERIT_FROM_PARENT_ID } from "components/common/ApprovalAlgorithmBlock";
import { Breadcrumbs } from "components/common/Breadcrumbs";
import { GiveAccessSection } from "components/common/GiveAccessSection";
import { HasAccessFromSection } from "components/common/HasAccessFromSection";
import { PrerequisitePermissionsBlock } from "components/common/PrerequisitePermissionsBlock";
import { PageTemplate } from "components/templates/PageTemplate";
import { LoadingSpinner } from "components/ui/LoadingSpinner";
import { useIntegrationResource } from "hooks/useIntegrationResource";
import { useIntegrationResourceRole } from "hooks/useIntegrationResourceRole";
import { useIntegrations } from "hooks/useIntegrations";
import { useIsOpenState } from "hooks/useIsOpenState";
import { RoleHeaderBlock } from "./components/RoleHeaderBlock";
import { VirtualizedRoleBlock } from "./components/VirtualizedRoleBlock";
import { useStyles } from "./styles";

export const IntegrationResourceRolePage: FC = ({ className }) => {
	const params = useParams<{ integrationResourceRoleId: string; integrationResourceId: string }>();
	const { role: integrationResourceRole, loadRole } = useIntegrationResourceRole(
		params?.integrationResourceRoleId || ""
	);
	const { resource: integrationResource } = useIntegrationResource(params?.integrationResourceId || "", true);
	const integrations = useIntegrations();
	const areYouSureModal = useIsOpenState();
	const [prerequisitePermissionId, setPrerequisitePermissionId] = useState<string | null>(
		integrationResourceRole?.prerequisitePermissionId || null
	);

	const classes = useStyles();
	const { t } = useTranslation();
	const navigate = useNavigate();

	const integration = integrations?.get(integrationResource?.integrationId || "") || null;
	const canDeleteRole = (integrationResource?.rolesCount || 0) > 1;

	const approvalAlgorithmId =
		integrationResourceRole?.approvalAlgorithmId ||
		integrationResource?.approvalAlgorithmId ||
		integration?.defaultApprovalAlgorithm?.id ||
		null;

	const onEditRole = useCallback(
		async (role: Partial<TIntegrationResourceRole>) => {
			if (!params?.integrationResourceRoleId) {
				return;
			}
			await editIntegrationResourceRole({ id: params.integrationResourceRoleId, ...role });
			await loadRole();
		},
		[loadRole, params.integrationResourceRoleId]
	);

	const onDeleteRole = useCallback(async () => {
		if (integrationResourceRole && canDeleteRole) {
			await deleteIntegrationResourceRole(integrationResourceRole.id);
		}
		areYouSureModal.close();
		if (!integrationResource || !integration) {
			return;
		}
		navigate(`/integrations/${integration.id}/resources/${integrationResource.id}`);
	}, [integrationResourceRole, canDeleteRole, areYouSureModal, integrationResource, integration, navigate]);

	const onApprovalAlgorithmChange = useCallback(
		(id: string) => void onEditRole({ approvalAlgorithmId: id === INHERIT_FROM_PARENT_ID ? null : id }),
		[onEditRole]
	);

	const onRename = useCallback((name: string) => onEditRole({ name }), [onEditRole]);

	const roleList = useMemo(() => integrationResourceRole && List([integrationResourceRole]), [integrationResourceRole]);

	useEffect(() => {
		if (integrationResourceRole) setPrerequisitePermissionId(integrationResourceRole.prerequisitePermissionId);
	}, [integrationResourceRole]);

	const totalAccessCount = useMemo(() => {
		return (
			roleList?.reduce((sum, role) => {
				return sum + (role?.hasAccessFromResources?.size || 0) + (role?.hasAccessFromRoles?.size || 0);
			}, 0) || 0
		);
	}, [roleList]);

	const showGivesAccessTable = useMemo(
		() =>
			(integrationResource &&
				integration &&
				!integration.virtual &&
				integrationResourceRole &&
				integrationResourceRole.givesAccessToRoles &&
				integrationResourceRole.givesAccessToRoles.size !== 0) ||
			integration?.manual,
		[integrationResourceRole, integrationResource, integration]
	);
	const showHasAccessFromTable = useMemo(
		() =>
			(integrationResourceRole &&
				integrationResource &&
				integration &&
				!integration.virtual &&
				(integrationResourceRole.hasAccessFromResources || integrationResourceRole.hasAccessFromRoles) &&
				totalAccessCount &&
				totalAccessCount > 0) ||
			integration?.manual,
		[integrationResourceRole, integrationResource, integration, totalAccessCount]
	);

	const breadcrumbs = useMemo(() => {
		if (!integration || !integrationResource || !integrationResourceRole) return [];
		return [
			{
				title: t("navigation.integrations"),
				url: "/integrations"
			},
			{
				title: integration.name,
				url: `/integrations/${integration.id}`
			},
			{
				title: integrationResource.name,
				url: `/integrations/${integration.id}/resources/${integrationResource.id}`
			},
			{
				title: integrationResourceRole.name
			}
		];
	}, [integration, integrationResource, integrationResourceRole, t]);

	return (
		<PageTemplate subPage className={className}>
			<PageTemplate.Title>
				<Breadcrumbs parts={breadcrumbs} />
			</PageTemplate.Title>
			<PageTemplate.Content noBorder noBackground className={classes.page}>
				{integration && integrationResource && integrationResourceRole ? (
					<div className={classes.rolePage}>
						<RoleHeaderBlock
							integrationResourceRole={integrationResourceRole}
							integrationResource={integrationResource}
							integration={integration}
							manual={integration.manual}
							virtual={integration.virtual}
							onSave={onEditRole}
							onRename={onRename}
							onDeleteButtonClick={integration.adapterless && canDeleteRole ? onDeleteRole : undefined}
						/>
						<div className={classes.content}>
							{approvalAlgorithmId && (
								<ApprovalAlgorithmBlock
									rounded
									approvalAlgorithmId={approvalAlgorithmId}
									onChangeAlgorithm={onApprovalAlgorithmChange}
									inheritOptionText={t("pages.integration.resource.inherit")}
								/>
							)}
							{showGivesAccessTable && roleList && <GiveAccessSection givesAccessRoles={roleList} />}
							{showHasAccessFromTable && roleList && (
								<HasAccessFromSection
									roles={roleList}
									totalAccessCount={totalAccessCount}
									integrationResource={integrationResource}
								/>
							)}
							{integration && !integration.virtual && (
								<PrerequisitePermissionsBlock
									prerequisitePermissionId={prerequisitePermissionId}
									afterAction={setPrerequisitePermissionId}
									asset={{
										prohibitedIds: {
											integrationResourceIds: !integrationResource.multirole ? [integrationResource.id] : null,
											integrationResourceRoleIds: [integrationResourceRole.id]
										},
										id: integrationResourceRole.id,
										name: integrationResourceRole.name,
										type: "integrationResourceRoles"
									}}
								/>
							)}
							{integration && integration.virtual && integrationResourceRole?.virtualizedRoleId && (
								<VirtualizedRoleBlock virtualizedRoleId={integrationResourceRole.virtualizedRoleId} />
							)}
						</div>
					</div>
				) : (
					<LoadingSpinner className={classes.spinner} />
				)}
			</PageTemplate.Content>
		</PageTemplate>
	);
};
