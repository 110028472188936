import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Chip } from "components/ui/chips/Chip";
import { StaticChip } from "components/ui/chips/StaticChip";
import { RequestsIcon } from "components/ui/Icons/RequestsIcon";
import { TitleBody } from "components/ui/TitleBody";
import { Tooltip } from "components/ui/Tooltip";

export const RequestNumberChip: FC<{ requestNumber: number; onClick?: () => void }> = ({ requestNumber, onClick }) => {
	const { t } = useTranslation();

	const tooltipContent = useMemo(() => {
		return <TitleBody title={t("common.requestDateAndNumber.requestNumber")} body={requestNumber} />;
	}, [t, requestNumber]);

	const ChipComponent = onClick ? Chip : StaticChip;

	return (
		<Tooltip content={tooltipContent}>
			<ChipComponent size="medium" variant="regular" PrefixIcon={RequestsIcon} onClick={onClick}>
				{t("number", { value: requestNumber })}
			</ChipComponent>
		</Tooltip>
	);
};
