import React from "react";
import { makeIcon } from "components/ui/Icon";

export const IndirectAccessIcon = makeIcon(
	<svg viewBox="0 0 24 24" fill="none">
		<path
			d="M3 13.6668C3 13.3835 3.09583 13.146 3.2875 12.9543C3.47917 12.7626 3.71667 12.6668 4 12.6668L9.3 12.6668C8.66667 12.2001 8.06667 11.671 7.5 11.0793C6.93333 10.4876 6.39167 9.81679 5.875 9.06679C5.69167 8.80012 5.60833 8.51262 5.625 8.20429C5.64167 7.89595 5.76667 7.62512 6 7.39179C6.18333 7.20845 6.4125 7.14595 6.6875 7.20429C6.9625 7.26262 7.18333 7.41679 7.35 7.66679C8.05 8.71679 9.04583 9.81262 10.3375 10.9543C11.6292 12.096 13.1833 12.6668 15 12.6668L17.175 12.6668L16.3 11.7668C16.1167 11.5835 16.025 11.3501 16.025 11.0668C16.025 10.7835 16.1167 10.5501 16.3 10.3668C16.5 10.1668 16.7375 10.071 17.0125 10.0793C17.2875 10.0876 17.5167 10.1835 17.7 10.3668L20.3 12.9668C20.4 13.0668 20.4708 13.1751 20.5125 13.2918C20.5542 13.4085 20.575 13.5335 20.575 13.6668C20.575 13.8001 20.5542 13.9251 20.5125 14.0418C20.4708 14.1585 20.4 14.2668 20.3 14.3668L17.7 16.9668C17.5167 17.1501 17.2833 17.2418 17 17.2418C16.7167 17.2418 16.4833 17.1501 16.3 16.9668C16.1 16.7668 16 16.5293 16 16.2543C16 15.9793 16.0917 15.7501 16.275 15.5668L17.175 14.6668L4 14.6668C3.71667 14.6668 3.47917 14.571 3.2875 14.3793C3.09583 14.1876 3 13.9501 3 13.6668Z"
			fill="#272931"
		/>
	</svg>
);
