import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { GroupIcon } from "components/ui/Icons/GroupIcon";
import { TooltipOnOverflow } from "components/ui/TooltipOnOverflow";
import { useDirectoryGroup } from "hooks/useDirectoryGroup";
import { useDirectoryGroupInfo } from "hooks/useGroupInfo";
import { DirectoryGroupModel } from "models/DirectoryGroupModel";
import { useStyles } from "./styles";
import { GroupNode } from "../entities/Nodes/GroupNode";
import type { TEntityNodeColor } from "../entities/Nodes/EntityNode";

type TGroupProps = {
	content?: never;
	group: DirectoryGroupModel | string;
};

type TContentProps = {
	content: React.ReactNode;
	group?: never;
};

type TProps = (TGroupProps | TContentProps) & {
	actions?: React.ReactNode;
	color?: TEntityNodeColor;
	delayShowTooltip?: number;
	highlighted?: boolean;
	fixedWidth?: boolean;
	icon?: React.ReactNode;
	isDeleted?: boolean;
	onDelete?: () => void;
	selected?: boolean;
};

export const GroupCard: FC<TProps> = ({
	className,
	content,
	delayShowTooltip,
	highlighted,
	group,
	icon,
	isDeleted,
	...nodeProps
}) => {
	const { t } = useTranslation();
	const classes = useStyles();

	const directoryGroup = useDirectoryGroup(typeof group === "string" ? group : "");
	const directoryGroupModel = group instanceof DirectoryGroupModel ? group : directoryGroup;
	const { name, Icon } = useDirectoryGroupInfo(directoryGroupModel?.name || "");
	const deleted = isDeleted || directoryGroupModel?.isDeleted;

	const groupIcon = useMemo(() => {
		if (icon) return icon;
		if (Icon) {
			return <Icon className={classes.icon} />;
		}
		return <GroupIcon className={classes.icon} />;
	}, [Icon, classes.icon, icon]);

	const nodeContent = useMemo(() => {
		if (content) return content;
		if (!directoryGroupModel) return t("common.unknown.group");
		return <TooltipOnOverflow delayShow={delayShowTooltip} textVariant="text_sm_sb" content={name} />;
	}, [content, delayShowTooltip, directoryGroupModel, t, name]);

	return (
		<GroupNode
			content={nodeContent}
			delayShowTooltip={delayShowTooltip}
			icon={groupIcon}
			className={className}
			highlighted={highlighted}
			iconAsImg={!!Icon}
			isDeleted={deleted}
			{...nodeProps}
		/>
	);
};
