import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	tooltip: {
		extend: "baseTooltip",
		backgroundColor: "var(--tooltip-background-color)",
		borderRadius: "var(--border-radius-sharp)",
		border: "var(--tooltip-border)",
		boxShadow: "var(--tooltip-box-shadow)",
		color: "var(--typography-primary-font-color)",
		minWidth: "var(--tooltip-min-width)",
		padding: "var(--tooltip-padding)",
		minHeight: "var(--tooltip-min-height)",
		maxWidth: "var(--tooltip-max-width)",
		boxSizing: "border-box",
		display: "flex",
		justifyContent: "center",
		alignItems: "center"
	},
	baseTooltip: {
		zIndex: 1000
	}
});
