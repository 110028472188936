import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { IconButton } from "components/ui/IconButton";
import { DeleteIcon } from "components/ui/Icons/DeleteIcon";
import { LoadingSpinner } from "components/ui/LoadingSpinner";
import { useStyles } from "./styles";
import type { TMaintainer } from "components/pages/IntegrationPage/components/MaintainersSection";

type TProps = {
	maintainer: TMaintainer;
	removeMaintainer: (maintainer: TMaintainer) => Promise<void>;
	isLoading: boolean;
};

export const ActionCell: FC<TProps> = ({ maintainer, removeMaintainer, isLoading }) => {
	const classes = useStyles();
	const { t } = useTranslation();

	const handleMaintainerRemove = useCallback(async () => {
		await removeMaintainer(maintainer);
	}, [maintainer, removeMaintainer]);

	return (
		<div className={classes.actionsCell}>
			{!isLoading ? (
				<IconButton
					size="medium"
					onClick={handleMaintainerRemove}
					tooltip={t(`common.tables.maintainers.deleteMaintainer`)}>
					<DeleteIcon />
				</IconButton>
			) : (
				<div className={classes.spinner}>
					<LoadingSpinner size={20} />
				</div>
			)}
		</div>
	);
};
