import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { getIntegrationAuditLogs } from "api/integrationAuditLogs";
import { FixedHeightCommonAccordion } from "components/common/FixedHeightCommonAccordion";
import { IconPrefix } from "components/ui/IconPrefix";
import { AuditLogsIcon } from "components/ui/Icons/AuditLogsIcon";
import { Typography } from "components/ui/Typography";
import { useLoadingState } from "hooks/useLoadingState";
import { DESC, usePagination } from "hooks/usePagination";
import { IntegrationModel } from "models/IntegrationModel";
import { AuditLogsTable } from "./components/AuditLogsTable";
import { useStyles } from "./styles";
import type { IPaginatedSearchOptions } from "utils/searchUtils";

interface IProps {
	integration: IntegrationModel;
}
const PAGE_SIZE = 15;
const DEFAULT_ROW_HEIGHT_PX = 60;
const DEFAULT_ACCORDION_HEIGHT_PX = 180;
const MIN_ROWS = 3;

const TRANSLATION_PREFIX = "pages.integration.auditLogsBlock";

export const AuditLogsSection: FC<IProps> = ({ integration }) => {
	const { isLoading: isLoadingAuditLogs, withLoader: withAuditLogsLoader } = useLoadingState();
	const { t } = useTranslation();
	const classes = useStyles();

	const fetchAuditLogs = useCallback(
		async (paginationOptions: IPaginatedSearchOptions) =>
			(integration && (await getIntegrationAuditLogs(integration.id, paginationOptions))) || Promise.resolve([]),
		[integration]
	);

	const {
		totalResults,
		getPage,
		items: auditLogs,
		lastPageNumber,
		totalPages
	} = usePagination({
		fetchItems: fetchAuditLogs,
		perPage: PAGE_SIZE,
		sortOrder: DESC,
		initialFilter: null
	});

	const getNextPage = useCallback(async () => {
		if (!isLoadingAuditLogs) {
			await withAuditLogsLoader(getPage(lastPageNumber + 1));
		}
	}, [getPage, isLoadingAuditLogs, lastPageNumber, withAuditLogsLoader]);

	const titleContent = useMemo(
		() => (
			<div className={classes.titleContainer}>
				<div className={classes.titlePart}>
					<IconPrefix Icon={AuditLogsIcon} content={t(`${TRANSLATION_PREFIX}.title`)} className={classes.icon} />
					<Typography variant="body_reg">{`( ${t("number", { value: totalResults || 0 })} )`}</Typography>
				</div>
			</div>
		),
		[classes.icon, classes.titleContainer, classes.titlePart, t, totalResults]
	);

	return (
		<FixedHeightCommonAccordion
			maxRows={PAGE_SIZE}
			minRows={MIN_ROWS}
			totalResults={totalResults}
			titleContent={titleContent}
			defaultRowHeight={DEFAULT_ROW_HEIGHT_PX}
			defaultAccordionHeight={DEFAULT_ACCORDION_HEIGHT_PX}>
			<AuditLogsTable
				lastPageNumber={lastPageNumber}
				totalPages={totalPages}
				auditLogs={auditLogs}
				fetchAuditLogs={getNextPage}
			/>
		</FixedHeightCommonAccordion>
	);
};
