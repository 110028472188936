import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Input } from "components/ui/Input";
import { usePersonalAccessTokensContext } from "context/personalAccessTokensContext";
import ApiError from "utils/errors/apiError";
import { checkIfTokenIsNew } from "../../../utils";
import type { TPersonalAccessToken } from "../../../PersonalAccessTokensTableSection";

type TProps = {
	token: TPersonalAccessToken;
	tokenName?: string;
	setNewTokenName: (name: string) => void;
	setError: (error: Error | null) => void;
};

const MIN_TOKEN_NAME_LENGTH = 2;
const MAX_TOKEN_NAME_LENGTH = 50;
const PROFILE_PAGE_TRANSLATION_PREFIX = "pages.profile.changeProfileForm.personalAccessTokens";
export const TokenNameCell: FC<TProps> = ({ token, tokenName, setNewTokenName, setError }) => {
	const [currentNewTokenName, setCurrentNewTokenName] = useState<string | undefined>(tokenName);
	const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
	const { t } = useTranslation();

	const {
		actions: { editToken }
	} = usePersonalAccessTokensContext();

	const errors = useMemo(() => (errorMessage ? [errorMessage] : []), [errorMessage]);

	const handleValueSubmitted = useCallback(
		(value: string) => {
			const isNewToken = checkIfTokenIsNew(token);
			if (value.length < MIN_TOKEN_NAME_LENGTH) {
				if (!isNewToken) {
					setCurrentNewTokenName(token.name);
				} else {
					const errorMessage = t(`${PROFILE_PAGE_TRANSLATION_PREFIX}.errors.tokenShortContent`);
					setError(
						new ApiError({
							title: t(`${PROFILE_PAGE_TRANSLATION_PREFIX}.errors.tokenTooShort`),
							message: errorMessage
						})
					);
					setErrorMessage(errorMessage);
				}
			} else if (value.length > MAX_TOKEN_NAME_LENGTH) {
				if (!isNewToken) {
					setCurrentNewTokenName(token.name);
				} else {
					const errorMessage = t(`${PROFILE_PAGE_TRANSLATION_PREFIX}.errors.tokenLongContent`);
					setError(
						new ApiError({
							title: t(`${PROFILE_PAGE_TRANSLATION_PREFIX}.errors.tokenTooLong`),
							message: errorMessage
						})
					);
					setErrorMessage(errorMessage);
				}
			} else if (!isNewToken) {
				if (value !== token.name) {
					void editToken(token.id, value);
				}
			} else {
				setErrorMessage(undefined);
				setError(null);
			}
		},
		[token, editToken, setError, t]
	);
	const handleOnBlur = useCallback(
		(e: React.FocusEvent<HTMLInputElement>) => {
			if (e.relatedTarget && e.relatedTarget.tagName === "BUTTON") return;

			const isNewToken = checkIfTokenIsNew(token);
			if (currentNewTokenName && currentNewTokenName !== "") {
				if (isNewToken) {
					setNewTokenName(currentNewTokenName);
				}
				handleValueSubmitted(currentNewTokenName);
			} else {
				setErrorMessage(undefined);
			}
		},
		[currentNewTokenName, handleValueSubmitted, setNewTokenName, token]
	);

	useEffect(() => {
		if (tokenName) {
			setCurrentNewTokenName(tokenName);
			handleValueSubmitted(tokenName);
		}
	}, [tokenName, handleValueSubmitted]);

	return (
		<Input
			autoFocus={checkIfTokenIsNew(token)}
			value={currentNewTokenName}
			onValueChange={setCurrentNewTokenName}
			onBlur={handleOnBlur}
			variant="table"
			error={Boolean(errorMessage)}
			errors={errors}
			placeholder={t(`${PROFILE_PAGE_TRANSLATION_PREFIX}.newTokenPlaceholder`)}
		/>
	);
};
