import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	errors: {
		color: "var(--color-red-dark)"
	},
	titleContainer: {
		alignItems: "center",
		display: "flex",
		flexDirection: "row",
		gap: "var(--spacing-x2, 8px)",
		justifyContent: "flex-start",
		flexWrap: "nowrap"
	},
	disabled: {
		color: "var(--color-grey-450)"
	}
});
